import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { FdFieldConfig, FdFieldConfigs } from '../../shared-components.module';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { FdSelectConfig } from '../fd-select/fd-select.component';

@Component({
  selector: 'fd-nationality',
  templateUrl: './fd-nationality.component.html',
})
export class FdNationalityComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit {
  @ViewChild('tooltip')
  tooltip: MatTooltip;

  @Input()
  formGroup: FormGroup;

  @Input()
  nationality: any;

  @Input()
  rne: any;

  @Input()
  rneEnabled: any;

  @Input()
  nationalityList = [];

  @Input()
  legalRepresentative: boolean = false;

  fieldsArray = new Array<FdFieldConfigs>();
  rneEnable: boolean;

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setNationalityList();
    this.rneEnable = !this.rneEnabled?.disabled;
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  nationalityChange(event: any) {
    if (!this.formControls) {
      return;
    }
    if (!this.isEmpty(event.value) && event.value !== 'Brasileiro') {
      this.rneEnable = true;
      this.el.nativeElement.querySelector('#nationality').setAttribute('fxflex', '50');
      this.setRneValidation();
    } else {
      this.rneEnable = false;
      this.removeNationalityValidators();
    }
  }

  private removeNationalityValidators(): void {
    if (this.legalRepresentative) {
      this.formControls.legalRepresentativeRne.setValue('');
      this.formControls.legalRepresentativeRne.setErrors(null);
      this.formControls.legalRepresentativeRne.clearValidators();
    } else {
      this.formControls.rne.setValue('');
      this.formControls.rne.setErrors(null);
      this.formControls.rne.clearValidators();
    }
  }

  private setRneValidation(): void {
    if (this.legalRepresentative) {
      this.formControls.legalRepresentativeRne.setValidators([Validators.required]);
    } else {
      this.formControls.rne.setValidators([Validators.required]);
    }
  }

  private isEmpty(str) {
    return !str || str.length === 0;
  }

  listNationality(text: string = '', field: FdFieldConfig) {
    if (field) {
      (field as FdSelectConfig).items = [];
      const itemsToAdd = this.nationalityList
        .filter((item) =>
          item.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .match(text.toLowerCase())
        )
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (field as FdSelectConfig).items.push(...itemsToAdd);
    } else {
      (this.nationality as FdSelectConfig).items = [];

      const itemsToAdd = this.nationalityList
        .filter((item) => item.label)
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (this.nationality as FdSelectConfig).items.push(...itemsToAdd);
    }
  }

  setNationalityList(): void {
    (this.nationality as FdSelectConfig).items = this.nationalityList;
  }
}
