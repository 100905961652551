<mat-card class="fd-delivery-installation-details__card-container" *ngIf="deliveryAndInstallation">
  <mat-card-title class="fd-delivery-installation-details__card-title">Entrega e Instalação</mat-card-title>
  <ng-container>
    <div
      *ngFor="let deliveryData of deliveryAndInstallation; let i = index"
      class="fd-delivery-installation-details__card-content"
      fxLayout="column"
      fxLayoutAlign="center stretch"
    >
      <div fxLayout.xs="column" fxFlexFill class="fd-delivery-installation-details__data_row" fxLayoutGap="10px">
        <div fxFlex="15">
          <strong>Número da OS</strong>
          <div>
            <span>{{ deliveryData?.osNumber }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Número Lógico</strong>
          <div>
            <span>{{ deliveryData?.logicNumber }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Data de Criação</strong>
          <div>
            <span>{{ deliveryData?.createdDate }}</span>
          </div>
        </div>

        <div fxFlex="20">
          <strong>Status da OS</strong>
          <div>
            <span>{{ deliveryData?.osStatus }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="merchantEsitefId && merchantEsitefKey"
      fxLayout="column"
      fxFlexFill
      class="fd-delivery-installation-details__data_row"
      fxLayoutGap="10px"
    >
      <div fxFlex="15">
        <strong>Configurações Carat</strong>
        <div *ngIf="merchantEsitefId === 'CLOVER'">
          <span>Link Pagamento Clover</span>
        </div>
        <div *ngIf="merchantEsitefId !== 'CLOVER'">
          <span>Merchant Key: {{ merchantEsitefKey }}</span>
        </div>
        <div *ngIf="merchantEsitefId !== 'CLOVER'">
          <span>Merchant ID: {{ merchantEsitefId }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="workingDays?.length > 0">
      <mat-card-subtitle class="fd-delivery-installation-details__card-sub-title">Horários de Funcionamento </mat-card-subtitle>
      <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="workingMiddleWeekDays?.length > 0">
        <div fxLayout.xs="column" fxFlexFill class="fd-delivery-installation-details__data_row">
          <div fxFlex="20">
            <span
              >{{ workingMiddleWeekDays[0]?.weekDay | weekDay }} a
              {{ workingMiddleWeekDays[workingMiddleWeekDays?.length - 1]?.weekDay | weekDay }}</span
            >
          </div>
          <div fxFlex="16">
            <span fxFlex="50">De {{ workingMiddleWeekDays[0]?.from }}</span>
            <span fxFlex="50">Até {{ workingMiddleWeekDays[0]?.to }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="workingWeekEndDays?.length > 0">
        <div fxLayout.xs="column" fxFlexFill class="fd-delivery-installation-details__data_row">
          <div fxFlex="20">
            <span>{{ workingWeekEndDays[1]?.weekDay | weekDay }} e {{ workingWeekEndDays[0]?.weekDay | weekDay }}</span>
          </div>
          <div fxFlex="16">
            <span fxFlex="50">De {{ workingWeekEndDays[0]?.from }}</span>
            <span fxFlex="50">Até {{ workingWeekEndDays[0]?.to }}</span>
          </div>
        </div>
      </div>

      <!-- TODO: Necessário ajustes na timeline de entrega. -->
      <!-- <app-delivery-stepper></app-delivery-stepper> -->
    </div>
    <br />
    <div *ngIf="deliveryAddress">
      <mat-card-subtitle class="fd-delivery-installation-details__card-sub-title">Endereço de entrega</mat-card-subtitle>
      <div fxLayout.xs="column" fxFlexFill class="fd-delivery-installation-details__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>CEP</strong>
          <div>
            <span>{{ deliveryAddress?.cep | cep }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Endereço</strong>
          <div>
            <span>{{ deliveryAddress?.address }}</span>
          </div>
        </div>
        <div fxFlex="5">
          <strong>Número</strong>
          <div>
            <span>{{ deliveryAddress?.number }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Complemento</strong>
          <div>
            <span>{{ deliveryAddress?.complement }}</span>
          </div>
        </div>
        <div fxFlex="20">
          <strong>Bairro</strong>
          <div>
            <span>{{ deliveryAddress?.neighborhood }}</span>
          </div>
        </div>
        <div fxFlex="13">
          <strong>Cidade</strong>
          <div>
            <span>{{ deliveryAddress?.city }}</span>
          </div>
        </div>
        <div fxflexfill>
          <strong>UF</strong>
          <div>
            <span>{{ deliveryAddress?.uf }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
