import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';

@Component({
  selector: 'app-option-picker-modal',
  templateUrl: './option-picker-modal.component.html',
  styleUrls: ['./option-picker-modal.component.scss'],
})
export class OptionPickerModalComponent implements OnInit {
  public items: Item[];
  public formGroup: FormGroup;

  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<OptionPickerModalComponent>, @Inject(MAT_DIALOG_DATA) public data: OptionPickerDialogData) {}

  public async ngOnInit() {
    this.items = this.data.items;
    this.formGroup = this.data.formGroup;
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;

    if (item.markAll) {
      this.items.map((x) => (x.selected = item.selected));
    } else {
      const selectedItems = this.items.filter((x) => x.selected && !x.markAll);
      // -1 pra desconsiderar o todos
      this.items.filter((x) => x.markAll).map((x) => (x.selected = selectedItems.length === this.items.length - 1));
    }

    // O valor "todos" não deve ser incluído.
    this.relatedFormControl.setValue(this.items.filter((x) => x.selected && !x.markAll));
  }

  get relatedFormControl(): AbstractControl {
    return this.data.formControl;
  }

  get shouldMarkAll() {
    return !!this.items.find((x) => !!x.markAll && !!x.selected);
  }

  public handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn();
    }
    this.dialogRef.close();
  }

  public handleConfirmClick(): void {
    if (this.data.confirmCallbackFn) {
      this.data.confirmCallbackFn();
    }
    this.dialogRef.close();
  }
}

export interface OptionPickerDialogData {
  items: Item[];
  title: string;
  formControl: AbstractControl;
  formGroup: FormGroup;
  cancelCallbackFn?: () => any;
  confirmCallbackFn?: () => any;
}
