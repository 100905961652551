export class TechnologyPriceDataModel {
  public technology: string;
  public price: number;
}

export class ParamAvailableTechnologyDTO {
  public annualBillingValue: string;
  public idCampaign: number;
  public personType: number;
  public mcc: number;
}
