import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { GatewayAntifraudInformationModel } from '../models/gateway-antifraud-information.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-gateway-antifraud-information',
  templateUrl: './gateway-antifraud-information.component.html',
  styleUrls: ['./gateway-antifraud-information.component.scss'],
})
export class GatewayAntifraudInformationComponent extends FormStep implements OnInit {
  get hasAntifraud() {
    return !!this.formControls.hasAntifraud.value;
  }

  get hasGateway() {
    return !!this.formControls.hasGateway.value;
  }

  get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }

  public STEP_NUMBER = 0;
  public gatewayAntifraudInformationModel: GatewayAntifraudInformationModel;

  @ViewChild('tooltip') public tooltip: MatTooltip;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public getGatewayTooltipText() {
    return 'Forneça os dados de contato do Gateway ou Integrador - \
    E-mail e/ou Telefone/Celular com DDD para envio das informações da integração';
  }
  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.setConditionalValidators();

    if (this.hasSplitTech) {
      this.setValueDefaultSplitTech();
    }
  }

  public setConditionalValidators() {
    this.formControls.antifraudDescription.setValidators([RequiredIfValidator(() => this.hasAntifraud)]);
    this.formControls.gatewayDescription.setValidators([RequiredIfValidator(() => this.hasGateway)]);
    this.formControls.hasChargebackCoverage.setValidators([RequiredIfValidator(() => this.hasAntifraud)]);
  }

  public next(): void {
    if (!this.hasGateway) {
      this.formControls.gatewayDescription.setErrors(null);
    }

    if (!this.hasAntifraud) {
      this.formControls.antifraudDescription.setErrors(null);
      this.formControls.hasChargebackCoverage.setErrors(null);
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const gatewayAntifraudInformation: GatewayAntifraudInformationModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ gatewayAntifraudInformation });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      hasGateway: ['', Validators.required],
      gatewayDescription: [''],
      hasAntifraud: ['', Validators.required],
      antifraudDescription: [''],
      hasChargebackCoverage: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.gatewayAntifraudInformationModel = proposal.gatewayAntifraudInformation;

    if (this.gatewayAntifraudInformationModel) {
      this.formControls.hasGateway.setValue(this.gatewayAntifraudInformationModel.hasGateway);
      this.formControls.gatewayDescription.setValue(this.gatewayAntifraudInformationModel.gatewayDescription);
      this.formControls.antifraudDescription.setValue(this.gatewayAntifraudInformationModel.antifraudDescription);
      this.formControls.hasChargebackCoverage.setValue(this.gatewayAntifraudInformationModel.hasChargebackCoverage);
      this.formControls.hasAntifraud.setValue(this.gatewayAntifraudInformationModel.hasAntifraud);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      hasGateway: {
        controlName: 'hasGateway',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      gatewayDescription: {
        label: 'Descreva',
        maxLength: 3500,
        controlName: 'gatewayDescription',
        messages: {
          required: 'Informe a descrição',
        },
      },
      hasAntifraud: {
        controlName: 'hasAntifraud',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },

      antifraudDescription: {
        label: 'Qual?',
        maxLength: 3500,
        controlName: 'antifraudDescription',
        messages: {
          required: 'Informe qual o seguro antifraude',
        },
      },

      hasChargebackCoverage: {
        controlName: 'hasChargebackCoverage',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
    };
  }

  private setValueDefaultSplitTech() {
    this.formGroup.controls.hasGateway.setValue(false);
    this.formGroup.controls.hasGateway.disable();
    this.formGroup.controls.hasAntifraud.setValue(false);
    this.formGroup.controls.hasAntifraud.disable();
  }
}
