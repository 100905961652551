import { CardTypeEnum } from '../enums/card-type.enum';

export function isCreditCardAmex(value: string) {
  if (!value) {
    console.error('Card number is not in a valid format');
    return;
  }

  const sixFirstDigits = Number(value.replace(/\D/g, '').substring(0, 6));

  if (sixFirstDigits.toString().length < 6) {
    return;
  }

  // AMEX Range
  if (sixFirstDigits >= 340000 && sixFirstDigits <= 379999) {
    return true;
  } else {
    return false;
  }
}

export function getCardFlagPath(value: string) {
  if (!value) {
    console.error('Card number is not in a valid format');
    return;
  }

  if (value.length < 6) {
    return;
  }

  const cardFlag = getCardFlag(value);

  if (cardFlag) {
    return cardFlag.imagePath;
  }
}

export function getCardFlag(value: string) {
  // AMEX Range
  const sixFirstDigits = Number(value.replace(/\D/g, '').substring(0, 6));

  if (sixFirstDigits >= 340000 && sixFirstDigits <= 379999) {
    return CardTypeEnum.AMEX;
  }
  // VISA Range
  else if (sixFirstDigits >= 400000 && sixFirstDigits <= 401177) {
    return CardTypeEnum.VISA;
  } else if (sixFirstDigits >= 401180 && sixFirstDigits <= 431273) {
    return CardTypeEnum.VISA;
  } else if (sixFirstDigits >= 431275 && sixFirstDigits <= 438934) {
    return CardTypeEnum.VISA;
  } else if (sixFirstDigits >= 438936 && sixFirstDigits <= 457392) {
    return CardTypeEnum.VISA;
  } else if (sixFirstDigits >= 457394 && sixFirstDigits <= 457630) {
    return CardTypeEnum.VISA;
  } else if (sixFirstDigits >= 457633 && sixFirstDigits <= 499999) {
    return CardTypeEnum.VISA;
  }

  // CABAL Range
  else if (sixFirstDigits >= 589657 && sixFirstDigits <= 589657) {
    return CardTypeEnum.CABAL;
  } else if (sixFirstDigits >= 603522 && sixFirstDigits <= 603522) {
    return CardTypeEnum.CABAL;
  } else if (sixFirstDigits >= 604200 && sixFirstDigits <= 604499) {
    return CardTypeEnum.CABAL;
  }

  // SOROCRED Range
  else if (sixFirstDigits >= 627892 && sixFirstDigits <= 627892) {
    return CardTypeEnum.SOROCRED;
  } else if (sixFirstDigits >= 636414 && sixFirstDigits <= 636414) {
    return CardTypeEnum.SOROCRED;
  }

  // BANESCARD Range
  else if (sixFirstDigits >= 585988 && sixFirstDigits <= 585988) {
    return CardTypeEnum.BANESCARD;
  } else if (sixFirstDigits >= 603182 && sixFirstDigits <= 603182) {
    return CardTypeEnum.BANESCARD;
  }

  // BANESE Range
  else if (sixFirstDigits >= 636117 && sixFirstDigits <= 636117) {
    return CardTypeEnum.BANESE;
  } else if (sixFirstDigits >= 637470 && sixFirstDigits <= 637470) {
    return CardTypeEnum.BANESE;
  }

  // ELO Range
  else if (sixFirstDigits >= 401178 && sixFirstDigits <= 401179) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 431274 && sixFirstDigits <= 431274) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 438935 && sixFirstDigits <= 438935) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 457393 && sixFirstDigits <= 457393) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 457631 && sixFirstDigits <= 457632) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 504175 && sixFirstDigits <= 504175) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506707 && sixFirstDigits <= 506708) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506715 && sixFirstDigits <= 506715) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506717 && sixFirstDigits <= 506721) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506724 && sixFirstDigits <= 506733) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506739 && sixFirstDigits <= 506748) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506750 && sixFirstDigits <= 506753) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 506774 && sixFirstDigits <= 506778) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509000 && sixFirstDigits <= 509002) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509004 && sixFirstDigits <= 509009) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509012 && sixFirstDigits <= 509029) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509031 && sixFirstDigits <= 509064) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509066 && sixFirstDigits <= 509072) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 509074 && sixFirstDigits <= 509810) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 636297 && sixFirstDigits <= 636297) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 636368 && sixFirstDigits <= 636368) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650405 && sixFirstDigits <= 650439) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650485 && sixFirstDigits <= 650538) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650541 && sixFirstDigits <= 650598) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650700 && sixFirstDigits <= 650718) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650720 && sixFirstDigits <= 650727) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 650901 && sixFirstDigits <= 650978) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 651652 && sixFirstDigits <= 651679) {
    return CardTypeEnum.ELO;
  } else if (sixFirstDigits >= 655000 && sixFirstDigits <= 655058) {
    return CardTypeEnum.ELO;
  }

  // HIPERCARD Range
  else if (sixFirstDigits >= 606282 && sixFirstDigits <= 606282) {
    return CardTypeEnum.HIPERCARD;
  } else if (sixFirstDigits >= 637095 && sixFirstDigits <= 637095) {
    return CardTypeEnum.HIPERCARD;
  } else if (sixFirstDigits >= 637568 && sixFirstDigits <= 637568) {
    return CardTypeEnum.HIPERCARD;
  } else if (sixFirstDigits >= 637599 && sixFirstDigits <= 637599) {
    return CardTypeEnum.HIPERCARD;
  } else if (sixFirstDigits >= 637609 && sixFirstDigits <= 637609) {
    return CardTypeEnum.HIPERCARD;
  } else if (sixFirstDigits >= 637612 && sixFirstDigits <= 637612) {
    return CardTypeEnum.HIPERCARD;
  }

  // MASTERCARD Range
  else if (sixFirstDigits >= 222100 && sixFirstDigits <= 272099) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 500000 && sixFirstDigits <= 504174) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 504176 && sixFirstDigits <= 506706) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506709 && sixFirstDigits <= 506714) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506716 && sixFirstDigits <= 506716) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506722 && sixFirstDigits <= 506723) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506734 && sixFirstDigits <= 506738) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506749 && sixFirstDigits <= 506749) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506754 && sixFirstDigits <= 506773) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 506779 && sixFirstDigits <= 508999) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509003 && sixFirstDigits <= 509003) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509010 && sixFirstDigits <= 509011) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509030 && sixFirstDigits <= 509030) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509065 && sixFirstDigits <= 509065) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509073 && sixFirstDigits <= 509073) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 509811 && sixFirstDigits <= 585987) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 585989 && sixFirstDigits <= 589656) {
    return CardTypeEnum.MASTERCARD;
  } else if (sixFirstDigits >= 589658 && sixFirstDigits <= 599999) {
    return CardTypeEnum.MASTERCARD;
  } else {
    return;
  }
}
