import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { FormStep } from 'src/app/order/components/form-step';
import { ProfessionalTypeEnum } from 'src/app/order/enums/professional-type.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Invoice, ProfessionalLicense, Proposal } from 'src/app/shared/models/proposal';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';

@Component({
  selector: 'app-nota-fiscal',
  templateUrl: './nota-fiscal.component.html',
  styleUrls: ['./nota-fiscal.component.scss'],
})
export class NotaFiscalComponent extends FormStep implements OnInit {
  get profTypeSelect() {
    return this.formControls.licenseTypeName.value;
  }

  @ViewChild('myTable') public table: MatTable<any>;

  public invoiceValuesTotal: number = 0.0;

  public invoices: Invoice[] = [];

  protected STEP_NUMBER: number = 0;

  protected CHANNEL_ISO_BIN = '010';
  protected INSTITUTION_BIN = '00000003';
  protected INSTITUTION_FISERV = '00000007';
  protected CHANNEL_ISO_FISERV = '040';
  protected SERVICE_CONTRACT_ISO_FISERV = 125;

  constructor(
    private formBuilder: FormBuilder,
    private cnaeService: CnaeService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public formatDate(dateStr: string): string {
    let valid = moment(dateStr, 'DD/MM/YYYY', true).isValid();
    if (valid) {
      return dateStr;
    }

    valid = moment(dateStr, 'DDMMYYYY', true).isValid();
    if (valid) {
      const dateValue = moment(dateStr, 'DDMMYYYY').toDate();
      return moment(dateValue).format('DD/MM/YYYY');
    }
    return dateStr;
  }

  public validateInclude(): boolean {
    if (this.isFormGroupValid()) {
      if (this.invoices.length === 3) {
        this.dialogService.openDialog(Messages.MAX_INVOICES);
        return false;
      }
      let i = 0;
      for (const invoice of this.invoices) {
        if (invoice.invoiceNumber === this.formControls.invoiceNumber.value) {
          this.dialogService.openDialog(Messages.INVOICE_NUMBER_EXIST);
          return false;
        }
        i++;
      }

      if (!this.validateDate()) {
        this.dialogService.openDialog(Messages.INVALID_INVOICE_DATE);
        return false;
      }

      const dayCount = this.validateForm();
      if (dayCount < 0) {
        this.dialogService.openDialog(Messages.PURCHASE_DATE_GREATER_THAN_TODAY);
        return false;
      } else if (dayCount > 90) {
        this.dialogService.openDialog(Messages.PURCHASE_DATE_GREATER_THAN_90_DAYS);
        return false;
      }

      const invoiceV = this.formControls.invoiceValue.value;
      if (invoiceV <= 0) {
        this.dialogService.openDialog(Messages.INVOICE_VALUE_ZERO);
        return false;
      }

      if (this.formControls.invoiceNumber.value == '') {
        this.dialogService.openDialog(Messages.INVOICE_NUMBER_EMPTY);
        return false;
      }

      if (this.formControls.productType.value == '') {
        this.dialogService.openDialog(Messages.INVOICE_PRODUCT_EMPTY);
        return false;
      }

      return true;
    }

    return false;
  }

  public include() {
    if (this.validateInclude()) {
      const invoice = new Invoice();
      invoice.invoiceNumber = this.formControls.invoiceNumber.value;
      invoice.invoiceValue = this.formControls.invoiceValue.value;
      invoice.productType = this.formControls.productType.value;
      invoice.invoiceDate = this.formControls.invoiceDate.value;
      this.invoices.push(invoice);
      this.clearFormControls();
      this.renderTable();
    }
  }

  public renderTable() {
    this.calculateValues();
    this.table.renderRows();
    this.lockFields(this.invoices.length === 3);
  }

  public lockFields(lock: boolean) {
    if (lock) {
      this.formControls.invoiceNumber.disable();
      this.formControls.invoiceValue.disable();
      this.formControls.productType.disable();
      this.formControls.invoiceDate.disable();
    } else {
      this.formControls.invoiceNumber.enable();
      this.formControls.invoiceValue.enable();
      this.formControls.productType.enable();
      this.formControls.invoiceDate.enable();
    }
  }
  public calculateValues() {
    let values = 0.0;
    for (const invoice of this.invoices) {
      values = values + invoice.invoiceValue;
    }

    this.invoiceValuesTotal = values;
  }

  public clearFormControls() {
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.formGroup.updateValueAndValidity();

    this.formControls.invoiceNumber.setValue(null);
    this.formControls.invoiceValue.setValue(null);
    this.formControls.productType.setValue(null);
    this.formControls.invoiceDate.setValue(null);
  }

  public validateIncludea(nroNf: number): boolean {
    this.isFormGroupValid();
    let i = 0;
    for (const invoice of this.invoices) {
      if (invoice.invoiceNumber === nroNf) {
        this.invoices.splice(i, 1);
        return false;
      }
      i++;
    }
    return true;
  }

  public remove(nroNf: number) {
    let i = 0;
    for (const invoice of this.invoices) {
      if (invoice.invoiceNumber === nroNf) {
        this.invoices.splice(i, 1);
        break;
      }
      i++;
    }

    this.renderTable();
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.reloadProposal();
  }

  public back(): void {
    this.wizardService.goToSpecificStep(2, 2);
  }

  public next(): void {
    this.submitted = true;

    if (this.isIsoHierarchy()) {
      if (this.invoices.length > 0) {
        if (this.invoiceValuesTotal < 400) {
          this.dialogService.openDialog(Messages.MAX_TOTAL_VALUE_INVOICES);
          return;
        }
      }
    } else {
      if (this.invoiceValuesTotal < 400) {
        this.dialogService.openDialog(Messages.MAX_TOTAL_VALUE_INVOICES);
        return;
      }
    }

    this.setStepJump(
      orderRoutingDefinitions.offer.stepNumber,
      orderRoutingDefinitions.offer.routes.INVOICE.order,
      this.profTypeSelect !== ProfessionalTypeEnum.INVOICE
    );
    this.setStepJump(
      orderRoutingDefinitions.offer.stepNumber,
      orderRoutingDefinitions.offer.routes.PROFESSIONAL_COUNCIL.order,
      this.profTypeSelect !== ProfessionalTypeEnum.PROFESSIONAL_COUNCIL
    );
    this.setStepJump(
      orderRoutingDefinitions.offer.stepNumber,
      orderRoutingDefinitions.offer.routes.OPERATING_LICENSE.order,
      this.profTypeSelect !== ProfessionalTypeEnum.OPERATING_LICENSE
    );

    this.persistData().then((persisted) => {
      if (persisted) {
        this.wizardService.nextStep();
      }
    });
  }

  public validateForm(): number {
    const invoiceDateForm = this.formControls.invoiceDate.value;
    const invoiceDateMoment = moment(invoiceDateForm, 'DD/MM/YYYY');
    const todayTemp = moment();
    const todayMoment = moment({
      year: todayTemp.year(),
      month: todayTemp.month(),
      day: todayTemp.date(),
    });

    const days = moment.duration(todayMoment.diff(invoiceDateMoment)).asDays();

    return days;
  }

  public reloadProposal() {
    this.invoices = this.proposal.professionalLicense.invoiceValues;
    if (this.invoices === undefined) {
      this.invoices = [];
    } else {
      this.calculateValues();
    }
  }

  public isIsoBin(data: UserDataModel): boolean {
    return this.CHANNEL_ISO_BIN && data.institution === this.INSTITUTION_BIN;
  }

  public isIsoFiserv(data: UserDataModel): boolean {
    return (
      data.channelType === this.CHANNEL_ISO_FISERV &&
      data.institution === this.INSTITUTION_FISERV &&
      data.serviceContract === this.SERVICE_CONTRACT_ISO_FISERV
    );
  }
  protected proposalGetCallback(): void {}

  protected createFields(): FdFieldConfigs {
    return {
      licenseTypeName: {
        label: 'Tipo Documento',
        controlName: 'licenseTypeName',
        messages: {
          required: 'Informe o tipo de conta',
        },
      },

      invoiceNumber: {
        label: 'Número da Nota Fiscal',
        controlName: 'invoiceNumber',
        mask: OnlyNumberMask,
        tooltipMessage: 'Informe o número da nota fiscal que consta no documento',
        maxLength: 10,
        messages: {
          required: 'Informe o numero da nota fiscal',
        },
      },

      invoiceValue: {
        label: 'Valor da Nota Fiscal',
        controlName: 'invoiceValue',
        messages: {
          required: 'Informe o valor da nota fiscal',
        },
      },

      productType: {
        label: 'Tipo de Produto',
        controlName: 'productType',
        tooltipMessage: 'tipo ou categoria dos produtos que constam na nota fiscal, exemplo: alimentos, eletrônicos',
        maxLength: 50,
        messages: {
          required: 'Informe a descrição dos produtos/serviços da nota fiscal',
        },
      },

      invoiceDate: {
        label: 'Data de Emissão',
        controlName: 'invoiceDate',
        mask: DateMask,
        tooltipMessage: 'Informe a data de emissão da nota',
        messages: {
          invalid: 'Informe uma data válida',
          required: 'Informe a data de emissão da nota fiscal',
        },
      },
    };
  }

  protected persistData(): Promise<boolean> {
    const professionalLicense = new ProfessionalLicense();
    professionalLicense.invoiceValues = this.invoices;
    professionalLicense.licenseTypeName = 'INVOICE';

    for (const inv of professionalLicense.invoiceValues) {
      const dateTmp = moment(inv.invoiceDate, 'DD/MM/YYYY');
      const dateStr = moment(dateTmp).format('DDMMYYYY');
      inv.invoiceDate = dateStr;
    }

    return this.dataStore.updateProposal({ professionalLicense });
  }

  protected updateFields(proposal: Proposal): void {}

  protected createFormGroup(): FormGroup {
    if (this.isIsoHierarchy()) {
      return this.formBuilder.group({
        licenseTypeName: [''],
        invoiceNumber: [''],
        invoiceValue: ['', Validators.min(0)],
        productType: [''],
        invoiceDate: [''],
      });
    }

    return this.formBuilder.group({
      licenseTypeName: [''],
      invoiceNumber: ['', Validators.required],
      invoiceValue: ['', Validators.required],
      productType: ['', Validators.required],
      invoiceDate: ['', Validators.required],
    });
  }

  private isIsoHierarchy(): boolean {
    const data = this.dataStoreService.getUserData();
    return this.isIsoBin(data) || this.isIsoFiserv(data);
  }

  private validateDate(): boolean {
    const isValidDate = this.formControls.invoiceDate.value;
    return moment(isValidDate, 'DD/MM/YYYY', true).isValid();
  }
}
