import { RouteRootDefinitions } from 'src/app/order/routing/routing-definitions';

export const startRoutingDefinitions: RouteRootDefinitions = {
    start: {
        routes: {
            PROPOSAL_HISTORY: {
                parentRoute: 'start',
                name: 'proposal-history'
            },
            PROPOSAL_DETAIL: {
                parentRoute: 'start',
                name: 'proposal-detail'
            },
            WELCOME: {
                parentRoute: 'start',
                name: 'welcome'
            },
            CAMPAIGN_SIMULATOR: {
                parentRoute: 'start',
                name: 'campaign-simulator'
            }
        }
    },
};
