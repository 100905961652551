import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { HierarchyComplementaryInformationModel } from '../../shared/models/user-data.model';
import { RouteJump } from '../services/internal/wizard/wizard.service';

export interface LocalStorageData {
  [proposalDraftKey: string]: LocalStorageAgentData;
}

export interface LocalStorageAgentData {
  proposal?: Proposal;
  jumpSteps?: RouteJump[];
  proposalExpirationDate?: string;
  dropdownItems?: DropdownItemsStore[];
  routeStatus?: RouteData[];
}

export interface RouteData {
  routeName: string;
  currentData?: any;
  step: number;
  subStep: number;
}

export class SessionInformation {
  public name: string;
  public sessionToken: string;
}

export class DropdownItemsStore {
  public key: string;
  public items: Item[];
}
