<h4 class="fd-subtitle">{{ isPrepaymentEnabled ? 'Taxas MDR Antecipação' : 'Taxas MDR' }}</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <table mat-table [dataSource]="dataSource" class="fd-table">
    <!-- ID Column -->
    <ng-container matColumnDef="flag" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let row">{{ row.flag }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="transaction" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Transação</th>
      <td mat-cell *matCellDef="let row">{{ row.transaction }}</td>
    </ng-container>

    <ng-container *ngIf="isCnaeLoterica" matColumnDef="tariffValue" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Tarifa</th>
      <ng-container *matCellDef="let row">
        <td mat-cell *ngIf="row.feeType === 'CURRENCY'">R${{ row.tariffValue }}</td>
        <td mat-cell *ngIf="row.feeType === 'TAX'">--</td>
      </ng-container>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="fee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Taxa</th>
      <ng-container *matCellDef="let row; let i = index">
        <td mat-cell #row *ngIf="row.feeType === 'TAX' || !isCnaeLoterica">
          <div formArrayName="feeForms" class="fd-table__form-container" style="position: relative">
            <div [formGroupName]="i">
              <fd-fee-editor
                (openDialogFn)="openFeeEditorModal($event, i)"
                [canEdit]="!hasSplitTech"
                [parentForm]="feeFormsControls[i]"
                [field]="fieldsArray[i].suggestedMdrFee"
              >
              </fd-fee-editor>

              <ng-container *ngIf="shouldShowAlert(i)">
                <fa-icon
                  matTooltipClass="fd-input__tooltip-dialog"
                  class="tooltip-icon"
                  icon="info-circle"
                  size="1x"
                  mat-button
                  [matTooltip]="UNDER_MINIMUM_LIMIT_TEXT"
                  #tooltip="matTooltip"
                  (click)="showTooltipAndStopPropagation()"
                >
                </fa-icon>
              </ng-container>
            </div>
          </div>
        </td>
        <td mat-cell #row *ngIf="row.feeType === 'CURRENCY' && isCnaeLoterica">--</td>
      </ng-container>
    </ng-container>

    <!-- Color Column -->

    <ng-container *ngIf="isCnaeLoterica">
      <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumnsLotteric"></tr>
      <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumnsLotteric"></tr>
    </ng-container>
    <ng-container *ngIf="!isCnaeLoterica">
      <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
    </ng-container>
  </table>
</form>

<div [ngClass]="{ hidden: !isPrepaymentEnabled }">
  <h4 class="fd-subtitle">
    {{ isAnticipationExternal ? 'Taxa de antecipação automática com agenda externa' : 'Taxa de antecipação automática' }}
  </h4>
  <app-prepayment-table (backAction)="back()"></app-prepayment-table>
</div>

<div *ngIf="isCEF" class="cef-disclaimer">
  As condições comerciais apresentadas nesta simulação referem-se ao item 6 da Solicitação de Credenciamento à CAIXA Pagamentos/FISERV,
  estabelecidas de acordo com o faturamento informado para o novo domicílio e o ramo de atividade do cliente, as quais estão sujeitas a
  aprovação da credenciadora.
</div>
<fd-button *ngIf="!isCEF" fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
<fd-button *ngIf="isCEF" [content]="'finalizar'" (trigger)="saveSimulation()"> </fd-button>

<fd-button *ngIf="!isCEF" [content]="'avançar'" (trigger)="next()"> </fd-button>
