import { AnimationEvent } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { clockRotate } from '../../animations/fd-animations';

@Component({
  selector: 'app-pending-proposal',
  templateUrl: './pending-proposal.component.html',
  styleUrls: ['./pending-proposal.component.scss'],
  animations: [clockRotate],
})
export class PendingProposalComponent implements OnInit {
  public isLoop = false;
  public statusState: string;

  constructor(private routingService: RoutingService) {}

  public async ngOnInit() {
    setTimeout(() => this.onLoopState(), 0);
  }

  public onAnimationDone(event: AnimationEvent): void {
    if (event.fromState !== 'void' && this.isLoop) {
      setTimeout(() => {
        this.statusState === ClockAnimationStates.START_STATE
          ? (this.statusState = ClockAnimationStates.LOOP_STATE)
          : (this.statusState = ClockAnimationStates.START_STATE);
      }, 0);
    }
  }

  public onLoopState() {
    this.isLoop = true;
    this.statusState = ClockAnimationStates.LOOP_STATE;
  }

  public navigateToHome() {
    this.routingService.navigateToHome();
  }
}

export enum ClockAnimationStates {
  LOOP_STATE = 'loop-state',
  START_STATE = 'start-state',
}
