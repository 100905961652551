import { Attribute, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export enum ButtonSize {
  NORMAL = 'normal',
  THIN = 'thin',
}
@Component({
  selector: 'fd-button',
  templateUrl: './fd-button.component.html',
  styleUrls: ['./fd-button.component.scss'],
})
export class FdButtonComponent implements OnInit {
  public fullWidth: boolean;
  public autoWidth: boolean;
  public primary: boolean;
  public secondary: boolean;
  public tertiary: boolean;

  @Input()
  public content: string;

  @Input()
  public size: ButtonSize = ButtonSize.NORMAL;

  get isThin(): boolean {
    return this.size === ButtonSize.THIN;
  }

  @Input()
  public disabled: boolean;

  @Output() public trigger = new EventEmitter<void>();

  public safeContent: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    @Attribute('fullWidth') fullWidth: string,
    @Attribute('autoWidth') autoWidth: string,
    @Attribute('fdSecondary') secondary: string,
    @Attribute('fdTertiary') tertiary: string
  ) {
    this.fullWidth = fullWidth === '';
    this.autoWidth = autoWidth === '';
    this.secondary = secondary === '';
    this.tertiary = tertiary === '';
    this.primary = !this.secondary && !this.tertiary;
  }

  public async ngOnInit() {
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  public handleTrigger(event: Event) {
    this.trigger.emit();
    event.stopPropagation();
    event.preventDefault();
  }
}
