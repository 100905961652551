import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';

@Component({
  selector: 'app-credit-manual-analysis-proposal',
  templateUrl: './credit-manual-analysis-proposal.component.html',
  styleUrls: ['./credit-manual-analysis-proposal.component.scss'],
})
export class CreditManualAnalysisProposalComponent {
  constructor(private routingService: RoutingService) {}

  public navigateToHome() {
    this.routingService.navigateToHome();
  }
}
