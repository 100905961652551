import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CurrencyValidator(minValue: number = 0, maxValue?: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const formattedValue = control.value === 0 || control.value === '' ? '0' : String(control.value || '');

    if (formattedValue) {
      const value = +parseFloat(formattedValue).toFixed(2);

      if (isNaN(value)) {
        return { notNumeric: true };
      }
      if (value < minValue) {
        return { valueTooLow: true };
      }
      if (maxValue >= 0 && value > maxValue) {
        return { valueTooHigh: true };
      }
    }

    return {};
  };
}
