export class CampaignSimulatorFilterModel {
  public annualBillingValue: number;
  public personType: string;
  public cpfCnpj: string;
  public cnae: number;
  public hasMdr: boolean;
  public hasMdrFlex: boolean;
  public mdrWithPrepayment: boolean;
  public uf: string;
  public idCampaign: number;
  public chargeModelEnum: string;
  public receivingDeadline: number;
  public mcc: number;
  public external: boolean;
}

export class CampaignSimulatorModel {
  public idCampaign: number;
  public campaignCode: string;
  public description: string;
  public hasMdr: boolean;
  public hasMdrFlex: boolean;
  public mdrWithPrepayment: boolean;
  public hasNegotiation: boolean;
  public chargeModelEnum: string;
  public maxAnnualBilling: number;
  public minAnnualBilling: number;
}
