import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.local';
import {
  AcquirerConciliator,
  EdiAdditionalDataModel,
} from '../../../components/complementary-information/edi/models/edi-additional-data.model';

@Injectable({
  providedIn: 'root',
})
export class ConciliatorService {
  public agentChannel: string;

  constructor(private http: HttpClient) {}

  public getConciliators(): Observable<EdiAdditionalDataModel[]> {
    return this.http.get<EdiAdditionalDataModel[]>(`${environment.apiUrls.conciliatorCompany}`);
  }

  public getEmailsConciliatorById(idConciliatorCompany: number): Observable<EdiAdditionalDataModel> {
    return this.http.get<EdiAdditionalDataModel>(`${environment.apiUrls.conciliatorCompany}/${idConciliatorCompany}`);
  }

  public getAcquirerByConciliatorById(idConciliatorCompany: number): Observable<AcquirerConciliator[]> {
    return this.http.get<AcquirerConciliator[]>(`${environment.apiUrls.conciliatorCompany}/${idConciliatorCompany}/acquirer`);
  }
}
