<app-order-resume></app-order-resume>

<h4 class="fd-subtitle">Dados de pagamento</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
	<div>
		<fd-input [parentForm]="formGroup" [field]="fields.cardholderName">
		</fd-input>
	</div>
	<div>
		<fd-input [parentForm]="formGroup" (input)="checkCardScheme($event)" [field]="fields.cardNumber">
		</fd-input>
	</div>
	<div fxLayoutGap="20px">
		<div fxFlex="50">
			<fd-input [parentForm]="formGroup" [field]="fields.expirationDate">
			</fd-input>
		</div>
		<div fxFlex="50">
			<fd-input [parentForm]="formGroup" [field]="fields.cvv">
			</fd-input>
		</div>
	</div>
	<div>
		<fd-select [field]="fields.installments" [parentForm]="formGroup"></fd-select>
	</div>
	<fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
	</fd-button>
	<fd-button [content]="'avançar'" (trigger)="next()">
	</fd-button>
</form>
