export function dynamicDigitsMask(length: number): RegExp[] | string[] {
  if (!length) {
    return;
  }

  const arr = [];
  const digit = /[0-9]/;
  for (let i = 0; i < length; i++) {
    arr.push(digit);
  }

  return arr;
}
