import { Validators } from '@angular/forms';

export const customAddressValidators = {

    zipCode: {
        validators: [
            Validators.required,
            Validators.maxLength(8),
            Validators.min(8)
        ],
        updateOn: 'blur'
    },
    address: {
        validators: [
            Validators.required,
            Validators.maxLength(100),
            Validators.min(1)
        ],
        updateOn: 'blur'
    },
    number: {
        validators: [
            Validators.required,
            Validators.maxLength(10),
        ],
        updateOn: 'blur'
    },
    complement: {
        validators: [
            Validators.maxLength(50),
            Validators.min(1)
        ],
        updateOn: 'blur'
    },
    neighborhood: {
        validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.min(1)
        ],
        updateOn: 'blur'
    },
    city: {
        validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.min(1)
        ],
        updateOn: 'blur'
    },
    state: {
        validators: [
            Validators.required,
            Validators.maxLength(2),
            Validators.min(2)
        ],
        updateOn: 'blur'
    }
};
