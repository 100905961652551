import { Validators } from '@angular/forms';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { CpfCnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { EmailRegExp } from 'src/app/order/validators/email-compare-validator';
import { NameSurnameValidator } from 'src/app/order/validators/name-surname-validator';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { WebsiteValidator } from 'src/app/order/validators/website.validator';
import { PhoneValidator } from '../../../validators/phone-validator';

export function customPersonalDataValidators(documentCategory: DocumentCategory, complianceDataEnabled?: boolean) {
  const isCPF = () => documentCategory === DocumentCategory.CPF;
  const isCNPJ = () => documentCategory === DocumentCategory.CNPJ;
  const shouldFillMotherName = () => isCPF() && complianceDataEnabled;
  const shouldFillConstitutionForm = () => isCNPJ() && complianceDataEnabled;

  return {
    cpfCnpj: {
      validators: [Validators.required, CpfCnpjValidator],
      updateOn: 'blur',
    },
    socialReason: {
      validators: [RequiredIfValidator(isCNPJ)],
      updateOn: 'blur',
    },
    companyFantasyName: {
      validators: [RequiredIfValidator(isCNPJ)],
      updateOn: 'blur',
    },
    openDate: {
      validators: [RequiredIfValidator(isCNPJ), dateValidator()],
      updateOn: 'blur',
    },
    companyEmail: {
      validators: [RequiredIfValidator(isCNPJ), Validators.maxLength(50), Validators.min(1), Validators.pattern(EmailRegExp)],
      updateOn: 'blur',
    },
    companyMobileNumber: {
      validators: [RequiredIfValidator(isCNPJ), PhoneValidator],
      updateOn: 'blur',
    },
    constitutionForm: {
      validators: [RequiredIfValidator(shouldFillConstitutionForm)],
      updateOn: 'blur',
    },
    name: {
      validators: [RequiredIfValidator(isCPF), NameSurnameValidator],
      updateOn: 'blur',
    },
    birthDate: {
      validators: [RequiredIfValidator(isCPF), dateValidator()],
      updateOn: 'blur',
    },
    motherName: {
      validators: [RequiredIfValidator(shouldFillMotherName), NameSurnameValidator],
      updateOn: 'blur',
    },
    personEmail: {
      validators: [RequiredIfValidator(isCPF), Validators.pattern(EmailRegExp)],
      updateOn: 'blur',
    },
    personMobileNumber: {
      validators: [RequiredIfValidator(isCPF), PhoneValidator],
      updateOn: 'blur',
    },
    fixedNumberOne: {
      validators: [RequiredIfValidator(isCPF), PhoneValidator],
      updateOn: 'blur',
    },
    fixedNumberTwo: {
      validators: [PhoneValidator],
      updateOn: 'blur',
    },
    personFantasyName: {
      validators: [RequiredIfValidator(isCPF)],
      updateOn: 'blur',
    },
    useAsDeliveryContact: {
      validators: [RequiredIfValidator(isCPF)],
      updateOn: 'blur',
    },
    useAsReceivingData: {
      validators: [RequiredIfValidator(isCPF)],
      updateOn: 'blur',
    },
    politicalExposition: {
      validators: [],
      updateOn: 'blur',
    },
    siteAddress: {
      validators: [WebsiteValidator],
      updateOn: 'blur',
    },
    nationality: {
      validators: [RequiredIfValidator(isCPF)],
      updateOn: 'blur',
    },
    rne: {
      validators: [],
      updateOn: 'blur',
    },
  };
}
