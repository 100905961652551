import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, interval } from 'rxjs';
import { takeWhile, window } from 'rxjs/operators';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Proposal } from 'src/app/shared/models/proposal';
import { ApiStatusService } from 'src/app/start/services/api-status.service';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { environment } from 'src/environments/environment.local';
import { Constants } from '../../constants/constants';
import { ConclusionPhaseEnum } from '../../enums/conclusion-phase.enum';
import { PaymentStatusEnum } from '../../enums/payment-status.enum';
import { ProposalStatusEnum } from '../../enums/proposal-status.enum';
import { Messages } from '../../messages/order.messages';
import { orderRoutingDefinitions } from '../../routing/routing-definitions';
import { ProposalService } from '../../services/external/proposal/proposal.service';
import { ConclusionPhaseService } from '../../services/internal/conclusion-phase/conclusion-phase.service';
import { DialogService } from '../../services/internal/dialog/dialog.service';
import { LoadingService } from '../../services/internal/loading/loading.service';
import { ScrollService } from '../../services/internal/scroll/scroll.service';
import { WizardService } from '../../services/internal/wizard/wizard.service';
import { DataStore } from '../../store/data.store';
import { FormStep } from '../form-step';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss'],
})
export class ConclusionComponent extends FormStep implements OnInit {
  get conclusionPhase() {
    return this.conclusionPhaseService.getPhase();
  }

  get isApproved() {
    return this.conclusionPhase === ConclusionPhaseEnum.APPROVED;
  }

  get isDev() {
    return environment && environment.development;
  }

  public STEP_NUMBER = 0;
  public proposalNumber: string;
  public subscription: Subscription;
  public PROPOSAL_CHECK_INTERVAL = interval(5000);
  public proposalJSON: string;
  public scheduledMaintenanceActive = false;
  public scheduledMaintenanceMessage = '';
  public idsApisStatusMaintenance = [2, 3, 8]; // Black-list; Serpro; Terminais

  constructor(
    private formBuilder: FormBuilder,
    private conclusionPhaseService: ConclusionPhaseService,
    private apiStatusService: ApiStatusService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public getScheduledMaintenanceFlag() {
    this.apiStatusService.getApiStatus().subscribe((response) => {
      if (response && response.length) {
        this.scheduledMaintenanceActive = response.some((x) => x.offlineStatus && x.showMessage);

        this.scheduledMaintenanceActive = response.some(
          (x) => x.offlineStatus && x.showMessage && this.idsApisStatusMaintenance.indexOf(x.id) >= 0
        );
        if (this.scheduledMaintenanceActive) {
          this.scheduledMaintenanceMessage =
            'As propostas NÃO podem ser submetidas, pois nosso site está em manutenção. Tente novamente mais tarde.';
        } else {
          this.scheduledMaintenanceActive = response.some((x) => x.offlineStatus && x.showMessage);
          if (this.scheduledMaintenanceActive) {
            this.scheduledMaintenanceMessage =
              'Atenção, uma ou mais APIs estão em manutenção! As propostas podem ser submetidas mas o fluxo de aprovação ocorrerá somente \
              após a conclusão da manutenção.';
          }
        }
      }
    });
  }

  public getProposalJSON() {
    this.dataStore.getInMemoryProposal().subscribe((prop) => {
      this.proposalJSON = JSON.stringify(prop, null, 2);
      event.preventDefault();

      const listener = (e: ClipboardEvent) => {
        const clipboardDataString = 'clipboardData';
        const clipboard = e.clipboardData || window[clipboardDataString];
        clipboard.setData('text', this.proposalJSON);
        e.preventDefault();
      };

      document.addEventListener('copy', listener, false);
      document.execCommand('copy');
      document.removeEventListener('copy', listener, false);
    });

    setTimeout(() => {}, 500);
  }

  public resubmit(proposal: Proposal) {
    this.proposalService
      .submitProposal(proposal)

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.dataStore.deleteDraftInformationFromLocalStorage();
          if (response && response.proposalNumber) {
            this.clearProposalCheckRoutine();
            this.setProposalCheckRoutine(response.proposalNumber);
          } else {
            this.dialogService.openDialogWithMessage(JSON.stringify(response));
          }
        },
        (error) => {
          this.checkError(error.details);
        }
      );
  }

  public resendWithSameData() {
    if (!this.proposalJSON) {
      this.dataStore.getInMemoryProposal().subscribe((proposal) => {
        this.resubmit(proposal);
      });
    } else {
      this.resubmit(JSON.parse(this.proposalJSON));
    }
  }

  public getProposalStatus(proposalNumber?: string) {
    if (!proposalNumber) {
      proposalNumber = this.proposalNumber;
    }

    this.proposalService
      .getProposalStatus(proposalNumber)
      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (!response || !response.proposalStatus) {
          this.dialogService.openErrorDialog(Messages.FAILED_TO_GET_PROPOSAL_DATA, 'Api Fiserv Online - Proposta', null, 'error');
          return;
        }

        switch (response.proposalStatus) {
          case ProposalStatusEnum.APPROVED:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.APPROVED);
            break;

          case ProposalStatusEnum.AWAITING_PAYMENT:
            if (response.paymentStatus === PaymentStatusEnum.PENDING) {
              this.wizardService.goToSpecificStep(
                orderRoutingDefinitions.orderFinishing.stepNumber,
                orderRoutingDefinitions.orderFinishing.routes.PAYMENT_DATA.order
              );
            }
            break;

          case ProposalStatusEnum.DENIED:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.REJECTED);
            break;

          case ProposalStatusEnum.IN_ANALYSIS:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.PENDING);
            break;

          case ProposalStatusEnum.IN_COMPLIANCE_MANUAL_ANALYSIS:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.COMPLIANCE_MANUAL_ANALYSIS);
            break;

          case ProposalStatusEnum.IN_CREDIT_MANUAL_ANALYSIS:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.CREDIT_MANUAL_ANALYSIS);
            break;

          default:
            this.conclusionPhaseService.setPhase(ConclusionPhaseEnum.PENDING);
        }
      });
  }

  public setProposalCheckRoutine(proposalNumber?: string) {
    this.subscription = this.PROPOSAL_CHECK_INTERVAL.pipe(takeWhile(() => !this.isApproved && this.alive)).subscribe((_) =>
      this.getProposalStatus(proposalNumber)
    );
  }

  public clearProposalCheckRoutine() {
    this.subscription.unsubscribe();
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.getScheduledMaintenanceFlag();
  }

  public createFormGroup() {
    return this.formBuilder.group([]);
  }

  public persistData() {
    return Promise.resolve(true);
  }

  public createFields() {
    return {};
  }

  public updateFields() {}
  protected proposalGetCallback(): void {
    if (!this.proposal || !this.proposal.proposalNumber) {
      const localStorageItem = localStorage.getItem(Constants.PROPOSAL_NUMBER);
      if (!localStorageItem) {
        this.dialogService.openErrorDialog(Messages.FAILED_TO_GET_PROPOSAL_DATA, 'Api Fiserv Online - Proposta', null, 'error');
        return;
      }

      this.proposalNumber = localStorageItem;
      this.setProposalCheckRoutine();
      return;
    }

    this.proposalNumber = this.proposal.proposalNumber;
    this.setProposalCheckRoutine();
  }
}
