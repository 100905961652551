import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { EmailRegExp } from 'src/app/order/validators/email-compare-validator';
import { PhoneValidator } from 'src/app/order/validators/phone-validator';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefBrowserIntegrationModel } from '../models/tef-browser-integration.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-tef-browser-integration',
  templateUrl: './tef-browser-integration.component.html',
  styleUrls: ['./tef-browser-integration.component.scss'],
})
export class TefBrowserIntegrationComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public tefBrowserIntegrationModel: TefBrowserIntegrationModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefBrowserIntegration: TefBrowserIntegrationModel = {
      ...this.formGroup.getRawValue(),
      contactPhoneNumbers: [this.formControls.contactPhoneNumber1.value],
      itResponsiblePhoneNumbers: [this.formControls.itResponsiblePhoneNumber1.value],
      itResponsibleEmails: [this.formControls.itResponsibleEmail.value, this.formControls.itResponsibleEmail2.value],
    };

    return this.dataStore.updateProposal({ tefBrowserIntegration });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      integratorEnterpriseName: ['', Validators.required],
      contactPhoneNumber1: ['', [Validators.required, PhoneValidator]],
      itResponsibleName: ['', Validators.required],
      itResponsiblePhoneNumber1: ['', [Validators.required, PhoneValidator]],
      itResponsibleEmail: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
      itResponsibleEmail2: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefBrowserIntegrationModel = proposal.tefBrowserIntegration;

    if (this.tefBrowserIntegrationModel) {
      this.formControls.contactPhoneNumber1.setValue(this.tefBrowserIntegrationModel.contactPhoneNumbers[0]);
      this.formControls.integratorEnterpriseName.setValue(this.tefBrowserIntegrationModel.integratorEnterpriseName);
      this.formControls.itResponsibleEmail.setValue(this.tefBrowserIntegrationModel.itResponsibleEmails[0]);
      this.formControls.itResponsibleEmail2.setValue(this.tefBrowserIntegrationModel.itResponsibleEmails[1]);
      this.formControls.itResponsibleName.setValue(this.tefBrowserIntegrationModel.itResponsibleName);
      this.formControls.itResponsiblePhoneNumber1.setValue(this.tefBrowserIntegrationModel.itResponsiblePhoneNumbers[0]);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      integratorEnterpriseName: {
        label: 'Nome da empresa integradora',
        controlName: 'integratorEnterpriseName',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      contactPhoneNumber1: {
        label: 'Telefone de contato',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'contactPhoneNumber1',
        messages: {
          required: 'Campo obrigatório',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
      itResponsibleName: {
        label: 'Nome do responsável',
        controlName: 'itResponsibleName',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      itResponsiblePhoneNumber1: {
        label: 'Celular',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'itResponsiblePhoneNumber1',
        messages: {
          required: 'Campo obrigatório',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
      itResponsibleEmail: {
        label: 'E-mail',
        controlName: 'itResponsibleEmail',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      itResponsibleEmail2: {
        label: 'E-mail 2',
        controlName: 'itResponsibleEmail2',
        messages: {
          required: 'Campo obrigatório',
        },
      },
    };
  }
}
