import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take, takeWhile } from 'rxjs/operators';
import { BuildingTypeEnum } from 'src/app/order/enums/building-type-enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { CepService } from 'src/app/order/services/external/cep/cep.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DeliveryAddressModel, MailingAddressModel, Proposal, RegistrationDataAddressModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { AddressComponent } from '../../address/address.component';
import { FormStep } from '../../form-step';
import { customAddressValidators } from '../address/registration-data-address.validations';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent extends FormStep implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public deliveryAddressModel: DeliveryAddressModel;
  public commercialAddress: RegistrationDataAddressModel;
  public fields: FdFieldConfigs;
  public lastZipCodeValue: string;
  public proposal: Proposal;

  public buildingTypeItems: Item[] = [
    {
      label: 'Comercial',
      value: BuildingTypeEnum.COMMERCIAL,
    },
    {
      label: 'Móvel',
      value: BuildingTypeEnum.MOBILE,
    },
    {
      label: 'Residencial',
      value: BuildingTypeEnum.RESIDENTIAL,
    },
    {
      label: 'Sala',
      value: BuildingTypeEnum.ROOM,
    },
    {
      label: 'Shopping',
      value: BuildingTypeEnum.SHOPPING,
    },
  ];

  @ViewChildren(AddressComponent)
  public addressComponentList: QueryList<AddressComponent>;

  @ViewChild(AddressComponent)
  public addressComponent: AddressComponent;

  protected STEP_NUMBER = 7;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public createFields(): FdFieldConfigs {
    return {
      buildingType: {
        label: 'Tipo do estabelecimento',
        items: this.buildingTypeItems,
        controlName: 'buildingType',
        messages: {
          required: 'Informe o tipo do estabelecimento',
        },
      },
      useAsMailingAddress: {
        controlName: 'useAsMailingAddress',
        items: [],
        messages: {
          required: 'Informe se deseja utilizar o mesmo endereço para correspondência',
        },
      },
      referencePoint: {
        label: 'Ponto de referência',
        controlName: 'referencePoint',
      },
      ...this.addressComponent.getFields(),
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      addressForm: this.addressComponent.getFormGroup(),
      buildingType: ['', Validators.required],
      useAsMailingAddress: ['', Validators.required],
      referencePoint: [''],
    });
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.registrationDataAddress) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Endereço de Entrega',
        () => this.back(),
        null,
        'error'
      );
      return;
    }
    this.commercialAddress = this.proposal.registrationDataAddress;
  }

  protected async persistData() {
    if (!this.addressComponent.successCepCall) {
      return Promise.resolve(false);
    }

    const rawValue = this.formGroup.getRawValue();

    let mailingAddress = new MailingAddressModel();

    if (this.formControls.useAsMailingAddress && !!this.formControls.useAsMailingAddress.value) {
      mailingAddress = rawValue.addressForm;
      await this.dataStore.updateProposal({ mailingAddress });
    }

    const deliveryAddress: DeliveryAddressModel = {
      ...rawValue.addressForm,
      buildingType: rawValue.buildingType,
      referencePoint: rawValue.referencePoint,
      genericZipCode: this.addressComponent.genericZipCode,
      useAsMailingAddress: this.formControls.useAsMailingAddress.value,
    };

    this.setStepJump(
      orderRoutingDefinitions.registrationData.stepNumber,
      orderRoutingDefinitions.registrationData.routes.MAILING_ADDRESS.order,
      !!rawValue.useAsMailingAddress
    );

    return await this.dataStore.updateProposal({ deliveryAddress });
  }

  protected updateFields(proposal: Proposal) {
    if (!proposal.deliveryAddress) {
      this.addressComponent.setAddress(null);
      this.formControls.useAsMailingAddress.setValue(true);
      return;
    }

    (this.fields.buildingType as FdSelectConfig).items.map(
      (item) => (item.selected = item.value === proposal.deliveryAddress.buildingType)
    );
    this.formControls.buildingType.setValue(proposal.deliveryAddress.buildingType);
    this.formControls.referencePoint.setValue(proposal.deliveryAddress.referencePoint);
    this.formControls.useAsMailingAddress.setValue(proposal.deliveryAddress.useAsMailingAddress);
    this.addressComponent.setAddress(proposal.deliveryAddress);
  }
}
