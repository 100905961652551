import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';

@Component({
  selector: 'app-rejected-proposal',
  templateUrl: './rejected-proposal.component.html',
  styleUrls: ['./rejected-proposal.component.scss'],
})
export class RejectedProposalComponent {
  constructor(private routingService: RoutingService) {}

  public navigateToHome() {
    this.routingService.navigateToHome();
  }
}
