<h4 class="fd-subtitle">Indique os detalhes do estabelecimento:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-button size="thin" fullWidth fdTertiary [content]="'utilizar dados da pessoa de contato'"
        (trigger)="getReceivingData()">
      </fd-button>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.fixedNumber"></fd-input>
    </div>

    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
