import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { FirstDataPinPadModel } from '../models/first-data-pin-pad.model';

@Component({
  selector: 'app-first-data-pin-pad',
  templateUrl: './first-data-pin-pad.component.html',
  styleUrls: ['./first-data-pin-pad.component.scss'],
})
export class FirstDataPinPadComponent extends FormStep implements OnInit {
  get hasUsb() {
    return (
      this.formControls.pinPadOptions &&
      this.formControls.pinPadOptions.value &&
      this.formControls.pinPadOptions.value.indexOf(PinPadFirstDataEnum.PIN_PAD_FIRST_DATA_USB) > -1
    );
  }

  get hasSerial() {
    return (
      this.formControls.pinPadOptions &&
      this.formControls.pinPadOptions.value &&
      this.formControls.pinPadOptions.value.indexOf(PinPadFirstDataEnum.PIN_PAD_FIRST_DATA_SERIAL) > -1
    );
  }

  public STEP_NUMBER = 0;
  public firstDataPinPadModel: FirstDataPinPadModel;

  public pinPadFirstDataOptions: Item[] = [
    {
      label: 'Pin Pad FIRST DATA USB',
      value: PinPadFirstDataEnum.PIN_PAD_FIRST_DATA_USB,
    },
    {
      label: 'Pin Pad FIRST DATA SERIAL',
      value: PinPadFirstDataEnum.PIN_PAD_FIRST_DATA_SERIAL,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.setConditionalValidators();
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.pinPadOptions.setValue(this.pinPadFirstDataOptions.filter((x) => x.selected).map((x) => x.value));
  }

  public setConditionalValidators() {
    this.formControls.amountSerial.setValidators([RequiredIfValidator(() => !!this.hasSerial)]);

    this.formControls.amountUsb.setValidators([RequiredIfValidator(() => !!this.hasUsb)]);
  }

  public next(): void {
    if (!this.hasUsb) {
      this.formControls.amountUsb.setErrors(null);
    }

    if (!this.hasSerial) {
      this.formControls.amountSerial.setErrors(null);
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const firstDataPinPadModel: FirstDataPinPadModel = {
      ...this.formGroup.getRawValue(),
      pinPadOptions: this.pinPadFirstDataOptions.filter((x) => x.selected).map((x) => x.value),
    };

    return this.dataStore.updateProposal({ firstDataPinPadModel });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      amountSerial: [''],
      amountUsb: [''],
      pinPadOptions: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.firstDataPinPadModel = proposal.firstDataPinPadModel;

    if (this.firstDataPinPadModel) {
      this.formControls.amountSerial.setValue(this.firstDataPinPadModel.amountSerial);
      this.formControls.amountUsb.setValue(this.firstDataPinPadModel.amountUsb);
      this.formControls.pinPadOptions.setValue(this.firstDataPinPadModel.pinPadOptions);
      this.pinPadFirstDataOptions.map((x) => (x.selected = this.firstDataPinPadModel.pinPadOptions.indexOf(x.value) > -1));
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      amountSerial: {
        controlName: 'amountSerial',
        mask: OnlyNumberMask,
        label: 'Quantidade',
        messages: {
          required: 'Informe uma quantidade',
        },
      },
      amountUsb: {
        controlName: 'amountUsb',
        mask: OnlyNumberMask,
        label: 'Quantidade',
        messages: {
          required: 'Informe uma quantidade',
        },
      },
      pinPadOptions: {
        controlName: 'pinPadOptions',
      },
    };
  }
}

export enum PinPadFirstDataEnum {
  PIN_PAD_FIRST_DATA_USB = 'PIN_PAD_FIRST_DATA_USB',
  PIN_PAD_FIRST_DATA_SERIAL = 'PIN_PAD_FIRST_DATA_SERIAL',
}
