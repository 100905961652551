<h4 class="fd-subtitle">Histórico do Negócio</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.allowedJurisdictions"> </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.companyTimeInBusiness"> </fd-input>
    </div>
    <h4 class="fd-subtitle">Empresa possui sócio composto por outra empresa?</h4>
    <div>
      <fd-radio-button
        [yesOrNotOptions]="true"
        [parentForm]="formGroup"
        (change)="changeOption($event)"
        [field]="fields.hasEnterprisedComposedPartner"
      >
      </fd-radio-button>
    </div>
    <h4 class="fd-subtitle">Empresa possui administradores ou procuradores fora do quadro societário?</h4>
    <div>
      <fd-radio-button
        [yesOrNotOptions]="true"
        [parentForm]="formGroup"
        (change)="changeOptionOutOf($event)"
        [field]="fields.outOfMembershipBoard"
      >
      </fd-radio-button>
    </div>
    <h4 class="fd-subtitle">Investigações, litígios e processos judiciais</h4>
    <span class="fd-text"> {{ getLawInvestigationText() }} </span>
    <div>
      <fd-radio-button
        [yesOrNotOptions]="true"
        [parentForm]="formGroup"
        (change)="changeOptionHasLaw($event)"
        [field]="fields.hasLawInvestigation"
      >
      </fd-radio-button>
    </div>
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
