import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CpfValidator } from './cpf-validator';

export const CpfCnpjValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const controlValue = control.value ? control.value : '';

  if (!controlValue) {
    return {};
  }

  if (controlValue.length <= 11) {
    return CpfValidator(control);
  }

  return CnpjValidator(control);
};

export const CnpjValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  let valorCnpj: string = control.value;

  if (!valorCnpj) {
    return {};
  }

  const multiplicador1: number[] = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const multiplicador2: number[] = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let soma: number;
  let resto: number;
  let digito: string;
  let tempCnpj: string;
  valorCnpj = valorCnpj.trim();
  valorCnpj = valorCnpj.replace(/[\.\-\/]/g, '');

  if (valorCnpj.length !== 14) {
    return { invalidCnpj: { value: control.value } };
  }
  tempCnpj = valorCnpj.substring(0, 12);
  soma = 0;
  for (let i = 0; i < 12; i++) {
    soma += Number(tempCnpj[i].toString()) * multiplicador1[i];
  }
  resto = soma % 11;
  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }
  digito = resto.toString();
  tempCnpj = tempCnpj + digito;
  soma = 0;
  for (let i = 0; i < 13; i++) {
    soma += Number(tempCnpj[i].toString()) * multiplicador2[i];
  }
  resto = soma % 11;
  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }
  digito = digito + resto.toString();
  return valorCnpj.endsWith(digito) ? {} : { invalidCnpj: { value: control.value } };
};
