import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefPinPadInformationModel } from '../models/tef-pin-pad-information.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-pin-pad-information',
  templateUrl: './pin-pad-information.component.html',
  styleUrls: ['./pin-pad-information.component.scss'],
})
export class PinPadInformationComponent extends FormStep implements OnInit {
  get hasOwnPinPad() {
    return !!this.formControls.hasOwnPinPad.value;
  }

  get hasThirdPartyPinPad() {
    return !!this.formControls.hasThirdPartyPinPad.value;
  }

  public STEP_NUMBER = 0;
  public tefPinPadInformationModel: TefPinPadInformationModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.setConditionalValidators();
  }

  public setConditionalValidators() {
    this.formControls.pinPadModel.setValidators([RequiredIfValidator(() => this.hasOwnPinPad)]);
    this.formControls.thirdPartyPinPadModel.setValidators([RequiredIfValidator(() => this.hasThirdPartyPinPad)]);
  }

  public next(): void {
    if (!this.hasOwnPinPad) {
      this.formControls.pinPadModel.setErrors(null);
    }

    if (!this.hasThirdPartyPinPad) {
      this.formControls.thirdPartyPinPadModel.setErrors(null);
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefPinPadInformation: TefPinPadInformationModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ tefPinPadInformation });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      hasOwnPinPad: ['', Validators.required],
      pinPadModel: [''],
      hasThirdPartyPinPad: ['', Validators.required],
      thirdPartyPinPadModel: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefPinPadInformationModel = proposal.tefPinPadInformation;

    if (this.tefPinPadInformationModel) {
      this.formControls.hasOwnPinPad.setValue(this.tefPinPadInformationModel.hasOwnPinPad);
      this.formControls.hasThirdPartyPinPad.setValue(this.tefPinPadInformationModel.hasThirdPartyPinPad);
      this.formControls.pinPadModel.setValue(this.tefPinPadInformationModel.pinPadModel);
      this.formControls.thirdPartyPinPadModel.setValue(this.tefPinPadInformationModel.thirdPartyPinPadModel);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      hasOwnPinPad: {
        controlName: 'hasOwnPinPad',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      pinPadModel: {
        controlName: 'pinPadModel',
        label: 'Modelo/Versão kernel',
        messages: {
          required: 'Informe o modelo/versão kernel',
        },
      },
      hasThirdPartyPinPad: {
        controlName: 'hasThirdPartyPinPad',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      thirdPartyPinPadModel: {
        controlName: 'thirdPartyPinPadModel',
        label: 'Modelo/Versão kernel',
        messages: {
          required: 'Informe o modelo/versão kernel',
        },
      },
    };
  }
}
