import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { ResponseAddressModel } from 'src/app/shared/models/response/response-address.model';
import { environment } from 'src/environments/environment.local';

@Injectable()
export class CepService {
  constructor(private http: HttpClient) {}

  public validateCep(cep: string): Observable<ResponseAddressModel> {
    return this.http
      .get<BaseApiResponse<ResponseAddressModel>>(`${environment.apiUrls.zipCode}/${cep}`)
      .pipe(map((x) => (this.invalidResponse(x) ? null : x.response)));
  }

  private invalidResponse(baseApiResponse: BaseApiResponse<ResponseAddressModel>): boolean {
    return !baseApiResponse || baseApiResponse.error || !baseApiResponse.response || !baseApiResponse.response.cepResponse;
  }
}
