const digitRegExp = /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑýÝ '\-]/;

export function SurnameMask(value) {
  const arr = value
    .split('')
    .map((char) => {
      if (digitRegExp.test(char)) {
        return digitRegExp;
      }
    });

  return arr.filter(regexp => !!regexp);
}
