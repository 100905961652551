import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/order/constants/constants';
import { SessionInformation } from 'src/app/order/store/data-store-models';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { LoginModel } from 'src/app/shared/models/login.model';
import { TokenModel } from 'src/app/shared/models/response/token.model';
import { RoutingService } from '../routing/routing.service';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment.local';
import { map } from 'rxjs/operators';
import { BaseApiResponseDetails } from 'src/app/shared/models/response/base-api-response.model';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private sessionInformation: SessionInformation;
  private tokenInformation$: BehaviorSubject<SessionInformation>;

  private userData: LoginModel;
  private userData$: BehaviorSubject<LoginModel>;

  private loggedIn = false;

  constructor(
    private routingService: RoutingService,
    private dataStoreService: DataStoreService,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private http: HttpClient
  ) {
    this.sessionInformation = new SessionInformation();
    this.tokenInformation$ = new BehaviorSubject(this.sessionInformation);

    this.userData = new LoginModel();
    this.userData$ = new BehaviorSubject(this.userData);
  }

  public getStorageData(): SessionInformation {
    const data = new SessionInformation();
    const strUser = this.dataStoreService.getUsername();
    const strToken = this.dataStoreService.getToken();

    data.name = strUser;
    data.sessionToken = strToken;

    try {
      if (data !== null && data.sessionToken.trim().length > 0 && data.name.trim().length > 0) {
        return data;
      }
    } catch {
      return null;
    }
  }

  public getAuthService(): Observable<SessionInformation> {
    return this.tokenInformation$;
  }

  public getSessionToken() {
    if (!this.sessionInformation.sessionToken && this.loggedIn) {
      this.sessionInformation.sessionToken = this.savedUserToken();
    }
    return this.sessionInformation.sessionToken;
  }

  public savedUserToken(): string {
    return localStorage.getItem(Constants.AUTH_SERVICE_TOKEN_KEY);
  }

  public savedUserName(): string {
    return localStorage.getItem(Constants.AUTH_SERVICE_NAME_KEY);
  }

  public isAuthServiceEmpty(authService: SessionInformation) {
    return !authService || Object.keys(authService).length === 0;
  }

  public setAuthService(authService: TokenModel, isCEF: boolean): boolean {
    if (authService.fgBloqueado === 'N') {
      const decodedToken = this.tokenService.getDecodedAccessToken(authService.sessionToken);

      if (!decodedToken) {
        return false;
      }
      decodedToken.inf.hierarchyComplementaryInformation = authService.hierarchyComplementaryInformation;

      this.dataStoreService.setSessionInformation({ name: authService.nome, sessionToken: authService.sessionToken }, decodedToken, isCEF);

      return true;
    }
    return false;
  }

  public logoutCefRedirect() {
    this.clear();
    this.routingService.navigateToLoginCEF(true, false);
  }

  public logoutApi(): Observable<ApiResultModel> {
    return this.http.post<ApiResultModel>(`${environment.apiUrls.authentication}/logout`, {});
  }

  public logout() {
    this.clear();
    this.routingService.navigateToLogin(true, false);
  }

  public logoutCEF() {
    this.clear();
    this.routingService.navigateToLoginCEF(true, false);
  }

  public expiredToken() {
    this.logout();
  }

  public isLoggedIn(): boolean {
    if (this.loggedIn) {
      return true;
    }

    this.sessionInformation = this.getStorageData();
    if (!this.checkToken(this.sessionInformation)) {
      this.clear();
      return false;
    }

    return true;
  }

  public loginSuccess(loginData: TokenModel, isCEF: boolean) {
    if (this.checkToken(loginData)) {
      this.loggedIn = this.setAuthService(loginData, isCEF);
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  public isTokenExpirated() {
    if (this.checkToken(this.sessionInformation)) {
      let tokenDecoded = this.tokenService.getDecodedAccessToken(this.sessionInformation.sessionToken);
      const now = Date.now().valueOf() / 1000;
      return typeof tokenDecoded.exp !== 'undefined' && tokenDecoded.exp < now;
    }
  }

  private clear() {
    this.dataStoreService.clearSessionInformation();
    this.dataStoreService.clearAllLocalStorageObjects();
    this.loggedIn = false;
  }

  private checkToken(model: TokenModel | SessionInformation) {
    return model && model !== null && model.sessionToken !== null;
  }

  private invalidResponse(baseApiResponse: BaseApiResponseDetails<any>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || (!baseApiResponse.details && !baseApiResponse.datails);
  }
}
