import { OnInit, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { ScrollService } from '../services/internal/scroll/scroll.service';

@Directive()
export abstract class FormBase implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public formGroup: FormGroup;

  constructor(protected scrollService: ScrollService) {}

  public async ngOnInit() {
    this.formGroup = this.createFormGroup();
  }
  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }
  protected abstract createFormGroup(): FormGroup;

  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach((field) => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray || control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });

    if (control) {
      this.scrollService.scrollAndFocusToFirstInvalid();
    }
  }
}
