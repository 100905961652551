import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { cpfMask } from 'src/app/order/masks/document-masks';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { LoginService } from 'src/app/order/services/external/login/login.service';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { LoginModel } from 'src/app/shared/models/login.model';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { environment } from 'src/environments/environment.local';
import { startRoutingDefinitions } from '../../routing/start-routing-definitions';
import { Observable } from 'rxjs';
import { TokenModel } from '../../../shared/models/response/token.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public alive = true;
  public isCEF: boolean = false;
  public isMFA: boolean = false;
  public correlationId: string;
  public loginButtonActive = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private loginService: LoginService,
    private routingService: RoutingService,
    private dataStore: DataStore,
    private wizardService: WizardService,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    private loadingService: LoadingService,
    private router: Router,
    private dataStoreService: DataStoreService
  ) {}

  public async ngOnInit() {
    this.startForms();

    const currentUrl = this.router.url;
    this.isCEF = currentUrl && currentUrl.indexOf('caixa') > -1;
  }

  public getLogoPath() {
    return this.isCEF ? '/assets/images/caixa-logo.png' : '/assets/images/fiserv-logo.png';
  }

  public startForms(): void {
    this.formGroup = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      mfaToken: [],
    });

    this.fields = {
      user: {
        label: 'CPF',
        disabled: false,
        controlName: 'user',
        mask: cpfMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido',
        },
      },
      password: {
        label: 'Senha',
        disabled: false,
        controlName: 'password',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha',
          invalid: 'Senha inválida',
        },
      },
      mfaToken: {
        label: 'Informe o token recebido por email',
        disabled: false,
        controlName: 'mfaToken',
        type: InputType.TEXT,
        messages: {
          required: 'Informe o token recebido por email',
          invalid: 'Token invalido',
        },
      },
    };
  }

  public login() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.NO_LOGIN_DATA);
      return;
    }

    this.loginButtonActive = false;
    const data = new LoginModel();
    data.username = this.formGroup.value.user;
    data.password = this.formGroup.value.password;

    let request: Observable<TokenModel>;

    if (this.isMFA) {
      request = this.loginService.loginMFA(data);
      data.correlationId = this.correlationId;
      data.tokenOTP = this.formGroup.value.mfaToken;
    } else {
      request = this.loginService.login(data);
    }

    request.pipe(takeWhile(() => this.alive)).subscribe(
      (response) => {
        if (response.otpDetails && response.otpDetails.correlationId) {
          this.isMFA = true;
          this.correlationId = response.otpDetails.correlationId;
          this.loginButtonActive = true;
        } else if (response && this.authService.loginSuccess(response, this.isCEF)) {
          const userData = this.dataStoreService.getUserData();
          if (
            userData.institution.endsWith('7') &&
            userData.hierarchyComplementaryInformation.find((x) => x.subChannel === '99999') &&
            this.router.url.includes('/login')
          ) {
            this.dialogService.openDialog(Messages.FISERV_ONLINE_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.redirectCef());
            return;
          }

          if (this.isCEF && !userData.institution.endsWith('7')) {
            this.dialogService.openDialog(Messages.SIMULATOR_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.authService.logoutCEF());
            return;
          }

          if (this.isCEF) {
            localStorage.removeItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

            this.wizardService.goToSpecificStep(
              orderRoutingDefinitions.simulationData.stepNumber,
              orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.order
            );

            return;
          }

          this.routingService.navigateWithParentTo(
            startRoutingDefinitions.start.routes.WELCOME.parentRoute,
            startRoutingDefinitions.start.routes.WELCOME.name
          );
          this.loginButtonActive = true;
        } else {
          this.loginButtonActive = true;
          this.dialogService.openDialog(Messages.NO_TOKEN_DATA);
          return;
        }
      },
      (err: HttpErrorResponse) => {
        this.loginButtonActive = true;
        const serializedError = err.error ? (err.error as ApiResultModel) : null;

        if (serializedError && serializedError.message) {
          if (
            serializedError.message.includes('Could not open JPA EntityManager for transaction') ||
            serializedError.message.includes('Cannot get Jedis connection')
          ) {
            this.dialogService.openDialog(Messages.LOGIN_ERROR_UNAVAILABLE_BACKEND);
          } else if (serializedError && serializedError.data && serializedError.data.includes('USER_PASSWORD_EXPIRATED')) {
            this.dialogService.openDialogWithMessage(serializedError.message, () => this.getPasswordRecovery());
          } else {
            this.dialogService.openDialogWithMessage(serializedError.message);
          }
        } else {
          this.dialogService.openDialog(Messages.LOGIN_ERROR);
        }
      }
    );
  }

  public reenviarToken() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.NO_LOGIN_DATA);
      return;
    }

    this.loginButtonActive = false;
    const data = new LoginModel();
    data.username = this.formGroup.value.user;
    data.password = this.formGroup.value.password;

    let request: Observable<TokenModel>;

    this.loginService
      .login(data)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response.otpDetails && response.otpDetails.correlationId) {
            this.isMFA = true;
            this.correlationId = response.otpDetails.correlationId;
            this.loginButtonActive = true;
          } else if (response && this.authService.loginSuccess(response, this.isCEF)) {
            const userData = this.dataStoreService.getUserData();
            if (
              userData.institution.endsWith('7') &&
              userData.hierarchyComplementaryInformation.find((x) => x.subChannel === '99999') &&
              this.router.url.includes('/login')
            ) {
              this.dialogService.openDialog(Messages.FISERV_ONLINE_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.redirectCef());
              return;
            }

            if (this.isCEF && !userData.institution.endsWith('7')) {
              this.dialogService.openDialog(Messages.SIMULATOR_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.authService.logoutCEF());
              return;
            }

            if (this.isCEF) {
              localStorage.removeItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

              this.wizardService.goToSpecificStep(
                orderRoutingDefinitions.simulationData.stepNumber,
                orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.order
              );

              return;
            }

            this.routingService.navigateWithParentTo(
              startRoutingDefinitions.start.routes.WELCOME.parentRoute,
              startRoutingDefinitions.start.routes.WELCOME.name
            );
            this.loginButtonActive = true;
          } else {
            this.loginButtonActive = true;
            this.dialogService.openDialog(Messages.NO_TOKEN_DATA);
            return;
          }
        },
        (err: HttpErrorResponse) => {
          this.loginButtonActive = true;
          const serializedError = err.error ? (err.error as ApiResultModel) : null;

          if (serializedError && serializedError.message) {
            if (
              serializedError.message.includes('Could not open JPA EntityManager for transaction') ||
              serializedError.message.includes('Cannot get Jedis connection')
            ) {
              this.dialogService.openDialog(Messages.LOGIN_ERROR_UNAVAILABLE_BACKEND);
            } else {
              this.dialogService.openDialogWithMessage(serializedError.message);
            }
          } else {
            this.dialogService.openDialog(Messages.LOGIN_ERROR);
          }
        }
      );
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getPasswordRecovery() {
    return window.open(environment.ADMIN_URL + '/password-recovery?type=2');
  }

  public createDraft(): Proposal {
    const userData = this.dataStoreService.getUserData();
    return {
      serviceContract: userData.serviceContract,
      channel: userData.channel,
      subChannel: this.dataStoreService.getUserDataSubChannel(userData),
      agentChannel: this.dataStoreService.getUserDataAgentChannel(userData),
      institution: userData.institution,
      channelType: userData.channelType,
    };
  }

  private redirectCef() {
    this.authService.logoutCefRedirect();
  }
}
