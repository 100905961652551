import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function PercentValidator(minValue: number = 0, maxValue?: number, allowZero: boolean = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const formattedValue = control.value;

    if (formattedValue === 0 && !allowZero) {
      return { valueZero: true };
    }

    if (allowZero || (!allowZero && !!formattedValue)) {
      const value = +parseFloat(formattedValue).toFixed(2);

      if (isNaN(value)) {
        return { notNumeric: true };
      }
      if (value < minValue) {
        return { valueTooLow: true };
      }
      if (maxValue && value > maxValue) {
        return { valueTooHigh: true };
      }
    }

    return {};
  };
}
