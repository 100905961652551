import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import {
  ECommerceFunctionalitiesEnum,
  ECommerceFunctionalitiesModel,
  ECommerceTechnologyTypeEnum,
} from '../models/e-commerce-funcionalities.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { ConfigPaymentSplit } from '../../../../../shared/models/config-payment-split';
import { UrlModel } from '../../../../../shared/models/url.model';
import { DocumentCategory } from '../../../../enums/document-category.enum';
import { TypeEcSplitEnum } from '../../../../enums/split-payment.enum';
import { cpfCnpjMask } from '../../../../masks/document-masks';
import { UrlService } from '../../../../services/external/url/url.service';
import { CpfCnpjValidator } from '../../../../validators/cpf-cnpj-validator';

@Component({
  selector: 'app-e-commerce-functionalities',
  templateUrl: './e-commerce-functionalities.component.html',
  styleUrls: ['./e-commerce-functionalities.component.scss'],
})
export class ECommerceFunctionalitiesComponent extends FormStep implements OnInit {
  get hasSplitPayment() {
    return !!this.eCommerceFunctionalities.filter((x) => x.value === ECommerceFunctionalitiesEnum.PAYMENT_SPLIT && x.selected).length;
  }

  get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }

  public STEP_NUMBER = 0;

  public eCommerceTechnologyTypes: Item[] = [
    {
      label: 'E-commerce',
      value: ECommerceTechnologyTypeEnum.E_COMMERCE,
    },
    {
      label: 'Aplicativo',
      value: ECommerceTechnologyTypeEnum.APP,
    },
  ];

  public eCommerceFunctionalities: Item[] = [];

  public eCommerceFunctionalitiesModel: ECommerceFunctionalitiesModel;

  public ecTypeList: Item[] = [
    {
      label: 'EC Master',
      value: TypeEcSplitEnum.EC_MASTER,
    },
    {
      label: 'EC Seller',
      value: TypeEcSplitEnum.EC_SELLER,
    },
  ];

  public showCnpj: boolean = false;

  public ablePaymentSplit: boolean = false;

  private readonly CPF_REGEX = /^[0-9]{11}$/;
  private readonly CNPJ_REGEX = /^[0-9]{14}$/;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    protected urlService: UrlService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.checkSplitPgtoFields();
    this.setConditionalValidators();
    this.ableSplitPayment();
  }

  public ableSplitPayment() {
    this.proposalService.ablePaymentSplit(this.proposal.serviceContract).subscribe(
      (ablePaymentSplit: ConfigPaymentSplit) => {
        this.ablePaymentSplit = ablePaymentSplit.com;
        this.loadECommerceFunctionalities();
        this.removePaymentSplit();
        if (this.hasSplitTech) {
          this.setValueDefaultSplitTech();
        }
      },
      () => {
        this.ablePaymentSplit = false;
        this.removePaymentSplit();
        this.dialogService.openDialog(Messages.GENERAL_ERROR);
      }
    );
  }

  public isPJ(): boolean {
    return this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CNPJ;
  }

  public removePaymentSplit() {
    if (this.eCommerceFunctionalitiesModel) {
      const indexPaymentSplit = this.eCommerceFunctionalitiesModel.eCommerceFunctionalities.indexOf(
        ECommerceFunctionalitiesEnum.PAYMENT_SPLIT
      );
      if (!this.ablePaymentSplit && this.eCommerceFunctionalitiesModel && indexPaymentSplit !== -1) {
        this.eCommerceFunctionalitiesModel.eCommerceFunctionalities.splice(indexPaymentSplit, 1);
        this.eCommerceFunctionalitiesModel.cnpjMid = undefined;
        this.formControls.eCommerceFunctionalities.setValue(this.eCommerceFunctionalitiesModel.eCommerceFunctionalities);
        this.formControls.cnpjMid.setValue(this.eCommerceFunctionalitiesModel.cnpjMid);
      }
    }
  }

  public onECommerceFunctionalitiesCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.eCommerceFunctionalities.setValue(this.eCommerceFunctionalities.filter((x) => x.selected).map((x) => x.value));

    if (item.selected && event.source.id === 'PAYMENT_SPLIT') {
      if (this.hasSplitTech) {
        this.showCnpj = true;
        this.formControls.ecType.setValue(TypeEcSplitEnum.EC_SELLER);
        this.formControls.cnpjMid.enable();
      } else {
        this.showCnpj = false;
        this.formControls.ecType.setValue(TypeEcSplitEnum.EC_MASTER);
        this.formControls.cnpjMid.setValue(this.proposal.registrationDataPersonalInfo.cpfCnpj);
        this.formControls.cnpjMid.disable();
      }
    }

    if (item.selected === false && event.source.id === 'PAYMENT_SPLIT') {
      // this.formGroup.reset();
      this.formControls.ecType.setValue(null);
      this.formControls.cnpjMid.setValue(null);
    }
  }

  public validateECMaster(document) {
    if ((this.CPF_REGEX.test(document) || this.CNPJ_REGEX.test(document)) && this.formControls.cnpjMid.valid) {
      this.proposalService.validateEcMaster(document, this.proposal.institution).subscribe(
        (validEcMaster) => {
          if (!validEcMaster) {
            this.formControls.cnpjMid.setValue('');
            this.dialogService.openDialog(Messages.EC_MASTER_NOT_FOUND);
          }
        },
        () => {
          this.formControls.cnpjMid.setValue('');
          this.dialogService.openDialog(Messages.GENERAL_ERROR);
        }
      );
    }
  }

  public validateUrl(url) {
    if (url && url !== '') {
      const urlModel: UrlModel = new UrlModel(url);
      this.urlService.validateStructure(urlModel).subscribe(
        (validStructureUrl) => {
          if (!validStructureUrl) {
            this.formControls.link.setValue('');
            this.dialogService.openDialog(Messages.INVALID_STRUCTURE_URL);
          }
        },
        () => {
          this.formControls.link.setValue('');
          this.dialogService.openDialog(Messages.GENERAL_ERROR);
        }
      );
    }
  }

  public onTechnologyTypeCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.eCommerceTechnologyTypes.setValue(this.eCommerceTechnologyTypes.filter((x) => x.selected).map((x) => x.value));
  }

  public setConditionalValidators() {
    this.formControls.cnpjMid.setValidators([RequiredIfValidator(() => this.hasSplitPayment), CpfCnpjValidator]);
  }

  public next(): void {
    this.submitted = true;

    if (!this.formControls.eCommerceTechnologyTypes.valid) {
      this.dialogService.openDialog(Messages.MISSING_ECOMMERCE_SALES_CHANNEL);
      return;
    }

    if (!this.formControls.eCommerceFunctionalities.valid) {
      this.dialogService.openDialog(Messages.MISSING_FUNCTIONALITY);
      return;
    }

    if (!this.hasSplitPayment) {
      this.formControls.cnpjMid.setErrors(null);
      this.formControls.ecType.setErrors(null);
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public mapTypeArrayToProperties(functionalitiesObj: ECommerceFunctionalitiesModel) {
    functionalitiesObj.eCommerceFlag = false;
    functionalitiesObj.appFlag = false;
    functionalitiesObj.recurrence = false;
    functionalitiesObj.linkPayment = false;
    functionalitiesObj.splitPayment = false;
    functionalitiesObj.virtualStore = false;

    functionalitiesObj.eCommerceTechnologyTypes.forEach((type) => {
      switch (type) {
        case ECommerceTechnologyTypeEnum.APP:
          functionalitiesObj.appFlag = true;
          return;
        case ECommerceTechnologyTypeEnum.E_COMMERCE:
          functionalitiesObj.eCommerceFlag = true;
          return;
      }
    });

    functionalitiesObj.eCommerceFunctionalities.forEach((type) => {
      switch (type) {
        case ECommerceFunctionalitiesEnum.PAYMENT_LINK:
          functionalitiesObj.linkPayment = true;
          return;
        case ECommerceFunctionalitiesEnum.PAYMENT_SPLIT:
          functionalitiesObj.splitPayment = true;
          return;

        case ECommerceFunctionalitiesEnum.RECURRENCE:
          functionalitiesObj.recurrence = true;
          return;

        case ECommerceFunctionalitiesEnum.VIRTUAL_SHOP:
          functionalitiesObj.virtualStore = true;
          return;
      }
    });
  }

  public persistData() {
    const commerceFunctionalities: ECommerceFunctionalitiesModel = {
      ...this.formGroup.getRawValue(),
      eCommerceTechnologyTypes: this.formControls.eCommerceTechnologyTypes.value,
      eCommerceFunctionalities: this.formControls.eCommerceFunctionalities.value,
    };

    this.mapTypeArrayToProperties(commerceFunctionalities);

    return this.dataStore.updateProposal({ commerceFunctionalities });
  }

  public checkSplitPgtoFields() {
    if (this.proposal.commerceFunctionalities !== null && this.proposal.commerceFunctionalities !== undefined) {
      if (this.proposal.commerceFunctionalities.splitPayment) {
        if (this.hasSplitTech) {
          this.formControls.ecType.setValue(TypeEcSplitEnum.EC_SELLER);
          this.formControls.cnpjMid.enable();
          if (this.proposal.commerceFunctionalities.cnpjMid === this.proposal.registrationDataPersonalInfo.cpfCnpj) {
            this.formControls.cnpjMid.setValue('');
          } else {
            this.formControls.cnpjMid.setValue(this.proposal.commerceFunctionalities.cnpjMid);
            this.validateECMaster(this.proposal.commerceFunctionalities.cnpjMid);
          }
          this.showCnpj = true;
        } else {
          this.formControls.ecType.setValue(TypeEcSplitEnum.EC_MASTER);
          this.formControls.cnpjMid.setValue(this.proposal.registrationDataPersonalInfo.cpfCnpj);
          this.formControls.cnpjMid.disable();
          this.showCnpj = false;
        }
      }
    }
  }

  public existsEcommerceFunctionalities() {
    return this.proposal.commerceFunctionalities && this.proposal.commerceFunctionalities.eCommerceFunctionalities;
  }

  public mountSplitFunctionality(label: string) {
    let checkedSplit: boolean = false;
    if (this.proposal.productSelection.hasSplitTech) {
      checkedSplit = true;
    } else {
      if (this.existsEcommerceFunctionalities()) {
        checkedSplit = this.proposal.commerceFunctionalities.eCommerceFunctionalities.includes(ECommerceFunctionalitiesEnum.PAYMENT_SPLIT);
      }
    }
    return {
      label,
      value: ECommerceFunctionalitiesEnum.PAYMENT_SPLIT,
      selected: checkedSplit,
    };
  }

  public createFormGroup() {
    return this.formBuilder.group({
      cnpjMid: ['', Validators.required],
      ecType: ['', Validators.required],
      link: ['', Validators.required],
      eCommerceTechnologyTypes: ['', Validators.required],
      eCommerceFunctionalities: ['', Validators.required],
    });
  }

  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.eCommerceFunctionalitiesModel = proposal.commerceFunctionalities;
    if (this.eCommerceFunctionalitiesModel) {
      if (this.hasSplitTech) {
        if (this.eCommerceFunctionalitiesModel.eCommerceFunctionalities.includes(ECommerceFunctionalitiesEnum.PAYMENT_SPLIT)) {
          this.eCommerceFunctionalitiesModel.eCommerceFunctionalities = [ECommerceFunctionalitiesEnum.PAYMENT_SPLIT];
          this.eCommerceFunctionalitiesModel.linkPayment = false;
          this.eCommerceFunctionalitiesModel.recurrence = false;
          this.eCommerceFunctionalitiesModel.virtualStore = false;
          this.eCommerceFunctionalitiesModel.splitPayment = true;
        } else {
          this.eCommerceFunctionalitiesModel.eCommerceFunctionalities = [];
          this.eCommerceFunctionalitiesModel.linkPayment = false;
          this.eCommerceFunctionalitiesModel.recurrence = false;
          this.eCommerceFunctionalitiesModel.virtualStore = false;
          this.eCommerceFunctionalitiesModel.splitPayment = false;
        }
      }

      this.formControls.cnpjMid.setValue(this.eCommerceFunctionalitiesModel.cnpjMid);
      this.formControls.link.setValue(this.eCommerceFunctionalitiesModel.link);
      this.eCommerceFunctionalities.map(
        (x) => (x.selected = this.eCommerceFunctionalitiesModel.eCommerceFunctionalities.indexOf(x.value) > -1)
      );
      this.eCommerceTechnologyTypes.map(
        (x) => (x.selected = this.eCommerceFunctionalitiesModel.eCommerceTechnologyTypes.indexOf(x.value) > -1)
      );
      this.formControls.eCommerceFunctionalities.setValue(this.eCommerceFunctionalitiesModel.eCommerceFunctionalities);
      this.formControls.eCommerceTechnologyTypes.setValue(this.eCommerceFunctionalitiesModel.eCommerceTechnologyTypes);
      this.formControls.ecType.setValue(this.eCommerceFunctionalitiesModel.ecType);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      cnpjMid: {
        label: 'CNPJ do EC Master correspondente',
        controlName: 'cnpjMid',
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CNPJ do EC Master correspondente',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido',
        },
      },
      link: {
        label: 'Link',
        controlName: 'link',
        messages: {
          required: 'Informe o link',
        },
      },

      ecType: {
        label: 'Escolha o Tipo de EC',
        items: this.ecTypeList,
        controlName: 'ecType',
        messages: {
          required: 'Informe se é um EC Master ou EC Seller',
          invalid: 'Informe se é um EC Master ou EC Seller',
        },
      },
    };
  }

  private setValueDefaultSplitTech() {
    this.formControls.eCommerceTechnologyTypes.setValue([ECommerceTechnologyTypeEnum.E_COMMERCE]);
    this.formControls.eCommerceFunctionalities.setValue([ECommerceFunctionalitiesEnum.PAYMENT_SPLIT]);
    this.eCommerceTechnologyTypes[0].selected = true;

    this.showCnpj = true;
    this.formControls.ecType.setValue(TypeEcSplitEnum.EC_SELLER);
    this.formControls.cnpjMid.enable();

    this.formGroup.controls.link.setValue('www.fiserv.com');
    this.formGroup.controls.link.disable();
  }

  private loadECommerceFunctionalities() {
    let eCommerceFunctionalities = [];

    if (this.hasSplitTech && this.ablePaymentSplit && this.isPJ()) {
      eCommerceFunctionalities = [this.mountSplitFunctionality('Split de pagamento (EC Seller)')];
    } else {
      eCommerceFunctionalities = [
        {
          label: 'Link de pagamento',
          value: ECommerceFunctionalitiesEnum.PAYMENT_LINK,
          selected: this.existsEcommerceFunctionalities()
            ? this.proposal.commerceFunctionalities.eCommerceFunctionalities.includes(ECommerceFunctionalitiesEnum.PAYMENT_LINK)
            : false,
        },
        {
          label: 'Recorrência',
          value: ECommerceFunctionalitiesEnum.RECURRENCE,
          selected: this.existsEcommerceFunctionalities()
            ? this.proposal.commerceFunctionalities.eCommerceFunctionalities.includes(ECommerceFunctionalitiesEnum.RECURRENCE)
            : false,
        },
        {
          label: 'Loja virtual/Site',
          value: ECommerceFunctionalitiesEnum.VIRTUAL_SHOP,
          selected: this.existsEcommerceFunctionalities()
            ? this.proposal.commerceFunctionalities.eCommerceFunctionalities.includes(ECommerceFunctionalitiesEnum.VIRTUAL_SHOP)
            : false,
        },
      ];
      if (this.ablePaymentSplit && this.isPJ()) {
        eCommerceFunctionalities.push(this.mountSplitFunctionality('Split de pagamento (EC Master)'));
      }
    }
    this.eCommerceFunctionalities = eCommerceFunctionalities;
  }
}
