import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment.local';
import { Constants } from '../constants/constants';
import { getOrderByRoute, getRouteByOrder, getRouteNameByOrder, getStepInfoByRoute } from '../routing/routing-definitions';
import { AuthService } from '../services/internal/auth/auth.service';
import { RoutingService } from '../services/internal/routing/routing.service';
import { StepInfo, WizardService } from '../services/internal/wizard/wizard.service';
import { RouteData } from '../store/data-store-models';
import { DataStoreService } from '../store/data-store.service';
import { DataStore } from '../store/data.store';

@Injectable({
  providedIn: 'root',
})
export class OrderStepGuard implements CanActivate {
  public validated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dataStore: DataStore,
    private dataStoreService: DataStoreService,
    private wizardService: WizardService,
    private authService: AuthService,
    private routingService: RoutingService,
    private router: Router
  ) {}

  public canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return (
      of(currentRoute)
        .pipe(withLatestFrom(this.wizardService.getCurrentStep(), this.dataStore.getRouteStatus()))
        // mapeia a rota atual, o passo atual e a proposta para as seguintes variáveis:
        .pipe(
          map(([route, currentStep, localStorageRouteData]: [ActivatedRouteSnapshot, StepInfo, RouteData[]]) => {
            let canActivate = true;
            const isCEF = this.dataStoreService.getCEFFlag();

            if (!this.authService.isLoggedIn() || this.dataStoreService.getUserData() === null) {
              this.routingService.navigateToLogin(false, false);
              return;
            }

            if (!isCEF && currentStep.step === 1) {
              this.wizardService.nextStep();
              return;
            }

            if (isCEF && (currentStep.step > 2 || (currentStep.step === 2 && currentStep.subStep > 10))) {
              this.wizardService.previousStep();
              return;
            }

            // Obtém rota de acordo com o passo e a etapa.
            const firstStepRouteName = getRouteNameByOrder(currentStep.step, currentStep.subStep);
            const routePathToBeActivated = this.getRoutePath(route);
            const stepInfo = getStepInfoByRoute(routePathToBeActivated);

            if (
              firstStepRouteName !== routePathToBeActivated ||
              (stepInfo.step > 1 && stepInfo.subStep > 1 && !this.validated$.getValue())
            ) {
              if (!this.validated$.getValue() && routePathToBeActivated === 'payment-data') {
                this.validated$.next(true);
                return canActivate;
              }

              canActivate = this.checkPreviousStepsValidity(localStorageRouteData);
            }
            return canActivate;
          })
        )
    );
  }

  private checkHasData(step: StepInfo, localStorageRouteData: RouteData[]) {
    const data = localStorageRouteData.find((item) => item.step === step.step && item.subStep === step.subStep);
    return data && data.currentData && Object.keys(data.currentData).length > 0;
  }

  private checkPreviousStepsValidity(localStorageRouteData: RouteData[]) {
    let canActivate = true;
    this.wizardService.stepsInfo.some((step, ind, arr) => {
      // Verifica se é o último passo, desconsiderando a tela de dados de pagamento e de proposta enviada
      if (ind >= arr.length - 3) {
        return;
      }

      if (!this.checkHasData(step, localStorageRouteData)) {
        const jumpSteps = this.dataStoreService.getLocalStorageObject(Constants.JUMP_STEPS_LOCALSTORAGE_KEY);

        if (!jumpSteps || !jumpSteps.some((x) => x.route === step.name && !!x.jump)) {
          this.wizardService.goToSpecificStep(step.step, step.subStep);
          canActivate = false;
          this.validated$.next(true);
          return true;
        }
      }
    });
    return canActivate;
  }

  private getRoutePath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig && route.routeConfig.path) {
      return route.routeConfig.path;
    } else {
      return route.url.map((segment) => segment.path).join('/');
    }
  }
}
