<h4 class="fd-subtitle">Declaração de aceitação aos termos e condições para uso de informações e dados pessoais e outras avenças</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <h4 class="fd-subtitle declaration-subtitle">Eu declaro que:</h4>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <span class="fd-text" [innerHTML]="getTermsText()"></span>
    <div>
      <fd-checkbox [parentForm]="formGroup" (change)="changeOption($event)" [field]="fields.accepted"> </fd-checkbox>
    </div>

    <div *ngIf="isOptinEnabled && fields.optIn.label && isAntecipationEnabled">
      <fd-checkbox [parentForm]="formGroup" (change)="changeOptionOptIn($event)" [field]="fields.optIn"> </fd-checkbox>
    </div>

    <div *ngIf="isOptinEdiEnabled && fields.optInEdi.label">
      <fd-checkbox [parentForm]="formGroup" (change)="changeOptionOptInEdi($event)" [field]="fields.optInEdi"> </fd-checkbox>
    </div>

    <fd-button #btnCancelar fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button
      #btnSubmit
      [content]="'enviar proposta'"
      [disabled]="!this.formControls.accepted || !this.formControls.accepted.value"
      id="btnSend"
      (trigger)="next()"
    >
    </fd-button>
  </div>
</form>
