<div class="pep-description-container">
  <h4 class="fd-subtitle pep-subtitle">Pessoa Politicamente Exposta?</h4>
  <fa-icon
    matTooltipClass="fd-input__tooltip-dialog"
    class="tooltip-icon"
    icon="info-circle"
    size="1x"
    mat-button
    [matTooltip]="getPepTooltipText()"
    #tooltip="matTooltip"
    (click)="showTooltipAndStopPropagation()"
  ></fa-icon>
</div>
<div>
  <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="politicalExposition" (change)="onChange($event)">
  </fd-radio-button>
</div>

<ng-container *ngIf="pepEnable">
  <div>
    <fd-input [parentForm]="formGroup" [field]="pepName"> </fd-input>
  </div>
  <div>
    <fd-input [parentForm]="formGroup" [field]="pepRole"> </fd-input>
  </div>
  <div>
    <fd-input [parentForm]="formGroup" [field]="pepGovernmentOrganization"> </fd-input>
  </div>
</ng-container>
