<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Canal de vendas</h4>

    <div *ngFor="let item of eCommerceTechnologyTypes" class="checkbox-container">
      <mat-checkbox
        [disabled]="hasSplitTech"
        (change)="onTechnologyTypeCheckboxChange(item, $event)"
        [value]="item.value"
        [checked]="item.selected"
        class="fd-checkbox"
        [id]="item.value"
        >{{ item.label }}</mat-checkbox
      >
    </div>

    <h4 class="fd-subtitle">Funcionalidades do E-commerce</h4>

    <div *ngFor="let item of eCommerceFunctionalities" class="checkbox-container">
      <mat-checkbox
        [disabled]="hasSplitTech"
        (change)="onECommerceFunctionalitiesCheckboxChange(item, $event)"
        [value]="item.value"
        [checked]="item.selected"
        class="fd-checkbox"
        [id]="item.value"
        >{{ item.label }}</mat-checkbox
      >
      <div *ngIf="item.selected && item.value === 'PAYMENT_SPLIT'">
        <fd-input (blur)="validateECMaster($event)" *ngIf="showCnpj" [parentForm]="formGroup" [field]="fields.cnpjMid"> </fd-input>
      </div>
    </div>

    <h4 class="fd-subtitle">URL/Redes Sociais na qual o estabelecimento expõe seus produtos ou serviços</h4>

    <div>
      <fd-input (blur)="validateUrl($event)" [parentForm]="formGroup" [field]="fields.link"> </fd-input>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
