import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { environment } from 'src/environments/environment.local';
import { ProposalDetailModel } from '../models/proposal-detail.model';
import { ProposalDetails } from '../models/proposal-details.model';
import { ProposalNote } from './../models/child-models/proposal-note';

@Injectable({
  providedIn: 'root',
})
export class ProposalDetailService {
  public agentChannel: string;

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
  }

  public getRegistrationDataDetails(proposalNumber: string): Observable<ProposalDetailModel> {
    return this.http.get<ProposalDetailModel>(`${environment.apiUrls.registrationDataDetail}/${proposalNumber}`);
  }

  public getOfferDataDetails(proposalNumber: string): Observable<ProposalDetailModel> {
    return this.http.get<ProposalDetailModel>(`${environment.apiUrls.offerDataDetail}/${proposalNumber}`);
  }

  public getProposalDataDetails(proposalNumber: string): Observable<ProposalDetailModel> {
    return this.http.get<ProposalDetailModel>(`${environment.apiUrls.proposalDataDetail}/${proposalNumber}`);
  }

  public getDeliveryInformationDataDetails(proposalNumber: string): Observable<ProposalDetailModel> {
    return this.http.get<ProposalDetailModel>(`${environment.apiUrls.deliveryInformationDataDetail}/${proposalNumber}`);
  }

  public getProposalNote(proposalNumber: string): Observable<ProposalNote[]> {
    return this.http.get<ProposalNote[]>(`${environment.apiUrls.proposalNote}/${proposalNumber}`);
  }

  public getDetailedProposal(proposalNumber: string): Observable<ProposalDetails> {
    return this.http
      .get<ProposalDetails>(`${environment.apiUrls.proposal}/${proposalNumber}/details`)
      .pipe(map((res) => this.mapToProposalDetails(res)));
  }

  private mapToProposalDetails(response: any): ProposalDetails {
    if (!response || !response.details) {
      return null;
    }
    return response.details as ProposalDetails;
  }
}
