<div fxLayoutGap="20px">
  <div fxFlex="100" id="nationality">
    <fd-select
      (loadItemsFn)="listNationality($event, nationality)"
      (selectionChange)="nationalityChange($event)"
      [parentForm]="formGroup"
      [field]="nationality"
    >
    </fd-select>
  </div>
  <div fxFlex="50" *ngIf="rneEnable">
    <fd-input [parentForm]="formGroup" [field]="rne"></fd-input>
  </div>
</div>
