import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateMask } from 'src/app/order/masks/date-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefActivationPeriodModel } from '../models/tef-activation-period.model';

import * as moment from 'moment';
import { WeekDayEnum } from 'src/app/order/enums/week-day.enum';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-activation-period',
  templateUrl: './activation-period.component.html',
  styleUrls: ['./activation-period.component.scss'],
})
export class ActivationPeriodComponent extends FormStep implements OnInit {
  get isRestrictedCnae() {
    return this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.restrictedCnaeSelected;
  }

  get hasECommerce() {
    return this.proposal && this.proposal.productSelection && !!this.proposal.productSelection.hasECommerce;
  }

  get hasTef() {
    return this.proposal && this.proposal.productSelection && !!this.proposal.productSelection.hasTef;
  }

  get hasEdi() {
    return this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasEdi;
  }

  public tefActivationPeriodModel: TefActivationPeriodModel;
  public proposal: Proposal;
  public weekDayOptions: Item[] = [
    {
      label: 'Domingo',
      value: WeekDayEnum.SUNDAY,
    },
    {
      label: 'Segunda-feira',
      value: WeekDayEnum.MONDAY,
    },
    {
      label: 'Terça-feira',
      value: WeekDayEnum.TUESDAY,
    },
    {
      label: 'Quarta-feira',
      value: WeekDayEnum.WEDNESDAY,
    },
    {
      label: 'Quinta-feira',
      value: WeekDayEnum.THURSDAY,
    },
    {
      label: 'Sexta-feira',
      value: WeekDayEnum.FRIDAY,
    },
    {
      label: 'Sábado',
      value: WeekDayEnum.SATURDAY,
    },
    {
      label: 'Todos',
      value: WeekDayEnum.ALL,
      markAll: true,
    },
  ];

  public currentDate;

  protected STEP_NUMBER = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.setMinDate();
    this.appendValidators();
  }

  public setMinDate() {
    this.currentDate = new Date(moment().format('DD/MM/YYYY'));
  }

  public btnNext() {
    if (!this.validateDate()) {
      return false;
    }
    this.next();
  }

  public async persistData(): Promise<boolean> {
    const rawValue = this.formGroup.getRawValue();

    let tefActivationPeriod = new TefActivationPeriodModel();

    tefActivationPeriod = {
      days: this.formControls.days.value as Item[],
      startDate: this.formatDate(this.formControls.dataInicial.value),
      endDate: this.formatDate(this.formControls.dataFinal.value),
      observation: this.formControls.observation.value,
    };

    return this.dataStore.updateProposal({ tefActivationPeriod });
  }

  public updateFields(proposal: Proposal) {
    this.tefActivationPeriodModel = proposal.tefActivationPeriod;

    if (this.tefActivationPeriodModel) {
      this.weekDayOptions.map(
        (x) =>
          (x.selected = this.tefActivationPeriodModel.days && this.tefActivationPeriodModel.days.map((x) => x.value).indexOf(x.value) > -1)
      );
      this.formControls.days.setValue(this.tefActivationPeriodModel.days);
      this.formControls.dataInicial.setValue(this.formatDateToFdInput(this.tefActivationPeriodModel.startDate));
      this.formControls.dataFinal.setValue(this.formatDateToFdInput(this.tefActivationPeriodModel.endDate));
      this.formControls.observation.setValue(this.tefActivationPeriodModel.observation);
    }
  }

  public createFields(): FdFieldConfigs {
    return {
      days: {
        label: 'Dias',
        controlName: 'days',
        items: this.weekDayOptions,
        modalTitle: 'Selecione um ou mais dias para a entrega:',
        placeholder: 'Selecione os dias',
        messages: {
          required: 'Informe um dia para entrega',
        },
      },
      observation: {
        label: 'Observação',
        controlName: 'observation',
      },
      dataInicial: {
        label: 'De',
        controlName: 'dataInicial',
        mask: DateMask,
        tooltipMessage: 'Informe a data inicial',
        messages: {
          invalid: 'Informe uma data válida',
          required: 'Informe a data inicial',
        },
      },
      dataFinal: {
        label: 'Até',
        controlName: 'dataFinal',
        mask: DateMask,
        tooltipMessage: 'Informe a data final',
        messages: {
          invalid: 'Informe uma data válida',
          required: 'Informe a data final',
        },
      },
    };
  }

  public appendValidators() {
    this.formControls.days.setValidators([Validators.required]);
    this.formControls.dataInicial.setValidators([Validators.required]);
    this.formControls.dataFinal.setValidators([Validators.required]);
  }

  public formatDate(data) {
    const [day, month, year] = data.split('/');

    const result = [year, month, day].join('-');

    return result;
  }

  public formatDateToFdInput(data) {
    const [year, month, day] = data.split('-');
    const result = [day, month, year].join('/');

    return result;
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      days: [''],
      observation: [''],
      dataInicial: [''],
      dataFinal: [''],
    });
  }
  protected proposalGetCallback(): void {}

  private validateDate(): boolean {
    const dataInicial = moment(this.formControls.dataInicial.value, 'DD/MM/YYYY').toDate();
    const dataFinal = moment(this.formControls.dataFinal.value, 'DD/MM/YYYY').toDate();
    const currentDate = new Date(moment().format('YYYY/MM/DD'));

    if (
      moment(this.formControls.dataInicial.value, 'DD/MM/YYYY', true).isValid() &&
      moment(this.formControls.dataFinal.value, 'DD/MM/YYYY', true).isValid()
    ) {
      if (dataInicial < currentDate) {
        this.dialogService.openDialog(Messages.INVALID_MIN_TEF_DATE);
        return false;
      } else if (dataFinal < currentDate) {
        this.dialogService.openDialog(Messages.INVALID_MAX_TEF_DATE);
        return false;
      } else if (dataInicial > dataFinal) {
        this.dialogService.openDialog(Messages.INVALID_MAX_AND_MIN_TEF_DATE);
        return false;
      }
    } else {
      this.dialogService.openDialog(Messages.INVALID_TEF_DATE);
      return false;
    }
    return true;
  }
}
