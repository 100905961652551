<h4 class="fd-subtitle">Horário de funcionamento:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-option-picker [parentForm]="formGroup" [field]="fields.businessDays"></fd-option-picker>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.weekOpeningHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.weekOpeningHour"
        >
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.weekClosingHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.weekClosingHour"
        >
        </fd-select>
      </div>
    </div>
    <div>
      <fd-label [text]="'Sábado e domingo'" [label]="'Dias'"></fd-label>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.weekendOpeningHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.weekendOpeningHour"
        >
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.weekendClosingHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.weekendClosingHour"
        >
        </fd-select>
      </div>
    </div>
    <div>
      <fd-button
        size="thin"
        fdSecondary
        *ngIf="showButtonStandardBusinessHours()"
        [content]="'horário comercial'"
        (trigger)="setStandardBusinessHours()"
      >
      </fd-button>
    </div>

    <h4 class="fd-subtitle">Fecha para o almoço?</h4>
    <div>
      <fd-radio-button (change)="clearFields($event)" [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.lunchClosing">
      </fd-radio-button>
    </div>
    <div *ngIf="closesForLunch" fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.lunchClosingHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.lunchClosingHour"
        >
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select
          (loadItemsFn)="loadHours($event, fields.lunchReopenHour.controlName)"
          [parentForm]="formGroup"
          [field]="fields.lunchReopenHour"
        >
        </fd-select>
      </div>
    </div>
    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
  </div>
</form>
