import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RatesClientValidation } from 'src/app/shared/models/rates-client-validation.model';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { environment } from 'src/environments/environment.local';

@Injectable()
export class ClientService {
  constructor(private http: HttpClient) {}

  public validateIfClientExists(requestCpfCnpj: string, institution: string): Observable<boolean> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .get<BaseApiResponse<boolean>>(`${environment.apiUrls.validateClient}/${institution}/${requestCpfCnpj}`)
      .pipe(map((x) => (this.invalidResponse(x) ? null : x.response)));
  }

  public validateTechnologyGroup(requestCpfCnpj: string, institution: string, technologyGroup: string): Observable<boolean> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .get<BaseApiResponse<boolean>>(`${environment.apiUrls.validateTechnologyGroup}/${technologyGroup}/${institution}/${requestCpfCnpj}`)
      .pipe(map((x) => (this.invalidResponse(x) ? null : x.response)));
  }

  public validateByHierarchyChannel(requestCpfCnpj: string, subChannel: string): Observable<boolean> {
    return this.http
      .get<BaseApiResponse<boolean>>(
        `${environment.apiUrls.validateByHierarchyChannel}/document/${requestCpfCnpj}/sub-channel/${subChannel}`
      )
      .pipe(map((x) => (this.invalidResponse(x) ? null : x.response)));
  }

  public validateRates(data: RatesClientValidation): Observable<boolean> {
    return this.http
      .post<BaseApiResponse<boolean>>(`${environment.apiUrls.validateByRates}`, data)
      .pipe(map((x) => (this.invalidResponse(x) ? null : x.response)));
  }

  private invalidResponse(baseApiResponse: BaseApiResponse<boolean>): boolean {
    return !baseApiResponse || baseApiResponse.error || !baseApiResponse.response;
  }
}
