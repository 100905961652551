import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cpfMask } from 'src/app/order/masks/document-masks';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { SurnameMask } from 'src/app/order/masks/surname-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { CpfValidator } from 'src/app/order/validators/cpf-validator';
import { PhoneValidator } from 'src/app/order/validators/phone-validator';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { DeliveryContactModel, Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { customDeliveryContactValidators } from './delivery-contact.validators';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-delivery-contact',
  templateUrl: './delivery-contact.component.html',
  styleUrls: ['./delivery-contact.component.scss'],
})
export class DeliveryContactComponent extends FormStep implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public deliveryContactModel: DeliveryContactModel;
  public fields: FdFieldConfigs;
  public proposal: Proposal;

  protected STEP_NUMBER = 5;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public clearFieldsValues() {
    this.formControls.cpf.setValue('');
    this.formControls.contactName.setValue('');
    this.formControls.emailAddress.setValue('');
    this.formControls.fixedNumberRequired.setValue('');
    this.formControls.fixedNumber.setValue('');
    this.formControls.mobileNumber.setValue('');
  }

  public handleError(error: any) {
    this.dialogService.openDialog(Messages.GENERAL_ERROR);
  }

  public createFields(): FdFieldConfigs {
    return {
      cpf: {
        label: 'CPF',
        controlName: 'cpf',
        type: InputType.TEL,
        mask: cpfMask,
        maskCharsReplace: /[. -]/g,
        messages: {
          required: 'Informe o CPF',
          invalidCpf: 'CPF inválido',
        },
      },
      contactName: {
        label: 'Nome',
        controlName: 'contactName',
        mask: SurnameMask,
        messages: {
          required: 'Informe o nome completo',
          invalid: 'Informe o nome completo',
        },
      },
      emailAddress: {
        label: 'E-mail',
        controlName: 'emailAddress',
        messages: {
          required: 'Informe o e-mail',
          pattern: 'E-mail inválido',
        },
      },
      fixedNumberRequired: {
        label: 'Telefone 1',
        controlName: 'fixedNumberRequired',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o telefone',
          invalid: 'Telefone inválido',
          pattern: 'Telefone inválido',
        },
      },
      fixedNumber: {
        label: 'Telefone 2',
        controlName: 'fixedNumber',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          invalid: 'Telefone inválido',
          pattern: 'Telefone inválido',
        },
      },
      mobileNumber: {
        label: 'Celular',
        controlName: 'mobileNumber',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o celular',
          invalid: 'Celular inválido',
          pattern: 'Celular inválido',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      contactName: ['', customDeliveryContactValidators.name],
      emailAddress: ['', customDeliveryContactValidators.emailAddress],
      fixedNumberRequired: ['', customDeliveryContactValidators.phoneNumber],
      fixedNumber: ['', PhoneValidator],
      mobileNumber: ['', customDeliveryContactValidators.cellPhoneNumber],
      cpf: ['', [Validators.required, CpfValidator]],
    });
  }
  protected proposalGetCallback(): void {}

  protected persistData() {
    const deliveryContact: DeliveryContactModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ deliveryContact });
  }

  protected updateFields(proposal: Proposal) {
    this.deliveryContactModel = proposal.deliveryContact;

    if (!this.deliveryContactModel) {
      return;
    }

    this.formControls.cpf.setValue(this.deliveryContactModel.cpf);
    this.formControls.contactName.setValue(this.deliveryContactModel.contactName);
    this.formControls.emailAddress.setValue(this.deliveryContactModel.emailAddress);
    this.formControls.fixedNumberRequired.setValue(this.deliveryContactModel.fixedNumberRequired);
    this.formControls.fixedNumber.setValue(this.deliveryContactModel.fixedNumber);
    this.formControls.mobileNumber.setValue(this.deliveryContactModel.mobileNumber);
  }
}
