import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { EnterpriseAdditionalQuestionsModel } from '../models/enterprise-additional-questions.model';

@Component({
  selector: 'app-enterprise-additional-questions',
  templateUrl: './enterprise-additional-questions.component.html',
  styleUrls: ['./enterprise-additional-questions.component.scss'],
})
export class EnterpriseAdditionalQuestionsComponent extends FormStep implements OnInit {
  get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }
  public STEP_NUMBER = 0;
  public enterpriseAdditionalQuestionsModel: EnterpriseAdditionalQuestionsModel;
  public transactionRisksAwareModalAccept: boolean;
  public prepaymentReservationModalAccept: boolean;

  public TRANSACTION_RISKS_AWARE_MODAL_MESSAGE =
    '<ul>\
                      <li>Informe o cliente dos riscos de perdas financeiras em função das vendas à distância..</li>\
                      <li>Isso significa que o % das vendas não poderá ser antecipada mesmo que o cliente tenha interesse.</li>\
                      <li> Este percentual varia caso a caso.</li>\
                    <ul/>';

  public PREPAYMENT_RESERVATION_MODAL_MESSAGE =
    '<ul>\
                      <li>A venda com pagamento online requer uma reserva para antecipação de recebíveis.</li>\
                      <li>As perdas podem ocorrer nos casos de transações fraudulentas e contestações de \
                      transações por parte do consumidor, gerando estorno ou chargeback.</li>\
                      <li>Oriente o cliente a guardar e-mails,\
                      WhatsApp, notas fiscais, recibos e comprovantes de entrega pois estes documentos podem \
                      ser solicitados em caso de disputa.</li>\
                    <ul/>';

  private readonly MAX_TRANSACTION_VALUE = 10000.0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private _cdr: ChangeDetectorRef
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.createFields();
    this._cdr.detectChanges();

    if (this.hasSplitTech) {
      this.setValueDefaultSplitTech();
    } else {
      this.formControls.transactionRisksAware.valueChanges.subscribe((value) => {
        if (!this.transactionRisksAwareModalAccept) {
          this.dialogService.openDialogWithHtmlMessage(
            this.TRANSACTION_RISKS_AWARE_MODAL_MESSAGE,
            () => (this.transactionRisksAwareModalAccept = true),
            'Entendi'
          );
        }
      });

      this.formControls.prepaymentReservationAcceptance.valueChanges.subscribe((value) => {
        if (!this.prepaymentReservationModalAccept) {
          this.dialogService.openDialogWithHtmlMessage(
            this.PREPAYMENT_RESERVATION_MODAL_MESSAGE,
            () => (this.prepaymentReservationModalAccept = true),
            'Entendi'
          );
        }
      });
    }
  }

  public persistData() {
    const enterpriseAdditionalQuestions: EnterpriseAdditionalQuestionsModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ enterpriseAdditionalQuestions });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      maxTransactionValue: ['', Validators.required],
      canReceiveFromInternationalCards: [true, Validators.required],
      stockCheck: ['', Validators.required],
      securityCheck: ['', Validators.required],
      documentCheck: ['', Validators.required],
      transactionRisksAware: [true, Validators.required],
      prepaymentReservationAcceptance: [true, Validators.required],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.enterpriseAdditionalQuestionsModel = proposal.enterpriseAdditionalQuestions;

    if (this.enterpriseAdditionalQuestionsModel) {
      this.formControls.maxTransactionValue.setValue(this.enterpriseAdditionalQuestionsModel.maxTransactionValue);
      this.formControls.canReceiveFromInternationalCards.setValue(this.enterpriseAdditionalQuestionsModel.canReceiveFromInternationalCards);
      this.formControls.stockCheck.setValue(this.enterpriseAdditionalQuestionsModel.stockCheck);
      this.formControls.securityCheck.setValue(this.enterpriseAdditionalQuestionsModel.securityCheck);
      this.formControls.documentCheck.setValue(this.enterpriseAdditionalQuestionsModel.documentCheck);
      this.formControls.transactionRisksAware.setValue(this.enterpriseAdditionalQuestionsModel.transactionRisksAware);
      this.formControls.prepaymentReservationAcceptance.setValue(this.enterpriseAdditionalQuestionsModel.prepaymentReservationAcceptance);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      maxTransactionValue: {
        label: 'Valor',
        controlName: 'maxTransactionValue',
        messages: {
          required: 'Informe o valor',
        },
      },
      canReceiveFromInternationalCards: {
        items: [],
        controlName: 'canReceiveFromInternationalCards',
        messages: {
          required: 'Informe uma opção',
        },
      },
      stockCheck: {
        label: 'Descreva',
        controlName: 'stockCheck',
        maxLength: 3500,
        messages: {
          required: 'Informe uma descrição',
        },
      },
      securityCheck: {
        label: 'Descreva',
        controlName: 'securityCheck',
        maxLength: 3500,
        messages: {
          required: 'Informe uma descrição',
        },
      },
      documentCheck: {
        label: 'Descreva',
        controlName: 'documentCheck',
        maxLength: 3500,
        messages: {
          required: 'Informe uma descrição',
        },
      },
      transactionRisksAware: {
        items: [],
        controlName: 'transactionRisksAware',
        messages: {
          required: 'Informe uma opção',
        },
      },
      prepaymentReservationAcceptance: {
        items: [],
        controlName: 'prepaymentReservationAcceptance',
        messages: {
          required: 'Informe uma opção',
        },
      },
    };
  }

  private setValueDefaultSplitTech() {
    this.formGroup.controls.maxTransactionValue.setValue(this.MAX_TRANSACTION_VALUE);
    this.formGroup.controls.maxTransactionValue.disable();
    this.formGroup.controls.stockCheck.setValue('-');
    this.formGroup.controls.stockCheck.disable();
    this.formGroup.controls.securityCheck.setValue('-');
    this.formGroup.controls.securityCheck.disable();
    this.formGroup.controls.documentCheck.setValue('-');
    this.formGroup.controls.documentCheck.disable();
    this.formGroup.controls.canReceiveFromInternationalCards.setValue(true);
    this.formGroup.controls.canReceiveFromInternationalCards.disable();
    this.formGroup.controls.transactionRisksAware.setValue(true);
    this.formGroup.controls.transactionRisksAware.disable();
    this.formGroup.controls.prepaymentReservationAcceptance.setValue(false);
    this.formGroup.controls.prepaymentReservationAcceptance.disable();
  }
}
