export enum PaymentStatusEnum {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
    NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum PaymentStatusCodeEnum {
    APPROVED = '00',
    PENDING = '01',
    REJECTED = '02',
    EXPIRED = '03',
    NOT_APPLICABLE = '04'
}
