import { Directive, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';

@Directive()
export class FdFieldBaseComponent implements ControlValueAccessor {
  @Input()
  set errors(value: ValidationErrors) {
    this.errorIds = (value && Object.keys(value)) || [];
  }

  set value(value: any) {
    this._value = value;
    this.onChange(value);
  }
  get value(): any {
    return this._value;
  }

  @HostBinding('class.fd-field--disabled') public disabled = false;

  public errorIds: string[] = [];

  private _value: any;
  private _onChange: (anything) => void;
  private _onTouched: () => void;

  public onTouched(): void {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  public onChange(value: any): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  public writeValue(value: any): void {
    this._value = value;
  }

  public registerOnChange(fn: (anything) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
