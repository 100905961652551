import { AnimationEvent } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { RouteProperties, getAllSteps } from '../../routing/routing-definitions';
import { WizardService } from '../../services/internal/wizard/wizard.service';
import { DataStoreService } from '../../store/data-store.service';
import { ballColor, lineCollapse, stepCollapse, subStepCollapse } from '../animations/fd-animations';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  animations: [subStepCollapse, lineCollapse, stepCollapse, ballColor],
})
export class WizardComponent implements OnDestroy {
  get isLastStep() {
    return (
      Math.max.apply(
        Math,
        this.steps.map((x) => x.stepNumber)
      ) === this.currentStep
    );
  }

  public hasAnyDoneStep = false;
  public collapseStep = false;
  public routingDefinitions = getAllSteps();
  public totalSubSteps: number;
  public currentStep: number;
  public currentSubStep: number;
  public stepName: string;
  public alive = true;
  public isCEF = this.dataStoreService.getCEFFlag();

  public steps: Step[] = [];
  public subSteps: RouteProperties[];

  constructor(private wizardService: WizardService, private dataStoreService: DataStoreService) {
    this.routingDefinitions.forEach((route) => {
      this.steps.push({
        stepNumber: route.stepNumber,
        stepName: route.stepName,
        subSteps: Object.values(route.routes).map((val) => {
          const obj: SubStep = {
            subStepNumber: val.order,
            active: false,
          };

          return obj;
        }),
        active: false,
        done: false,
        collapsed: false,
      });
    });

    this.wizardService
      .getCurrentStep()
      .pipe(takeWhile(() => this.alive))
      .subscribe((current) => {
        this.currentSubStep = current.subStep;
        this.currentStep = current.step;
        this.stepName = this.getStepName(current.step);

        this.setStepAsActive(current.step);
        this.setSubStepAsActive(current.step, current.subStep);

        this.totalSubSteps = this.getTotalSubSteps(current.step);
      });
  }

  public getStepStatus(index: number) {
    if (!this.steps[index].active) {
      if (this.steps[index].done) {
        return 'stepDone';
      }
      return 'stepInactive';
    } else if (this.steps[index].active) {
      return 'stepActive';
    }
  }

  public triggerNextAction(event: AnimationEvent, index: number) {
    if (event.toState === 'stepDone') {
      if (this.hasAnyDoneStep) {
        this.steps[index].collapsed = true;
      } else {
        this.hasAnyDoneStep = true;
      }
    }
  }

  public triggerStepCollapse(event: AnimationEvent) {
    if (event.toState === 'collapse') {
      this.collapseStep = true;
    }
  }

  public getLineCollapseStatus() {
    if (this.isLastStep) {
      return 'end';
    } else if (this.hasAnyDoneStep) {
      return 'collapse';
    } else {
      return 'uncollapse';
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getStepName(stepIndex: number): string {
    stepIndex = stepIndex - 2;
    return this.steps[stepIndex].stepName;
  }

  public setStepAsActive(stepIndex: number) {
    // index zero based
    stepIndex = stepIndex - 2;
    this.steps.forEach((step, index) => {
      if (index === stepIndex) {
        step.active = true;
        step.collapsed = false;
        step.done = false;

        if (stepIndex === 0) {
          this.hasAnyDoneStep = false;
        }

        return;
      }
      if (index > stepIndex) {
        step.done = false;
      } else if (index < stepIndex) {
        step.done = true;
        this.hasAnyDoneStep = true;
      }
      step.active = false;
    });
  }

  public setSubStepAsActive(stepIndex: number, subStepIndex: number) {
    // index zero based
    stepIndex = stepIndex - 2;
    subStepIndex = subStepIndex - 1;
    const step = this.steps[stepIndex];

    step.subSteps.forEach((subStep, index) => {
      if (index <= subStepIndex) {
        subStep.active = true;
        return;
      }
      subStep.active = false;
    });
  }

  public getTotalSubSteps(stepIndex: number): number {
    return this.steps.filter((step) => step.stepNumber === stepIndex).map((x) => x.subSteps)[0].length;
  }
}

export interface Step {
  stepNumber: number;
  stepName: string;
  subSteps: SubStep[];
  active: boolean;
  done: boolean;
  collapsed: boolean;
}

export interface SubStep {
  subStepNumber: number;
  active: boolean;
}
