import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { OfferTypeEnum } from 'src/app/order/enums/offer-type.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FeeTypeModel, Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

import { CnaeCreditRiskService } from 'src/app/order/services/external/cnae-credit-risk/cnae-credit-risk.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { CnaeCreditRiskModel } from 'src/app/shared/models/response/response-cnae-credit-risk.model';
import { FeeService } from '../../../services/external/fee/fee.service';
import { SearchCnaeCreditRiskModel } from './models/search-cnae-credit-risk.model';
import { MdrFlexFeeModel } from '../../../../shared/models/response/response-mdr-flex-fee.model';

@Component({
  selector: 'app-fee-type',
  templateUrl: './fee-type.component.html',
  styleUrls: ['./fee-type.component.scss'],
})
export class FeeTypeComponent extends FormStep implements OnInit {
  get isMdrFlex() {
    if (this.formControls.feeType.value === FeeTypeEnum.FLEX) {
      return true;
    } else {
      return false;
    }
  }

  public proposal: Proposal;
  public feeTypeModel: FeeTypeModel;
  public oldFeeTypeModel: FeeTypeModel;
  public configuration: ConfigurationModel;
  public feeType: FeeTypeEnum;
  public ableExternalSchedule: boolean = false;

  public STEP_NUMBER = 4;
  public optionsFee: Item[] = [
    {
      label: 'MDR',
      value: FeeTypeEnum.MDR,
    },
    {
      label: this.dataStoreService.getCEFFlag() ? 'MDR com Antecipação' : 'MDR Flex',
      value: FeeTypeEnum.FLEX,
    },
  ];
  public isCEF: boolean;
  public optionsEnablePrepayment: Item[] = [
    {
      label: 'Habilitar',
      value: true,
    },
    {
      label: 'Não habilitar',
      value: false,
    },
  ];

  public optionsPrepayment: Item[] = [
    {
      label: 'Antecipação Automática',
      value: false,
    },
    {
      label: 'Antecipação Automática e Agenda Externa',
      value: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    protected feeService: FeeService,
    protected cnaeCreditRiskService: CnaeCreditRiskService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.setStepJump(
      orderRoutingDefinitions.offer.stepNumber,
      orderRoutingDefinitions.offer.routes.FEE_TYPE.order,
      (!this.configuration && this.configuration.ableMdrBillingCharge) ||
        (!this.configuration && this.configuration.ableMdrFlexBillingCharge && !this.configuration && this.configuration.ableAnticipation)
    );

    if (this.proposal.productSelection && this.proposal.productSelection.hasSplitTech) {
      this.splitTechOpts();
    } else if (!this.configuration && this.configuration.ableMdrBillingCharge) {
      this.formControls.feeType.setValue(FeeTypeEnum.FLEX);
      this.formControls.enablePrepayment.setValue(false);
      this.next();
    } else if (!this.configuration && this.configuration.ableMdrFlexBillingCharge) {
      if (!this.configuration && this.configuration.ableAnticipation) {
        this.formControls.feeType.setValue(FeeTypeEnum.MDR);
        this.formControls.enablePrepayment.setValue(false);
        this.next();
      } else {
        (this.fields.feeType as FdRadioButtonConfig).items = [
          {
            label: 'MDR',
            value: FeeTypeEnum.MDR,
          },
        ];
        this.feeType = FeeTypeEnum.MDR;
        this.formControls.feeType.setValue(FeeTypeEnum.MDR);
      }
    }

    this.getReceivingDeadLine();
    this.getAbleExternalSchedule();

    this.formControls.enablePrepayment.setValidators([
      RequiredIfValidator(() => this.configuration && this.configuration.ableAnticipation),
    ]);
    this.formControls.receivingDeadline.setValidators([RequiredIfValidator(() => this.isMdrFlex)]);
    this.updateCnaeCreditRiskOpts();
  }

  public getAbleExternalSchedule() {
    this.configurationService.getAbleExternalSchedule().subscribe(
      (ableExternalSchedule: boolean) => {
        this.ableExternalSchedule = ableExternalSchedule;
      },
      (error) => {
        console.error(error);
        this.ableExternalSchedule = false;
      }
    );
  }

  public hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }

  public async persistData() {
    const feeTypeModel: FeeTypeModel = {
      ...this.formGroup.getRawValue(),
    };

    if (this.oldFeeTypeModel) {
      if (feeTypeModel.enablePrepayment !== this.oldFeeTypeModel.enablePrepayment) {
        this.proposal.mdrFeeModel = null;
      }
      if (!feeTypeModel.enablePrepayment) {
        this.proposal.prepaymentFeeModel = null;
      } else {
        if (feeTypeModel.anticipationExternal !== this.oldFeeTypeModel.anticipationExternal) {
          this.proposal.prepaymentFeeModel = null;
        }
      }
    }

    const mdrFeeModel = this.proposal.mdrFeeModel;
    const prepaymentFeeModel = this.proposal.prepaymentFeeModel;

    return await this.dataStore.updateProposal({ feeTypeModel, mdrFeeModel, prepaymentFeeModel });
  }

  public onChangeFeeType(event: FeeTypeEnum) {
    this.feeType = event;

    if (this.feeType === FeeTypeEnum.FLEX) {
      this.onChangeEnablePrepayment(false);
      this.formControls.receivingDeadline.setValidators([RequiredIfValidator(() => true)]);
    } else {
      this.onChangeEnablePrepayment(true);
      this.formControls.receivingDeadline.setValidators([RequiredIfValidator(() => false)]);
    }
  }

  public onChangeEnablePrepayment(event: boolean) {
    this.setValueFormEnablePrepayment(event);
    this.setValueFormAnticipationExternal(false);
  }

  public onChangeAnticipationExternal(event: boolean) {
    this.setValueFormAnticipationExternal(event);
  }

  public setValueFormEnablePrepayment(value: boolean) {
    this.formControls.enablePrepayment.setValue(value);

    (this.fields.enablePrepayment as FdRadioButtonConfig).items.forEach((item) => {
      item.selected = item.value === value;
    });
  }

  public setValueFormAnticipationExternal(value: boolean) {
    this.formControls.anticipationExternal.setValue(value);

    (this.fields.anticipationExternal as FdRadioButtonConfig).items.forEach((item) => {
      item.selected = item.value === value;
    });
  }

  public getReceivingDeadLine() {
    this.feeService
      .getFactorFeesReceivingDeadLine(
        this.proposal.offerDataDetails.cnae,
        this.proposal.offerDataDetails.revenueRange,
        this.proposal.offerDataDetails.campaign
      )
      .toPromise()
      .then((response) => {
        if (response && response.length) {
          const arr = response.map(
            (x) =>
              ({
                value: x,
                label: `Prazo: ${x} dias`,
              } as Item)
          );
          (this.fields.receivingDeadline as FdSelectConfig).items = [];
          (this.fields.receivingDeadline as FdSelectConfig).items.push(...arr);
        }
      })
      .catch((error) => {
        this.dialogService.openDialog(Messages.NOT_FOUND_MCC_FOR_CNAE, () => this.back());
      });
  }

  public next() {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.setStepJump(
            orderRoutingDefinitions.offer.stepNumber,
            orderRoutingDefinitions.offer.routes.FEE_EDIT.order,
            this.formGroup.value.feeType === FeeTypeEnum.FLEX
          );

          this.setStepJump(
            orderRoutingDefinitions.offer.stepNumber,
            orderRoutingDefinitions.offer.routes.MDR_FLEX_FEE_EDIT.order,
            this.formGroup.value.feeType === FeeTypeEnum.MDR
          );

          this.setStepJump(
            orderRoutingDefinitions.offer.stepNumber,
            orderRoutingDefinitions.offer.routes.SALES_DATA.order,
            this.proposal.offerDataType.offerType === OfferTypeEnum.RENTAL
          );

          this.wizardService.nextStep();
        }
      });
    }
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      feeType: ['', Validators.required],
      enablePrepayment: [''],
      receivingDeadline: [''],
      anticipationExternal: [''],
    });
  }

  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.offerDataType || !this.proposal.offerDataType.offerType) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Taxas',
        () => this.back(),
        null,
        'error'
      );
    }
    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
  }

  protected updateFields(proposal: Proposal) {
    this.feeTypeModel = proposal.feeTypeModel;
    if (this.feeTypeModel) {
      this.oldFeeTypeModel = JSON.parse(JSON.stringify(this.feeTypeModel));

      this.changeFeeType(this.feeTypeModel.feeType);

      this.changeEnablePrepayment(this.feeTypeModel.enablePrepayment);

      this.changeAnticipationExternal(this.feeTypeModel.anticipationExternal ?? false);

      this.formControls.receivingDeadline.setValue(proposal.feeTypeModel.receivingDeadline);
    }
  }

  public changeFeeType(value: FeeTypeEnum) {
    this.feeType = value;
    this.setValueFormFeeType(this.feeTypeModel.feeType);
  }

  public changeAnticipationExternal(value: boolean) {
    this.feeTypeModel.anticipationExternal = value;
    this.setValueFormAnticipationExternal(value);
  }

  public changeEnablePrepayment(value: boolean) {
    this.feeTypeModel.enablePrepayment = value;
    this.setValueFormEnablePrepayment(value);
  }

  public setValueFormFeeType(value: FeeTypeEnum) {
    this.formControls.feeType.setValue(value);

    (this.fields.feeType as FdRadioButtonConfig).items.forEach((item) => {
      item.selected = item.value === value;
    });
  }

  protected createFields(): FdFieldConfigs {
    return {
      feeType: {
        items: this.optionsFee,
        controlName: 'feeType',
        messages: {
          required: 'Informe o tipo de taxa',
          invalid: 'Tipo de taxa inválida',
        },
      },
      enablePrepayment: {
        items: this.optionsEnablePrepayment,
        controlName: 'enablePrepayment',
        messages: {
          required: 'Informe a antecipação automática',
          invalid: 'Antecipação automática inválida',
        },
      },
      receivingDeadline: {
        controlName: 'receivingDeadline',
        messages: {
          required: 'Informe o prazo de recebimento',
          invalid: 'Antecipação automática inválida',
        },
      },
      anticipationExternal: {
        items: this.optionsPrepayment,
        controlName: 'anticipationExternal',
        messages: {
          required: 'Informe o tipo de antecipação automática',
          invalid: 'Tipo antecipação automática inválida',
        },
      },
    };
  }

  private splitTechOpts() {
    (this.fields.feeType as FdRadioButtonConfig).items = [
      {
        label: 'MDR',
        value: FeeTypeEnum.MDR,
      },
    ];

    (this.fields.enablePrepayment as FdRadioButtonConfig).items = [
      {
        label: 'Não Habilitar',
        value: false,
      },
    ];

    this.formControls.feeType.setValue(FeeTypeEnum.MDR);
    this.formControls.enablePrepayment.setValue(false);
  }

  private updateCnaeCreditRiskOpts() {
    const filter = new SearchCnaeCreditRiskModel();
    filter.cnaeNumber = this.proposal.offerDataDetails.cnae;
    filter.serviceContract = this.configuration.serviceContract;

    this.cnaeCreditRiskService
      .getCnaeCreditRisk(filter)
      .toPromise()
      .then((response) => this.cnaeCreditRiskOptsConfig(response))
      .catch((error) => {
        this.dialogService.openDialog(Messages.GENERAL_ERROR);
      });
  }

  private cnaeCreditRiskOptsConfig(cnaeCreditRisk: CnaeCreditRiskModel) {
    if (cnaeCreditRisk === null) {
      return;
    }

    if (cnaeCreditRisk.mdrFlexAllowed) {
      (this.fields.feeType as FdRadioButtonConfig).items = [
        {
          label: 'MDR',
          value: FeeTypeEnum.MDR,
        },
      ];
      this.formControls.feeType.setValue(FeeTypeEnum.MDR);
      this.feeType = this.formControls.feeType.value;
    }

    if (cnaeCreditRisk.prepaymentAllowed) {
      (this.fields.enablePrepayment as FdRadioButtonConfig).items = [
        {
          label: 'Não Habilitar',
          value: false,
        },
      ];
      this.formControls.enablePrepayment.setValue(false);
    }
  }
}
