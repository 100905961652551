import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FdSelectConfig } from '../fd-select/fd-select.component';

@Component({
  selector: 'fd-checkbox',
  templateUrl: './fd-checkbox.component.html',
  styleUrls: ['./fd-checkbox.component.scss'],
})
export class FdCheckboxComponent implements OnInit {
  @Input()
  public field: FdSelectConfig;

  @Input()
  public parentForm: FormGroup;

  constructor() {}

  public async ngOnInit() {
    if (this.relatedFormControl.value === null || this.relatedFormControl.value === undefined) {
      this.relatedFormControl.setValue(false);
    }
  }

  get hasError() {
    return this.relatedFormControl.errors !== null && this.relatedFormControl.touched;
  }

  get errorMessages() {
    const test = Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);

    return test;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }
}
