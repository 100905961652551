<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-select
        (loadItemsFn)="getCnaeList($event)"
        [parentForm]="formGroup"
        [field]="fields.cnae"
        (selectionChange)="getRevenueRangeList()"
      >
      </fd-select>
    </div>

    <div>
      <fd-currency-input [parentForm]="formGroup" (blur)="autoCompleteRevenueRange()" [field]="fields.annualRevenue"> </fd-currency-input>
    </div>

    <div>
      <fd-select
        (loadItemsFn)="getCampaignList()"
        [parentForm]="formGroup"
        (selectionChange)="getRevenueRangeList()"
        [field]="fields.campaign"
      ></fd-select>
    </div>
    <div>
      <fd-select [parentForm]="formGroup" [field]="fields.revenueRange"></fd-select>
    </div>

    <div>
      <fd-currency-input [parentForm]="formGroup" [field]="fields.annualVolumeSalesCardGroup"> </fd-currency-input>
    </div>

    <div>
      <fd-currency-input [parentForm]="formGroup" [field]="fields.averageTicket"> </fd-currency-input>
    </div>

    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
  </div>
</form>
