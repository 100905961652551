<div class="fd-proposal-detail__header" *ngIf="proposalStatusModel">
  <img class="fd-proposal-detail__status-img" [src]="imagePath" />
  <span
    class="fd-proposal-detail__title-text"
    [ngClass]="{
      'fd-proposal-detail__title-text--orange':
        proposalStatusModel.proposalStatus === 'AWAITING PAYMENT' && proposalStatusModel.paymentStatus !== 'NOT_APPLICABLE',
      'fd-proposal-detail__title-text--green': proposalStatusModel.proposalStatus === 'APPROVED',
      'fd-proposal-detail__title-text--red':
        proposalStatusModel.proposalStatus === 'DENIED' || proposalStatusModel.proposalStatus === 'DENIED - INTEGRATOR',
      'fd-proposal-detail__title-text--yellow':
        proposalStatusModel.proposalStatus === 'IN ANALYSIS' ||
        (proposalStatusModel.proposalStatus === 'AWAITING PAYMENT' && proposalStatusModel.paymentStatus === 'NOT_APPLICABLE') ||
        proposalStatusModel.proposalStatus === 'IN CREDIT MANUAL ANALYSIS'
    }"
    >{{ cardTitle }}</span
  >
</div>

<div class="fd-proposal-detail__accordion" *ngIf="success">
  <app-business-data-detail
    [businessData]="detailedProposal?.businessData"
    [commercialAddressDetails]="detailedProposal?.commercialAddress"
  >
  </app-business-data-detail>
  <app-owner-data-detail [ownerData]="detailedProposal?.ownerData" [contactPerson]="detailedProposal?.contactPerson">
  </app-owner-data-detail>
  <app-banking-data-detail [bankData]="detailedProposal?.bankData"></app-banking-data-detail>
  <app-commercial-conditions-data-detail
    [technologyData]="detailedProposal?.technologyData"
    [commercialConditions]="detailedProposal?.commercialConditions"
  >
  </app-commercial-conditions-data-detail>
  <app-contracted-services-data-detail [contractedServices]="detailedProposal?.contractedServices" [rateData]="detailedProposal?.rateData">
  </app-contracted-services-data-detail>
  <app-delivery-installation-details
    [deliveryAndInstallation]="detailedProposal?.deliveryAndInstallation"
    [workingDays]="detailedProposal?.workingDays"
    [deliveryAddress]="detailedProposal?.deliveryAddress"
  >
  </app-delivery-installation-details>
  <app-credit-risk-notes-detail
    [obsRiskCredit]="detailedProposal?.obsRiskCredit"
    [proposalRejection]="detailedProposal?.proposalRejectionReason"
  ></app-credit-risk-notes-detail>
</div>

<fd-button [content]="'Voltar'" (trigger)="navigateToHome()"> </fd-button>
