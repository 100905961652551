import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function dateValidator(allowFuture = false, allowPast = true, onlyMonthAndYear = false): ValidatorFn {
  const allowPre1900 = false;
  const rightNow = moment();
  const firstDateIn1900 = moment('1900-01-01', 'YYYY-MM-DD', true);

  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return {};
    }

    let inputDate;

    if (onlyMonthAndYear) {
      inputDate = moment(control.value, 'MM/YYYY', true);
    } else {
      let value = control.value;

      if (typeof control.value === 'object') {
        value = moment(control.value).format('DD/MM/YYYY');
      }

      if (value.indexOf('/') > -1) {
        value = value.replace(/\//g, '');
      }
      inputDate = moment(value, 'DDMMYYYY', true);
    }

    let isDateValid = inputDate.isValid();

    if (isDateValid && !allowPast) {
      isDateValid = inputDate.isSameOrAfter(rightNow);
    }

    if (isDateValid && !allowFuture) {
      isDateValid = inputDate.isSameOrBefore(rightNow);
    }

    if (isDateValid && !allowPre1900) {
      isDateValid = inputDate.isSameOrAfter(firstDateIn1900);
    }

    return isDateValid ? {} : { invalid: true };
  };
}

export function dateAgeValidator(minYears: number, maxYears: number): ValidatorFn {
  const rightNow = moment();

  return (control: AbstractControl): ValidationErrors => {
    const inputDate = moment(control.value, 'DD/MM/YYYY', true);

    const yearDiff = rightNow.diff(inputDate, 'years');
    if (yearDiff < minYears || yearDiff > maxYears) {
      return { invalidRange: true };
    }

    return {};
  };
}
