  <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="fields">
    <div>
      <fd-input (blur)="cepChange($event)" [parentForm]="formGroup" [field]="fields.zipCode">
      </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.address">
      </fd-input>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.addressNumber">
        </fd-input>
      </div>
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.complement">
        </fd-input>
      </div>
    </div>
    <div class="checkbox-container">
      <mat-checkbox (change)="onCheckboxChange(withoutNumber, $event)" [disabled]="isDisabled"
        [value]="withoutNumber.value" [checked]="withoutNumber.selected" class="fd-checkbox" [id]="withoutNumber.value">{{withoutNumber.label}}
      </mat-checkbox>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.neighborhood">
      </fd-input>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="80">
        <fd-input [parentForm]="formGroup" [field]="fields.city">
        </fd-input>
      </div>
      <div fxFlex="20">
        <fd-input [parentForm]="formGroup" [field]="fields.state">
        </fd-input>
      </div>
    </div>
  </div>
