import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeWhile, tap } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/internal/operators';
import { Messages } from 'src/app/order/messages/order.messages';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-input',
  templateUrl: 'fd-input.component.html',
  styleUrls: ['fd-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FdInputComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  },
})
export class FdInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    this.cdr.detectChanges();
    return this.hasError;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl && this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get shouldLabelFloat() {
    return this.focused;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get required(): boolean {
    return (
      this.relatedFormControl &&
      this.relatedFormControl.validator &&
      this.relatedFormControl.validator(this.relatedFormControl) !== null &&
      this.relatedFormControl.validator(this.relatedFormControl).hasOwnProperty(this.REQUIRED_VALIDATOR_KEY)
    );
  }

  get hasIcon(): boolean {
    return this.field && !!this.field.iconHTML;
  }
  public static nextId = 0;
  public alive = true;

  @ViewChild('tooltip') public tooltip: MatTooltip;

  @Input()
  public field: FdInputConfig;

  @Output()
  public input = new EventEmitter<string>();

  @Input()
  public parentForm: FormGroup;

  @Output()
  public blur = new EventEmitter<string>();

  public stateChanges = new Subject<void>();
  public focused = false;
  public errorState = false;
  public controlType = 'fd-input';
  public describedBy = '';
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  private _placeholder: string;

  constructor(
    formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    focusMonitor
      .monitor(elementRef, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe((origin) => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  public onChange = (_: any) => {};
  public onTouched = () => {};

  public showTooltipAndStopPropagation($event: any) {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  public unmask(control: AbstractControl, maskCharsReplace: RegExp): string {
    let unmaskedValue = '';
    if (!control) {
      return;
    }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, {
              emitEvent: false,
              emitModelToViewChange: false,
            });
          }
        })
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    return unmaskedValue;
  }

  public ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public async ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if (this.field && this.field.mask) {
      this.unmask(this.relatedFormControl, this.field.maskCharsReplace);
    }

    if (this.field && this.field.iconHTML) {
    }
  }

  public setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      if (this.field.isNumber) {
        this.blur.emit(inputValue.replace(/\D+/g, ''));
      }
      this.blur.emit(inputValue);
    }
    event.stopPropagation();
  }

  public handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }
}

export type Mask = (RegExp | string)[];

export enum InputType {
  DATE = 'date',
  EMAIL = 'email',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  RANGE = 'range',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week',
}

export interface FdInputConfig {
  controlName: string;
  label: string;
  tooltipMessage?: string;
  hint?: string;
  iconHTML?: SafeHtml;
  maxLength?: number;
  disabled?: boolean;
  type?: InputType;
  mask?: Mask | ((value: string) => Mask);
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
  isNumber?: boolean;
}
