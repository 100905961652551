import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { cpfCnpjMask } from 'src/app/order/masks/document-masks';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { CpfValidator } from 'src/app/order/validators/cpf-validator';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit {
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;

  public loginActive = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    private loadingService: LoadingService
  ) {}

  public async ngOnInit() {
    this.startForms();
  }

  public startForms(): void {
    this.formGroup = this.formBuilder.group({
      cpf: ['', [Validators.required, CpfValidator]],
    });

    this.fields = {
      cpf: {
        label: 'CPF/CNPJ',
        disabled: false,
        controlName: 'cpf',
        mask: cpfCnpjMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um CPF válido',
          invalid: 'CPF inválido',
        },
      },
    };
  }

  public sendRecoveryLink() {}
}
