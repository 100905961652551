<form *ngIf="formGroup" [formGroup]="formGroup">
  <div formArrayName="partnerForms">
    <form [formGroupName]="i" *ngFor="let childFormGroup of partnerFormsControls; let i = index">
      <h4 class="fd-subtitle">Dados do proprietário {{ i + 1 }}:</h4>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <ng-container *ngIf="!documentCategory || fieldsArray[i].documentCategory.context === 'CPF'">
          <div>
            <fd-input
              (blur)="cpfCnpjChange(i, $event)"
              (change)="(setDocumentCategoryAndRemoveValidation)"
              [parentForm]="childFormGroup"
              [field]="fieldsArray[i].cpfCnpj"
            >
            </fd-input>
          </div>
          <div>
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].name"> </fd-input>
          </div>
          <div>
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].birthDate"> </fd-input>
          </div>
          <div *ngIf="motherDataEnabled">
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].motherName"> </fd-input>
          </div>
          <fd-nationality
            [formGroup]="childFormGroup"
            [nationality]="fieldsArray[i].nationality"
            [rne]="fieldsArray[i].rne"
            [rneEnabled]="fieldsArray[i].rneEnabled"
            [nationalityList]="nationalityList"
          ></fd-nationality>
          <div>
            <fd-currency-input [parentForm]="childFormGroup" [field]="fieldsArray[i].companyParticipation"></fd-currency-input>
          </div>

          <fd-pep
            [formGroup]="childFormGroup"
            [politicalExposition]="fieldsArray[i].politicalExposition"
            [pepName]="fieldsArray[i].pepName"
            [pepRole]="fieldsArray[i].pepRole"
            [pepGovernmentOrganization]="fieldsArray[i].pepGovernmentOrganization"
          ></fd-pep>

          <ng-container *ngIf="isSerproPersonalInfoPageStrategy">
            <ng-container *ngIf="!fieldsArray[i].legalRepresentativeEnable.disabled">
              <h4 class="fd-subtitle">Representante Legal:</h4>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeCpf"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeName"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeBirthDate"></fd-input>
              </div>

              <fd-nationality
                [formGroup]="childFormGroup"
                [nationality]="fieldsArray[i].legalRepresentativeNationality"
                [rne]="fieldsArray[i].legalRepresentativeRne"
                [rneEnabled]="fieldsArray[i].legalRepresentativeRneEnabled"
                [legalRepresentative]="true"
                [nationalityList]="nationalityList"
              ></fd-nationality>

              <fd-pep
                [formGroup]="childFormGroup"
                [politicalExposition]="fieldsArray[i].legalRepresentativePoliticalExposition"
                [pepName]="fieldsArray[i].legalRepresentativePepName"
                [pepRole]="fieldsArray[i].legalRepresentativePepRole"
                [legalRepresentative]="true"
                [pepGovernmentOrganization]="fieldsArray[i].legalRepresentativePepGovernmentOrganization"
              ></fd-pep>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!isSerproPersonalInfoPageStrategy">
            <h4 class="fd-subtitle">Representante Legal:</h4>
            <div>
              <fd-radio-button
                [yesOrNotOptions]="true"
                [parentForm]="childFormGroup"
                [field]="fieldsArray[i].isLegalRepresentativeOptions"
                (change)="onChangeLegalRepresentativeOptions($event, i)"
              >
              </fd-radio-button>
            </div>
            <ng-container *ngIf="showLegalRepresentativeFields">
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeCpf"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeName"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeBirthDate"></fd-input>
              </div>

              <fd-nationality
                [formGroup]="childFormGroup"
                [nationality]="fieldsArray[i].legalRepresentativeNationality"
                [rne]="fieldsArray[i].legalRepresentativeRne"
                [rneEnabled]="fieldsArray[i].legalRepresentativeRneEnabled"
                [legalRepresentative]="true"
                [nationalityList]="nationalityList"
              ></fd-nationality>

              <fd-pep
                [formGroup]="childFormGroup"
                [politicalExposition]="fieldsArray[i].legalRepresentativePoliticalExposition"
                [pepName]="fieldsArray[i].legalRepresentativePepName"
                [pepRole]="fieldsArray[i].legalRepresentativePepRole"
                [legalRepresentative]="true"
                [pepGovernmentOrganization]="fieldsArray[i].legalRepresentativePepGovernmentOrganization"
              ></fd-pep>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="fieldsArray[i].documentCategory.context === 'CNPJ'">
          <div>
            <fd-input (blur)="cpfCnpjChange(i, $event)" [parentForm]="childFormGroup" [field]="fieldsArray[i].cpfCnpj"> </fd-input>
          </div>
          <div>
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].socialReason"></fd-input>
          </div>
          <div>
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].openDate"></fd-input>
          </div>
          <div>
            <fd-currency-input [parentForm]="childFormGroup" [field]="fieldsArray[i].companyParticipation"></fd-currency-input>
          </div>

          <ng-container *ngIf="isSerproPersonalInfoPageStrategy">
            <ng-container *ngIf="!fieldsArray[i].legalRepresentativeEnable.disabled">
              <h4 class="fd-subtitle">Representante Legal:</h4>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeCpf"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeName"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeBirthDate"></fd-input>
              </div>

              <fd-nationality
                [formGroup]="childFormGroup"
                [nationality]="fieldsArray[i].legalRepresentativeNationality"
                [rne]="fieldsArray[i].legalRepresentativeRne"
                [rneEnabled]="fieldsArray[i].legalRepresentativeRneEnabled"
                [legalRepresentative]="true"
                [nationalityList]="nationalityList"
              ></fd-nationality>

              <fd-pep
                [formGroup]="childFormGroup"
                [politicalExposition]="fieldsArray[i].legalRepresentativePoliticalExposition"
                [pepName]="fieldsArray[i].legalRepresentativePepName"
                [pepRole]="fieldsArray[i].legalRepresentativePepRole"
                [legalRepresentative]="true"
                [pepGovernmentOrganization]="fieldsArray[i].legalRepresentativePepGovernmentOrganization"
              ></fd-pep>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!isSerproPersonalInfoPageStrategy">
            <h4 class="fd-subtitle">Representante Legal:</h4>
            <div>
              <fd-radio-button
                [yesOrNotOptions]="true"
                [parentForm]="childFormGroup"
                [field]="fieldsArray[i].isLegalRepresentativeOptions"
                (change)="onChangeLegalRepresentativeOptions($event, i)"
              >
              </fd-radio-button>
            </div>
            <ng-container *ngIf="showLegalRepresentativeFields">
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeCpf"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeName"></fd-input>
              </div>
              <div>
                <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].legalRepresentativeBirthDate"></fd-input>
              </div>

              <fd-nationality
                [formGroup]="childFormGroup"
                [nationality]="fieldsArray[i].legalRepresentativeNationality"
                [rne]="fieldsArray[i].legalRepresentativeRne"
                [rneEnabled]="fieldsArray[i].legalRepresentativeRneEnabled"
                [legalRepresentative]="true"
                [nationalityList]="nationalityList"
              ></fd-nationality>

              <fd-pep
                [formGroup]="childFormGroup"
                [politicalExposition]="fieldsArray[i].legalRepresentativePoliticalExposition"
                [pepName]="fieldsArray[i].legalRepresentativePepName"
                [pepRole]="fieldsArray[i].legalRepresentativePepRole"
                [legalRepresentative]="true"
                [pepGovernmentOrganization]="fieldsArray[i].legalRepresentativePepGovernmentOrganization"
              ></fd-pep>
            </ng-container>
          </ng-container>
        </ng-container>

        <div>
          <fd-button
            size="thin"
            *ngIf="shouldShowRemoveButton(i)"
            fdSecondary
            [content]="removePartnerButtonHTML"
            (trigger)="removePartnerForm(i)"
          >
          </fd-button>
        </div>
      </div>
    </form>
    <fd-button size="thin" fdTertiary [content]="addPartnerButtonHTML" (trigger)="addPartnerForm()"> </fd-button>
  </div>

  <fd-button fdSecondary [content]="'cancelar'" (trigger)="back(false)"> </fd-button>
  <fd-button [content]="'avançar'" (trigger)="checkSerproCall()"> </fd-button>
</form>
