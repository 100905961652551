import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TerminalAmountModel } from 'src/app/order/components/offer/product-selection/models/terminal-amount.model';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class TerminalService {
  constructor(private http: HttpClient) {}

  public getPOSAmount(serviceContract: number, cpfCnpj: string): Observable<TerminalAmountModel> {
    const query = `${environment.apiUrls.terminalAmount}/service-contract/${serviceContract}/cpf-cnpj/${cpfCnpj}`;
    return this.http.get<TerminalAmountModel>(query);
  }

  public checkPOSAmount(serviceContract: number, cpfCnpj: string, amount: number): Observable<TerminalAmountModel> {
    const query = `${environment.apiUrls.terminalCheck}/service-contract/${serviceContract}/cpf-cnpj/${cpfCnpj}/amount/${amount}`;
    return this.http.get<TerminalAmountModel>(query);
  }
}
