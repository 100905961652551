import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProposalDetailComponent } from '../components/proposal-detail/proposal-detail.component';
import { ProposalHistoryComponent } from '../components/proposal-history/proposal-history.component';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { StartGuard } from '../guards/start-guard';
import { StartComponent } from '../start.component';
import { startRoutingDefinitions as routing } from './start-routing-definitions';
import {CampaignSimulatorComponent} from "../components/campaign-simulator/campaign-simulator.component";
import {
    TechnologyPriceTableComponent
} from "../components/campaign-simulator/technology-price-table/technology-price-table.component";
import {SharedComponentsModule} from "../../shared/shared-components.module";

const ROUTES: Routes = [
  {
    path: 'start',
    component: StartComponent,
    children: [
      {
        path: routing.start.routes.WELCOME.name,
        component: WelcomeComponent,
        canActivate: [StartGuard],
      },
      {
        path: routing.start.routes.PROPOSAL_HISTORY.name,
        component: ProposalHistoryComponent,
        canActivate: [StartGuard],
      },
      {
        path: routing.start.routes.PROPOSAL_DETAIL.name,
        component: ProposalDetailComponent,
        canActivate: [StartGuard],
      },
      {
        path: routing.start.routes.CAMPAIGN_SIMULATOR.name,
        component: CampaignSimulatorComponent,
        canActivate: [StartGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class StartRoutingModule {}
