import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { PaymentStatusCodeEnum } from 'src/app/order/enums/payment-status.enum';
import { ProposalStatusEnum } from 'src/app/order/enums/proposal-status.enum';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { ProposalDetailService } from 'src/app/start/components/proposal-detail/services/proposal-detail.service';
import { ProposalQueryModel } from '../../models/response/proposal-query.model';

@Component({
  selector: 'fd-proposal-card',
  templateUrl: './fd-proposal-card.component.html',
  styleUrls: ['./fd-proposal-card.component.scss'],
})
export class FdProposalCardComponent implements OnDestroy {
  public proposalStatus: ProposalStatusEnum;
  public alive = true;
  public PaymentStatusCodeEnum = PaymentStatusCodeEnum;

  @Input()
  public proposalData: ProposalQueryModel;

  get isCPF() {
    return this.proposalData.cpfCnpj.length === 11;
  }

  get isCNPJ() {
    return this.proposalData.cpfCnpj.length === 14;
  }

  constructor(
    private routingService: RoutingService,
    private wizardService: WizardService,
    private loadingService: LoadingService,
    private proposalDetailService: ProposalDetailService
  ) {}

  get imagePath() {
    const baseUrl = '/assets/images/proposal-status/';
    switch (this.proposalData.generalProposalStatus) {
      case ProposalStatusEnum.APPROVED:
        return baseUrl + 'approved.png';

      case ProposalStatusEnum.DENIED:
        return baseUrl + 'rejected.png';

      case ProposalStatusEnum.DENIED_INTEGRATOR:
        return baseUrl + 'rejected.png';

      case ProposalStatusEnum.IN_ANALYSIS:
        return baseUrl + 'pending.png';

      case ProposalStatusEnum.AWAITING_PAYMENT:
        if (this.proposalData.paymentStatusCode === PaymentStatusCodeEnum.NOT_APPLICABLE) {
          return baseUrl + 'pending.png';
        }
        return baseUrl + 'awaiting-payment.png';

      case ProposalStatusEnum.NOT_FINISHED:
      default:
        return baseUrl + 'pending.png';
    }
  }

  public navigateToPaymentData() {
    this.proposalDetailService
      .getOfferDataDetails(this.proposalData.proposalNumber)

      .pipe(takeWhile(() => this.alive))
      .subscribe((response) => {
        if (response) {
          localStorage.removeItem(Constants.PROPOSAL_NUMBER);
          localStorage.setItem(Constants.PROPOSAL_NUMBER, this.proposalData.proposalNumber);

          localStorage.removeItem(Constants.PROPOSAL_PRODUCTS_KEY);
          localStorage.setItem(Constants.PROPOSAL_PRODUCTS_KEY, JSON.stringify(response));
          this.wizardService.goToSpecificStep(
            orderRoutingDefinitions.orderFinishing.stepNumber,
            orderRoutingDefinitions.orderFinishing.routes.PAYMENT_DATA.order
          );
        }
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public navigateToDetail() {
    localStorage.removeItem(Constants.PROPOSAL_NUMBER);
    localStorage.setItem(Constants.PROPOSAL_NUMBER, this.proposalData.proposalNumber);
    this.routingService.navigateTo('/start/proposal-detail');
  }

  public deniedOfferDescrition(statusCode: string): string {
    const title = 'Rejeitado por Políticas Internas de Crédito e Risco';
    switch (statusCode) {
      case '02':
        return title + ' - ' + this.proposalData.ptDescription;
      case '38':
        return title + ' - ' + this.proposalData.ptDescription;
      default:
        return title;
    }
  }
  get cardTitle() {
    if (this.proposalData && this.proposalData.ptDescription) {
      if (this.proposalData.ptDescription === 'Rejeitado Crédito') {
        return this.deniedOfferDescrition(this.proposalData.statusCode);
      }
      return this.proposalData.ptDescription;
    } else {
      return 'Em análise';
    }
  }
}

export class CardInformationProperties {
  public label: string;
  public value: string;
}
