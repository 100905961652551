<div class="fd-modal" [formGroup]="formGroup">
  <div mat-dialog-content class="fd-modal__title">
    <p>{{data.title}}</p>
  </div>
  <div class="fd-modal__item-stripe">
    <span class="fd-modal__item-text">{{data?.itemDescription}}</span>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center" mat-dialog-content class="fd-modal__min-max-container">
    <div fxFlex="50">
      <div>Mínimo</div>
      <div class="fd-modal__max-value">{{ minValueDisplay }} %</div>
    </div>
    <div fxFlex="50">
      <div>Sugerido</div>
      <div class="fd-modal__max-value">{{ maxValueDisplay }} %</div>
    </div>
    <div class="fd-modal__price-container">
      <fd-currency-input [parentForm]="formGroup" [field]="inputField"></fd-currency-input>
    </div>
  </div>
  <fd-button [content]="'salvar'" (trigger)="handleConfirmClick()">
  </fd-button>
  <fd-button fdSecondary [content]="'cancelar'" (trigger)="handleCancelClick()">
  </fd-button>
</div>
