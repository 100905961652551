import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { HistoryPartnerAdminAndLegalIssuesModel } from '../models/history-partner-admin-and-legal-issues.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-history-partner-admin-and-legal-issues',
  templateUrl: './history-partner-admin-and-legal-issues.component.html',
  styleUrls: ['./history-partner-admin-and-legal-issues.component.scss'],
})
export class HistoryPartnerAdminAndLegalIssuesComponent extends FormStep implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public historyPartnerAdminAndLegalIssuesModel: HistoryPartnerAdminAndLegalIssuesModel;
  public proposal: Proposal;
  public hasEnterprisedComposedPartner: boolean;
  public outOfMembershipBoard: boolean;
  public hasLawInvestigation: boolean;

  protected STEP_NUMBER = 5;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public setAndCheckEmpty(control: AbstractControl, data: string) {
    if (data) {
      control.setValue(data);
      control.disable();
    } else {
      control.enable();
    }
  }

  public handleError(error: any) {
    this.dialogService.openDialog(Messages.GENERAL_ERROR);
  }

  public createFields(): FdFieldConfigs {
    return {
      allowedJurisdictions: {
        label: 'Cidades/estados onde a Empresa pode atuar',
        controlName: 'allowedJurisdictions',
        tooltipMessage: 'Outra(s) jurisdição(ões) (países/estados/cidades) onde a Empresa está autorizada a conduzir negócios',
        messages: {
          required: 'Informe a(s) cidades/estados',
        },
      },
      companyTimeInBusiness: {
        label: 'Há quanto tempo a empresa atua neste ramo?',
        controlName: 'companyTimeInBusiness',
        messages: {
          required: 'Informe o tempo da empresa no ramo',
        },
      },
      hasEnterprisedComposedPartner: {
        items: [],
        controlName: 'hasEnterprisedComposedPartner',
        messages: {
          required: 'Informe o tipo de taxa',
          invalid: 'Tipo de taxa inválida',
        },
      },
      outOfMembershipBoard: {
        items: [],
        controlName: 'outOfMembershipBoard',
        messages: {
          required: 'Informe uma opção',
        },
      },
      hasLawInvestigation: {
        items: [],
        controlName: 'hasLawInvestigation',
        messages: {
          required: 'Informe uma opção',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      companyTimeInBusiness: ['', Validators.required],
      allowedJurisdictions: ['', Validators.required],
      hasEnterprisedComposedPartner: ['', Validators.required],
      outOfMembershipBoard: ['', Validators.required],
      hasLawInvestigation: ['', Validators.required],
    });
  }

  public getLawInvestigationText() {
    return 'A Empresa ou quaisquer de seus proprietários, diretores, administradores ou \
    funcionários-chave já foram investigados, acusados, condenados ou sujeitos a\
    qualquer multa, penalidade, acordo, ordem, exclusão ou outra ação adversa por\
    determinação judicial, governamental, de agências não governamentais ou\
    organizações, entidades ou autoridades internacionais (exemplos: Banco\
    Mundial, FMI, ONU, etc.) por violação de quaisquer leis, regulamentos, regras ou\
    código de conduta?';
  }

  public changeOption(event: boolean) {
    this.hasEnterprisedComposedPartner = event;
  }
  public changeOptionOutOf(event: boolean) {
    this.outOfMembershipBoard = event;
  }
  public changeOptionHasLaw(event: boolean) {
    this.hasLawInvestigation = event;
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.deliveryContact) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Endereço de Entrega',
        () => this.back(),
        null,
        'error'
      );
    }
  }

  protected persistData() {
    const historyPartnerAdminAndLegalIssuesModel: HistoryPartnerAdminAndLegalIssuesModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ historyPartnerAdminAndLegalIssuesModel });
  }

  protected updateFields(proposal: Proposal) {
    this.historyPartnerAdminAndLegalIssuesModel = proposal.historyPartnerAdminAndLegalIssuesModel;

    if (!this.historyPartnerAdminAndLegalIssuesModel) {
      return;
    }

    this.formControls.companyTimeInBusiness.setValue(this.historyPartnerAdminAndLegalIssuesModel.companyTimeInBusiness);
    this.formControls.allowedJurisdictions.setValue(this.historyPartnerAdminAndLegalIssuesModel.allowedJurisdictions);
    this.formControls.hasEnterprisedComposedPartner.setValue(this.historyPartnerAdminAndLegalIssuesModel.hasEnterprisedComposedPartner);
    this.formControls.outOfMembershipBoard.setValue(this.historyPartnerAdminAndLegalIssuesModel.outOfMembershipBoard);
    this.formControls.hasLawInvestigation.setValue(this.historyPartnerAdminAndLegalIssuesModel.hasLawInvestigation);
  }
}
