import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from '../order.component';

import { BranchOfficesComponent } from '../components/complementary-information/e-commerce/branch-offices/branch-offices.component';
import { BusinessProspectionComponent } from '../components/complementary-information/e-commerce/business-prospection/business-prospection.component';
import { ECommerceFunctionalitiesComponent } from '../components/complementary-information/e-commerce/e-commerce-functionalities/e-commerce-functionalities.component';
import { EnterpriseAdditionalQuestionsComponent } from '../components/complementary-information/e-commerce/enterprise-additional-questions/enterprise-additional-questions.component';
import { GatewayAntifraudInformationComponent } from '../components/complementary-information/e-commerce/gateway-antifraud-information/gateway-antifraud-information.component';
import { EdiAdditionalDataComponent } from '../components/complementary-information/edi/edi-additional-data/edi-additional-data.component';
import { BusinessAffiliationsComponent } from '../components/complementary-information/restricted-cnae/business-affiliations/business-affiliations.component';
import { HistoryPartnerAdminAndLegalIssuesComponent } from '../components/complementary-information/restricted-cnae/history-partner-admin-and-legal-issues/history-partner-admin-and-legal-issues.component';
import { ActivationPeriodComponent } from '../components/complementary-information/tef/activation-period/activation-period.component';
import { FirstDataPinPadComponent } from '../components/complementary-information/tef/first-data-pin-pad/first-data-pin-pad.component';
import { PinPadInformationComponent } from '../components/complementary-information/tef/pin-pad-information/pin-pad-information.component';
import { TefBusinessInformationComponent } from '../components/complementary-information/tef/tef-business-information/tef-business-information.component';
import { TefIntegrationComponent } from '../components/complementary-information/tef/tef-integration/tef-integration.component';
import { TefItContactComponent } from '../components/complementary-information/tef/tef-it-contact/tef-it-contact.component';
import { TefProviderInformationComponent } from '../components/complementary-information/tef/tef-provider-information/tef-provider-information.component';
import { TefBrowserItContactComponent } from '../components/complementary-information/tef-browser/tef-browser-it-contact/tef-browser-it-contact.component';
import { TefBrowserIntegrationComponent } from '../components/complementary-information/tef-browser/tef-browser-integration/tef-browser-integration.component';
import { TefBrowserActivationPeriodComponent } from '../components/complementary-information/tef-browser/tef-browser-activation-period/tef-browser-activation-period.component';
import { ConclusionComponent } from '../components/conclusion/conclusion.component';
import { DeliveryHoursComponent } from '../components/delivery-data/delivery-hours/delivery-hours.component';
import { WorkingHoursComponent } from '../components/delivery-data/working-hours/working-hours.component';
import { FeeTypeComponent } from '../components/offer/fee-type/fee-type.component';
import { MdrFeeEditComponent } from '../components/offer/mdr-fee-edit/mdr-fee-edit.component';
import { MdrFlexFeeEditComponent } from '../components/offer/mdr-flex-fee-edit/mdr-flex-fee-edit.component';
import { OfferDetailsComponent } from '../components/offer/offer-details/offer-details.component';
import { OfferTypeComponent } from '../components/offer/offer-type/offer-type.component';
import { ProductSelectionComponent } from '../components/offer/product-selection/product-selection.component';
import { LicenseComponent } from '../components/offer/professional-license-register/components/license/license.component';
import { NotaFiscalComponent } from '../components/offer/professional-license-register/components/nota-fiscal/nota-fiscal.component';
import { ProfRegisterComponent } from '../components/offer/professional-license-register/components/prof-register/prof-register.component';
import { ProfessionalLicenseRegisterComponent } from '../components/offer/professional-license-register/professional-license-register.component';
import { SalesDataComponent } from '../components/offer/sales-data/sales-data.component';
import { PaymentDataComponent } from '../components/order-finishing-data/payment-data/payment-data.component';
import { BankDataComponent } from '../components/proposal-data/bank-data/bank-data.component';
import { DeliveryContactComponent } from '../components/proposal-data/delivery-contact/delivery-contact.component';
import { PartnerDataComponent } from '../components/proposal-data/partner-data/partner-data.component';
import { ReceivingDataComponent } from '../components/proposal-data/receiving-data/receiving-data.component';
import { ProposalTermsAcceptanceComponent } from '../components/proposal-terms-acceptance/proposal-terms-acceptance.component';
import { RegistrationDataAddressComponent } from '../components/registration-data/address/registration-data-address.component';
import { DeliveryAddressComponent } from '../components/registration-data/delivery-address/delivery-address.component';
import { MailingAddressComponent } from '../components/registration-data/mailing-address/mailing-address.component';
import { RegistrationDataPersonalInfoComponent } from '../components/registration-data/personal-info/registration-data-personal-info.component';
import { OrderStepGuard } from '../guards/step-guard';
import { orderRoutingDefinitions as routing } from './routing-definitions';

const ROUTES: Routes = [
  {
    path: 'order',
    component: OrderComponent,
    // TODO: Verificar se todos os steps estão com guard.
    children: [
      {
        path: routing.registrationData.routes.PERSONAL_INFO.name,
        component: RegistrationDataPersonalInfoComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.registrationData.routes.ADDRESS.name,
        component: RegistrationDataAddressComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.registrationData.routes.DELIVERY_ADDRESS.name,
        component: DeliveryAddressComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.registrationData.routes.MAILING_ADDRESS.name,
        component: MailingAddressComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.OFFER_DETAILS.name,
        component: OfferDetailsComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.OFFER_TYPE.name,
        component: OfferTypeComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.PRODUCT_SELECTION.name,
        component: ProductSelectionComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.FEE_TYPE.name,
        component: FeeTypeComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.FEE_EDIT.name,
        component: MdrFeeEditComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.MDR_FLEX_FEE_EDIT.name,
        component: MdrFlexFeeEditComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.SALES_DATA.name,
        component: SalesDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.PROFESSIONAL_LICENSE_REGISTER.name,
        component: ProfessionalLicenseRegisterComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.OPERATING_LICENSE.name,
        component: LicenseComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.INVOICE.name,
        component: NotaFiscalComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.offer.routes.PROFESSIONAL_COUNCIL.name,
        component: ProfRegisterComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.proposal.routes.DELIVERY_CONTACT.name,
        component: DeliveryContactComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.proposal.routes.RECEIVING_DATA.name,
        component: ReceivingDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.proposal.routes.PARTNER_DATA.name,
        component: PartnerDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.proposal.routes.BANK_DATA.name,
        component: BankDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.openingHours.routes.WORKING_HOURS.name,
        component: WorkingHoursComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.openingHours.routes.DELIVERY_HOURS.name,
        component: DeliveryHoursComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.enterpriseComplementaryInformation.routes.HISTORY_PARTNER_ADMIN_AND_LEGAL_ISSUES.name,
        component: HistoryPartnerAdminAndLegalIssuesComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.enterpriseComplementaryInformation.routes.BUSINESS_AFFILIATIONS.name,
        component: BusinessAffiliationsComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.eCommerceComplementaryInformation.routes.E_COMMERCE_FUNCTIONALITIES.name,
        component: ECommerceFunctionalitiesComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.eCommerceComplementaryInformation.routes.GATEWAY_ANTIFRAUD_INFORMATION.name,
        component: GatewayAntifraudInformationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.eCommerceComplementaryInformation.routes.BRANCH_OFFICES.name,
        component: BranchOfficesComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.eCommerceComplementaryInformation.routes.BUSINESS_PROSPECTION.name,
        component: BusinessProspectionComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.eCommerceComplementaryInformation.routes.ENTERPRISE_ADDITIONAL_QUESTIONS.name,
        component: EnterpriseAdditionalQuestionsComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_IT_CONTACT.name,
        component: TefItContactComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_PIN_PAD_INFORMATION.name,
        component: PinPadInformationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_BUSINESS_INFORMATION.name,
        component: TefBusinessInformationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.FIRST_DATA_PIN_PAD.name,
        component: FirstDataPinPadComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_PROVIDER_INFORMATION.name,
        component: TefProviderInformationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_INTEGRATION.name,
        component: TefIntegrationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefComplementaryInformation.routes.TEF_ACTIVATION_PERIOD.name,
        component: ActivationPeriodComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefBrowserComplementaryInformation.routes.TEF_BROWSER_IT_CONTACT.name,
        component: TefBrowserItContactComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefBrowserComplementaryInformation.routes.TEF_BROWSER_INTEGRATION.name,
        component: TefBrowserIntegrationComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.tefBrowserComplementaryInformation.routes.TEF_BROWSER_ACTIVATION_PERIOD.name,
        component: TefBrowserActivationPeriodComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.ediComplementaryInformation.routes.EDI_ADDITIONAL_DATA.name,
        component: EdiAdditionalDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.orderFinishing.routes.PAYMENT_DATA.name,
        component: PaymentDataComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.proposalTerms.routes.PROPOSAL_TERMS_ACCEPTANCE.name,
        component: ProposalTermsAcceptanceComponent,
        canActivate: [OrderStepGuard],
      },
      {
        path: routing.submittedProposal.routes.SUBMITTED_PROPOSAL.name,
        component: ConclusionComponent,
        canActivate: [OrderStepGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
