import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefItContactModel } from '../models/tef-it-contact.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { PhoneValidator } from 'src/app/order/validators/phone-validator';

@Component({
  selector: 'app-tef-it-contact',
  templateUrl: './tef-it-contact.component.html',
  styleUrls: ['./tef-it-contact.component.scss'],
})
export class TefItContactComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public tefItContactModel: TefItContactModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefItContact: TefItContactModel = {
      ...this.formGroup.getRawValue(),
    };

    return this.dataStore.updateProposal({ tefItContact });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      contactName: ['', Validators.required],
      contactMobileNumber: ['', [Validators.required, PhoneValidator]],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefItContactModel = proposal.tefItContact;

    if (this.tefItContactModel) {
      this.formControls.contactName.setValue(this.tefItContactModel.contactName);
      this.formControls.contactMobileNumber.setValue(this.tefItContactModel.contactMobileNumber);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      contactName: {
        label: 'Nome',
        controlName: 'contactName',
        messages: {
          required: 'Informe o nome do contato',
        },
      },
      contactMobileNumber: {
        label: 'Celular',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'contactMobileNumber',
        messages: {
          required: 'Informe o celular',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
    };
  }
}
