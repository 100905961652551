import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { OptInAuthModel } from 'src/app/shared/models/opt-in-auth.model';
import { OptInModel } from 'src/app/shared/models/opt-in.model';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class OptInService {
  constructor(private http: HttpClient) {}

  public getOptInProperties(optInAuth: OptInAuthModel): Observable<OptInModel> {
    return this.http.post<OptInModel>(`${environment.apiUrls.optIn}/terms`, optInAuth);
  }
}
