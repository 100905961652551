import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;

export const WebsiteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const value: string = control.value;

  if (!value) {
    return {};
  }

  if (!value.match(regex)) {
    return { invalid: true };
  }

  return {};
};
