import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-back-to-home-modal',
  templateUrl: './back-to-home-modal.component.html',
  styleUrls: ['./back-to-home-modal.component.scss'],
})
export class BackToHomeModalComponent {
  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<BackToHomeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: BackToHomeDialogData) {}

  public handleDiscardDraftClick(): void {
    if (this.data.discardDraftCallbackFn) {
      this.data.discardDraftCallbackFn();
    }
    this.dialogRef.close();
  }

  public handleKeepDraftClick(): void {
    if (this.data.keepDraftCallbackFn) {
      this.data.keepDraftCallbackFn();
    }
    this.dialogRef.close();
  }

  public handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn();
    }
    this.dialogRef.close();
  }
}

export interface BackToHomeDialogData {
  title: string;
  description: string;
  discardDraftCallbackFn?: () => any;
  keepDraftCallbackFn?: () => any;
  cancelCallbackFn?: () => any;
}
