import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent {
  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<MessageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: MessageModalDialogData) {}

  public handleClick(): void {
    if (this.data.callbackFn) {
      this.data.callbackFn();
    }
    this.dialogRef.close();
  }

  public getButtonContent() {
    return (this.data && this.data.buttonText) || 'fechar';
  }
}

export interface MessageModalDialogData {
  title: string;
  description: string;
  html?: boolean;
  buttonText: string;
  callbackFn: () => any;
}
