import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { MdrFlexModalityEnum } from 'src/app/order/enums/mdr-flex-modality.enum';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { FeeCalculationModel } from 'src/app/shared/models/fee-calculation.model';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { CalculatedFeeModel } from 'src/app/shared/models/response/response-calculated-fee.model';
import { FactorFeeModel } from 'src/app/shared/models/response/response-factor-fee.model';
import { MdrFeeModel } from 'src/app/shared/models/response/response-mdr-fee.model';
import { MdrFlexFeeModel } from 'src/app/shared/models/response/response-mdr-flex-fee.model';
import { PrepaymentFeeModel } from 'src/app/shared/models/response/response-prepayment-fee.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class FeeService {
  public agentChannel: string;
  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
  }

  public getMdrFees(
    cnae: string,
    uf: string,
    idCampaign: string,
    idRange: string,
    personType: string,
    anticipation: boolean
  ): Observable<MdrFeeModel[]> {
    return this.http.get<MdrFeeModel[]>(
      `${environment.apiUrls.fees}/mdr?cnae=${cnae}&uf=${uf}&id-campaign=${idCampaign}&id-range=${idRange}&person-type=${personType}&anticipation=${anticipation}`
    );
  }

  public getFactorFees(cnae: string, idRange: string, idCampaign: string, receivingDeadline: number): Observable<FactorFeeModel[]> {
    return this.http.get<FactorFeeModel[]>(
      // tslint:disable-next-line: max-line-length
      `${environment.apiUrls.fees}/factor?cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}&receiving-deadline=${receivingDeadline}`
    );
  }

  public getFactorFeesReceivingDeadLine(cnae: string, idRange: string, idCampaign: string): Observable<number[]> {
    return this.http.get<number[]>(
      `${environment.apiUrls.fees}/factor-receivingdeadline?cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}`
    );
  }

  public getMdrFlexFees(
    cnae: string,
    uf: string,
    idCampaign: string,
    idRange: string,
    receivingDeadline: number,
    personType: string
  ): Observable<MdrFlexFeeModel[]> {
    return this.http.get<MdrFlexFeeModel[]>(
      // tslint:disable-next-line: max-line-length
      `${environment.apiUrls.fees}/mdrflex?cnae=${cnae}&uf=${uf}&id-campaign=${idCampaign}&id-range=${idRange}&person-type=${personType}&receiving-deadline=${receivingDeadline}`
    );
  }

  public calculateFees(
    feeCalculation: FeeCalculationModel[],
    cnae: string,
    uf: string,
    idRange: string,
    idCampaign: string,
    receivingDeadline: number,
    personType: string
  ): Observable<FeeCalculationModel[]> {
    feeCalculation = feeCalculation.filter((fee) => fee.modality !== MdrFlexModalityEnum.ADDITIONAL_PER_INSTALLMENT);
    return this.http.post<FeeCalculationModel[]>(
      // tslint:disable-next-line: max-line-length
      `${environment.apiUrls.fees}/calculate/mdrflex?uf=${uf}&cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}&person-type=${personType}&receiving-deadline=${receivingDeadline}`,
      feeCalculation
    );
  }

  public getPrepaymentFee(cnae: string, idRange: string, idCampaign: string, external: boolean) {
    return this.http.get<PrepaymentFeeModel>(
      `${environment.apiUrls.fees}/prepayment?cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}&external=${external}`
    );
  }
}
