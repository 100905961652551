<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Valor máximo esperado por transação</h4>
    <div>
      <fd-currency-input [parentForm]="formGroup" [field]="fields.maxTransactionValue"> </fd-currency-input>
    </div>

    <h4 class="fd-subtitle">Recebe de cartões internacionais?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.canReceiveFromInternationalCards">
      </fd-radio-button>
    </div>

    <div class="field-container">
      <span class="fd-text">No processo de visita comercial, foi possível checar estoque e atividade empresarial no local? Descreva:</span>
      <fd-input [parentForm]="formGroup" [field]="fields.stockCheck"> </fd-input>
    </div>

    <div class="field-container">
      <span class="fd-text">Estabelecimento faz alguma checagem de segurança do comprador? Descreva:</span>
      <fd-input [parentForm]="formGroup" [field]="fields.securityCheck"> </fd-input>
    </div>

    <div class="field-container">
      <span class="fd-text"
        >Estabelecimento verifica documentos e coleta assinaturas para comprovar o ato de entrega do produto/serviço? Descreva:</span
      >
      <fd-input [parentForm]="formGroup" [field]="fields.documentCheck"> </fd-input>
    </div>

    <h4 class="fd-subtitle">
      Estabelecimento ficou ciente dos riscos de transações sem a presença física do cartão e que em caso de contestação das vendas,
      <strong>há risco de não recebimento pela venda contestada?</strong>
    </h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.transactionRisksAware"> </fd-radio-button>
    </div>

    <h4 class="fd-subtitle">Estabelecimento concorda com a reserva para antecipar, necessária para quem atua com cartões não presentes?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.prepaymentReservationAcceptance">
      </fd-radio-button>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'próxima etapa'" (trigger)="next()"> </fd-button>
  </div>
</form>
