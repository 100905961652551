<h4 class="fd-subtitle">Indique os dados da pessoa de contato:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.cpf"> 
      </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.contactName">
      </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.emailAddress">
      </fd-input>
    </div>
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.fixedNumberRequired">
        </fd-input>
      </div>
      <div fxFlex="50">
        <fd-input [parentForm]="formGroup" [field]="fields.fixedNumber">
        </fd-input>
      </div>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.mobileNumber">
      </fd-input>
    </div>

    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
