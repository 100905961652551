import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseStatusProfessionalLicense } from 'src/app/order/components/offer/offer-details/models/status-professional-license';
import { SplitTech } from 'src/app/order/components/offer/product-selection/models/terminal-amount.model';
import { Constants } from 'src/app/order/constants/constants';
import { ProposalStatusEnum } from 'src/app/order/enums/proposal-status.enum';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { Proposal } from 'src/app/shared/models/proposal';
import { BaseApiResponse, BaseApiResponseDetails } from 'src/app/shared/models/response/base-api-response.model';
import { ProposalQueryModel } from 'src/app/shared/models/response/proposal-query.model';
import { ProposalStatusModel } from 'src/app/shared/models/response/response-proposal-status.model';
import { SubmittedProposalModel } from 'src/app/shared/models/response/response-submitted-proposal.model';
import { UnfilledProposalModel } from 'src/app/start/components/welcome/welcome.component';
import { environment } from 'src/environments/environment.local';
import { ConfigPaymentSplit } from '../../../../shared/models/config-payment-split';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  public agentChannel: string;

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
  }

  public submitProposal(proposal: Proposal): Observable<SubmittedProposalModel> {
    if (proposal && proposal.partnerDataModel) {
      proposal.partnerDataModel.forEach((partner) => {
        if ((partner.socialReason !== undefined || partner.socialReason !== '') && (partner.name === undefined || partner.name === '')) {
          partner.name = partner.socialReason;
        }
      });
    }

    return this.http
      .post<BaseApiResponseDetails<SubmittedProposalModel>>(`${environment.apiUrls.proposal}`, proposal)
      .pipe(map((returnValue) => (returnValue ? returnValue.details : null)));
  }

  public findDuplicates(cpfCnpj: string, technologyGroup: string[]) {
    let params = new HttpParams();

    params = params.set('technologiesGroups', technologyGroup.toString());

    return this.http.get<boolean>(`${environment.apiUrls.proposal}/check-duplicity/${cpfCnpj}`, { params });
  }

  public getRecentProposals(
    startDate: string,
    endDate: string,
    statusList?: ProposalStatusEnum[],
    size?: number,
    page?: number
  ): Observable<Pageable<ProposalQueryModel[]>> {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
    let isPendingPayment = false;

    if (statusList) {
      statusList.forEach((x, ind, arr) => {
        switch (x) {
          case ProposalStatusEnum.AWAITING_PAYMENT:
            isPendingPayment = true;
            statusList.splice(ind, 0);
            break;
        }
      });
    }

    let pageQuery = '';
    let sizeQuery = '';
    if (size) {
      sizeQuery = `&size=${size}`;
    }
    if (page) {
      pageQuery = `&page=${page}`;
    }

    const model = {
      statusList,
      isPendingPayment,
      startDate,
      endDate,
    };

    return this.http
      .post<Pageable<ProposalQueryModel[]>>(`${environment.apiUrls.proposal}/submitted?1=1${pageQuery}${sizeQuery}`, model)
      .pipe(map((returnValue) => returnValue));
  }

  public getProposalStatus(proposalNumber: string): Observable<ProposalStatusModel> {
    return this.http.get<BaseApiResponseDetails<any>>(`${environment.apiUrls.proposal}/${proposalNumber}`).pipe(
      map((returnValue) => {
        if (this.invalidResponse(returnValue)) {
          return null;
        } else {
          if (returnValue.details) {
            return returnValue.details;
          } else if (returnValue.datails) {
            return returnValue.datails;
          } else {
            return null;
          }
        }
      })
    );
  }

  public isProfessionalLicenceMandatory(serviceContract: number): Observable<ResponseStatusProfessionalLicense> {
    return this.http.get<ResponseStatusProfessionalLicense>(`${environment.apiUrls.professionalLicenseStatus}/${serviceContract}`);
  }

  public getSplitTechs(): Observable<SplitTech[]> {
    return this.http.get<SplitTech[]>(`${environment.apiUrls.splitTech}`);
  }

  public validateEcMaster(cpfCnpj: string, institution: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.proposal}/ec-master/${cpfCnpj}/${institution}`);
  }

  public ablePaymentSplit(serviceContract: number): Observable<ConfigPaymentSplit> {
    return this.http.get<ConfigPaymentSplit>(`${environment.apiUrls.generalConfig}/payment-split/${serviceContract}/able`);
  }

  private invalidResponse(baseApiResponse: BaseApiResponseDetails<ProposalStatusModel | Pageable<ProposalQueryModel[]>>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || (!baseApiResponse.details && !baseApiResponse.datails);
  }
}
