<form [formGroup]="formGroup">
  <h4 class="fd-subtitle">Deseja habilitar outro adquirente no Conciliador?</h4>

  <div *ngFor="let item of acquirers" class="checkbox-container">
    <mat-checkbox (change)="onCheckboxChange(item, $event)" [value]="item.value" [checked]="item.selected"
                  class="fd-checkbox" [id]="item.value">{{item.label}}</mat-checkbox>
  </div>

  <div class="button-container">
    <fd-button fdSecondary [content]="'Cancelar'" (trigger)="cancel()">
    </fd-button>
    <fd-button [content]="'Salvar'" (trigger)="save()">
    </fd-button>
  </div>
</form>
