import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Modal } from 'src/app/shared/models/response/modal-response';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private http: HttpClient) {}

  public getModal(zipCode: string): Observable<Modal> {
    return this.http.get<Modal>(`${environment.apiUrls.modal}?zip-code=${zipCode}`);
  }
}
