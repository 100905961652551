import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalDraftQueryResultModel } from 'src/app/shared/models/proposal-draft-query-result.model';
import { ProposalDraftSendModel } from 'src/app/shared/models/proposal-draft-send.model';
import { environment } from 'src/environments/environment.local';
import { Constants } from '../constants/constants';
import { Messages } from '../messages/order.messages';
import { getRouteNameByOrder, getStepInfoByRoute } from '../routing/routing-definitions';
import { ProposalDraftService } from '../services/external/proposal-draft/proposal-draft.service';
import { DialogService } from '../services/internal/dialog/dialog.service';
import { LoadingService } from '../services/internal/loading/loading.service';
import { RoutingService } from '../services/internal/routing/routing.service';
import { WizardService } from '../services/internal/wizard/wizard.service';
import { DropdownItemsStore, RouteData } from './data-store-models';
import { DataStoreService } from './data-store.service';

@Injectable({
  providedIn: 'root',
})
export class DataStore {
  private _stepStatus: Map<number, boolean> = new Map();

  private _proposal: Proposal;
  private proposal$: BehaviorSubject<Proposal>;

  private _dropdownItems: DropdownItemsStore[] = new Array<DropdownItemsStore>();
  private dropdownItems$: BehaviorSubject<DropdownItemsStore[]>;

  private _routeStatus: RouteData[];
  private routeStatus: BehaviorSubject<RouteData[]>;

  private readonly MAX_SERVICE_LOAD_ATTEMPTS: number = 10;
  private attempts: number = 1;

  private _closure: boolean;
  private closure: BehaviorSubject<boolean>;

  constructor(
    private dialogService: DialogService,
    private wizardService: WizardService,
    private routingService: RoutingService,
    private dataStoreService: DataStoreService,
    private proposalDraftService: ProposalDraftService,
    private loadingService: LoadingService
  ) {
    this._proposal = {};
    this.proposal$ = new BehaviorSubject(this._proposal);

    this.dropdownItems$ = new BehaviorSubject(this._dropdownItems);

    this._closure = false;
    this.closure = new BehaviorSubject(this._closure);

    this._routeStatus = [];
    this.routeStatus = new BehaviorSubject(this._routeStatus);
  }

  public setClosure(closure: boolean): void {
    this._closure = closure;
    this.closure.next(this._closure);
  }

  public appendDropdownItems(key: string, items: Item[]) {
    const dropdownItems = this.dataStoreService.getLocalStorageObject(Constants.DROPDOWN_ITEMS_LOCALSTORAGE_KEY) || [];

    const currentItem = dropdownItems.find((x) => x.key === key);

    if (currentItem) {
      currentItem.items = items;
    } else {
      dropdownItems.push({ key, items });
    }
    this.setLocalStorageObject(dropdownItems, Constants.DROPDOWN_ITEMS_LOCALSTORAGE_KEY);
  }

  public deleteDraftInformationFromLocalStorage(draftUuid: string = null) {
    this.dataStoreService.deleteDraftInformationFromLocalStorage(draftUuid);
  }

  public getDropdownItems(key: string): Item[] {
    let dropdownItems: Observable<DropdownItemsStore[]>;
    const storedItems = this.dataStoreService.getLocalStorageObject(Constants.DROPDOWN_ITEMS_LOCALSTORAGE_KEY) || [];
    if (!storedItems || !storedItems.length) {
      return [];
    }
    // of === Observable.of
    dropdownItems = of(this.dataStoreService.getLocalStorageObject(Constants.DROPDOWN_ITEMS_LOCALSTORAGE_KEY));

    let returnValue;

    dropdownItems.subscribe((item) => {
      if (!item) {
        return;
      }
      const found = item.find((x) => x.key === key);
      if (found) {
        returnValue = found.items;
      }
    });

    return returnValue;
  }

  public getClosure(): Observable<boolean> {
    return this.closure;
  }

  public getProposal(): Observable<Proposal> {
    const draftUuid = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);
    if (draftUuid) {
      return this.proposalDraftService.getDraftByUuidAsProposal(draftUuid);
    }
    return of({});
  }

  public getInMemoryProposal() {
    return this.proposal$;
  }

  public isObjectEmpty(proposal: Proposal) {
    return !proposal || Object.keys(proposal).length === 0;
  }

  public setRouteStatus(proposal: Proposal, routeParam?: string): void {
    this.wizardService
      .getCurrentStep()
      .pipe(take(1))
      .subscribe((stepInfo) => {
        const proposalObj = proposal[Object.keys(proposal)[0]];
        const isEmpty =
          Object.keys(proposalObj).filter((key) => proposalObj[key] === null || proposalObj[key] === undefined || proposalObj[key] === '')
            .length === Object.keys(proposalObj).length;
        if (isEmpty) {
          return;
        }

        const route = routeParam ? routeParam : getRouteNameByOrder(stepInfo.step, stepInfo.subStep);

        const routeStatus = this.dataStoreService.getLocalStorageObject(Constants.ROUTE_STATUS_LOCALSTORAGE_KEY) || [];

        if (this.routeHasSensitiveData(route) && !environment.saveSensitiveDataOnLocalStorage) {
          return;
        }

        const routeData: RouteData = {
          routeName: route,
          currentData: proposal,
          step: stepInfo.step,
          subStep: stepInfo.subStep,
        };

        if (routeStatus && routeStatus.some((value) => value.routeName === routeData.routeName)) {
          routeStatus
            .filter((value) => value.routeName === routeData.routeName)
            .forEach((value) => {
              value.currentData = routeData.currentData;
            });
        } else {
          routeStatus.push(routeData);
        }

        this.setLocalStorageObject(routeStatus, Constants.ROUTE_STATUS_LOCALSTORAGE_KEY);
      });
  }

  public clearProposalKeyFromLocalStorage(key: string) {
    const parsedAgentData = this.dataStoreService.getAgentDataFromLocalStorage();

    if (parsedAgentData) {
      if (parsedAgentData && parsedAgentData.proposal) {
        delete parsedAgentData.proposal[key];
      }
    }

    if (parsedAgentData && parsedAgentData.proposal) {
      this.dataStoreService.setAgentDataOnLocalStorage(parsedAgentData);
    }
  }

  public setLocalStorageObject(obj: any, key: string, mergeOperation = true) {
    let parsedAgentData = this.dataStoreService.getAgentDataFromLocalStorage();

    if (parsedAgentData) {
      if (!(obj instanceof Array)) {
        Object.keys(obj).forEach((key) => {
          if (parsedAgentData && parsedAgentData.proposal) {
            delete parsedAgentData.proposal[key];
          }
        });
      }
      if (!parsedAgentData[key] || !mergeOperation) {
        parsedAgentData[key] = obj;
      } else {
        merge(parsedAgentData[key], obj);
      }
    } else {
      parsedAgentData = {
        [key]: obj,
      };
    }

    this.dataStoreService.setAgentDataOnLocalStorage(parsedAgentData);
  }

  public getDraftProposals(): Observable<ProposalDraftQueryResultModel[]> {
    return this.proposalDraftService.getDraftProposals({
      draftCpfCnpj: this.dataStoreService.getAgentCpfCnpjKey(),
    });
  }

  public routeHasSensitiveData(route: string) {
    const stepInfo = getStepInfoByRoute(route);

    return stepInfo && !!stepInfo.hasSensitiveData;
  }

  public deleteECommerceProperties() {
    delete this._proposal.branchOffices;
    delete this._proposal.businessProspection;
    delete this._proposal.commerceFunctionalities;
    delete this._proposal.commerceTermsAcceptance;
    delete this._proposal.enterpriseAdditionalQuestions;
    delete this._proposal.gatewayAntifraudInformation;
    delete this._proposal.visitContact;
    delete this._proposal.visitContactSelection;
  }

  public deleteProfessionalLicenseProperties() {
    delete this._proposal.professionalLicense;
  }

  public deleteComplianceProperties() {
    delete this._proposal.historyPartnerAdminAndLegalIssuesModel;
    delete this._proposal.enterprisePartnerDataModel;
    delete this._proposal.outOfMembershipBoardOwners;
    delete this._proposal.outOfMembershipBoardPartners;
    delete this._proposal.businessAffiliations;
    delete this._proposal.termsAcceptance;
  }

  public deleteTefProperties() {
    delete this._proposal.tefItContact;
    delete this._proposal.tefPinPadInformation;
    delete this._proposal.tefBusinessInformation;
    delete this._proposal.firstDataPinPadModel;
    delete this._proposal.tefProviderInformation;
    delete this._proposal.tefIntegration;
    delete this._proposal.tefActivationPeriod;
    delete this._proposal.tefTermsAcceptance;
  }

  public deleteTefBrowserProperties() {
    delete this._proposal.tefBrowserItContact;
    delete this._proposal.tefBrowserIntegration;
    delete this._proposal.tefBrowserActivationPeriod;
  }

  public deleteEdiProperties() {
    delete this._proposal.ediAdditionalData;
  }

  public async updateProposal(proposal: Proposal, route?: string, cancelRouteStatusSet: boolean = false): Promise<boolean> {
    return await this.updateProposalInternal(proposal, route, cancelRouteStatusSet)
      .then(
        () => Promise.resolve(true),
        () => Promise.reject(false)
      )
      .catch(() => Promise.reject(false));
  }

  public async updateProposalInternal(proposal: Proposal, route?: string, cancelRouteStatusSet: boolean = false): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      Object.keys(proposal).forEach((key) => {
        this.clearProposalKey(key);
      });

      await this.proposalDraftService
        .saveProposalDraft(this.createProposalDraftObject(proposal))
        .toPromise()
        .then(
          (response) => {
            if (!response || !response.externalDraftUuid) {
              reject(false);
            }
            localStorage.setItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY, response.externalDraftUuid);
            if (!cancelRouteStatusSet) {
              this.setRouteStatus(proposal, route);
            }
            resolve(true);
          },
          (error) => {
            if (error && error.status === 400) {
              this.dialogService.openDialog(Messages.DRAFT_LIMIT_EXCEEDED, () => this.routingService.navigateToHome(true));
              reject(false);
              return;
            }
            this.dialogService.openErrorDialog(Messages.DRAFT_SAVE_ERROR, error);
            reject(false);
          }
        )
        .catch((error) => {
          let errorArg = error;
          if (error.error && typeof error.error === 'object') {
            errorArg = error.error;
            if (error.error instanceof ProgressEvent) {
              errorArg = error.message;
            }
          }
          this.dialogService.openErrorDialog(Messages.DRAFT_SAVE_ERROR, errorArg);
          reject(false);
        });
    });
  }

  public createProposalDraftObject(proposal: Proposal): ProposalDraftSendModel {
    const createdObj: ProposalDraftSendModel = {
      content: JSON.stringify(proposal),
      draftCpfCnpj: this.dataStoreService.getAgentCpfCnpjKey(),
    };

    const activeDraftKey = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    if (activeDraftKey) {
      createdObj.externalDraftUuid = activeDraftKey;
    }

    return createdObj;
  }

  public clearProposalKey(key: string) {
    delete this._proposal[key];
    this.clearProposalKeyFromLocalStorage(key);
  }

  public setStepStatus(step: number, status: boolean): void {
    this._stepStatus.set(step, status);
  }

  public getRouteStatus(): Observable<RouteData[]> {
    if (this.dataStoreService.getLocalStorageObject(Constants.ROUTE_STATUS_LOCALSTORAGE_KEY)) {
      // of === Observable.of
      return of(this.dataStoreService.getLocalStorageObject(Constants.ROUTE_STATUS_LOCALSTORAGE_KEY));
    }
    return of([]);
  }

  public isStepValid(step: number): boolean {
    return this._stepStatus.get(step) || false;
  }
}
