import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyValidator } from 'src/app/order/validators/currency-validator';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdValueEditorConfig } from 'src/app/shared/fd-form-components/fd-value-editor/fd-value-editor.component';

@Component({
  selector: 'app-value-editor-modal',
  templateUrl: './value-editor-modal.component.html',
  styleUrls: ['./value-editor-modal.component.scss'],
})
export class ValueEditorModalComponent implements OnInit {
  public formGroup: FormGroup;
  public innerValueControl: AbstractControl;

  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ValueEditorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ValueEditorDialogData) {}

  public async ngOnInit() {
    if (!this.data) {
      console.error('No data present on modal object');
      return;
    }
    this.formGroup = this.data.formGroup;
    this.innerValueControl = this.formGroup.get(this.data.field.childControl.controlName);
    if (this.data.initialValue) {
      this.innerValueControl.setValue(this.data.initialValue);
    }
    if (!this.innerValueControl.value) {
      this.innerValueControl.setValue(0);
    }
    this.setValidator();
  }

  public setValidator() {
    const validators = this.data.minValue === 0 ? [CurrencyValidator(this.data.minValue)] : [Validators.required];
    this.innerValueControl.setValidators(validators);
    this.innerValueControl.markAsUntouched();
  }

  get relatedFormControl(): AbstractControl {
    return this.data.formControl;
  }

  get inputField(): FdInputConfig {
    return this.data.field.childControl;
  }

  public handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn(this.data);
    }
    this.dialogRef.close();
  }

  public handleConfirmClick(): void {
    if (this.innerValueControl.valid) {
      if (this.data.confirmCallbackFn) {
        this.data.confirmCallbackFn(this.data);
      }
      this.dialogRef.close();
    }
  }
}

export interface ValueEditorDialogData {
  title: string;
  itemDescription?: string;
  minValue?: number;
  initialValue?: number;
  maxValue?: number;
  formControl: AbstractControl;
  formGroup: FormGroup;
  field: FdValueEditorConfig;
  cancelCallbackFn?: (data?: any) => any;
  confirmCallbackFn?: (data?: any) => any;
}
