import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep',
})
export class CepPipe implements PipeTransform {
  public transform(value: any): any {
    if (!value || value.length < 8) {
      return value;
    }
    return `${value.substring(0, 5)}-${value.substring(5, 9)}`;
  }
}
