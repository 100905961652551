import { Component, Input, OnInit } from '@angular/core';
import { BankDataDetails } from '../../models/child-models/bank-data-details.model';

@Component({
  selector: 'app-banking-data-detail',
  templateUrl: './banking-data-detail.component.html',
  styleUrls: ['./banking-data-detail.component.scss'],
})
export class BankingDataDetailComponent {
  @Input()
  public bankData: BankDataDetails;

  constructor() {}
}
