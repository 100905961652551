import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment.local';
import { version } from '../environments/version';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'credenciamento';

  ngOnInit() {
    console.log('Application profile:', environment.profile);
    console.log('Last build date:', version.timeStamp);
    console.log('Version:', version.version);
  }
}
