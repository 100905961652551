import {Validators} from '@angular/forms';
import {EmailRegExp} from 'src/app/order/validators/email-compare-validator';
import {MobilePhoneValidator, PhoneValidator} from 'src/app/order/validators/phone-validator';


export const customDeliveryContactValidators = {

    name: {
        validators: [
            Validators.required
        ],
            updateOn: 'blur'
    },
    emailAddress: {
        validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.min(1),
            Validators.pattern(EmailRegExp)
        ],
            updateOn: 'blur'
    },
    phoneNumber: {
        validators: [
            PhoneValidator,
            Validators.required
        ],
            updateOn: 'blur'
    },
  cellPhoneNumber: {
    validators: [
      PhoneValidator,
      Validators.required
    ],
    updateOn: 'blur'
  }
}
