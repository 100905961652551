<mat-card class="fd-credit-risk-notes-detail__card-container" *ngIf="obsRiskCredit?.length > 0 || proposalRejection?.length > 0">
  <mat-card-title class="fd-credit-risk-notes-detail__card-title">Observações de Crédito e Risco</mat-card-title>
  <ng-container *ngIf="obsRiskCredit?.length > 0">
    <div *ngFor="let obs of obsRiskCredit; let i = index" fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-credit-risk-notes-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="20">
          <span>Data Inserida</span>
          <div>
            <strong>{{ obs?.lastModifiedDate }}</strong>
          </div>
        </div>
        <div fxFlex="20">
          <span>Tipo de Observação</span>
          <div>
            <strong>{{ obs?.proposalNoteType }}</strong>
          </div>
        </div>
        <div fxFlex="60">
          <span>Observação</span>
          <div>
            <strong>{{ obs?.proposalNote }}</strong>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="proposalRejection?.length > 0">
    <div [style.marginTop.px]="10" *ngFor="let reason of proposalRejection">
      <li>{{ reason.rejectionCd }} - {{ reason.reasonDescription }} <br /></li>
    </div>
  </ng-container>
</mat-card>
