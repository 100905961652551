<div [formGroup]="formGroup">
  <div mat-dialog-content class="fd-modal__title">
    <p>{{ data.title }}</p>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="stretch" mat-dialog-content class="fd-modal__option-container">
    <div class="fd-modal__option" *ngFor="let item of items; let i = index" style="width: 50%">
      <mat-checkbox
        (change)="onCheckboxChange(item, $event)"
        [value]="item.value"
        [checked]="item.selected"
        class="fd-checkbox"
        [id]="item.value"
        >{{ item.label }}</mat-checkbox
      >
    </div>
  </div>
  <fd-button [content]="'avançar'" id="btnNext" (trigger)="handleConfirmClick()"> </fd-button>
  <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="handleCancelClick()"> </fd-button>
</div>
