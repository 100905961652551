<form *ngIf="formGroup" [formGroup]="formGroup">
  <table mat-table [dataSource]="dataSource" class="fd-table">

    <!-- ID Column -->
    <ng-container matColumnDef="billingRange" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Faturamento</th>
      <td mat-cell *matCellDef="let row"> {{row.billingRange}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="suggestedPrepaymentFee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header"> Taxa sugerida</th>
      <td mat-cell *matCellDef="let row">
        <fd-fee-editor (openDialogFn)="openPrepaymentFeeEditorModal($event)"
          [parentForm]="formGroup" [field]="fields.suggestedPrepaymentFee"></fd-fee-editor>
      </td>
    </ng-container>

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</form>
