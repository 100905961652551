<h4 class="fd-subtitle">Horário de entrega:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div *ngIf="showdeliveryTypeField">
      <fd-select [parentForm]="formGroup" [field]="fields.deliveryType"></fd-select>
    </div>

    <div *ngIf="formControls.deliveryType.value !== 'IN_AGENCY'">
      <div>
        <fd-option-picker [parentForm]="formGroup" [field]="fields.days" (modalClosed)="modalClosed($event)"></fd-option-picker>
      </div>

      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-select (loadItemsFn)="loadHours($event, fields.fromHour.controlName)" [parentForm]="formGroup" [field]="fields.fromHour">
          </fd-select>
        </div>
        <div fxFlex="50">
          <fd-select (loadItemsFn)="loadHours($event, fields.untilHour.controlName)" [parentForm]="formGroup" [field]="fields.untilHour">
          </fd-select>
        </div>
      </div>
    </div>

    <div *ngIf="formControls.deliveryType.value === 'IN_AGENCY'">
      <fd-select [parentForm]="formGroup" [field]="fields.agency"></fd-select>
    </div>

    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'próxima etapa'" id="btnNext" (trigger)="next()"> </fd-button>
  </div>
</form>
