import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchCnaeCreditRiskModel } from 'src/app/order/components/offer/fee-type/models/search-cnae-credit-risk.model';
import { CnaeCreditRiskModel } from 'src/app/shared/models/response/response-cnae-credit-risk.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class CnaeCreditRiskService {
  constructor(private httpClient: HttpClient) {}

  public getCnaeCreditRisk(filter: SearchCnaeCreditRiskModel): Observable<CnaeCreditRiskModel> {
    return this.httpClient.get<CnaeCreditRiskModel>(
      `${environment.apiUrls.cnaeCreditRisk}/${filter.cnaeNumber}/${filter.serviceContract}`
    );
  }
}
