import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Messages } from 'src/app/order/messages/order.messages';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private dialogService: DialogService) {}

  public handleXHRError(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any) {
    console.error(err);
    const serializedError = err.error ? (err.error as ApiResultModel) : null;

    if (serializedError && serializedError.message) {
      if (callbackFn) {
        this.dialogService.openDialogWithMessage(serializedError.message, () => callbackFn());
        return;
      }
      this.dialogService.openDialogWithMessage(serializedError.message);
    } else {
      if (callbackFn) {
        this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
        return;
      }
      this.dialogService.openDialog(genericErrorMessage);
    }
  }
}
