export class SerproV2PartnerValidationModel {
  public cnpj: string;
  public socios: PartnerV2ValidationDTO[] = [];
}

export class PartnerV2ValidationDTO {
  public nome: string;
  public cpf: string;
  public cnpj: string;
  public dataNascimento: string;
  public dataFundacao: string;
  public isPep: boolean;
  public porcentagemParticipacao: number;
  public representanteLegal: LegalRepresentativeDTO;
}

export class LegalRepresentativeDTO {
  public nome: string;
  public cpf: string;
}
