import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OfferTypeEnum } from 'src/app/order/enums/offer-type.enum';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { OfferDataTypeModel, Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

import { ProfessionalTypeEnum } from 'src/app/order/enums/professional-type.enum';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-offer-type',
  templateUrl: './offer-type.component.html',
  styleUrls: ['./offer-type.component.scss'],
})
export class OfferTypeComponent extends FormStep implements OnInit, OnDestroy {
  public offerDataTypeModel: OfferDataTypeModel;

  public STEP_NUMBER = 3;
  public options: Item[] = [
    {
      label: 'Aluguel',
      value: OfferTypeEnum.RENTAL,
    },
    {
      label: 'Venda',
      value: OfferTypeEnum.SALES,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public back() {
    let orderNumber = 0;

    if (this.proposal.professionalLicense === undefined || this.proposal.professionalLicense === null) {
      orderNumber = 1;
    } else if (this.proposal.professionalLicense.licenseTypeName === ProfessionalTypeEnum.INVOICE) {
      orderNumber = 3;
    } else if (this.proposal.professionalLicense.licenseTypeName === ProfessionalTypeEnum.OPERATING_LICENSE) {
      orderNumber = 4;
    } else if (this.proposal.professionalLicense.licenseTypeName === ProfessionalTypeEnum.PROFESSIONAL_COUNCIL) {
      orderNumber = 5;
    } else {
      orderNumber = 1;
    }

    this.wizardService.goToSpecificStep(3, orderNumber);
  }

  protected proposalGetCallback(): void {}

  protected persistData() {
    const offerDataType: OfferDataTypeModel = {
      ...this.formGroup.getRawValue(),
    };

    return this.dataStore.updateProposal({ offerDataType });
  }

  protected updateFields(proposal: Proposal) {
    this.offerDataTypeModel = proposal.offerDataType;
    if (this.offerDataTypeModel) {
      (this.fields.offerType as FdRadioButtonConfig).items.forEach((item) => {
        item.selected = item.value === this.offerDataTypeModel.offerType;
      });
      this.formControls.offerType.setValue(this.offerDataTypeModel.offerType);
    } else {
      this.formControls.offerType.setValue(OfferTypeEnum.RENTAL);
    }
  }

  protected createFormGroup(): FormGroup {
    return this.formBuilder.group({
      offerType: ['', Validators.required],
    });
  }

  protected createFields(): FdFieldConfigs {
    return {
      offerType: {
        items: this.options,
        controlName: 'offerType',
        messages: {
          required: 'Informe o tipo de credenciamento',
          invalid: 'Tipo de credenciamento inválido',
        },
      },
    };
  }
}
