import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/order/constants/constants';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig } from '../fd-form-components/fd-select/fd-select.component';
import { HierarchyComplementaryInformationModel } from '../models/user-data.model';
import { FdFieldConfigs } from '../shared-components.module';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output()
  public backAction = new EventEmitter<void>();

  @Input()
  public hasHierarchyDropdown = false;
  public userData = this.dataStoreService.getUserData();
  public name: string;
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public isCEF = this.dataStoreService.getCEFFlag();
  public isMDROrMDRFlexStep: boolean;

  constructor(
    private dataStore: DataStore,
    private dataStoreService: DataStoreService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private headerService: HeaderService,
    private wizardService: WizardService,
    private dialogService: DialogService,
    private routingService: RoutingService
  ) {}

  public async ngOnInit() {
    this.formGroup = this.formBuilder.group({
      subChannel: [''],
      agentChannel: [''],
    });

    this.fields = this.createFields();
    this.name = this.dataStoreService.getUsername();

    this.formGroup.controls.agentChannel.disable();

    this.wizardService.getCurrentStep().subscribe((item) => {
      this.isMDROrMDRFlexStep =
        item.step === orderRoutingDefinitions.offer.stepNumber &&
        (item.subStep === orderRoutingDefinitions.offer.routes.MDR_FLEX_FEE_EDIT.order ||
          item.subStep === orderRoutingDefinitions.offer.routes.FEE_EDIT.order);
    });
  }

  public getButtonLabel() {
    return this.isCEF ? 'VISUALIZADOR DE TAXAS' : 'NOVO CREDENCIAMENTO';
  }

  public groupBy(lista, coluna) {
    const colunas = {};
    const resultado = [];

    lista.forEach((item) => {
      const reg = {};

      colunas[item[coluna]] = colunas[item[coluna]] || [];

      for (const i in item) {
        if (i !== coluna) {
          reg[i] = item[i];
        }
      }

      colunas[item[coluna]].push(reg);
    });

    for (const i in colunas) {
      if (i) {
        resultado.push({ key: i, values: colunas[i] });
      }
    }

    return resultado;
  }

  get hasMoreThanOneHierarchy() {
    return this.userData.hierarchyComplementaryInformation && this.userData.hierarchyComplementaryInformation.length > 1;
  }

  get hasMoreThanOneAgentIdBySubChannel() {
    let hasMoreThanOneAgentId: boolean = false;
    const hierarchyComplementaryInformation = this.userData.hierarchyComplementaryInformation;
    let hierarchyComplementaryGroupBySubChannel = this.groupBy(hierarchyComplementaryInformation, 'subChannel');

    const subChannelSelected = (this.fields.subChannel as FdSelectConfig).items.find((x) => x.selected);

    if (subChannelSelected) {
      hierarchyComplementaryGroupBySubChannel = hierarchyComplementaryGroupBySubChannel.filter((h) => {
        if (h.key === subChannelSelected.value) {
          return h;
        }
      });
    }

    hierarchyComplementaryGroupBySubChannel.forEach((s) => {
      if (s.values && s.values.length > 1) {
        hasMoreThanOneAgentId = true;
      }
    });

    return hasMoreThanOneAgentId;
  }

  public loadSubChannels() {
    const userData = this.dataStoreService.getUserData();

    const h = this.dataStoreService.getHierarchyComplementaryInformation();

    if (this.userData.hierarchyComplementaryInformation.some((h) => h.selectedSubChannel && h.selectedAgentChannel)) {
      const distinct = [];
      (this.fields.subChannel as FdSelectConfig).items.push(
        ...this.userData.hierarchyComplementaryInformation
          .filter((value) => {
            if (!distinct.includes(value.subChannel)) {
              distinct.push(value.subChannel);
              return value;
            }
          })
          .map((item: HierarchyComplementaryInformationModel, ind, arr) => ({
            value: item.subChannel,
            label: item.subChannelDescription,
            selected: item.selectedSubChannel && item.selectedAgentChannel,
          }))
      );
    } else {
      const distinct = [];
      (this.fields.subChannel as FdSelectConfig).items.push(
        ...this.userData.hierarchyComplementaryInformation
          .filter((value) => {
            if (!distinct.includes(value.subChannel)) {
              distinct.push(value.subChannel);
              return value;
            }
          })
          .map((item: HierarchyComplementaryInformationModel, ind, arr) => ({
            value: item.subChannel,
            label: item.subChannelDescription,
            selected: ind === 0,
          }))
      );
    }

    const itemSelected = (this.fields.subChannel as FdSelectConfig).items.find((x) => x.selected);

    this.formGroup.controls.subChannel.setValue(itemSelected.value);

    userData.hierarchyComplementaryInformation.map((y) => {
      if (y.subChannel === this.formGroup.controls.subChannel.value) {
        y.selectedSubChannel = true;
        return;
      }

      delete y.selectedSubChannel;
    });

    this.dataStoreService.setUserData(userData);
    this.formGroup.controls.agentChannel.enable();
    this.loadAgentChannels(this.formGroup.controls.subChannel.value);
  }

  public loadAgentChannels(subChannel: string) {
    (this.fields.agentChannel as FdSelectConfig).items = [];
    (this.fields.agentChannel as FdSelectConfig).items.push(
      ...this.userData.hierarchyComplementaryInformation
        .filter((x) => x.subChannel === subChannel)
        .map((item: HierarchyComplementaryInformationModel, ind, arr) => ({
          value: item.agentId,
          label: item.agentDescription,
          selected: ind === 0,
        }))
    );

    this.formGroup.controls.agentChannel.setValue((this.fields.agentChannel as FdSelectConfig).items.find((x) => x.selected).value);
    const userData = this.dataStoreService.getUserData();

    userData.hierarchyComplementaryInformation.map((y) => {
      if (y.agentId === this.formGroup.controls.agentChannel.value) {
        y.selectedAgentChannel = true;
        return;
      }

      delete y.selectedAgentChannel;
    });

    this.dataStoreService.setUserData(userData);
  }

  public setSelectedSubChannel() {
    if (!this.formGroup.controls.subChannel.value) {
      return;
    }

    const userData = this.dataStoreService.getUserData();

    userData.hierarchyComplementaryInformation.map((y) => {
      if (y.subChannel === this.formGroup.controls.subChannel.value) {
        y.selectedSubChannel = true;
        return;
      }

      delete y.selectedSubChannel;
    });
    (this.fields.subChannel as FdSelectConfig).items.forEach((subchannel) => {
      if (this.formGroup.controls.subChannel.value !== subchannel.value) {
        subchannel.selected = false;
      } else {
        subchannel.selected = true;
      }
    });

    this.dataStoreService.setUserData(userData);
    this.formGroup.controls.agentChannel.enable();

    this.loadAgentChannels(this.formGroup.controls.subChannel.value);
    this.headerService.hasChangeSelectorHierarchyInformation();
  }

  public setSelectedAgentChannel() {
    if (!this.formGroup.controls.agentChannel.value) {
      return;
    }

    const userData = this.dataStoreService.getUserData();

    userData.hierarchyComplementaryInformation.map((y) => {
      if (y.agentId === this.formGroup.controls.agentChannel.value) {
        y.selectedAgentChannel = true;
        return;
      }

      delete y.selectedAgentChannel;
    });

    this.dataStoreService.setUserData(userData);
    this.headerService.hasChangeSelectorHierarchyInformation();
  }

  public createFields(): FdFieldConfigs {
    return {
      subChannel: {
        label: 'Selecione um subcanal',
        controlName: 'subChannel',
        items: [],
      },
      agentChannel: {
        label: 'Selecione um agente',
        controlName: 'agentChannel',
        items: [],
        placeholder: 'Selecione um agente',
      },
    };
  }

  public logout() {
    this.authService.logoutApi().subscribe(() => (this.isCEF ? this.authService.logoutCEF() : this.authService.logout()));
  }

  public triggerBackAction() {
    this.backAction.emit();
  }
}
