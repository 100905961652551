import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }],
})
export class ErrorModalComponent {
  @Output() public buttonClicked = new EventEmitter<any>();
  @ViewChild('tooltip') public tooltip: MatTooltip;

  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalDialogData) {}

  public handleClick(): void {
    if (this.data.callbackFn) {
      this.data.callbackFn();
    }
    this.dialogRef.close();
  }

  public getButtonContent() {
    return (this.data && this.data.buttonText) || 'fechar';
  }

  public copyErrorToClipboard(error: string) {
    const listener = (e: ClipboardEvent) => {
      const clipboardDataString = 'clipboardData';
      const clipboard = e.clipboardData || window[clipboardDataString];
      clipboard.setData('text', error);
      e.preventDefault();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);

    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 2000);
  }
}

export interface ErrorModalDialogData {
  title: string;
  description: string;
  errorDetails: string;
  buttonText: string;
  alert: string;
  callbackFn: () => any;
}
