<form *ngIf="formGroup" [formGroup]="formGroup">
  <h4 class="fd-subtitle">Envio do extrato EDI para a conciliadora</h4>
  <div>
    <fd-select (selectionChange)="selectConciliator()" (loadItemsFn)="listConciliators($event, fields.conciliator)"
               [parentForm]="formGroup" [field]="fields.conciliator">
    </fd-select>
  </div>

  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'próxima etapa'" (trigger)="next()">
    </fd-button>
  </div>
</form>
