import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private routingService: RoutingService) {}

  public canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return of(currentRoute).pipe(
      map((route: ActivatedRouteSnapshot) => {
        let canActivate = true;
        if (this.authService.isLoggedIn()) {
          this.routingService.navigateToHome(true);
          canActivate = false;
        }

        return canActivate;
      })
    );
  }
}
