<form *ngIf="!!formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-input (blur)="cpfCnpjChange($event)" [parentForm]="formGroup" [field]="fields.cpfCnpj"> </fd-input>
    </div>
    <div *ngIf="documentCategory === 'CNPJ'">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.socialReason"> </fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.companyFantasyName"> </fd-input>
      </div>
      <div *ngIf="complianceDataEnabled">
        <fd-input [parentForm]="formGroup" [field]="fields.constitutionForm"> </fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.openDate"> </fd-input>
      </div>
      <div>
        <fd-input (blur)="emailChange($event, 'companyEmail')" [parentForm]="formGroup" [field]="fields.companyEmail"> </fd-input>
      </div>
      <div>
        <fd-input (blur)="phoneChange($event, 'companyMobileNumber')" [parentForm]="formGroup" [field]="fields.companyMobileNumber">
        </fd-input>
      </div>

      <div *ngIf="showPartnerField">
        <fd-input [parentForm]="formGroup" [field]="fields.partnerId"> </fd-input>
      </div>

      <div *ngIf="isExceptionFlow">
        <fd-input (blur)="notesChange($event)" [parentForm]="formGroup" [field]="fields.notesCreditRisk"> </fd-input>
      </div>
    </div>
    <div *ngIf="!documentCategory || documentCategory === 'CPF'">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.name"> </fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.personFantasyName"> </fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.birthDate"> </fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.siteAddress"> </fd-input>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="100" *ngIf="!rneEnabled">
          <fd-select (loadItemsFn)="listNationality($event, fields.nationality)" [parentForm]="formGroup" [field]="fields.nationality">
          </fd-select>
        </div>
        <div fxFlex="50" *ngIf="rneEnabled">
          <fd-select (loadItemsFn)="listNationality($event, fields.nationality)" [parentForm]="formGroup" [field]="fields.nationality">
          </fd-select>
        </div>
        <div fxFlex="50" *ngIf="rneEnabled">
          <fd-input [parentForm]="formGroup" [field]="fields.rne"> </fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input (blur)="phoneChange($event, 'fixedNumberOne')" [parentForm]="formGroup" [field]="fields.fixedNumberOne"> </fd-input>
        </div>
        <div fxFlex="50">
          <fd-input (blur)="phoneChange($event, 'fixedNumberTwo')" [parentForm]="formGroup" [field]="fields.fixedNumberTwo"> </fd-input>
        </div>
      </div>
      <div *ngIf="motherDataEnabled">
        <fd-input [parentForm]="formGroup" [field]="fields.motherName"> </fd-input>
      </div>
      <div>
        <fd-input (blur)="emailChange($event, 'personEmail')" [parentForm]="formGroup" [field]="fields.personEmail"> </fd-input>
      </div>
      <div>
        <fd-input (blur)="phoneChange($event, 'personMobileNumber')" [parentForm]="formGroup" [field]="fields.personMobileNumber">
        </fd-input>
      </div>
      <div *ngIf="isExceptionFlow">
        <fd-input (blur)="notesChange($event)" [parentForm]="formGroup" [field]="fields.notesCreditRisk"> </fd-input>
      </div>

      <div *ngIf="showPartnerField">
        <fd-input [parentForm]="formGroup" [field]="fields.partnerId"> </fd-input>
      </div>

      <div class="pep-description-container">
        <h4 class="fd-subtitle pep-subtitle">Pessoa Politicamente Exposta?</h4>
        <fa-icon
          matTooltipClass="fd-input__tooltip-dialog"
          class="tooltip-icon"
          icon="info-circle"
          size="1x"
          mat-button
          [matTooltip]="getPepTooltipText()"
          #tooltip="matTooltip"
          (click)="showTooltipAndStopPropagation()"
        ></fa-icon>
      </div>
      <div>
        <fd-radio-button
          [yesOrNotOptions]="true"
          [parentForm]="formGroup"
          [field]="fields.politicalExposition"
          (change)="pepEnabledForm($event)"
        ></fd-radio-button>
      </div>

      <ng-container *ngIf="isPepEnable">
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.pepName"> </fd-input>
        </div>
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.pepRole"> </fd-input>
        </div>
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.pepGovernmentOrganization"> </fd-input>
        </div>
      </ng-container>

      <h4 class="fd-subtitle">Utilizar dados para contato?</h4>
      <div>
        <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.useAsDeliveryContact"> </fd-radio-button>
      </div>

      <h4 class="fd-subtitle">Utilizar dados para recebimento?</h4>
      <div>
        <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.useAsReceivingData"> </fd-radio-button>
      </div>
    </div>
  </div>
</form>
<fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="navigateToHome()"> </fd-button>
<fd-button [disabled]="!successDocumentCall" id="btnNext" [content]="'próxima etapa'" (trigger)="checkSerproConfiguration()"> </fd-button>
