import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormStep } from 'src/app/order/components/form-step';
import { ProfessionalTypeEnum } from 'src/app/order/enums/professional-type.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { ProfessionalLicense, Proposal } from 'src/app/shared/models/proposal';
import { ConfigurationService } from 'src/app/start/services/configuration.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent extends FormStep implements OnInit {
  protected STEP_NUMBER: number;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.lockFieldNf();
  }

  public lockFieldNf() {
    this.formControls.licenseTypeName.setValue(ProfessionalTypeEnum.OPERATING_LICENSE);
    this.formControls.licenseTypeName.disable();
  }
  protected proposalGetCallback(): void {}

  protected createFields() {
    return {
      licenseTypeName: {
        label: 'Número do Processo',
        controlName: 'licenseTypeName',
      },

      processNumber: {
        label: 'Número do Processo',
        controlName: 'processNumber',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe o número do processo',
        },
        tooltipMessage: 'Informe o número do processo que consta no alvará',
        maxLength: 30,
      },

      dmlNumber: {
        label: 'Número do DML',
        controlName: 'dmlNumber',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe o número do DML',
        },
        maxLength: 30,
      },

      validityDate: {
        label: 'Validade do Documento',
        controlName: 'validityDate',
        maskCharsReplace: /[/\//]/g,
        mask: DateMask,
        messages: {
          required: 'Informe a data de validade do documento',
          invalid: 'Data inválida',
        },
        tooltipMessage: 'Informe a data de validade do documento',
      },
    };
  }

  protected persistData(): Promise<boolean> {
    const professionalLicense: ProfessionalLicense = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ professionalLicense });
  }

  protected updateFields(proposal: Proposal): void {
    if (proposal && proposal.professionalLicense) {
      const prof = this.proposal.professionalLicense;
      this.formControls.licenseTypeName.setValue(prof.licenseTypeName);
      this.formControls.processNumber.setValue(prof.processNumber);
      this.formControls.dmlNumber.setValue(prof.dmlNumber);
      this.formControls.validityDate.setValue(prof.validityDate);
      this.formControls.licenseTypeName.setValue(ProfessionalTypeEnum.OPERATING_LICENSE);
    }
  }

  protected createFormGroup(): FormGroup {
    return this.formBuilder.group({
      // alvará
      licenseTypeName: ['', Validators.required],
      processNumber: ['', Validators.required],
      dmlNumber: ['', Validators.required],
      validityDate: ['', [dateValidator(true, false), Validators.required]],
    });
  }
}
