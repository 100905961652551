import { StepInfo } from '../services/internal/wizard/wizard.service';

export const orderRoutingDefinitions: RouteRootDefinitions = {
  registrationData: {
    stepNumber: 2,
    stepName: 'Dados Cadastrais',
    routes: {
      PERSONAL_INFO: {
        order: 1,
        parentRoute: 'order',
        name: 'personal-info',
      },
      ADDRESS: {
        order: 2,
        parentRoute: 'order',
        name: 'address',
      },
      DELIVERY_ADDRESS: {
        order: 3,
        parentRoute: 'order',
        name: 'delivery-address',
      },

      MAILING_ADDRESS: {
        order: 4,
        parentRoute: 'order',
        name: 'mailing-address',
      },
    },
  },
  offer: {
    stepNumber: 3,
    stepName: 'Oferta',
    routes: {
      OFFER_DETAILS: {
        order: 1,
        parentRoute: 'order',
        name: 'offer-details',
      },
      PROFESSIONAL_LICENSE_REGISTER: {
        order: 2,
        parentRoute: 'order',
        name: 'professional-license-register',
      },
      INVOICE: {
        order: 3,
        parentRoute: 'order',
        name: 'nota-fiscal',
      },
      OPERATING_LICENSE: {
        order: 4,
        parentRoute: 'order',
        name: 'license',
      },
      PROFESSIONAL_COUNCIL: {
        order: 5,
        parentRoute: 'order',
        name: 'prof-register',
      },
      OFFER_TYPE: {
        order: 6,
        parentRoute: 'order',
        name: 'offer-type',
      },
      PRODUCT_SELECTION: {
        order: 7,
        parentRoute: 'order',
        name: 'product-selection',
      },
      FEE_TYPE: {
        order: 8,
        parentRoute: 'order',
        name: 'fee-type',
      },
      FEE_EDIT: {
        order: 9,
        parentRoute: 'order',
        name: 'fee-edit',
      },
      MDR_FLEX_FEE_EDIT: {
        order: 10,
        parentRoute: 'order',
        name: 'mdr-flex-fee-edit',
      },
      SALES_DATA: {
        order: 11,
        parentRoute: 'order',
        name: 'sales-data',
      },
    },
  },
  proposal: {
    stepNumber: 4,
    stepName: 'Proposta',
    routes: {
      DELIVERY_CONTACT: {
        order: 1,
        parentRoute: 'order',
        name: 'delivery-contact',
      },
      RECEIVING_DATA: {
        order: 2,
        parentRoute: 'order',
        name: 'receiving-data',
      },
      PARTNER_DATA: {
        order: 3,
        parentRoute: 'order',
        name: 'partner-data',
      },
      BANK_DATA: {
        order: 4,
        parentRoute: 'order',
        name: 'bank-data',
      },
    },
  },
  openingHours: {
    stepNumber: 5,
    stepName: 'Horários de Funcionamento',
    routes: {
      WORKING_HOURS: {
        order: 1,
        parentRoute: 'order',
        name: 'working-hours',
      },
      DELIVERY_HOURS: {
        order: 2,
        parentRoute: 'order',
        name: 'delivery-hours',
      },
    },
  },
  enterpriseComplementaryInformation: {
    stepNumber: 6,
    stepName: 'Informações complementares para CNAE',
    routes: {
      HISTORY_PARTNER_ADMIN_AND_LEGAL_ISSUES: {
        order: 1,
        parentRoute: 'order',
        name: 'history-partner-admin-and-legal-issues',
      },
      BUSINESS_AFFILIATIONS: {
        order: 2,
        parentRoute: 'order',
        name: 'business-affiliations',
      },
    },
  },
  eCommerceComplementaryInformation: {
    stepNumber: 7,
    stepName: 'Informações complementares da tecnologia E-Commerce',
    routes: {
      E_COMMERCE_FUNCTIONALITIES: {
        order: 1,
        parentRoute: 'order',
        name: 'e-commerce-functionalities',
      },
      GATEWAY_ANTIFRAUD_INFORMATION: {
        order: 2,
        parentRoute: 'order',
        name: 'gateway-antifraud-information',
      },
      BRANCH_OFFICES: {
        order: 3,
        parentRoute: 'order',
        name: 'branch-offices',
      },
      BUSINESS_PROSPECTION: {
        order: 4,
        parentRoute: 'order',
        name: 'business-prospection',
      },
      ENTERPRISE_ADDITIONAL_QUESTIONS: {
        order: 5,
        parentRoute: 'order',
        name: 'enterprise-additional-questions',
      },
    },
  },
  tefComplementaryInformation: {
    stepNumber: 8,
    stepName: 'Informações complementares da tecnologia TEF',
    routes: {
      TEF_IT_CONTACT: {
        order: 1,
        parentRoute: 'order',
        name: 'tef-it-contact',
      },
      TEF_PIN_PAD_INFORMATION: {
        order: 2,
        parentRoute: 'order',
        name: 'tef-pin-pad-information',
      },
      TEF_BUSINESS_INFORMATION: {
        order: 3,
        parentRoute: 'order',
        name: 'tef-business-information',
      },
      FIRST_DATA_PIN_PAD: {
        order: 4,
        parentRoute: 'order',
        name: 'first-data-pin-pad',
      },
      TEF_PROVIDER_INFORMATION: {
        order: 5,
        parentRoute: 'order',
        name: 'tef-provider-information',
      },
      TEF_INTEGRATION: {
        order: 6,
        parentRoute: 'order',
        name: 'tef-integration',
      },
      TEF_ACTIVATION_PERIOD: {
        order: 7,
        parentRoute: 'order',
        name: 'tef-activation-period',
      },
    },
  },
  tefBrowserComplementaryInformation: {
    stepNumber: 9,
    stepName: 'Informações complementares da tecnologia POS SITEF BROWSER',
    routes: {
      TEF_BROWSER_IT_CONTACT: {
        order: 1,
        parentRoute: 'order',
        name: 'tef-browser-it-contact',
      },
      TEF_BROWSER_INTEGRATION: {
        order: 2,
        parentRoute: 'order',
        name: 'tef-browser-integration',
      },
      TEF_BROWSER_ACTIVATION_PERIOD: {
        order: 3,
        parentRoute: 'order',
        name: 'tef-browser-activation-period',
      },
    },
  },
  ediComplementaryInformation: {
    stepNumber: 10,
    stepName: 'Informações complementares de EDI',
    routes: {
      EDI_ADDITIONAL_DATA: {
        order: 1,
        parentRoute: 'order',
        name: 'edi-additional-data',
      },
    },
  },
  proposalTerms: {
    stepNumber: 11,
    stepName: 'Termos de uso',
    routes: {
      PROPOSAL_TERMS_ACCEPTANCE: {
        order: 1,
        parentRoute: 'order',
        name: 'proposal-terms-acceptance',
      },
    },
  },
  orderFinishing: {
    stepNumber: 12,
    stepName: 'Finalizar o Pedido',
    routes: {
      PAYMENT_DATA: {
        order: 1,
        parentRoute: 'order',
        name: 'payment-data',
      },
    },
  },
  submittedProposal: {
    stepNumber: 13,
    stepName: 'Proposta Submetida',
    routes: {
      SUBMITTED_PROPOSAL: {
        order: 1,
        parentRoute: 'order',
        name: 'submitted-proposal',
      },
    },
  },
};

export function getTotalPhases(): number {
  return Object.values(orderRoutingDefinitions).length;
}

export function getAllSteps(): RoutingDefinitions[] {
  return Object.values(orderRoutingDefinitions);
}

export function getTotalSteps(stepNumber: number): number {
  const definition: RoutingDefinitions = getRoutingDefinition(stepNumber);
  return Object.values(definition.routes).length;
}

export function getRouteNameByOrder(stepNumber: number, routeOrder: number): string {
  const definition: RoutingDefinitions = getRoutingDefinition(stepNumber);
  const route: RouteProperties = getRoutePropertyByOrder(routeOrder, definition);

  return route && route.name;
}

export function getRouteByOrder(stepNumber: number, routeOrder: number): RouteProperties {
  const definition: RoutingDefinitions = getRoutingDefinition(stepNumber);
  const route: RouteProperties = getRoutePropertyByOrder(routeOrder, definition);

  return route;
}

export function getParentRouteByOrder(stepNumber: number, routeOrder: number): string {
  const definition: RoutingDefinitions = getRoutingDefinition(stepNumber);
  const route: RouteProperties = getRoutePropertyByOrder(routeOrder, definition);

  return route && route.parentRoute;
}

export function getOrderByRoute(routeName: string): number {
  let order = 0;
  Object.keys(orderRoutingDefinitions).forEach((phase) => {
    Object.keys(orderRoutingDefinitions[phase].routes).forEach((route) => {
      if (orderRoutingDefinitions[phase].routes[route].name === routeName) {
        order = orderRoutingDefinitions[phase].routes[route].order;
      }
    });
  });
  return order;
}

export function getStepInfoByRoute(routeName: string): StepInfo {
  let step = 0;
  let subStep = 0;
  let name;
  let hasSensitiveData;
  Object.keys(orderRoutingDefinitions).forEach((phase) => {
    Object.keys(orderRoutingDefinitions[phase].routes).forEach((route) => {
      if (orderRoutingDefinitions[phase].routes[route].name === routeName) {
        step = orderRoutingDefinitions[phase].stepNumber;
        name = orderRoutingDefinitions[phase].routes[route].name;
        subStep = orderRoutingDefinitions[phase].routes[route].order;
        hasSensitiveData = orderRoutingDefinitions[phase].routes[route].hasSensitiveData;
      }
    });
  });
  return { step, subStep, name, hasSensitiveData };
}

function getRoutingDefinition(stepNumber: number): RoutingDefinitions {
  const definition: RoutingDefinitions | undefined = Object.values(orderRoutingDefinitions).find((p) => p.stepNumber === stepNumber);

  if (!definition) {
    throw new Error(`não foi encontrado uma definição de rota para a fase [${stepNumber}]`);
  }
  return definition;
}

function getRoutePropertyByOrder(routeOrder: number, definition: RoutingDefinitions): RouteProperties {
  const route: RouteProperties | undefined = Object.values(definition.routes).find((r) => r.order === routeOrder);

  if (!route) {
    throw new Error(`não foi encontrado uma rota com a ordem [${routeOrder}]`);
  }
  return route;
}

export interface RouteRootDefinitions {
  [key: string]: RoutingDefinitions;
}

export interface RoutingDefinitions {
  stepNumber?: number;
  stepName?: string;
  isLastStep?: boolean;
  routes: { [key: string]: RouteProperties };
}

export interface RouteProperties {
  name: string;
  parentRoute?: string;
  order?: number;
  filter?: string[];
  jump?: boolean;
  hasSensitiveData?: boolean;
}
