import { Component, Input, OnInit } from '@angular/core';
import { CommercialAddressDetails } from '../../models/child-models/commercial-address-details.model';
import { DeliveryInstallationDetails } from '../../models/child-models/delivery-installation-details.model';
import { WorkingDaysDetailModel } from '../../models/child-models/working-days-detail.model';

@Component({
  selector: 'app-delivery-installation-details',
  templateUrl: './delivery-installation-details.component.html',
  styleUrls: ['./delivery-installation-details.component.scss'],
})
export class DeliveryInstallationDetailsComponent implements OnInit {
  @Input()
  public deliveryAndInstallation: DeliveryInstallationDetails[];

  @Input()
  public workingDays: WorkingDaysDetailModel[] = [];

  @Input()
  public deliveryAddress: CommercialAddressDetails;

  public workingMiddleWeekDays: WorkingDaysDetailModel[] = new Array<WorkingDaysDetailModel>();
  public workingWeekEndDays: WorkingDaysDetailModel[] = new Array<WorkingDaysDetailModel>();

  private weekMiddleDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
  private weekEndDays = ['SATURDAY', 'SUNDAY'];

  merchantEsitefId: string = '';
  merchantEsitefKey: string = '';

  constructor() {}

  public ngOnInit() {
    this.simplifyWorkingDays(this.workingDays);
    this.getMerchantEsitef(this.deliveryAndInstallation);
  }

  private simplifyWorkingDays(workingDays: WorkingDaysDetailModel[]): void {
    if (workingDays.length > 0) {
      this.workingMiddleWeekDays = workingDays.filter((day) => this.weekMiddleDays.findIndex((md) => md == day.weekDay.toString()) != -1);
      this.workingWeekEndDays = workingDays.filter((day) => this.weekEndDays.findIndex((md) => md == day.weekDay.toString()) != -1);
    }
  }

  private getMerchantEsitef(deliveryAndInstallations: DeliveryInstallationDetails[]) {
    if (deliveryAndInstallations && deliveryAndInstallations.length > 0) {
      let deliveryAndInstallationWithMerchantEsitef: DeliveryInstallationDetails = deliveryAndInstallations.find(
        (delAndInstall) => delAndInstall.merchantEsitef !== null
      );
      if (deliveryAndInstallationWithMerchantEsitef && deliveryAndInstallationWithMerchantEsitef.merchantEsitef !== null) {
        this.merchantEsitefId = deliveryAndInstallationWithMerchantEsitef.merchantEsitef.merchantEsitefId;
        this.merchantEsitefKey = deliveryAndInstallationWithMerchantEsitef.merchantEsitef.merchantEsitefKey;
      }
    }
  }
}
