<mat-card class="fd-contracted-services-data-detail__card-container">
  <mat-card-title class="fd-contracted-services-data-detail__card-title">Serviços Contratados</mat-card-title>
  <ng-container>
    <div class="fd-contracted-services-data-detail__card-margin" fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-contracted-services-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="20" *ngIf="contractedServices?.enabledAnticipation">
          <strong>Antecipação Habilitada</strong>
          <div>
            <span>{{ contractedServices?.enabledAnticipation ? 'Sim' : 'Não' }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="contractedServices?.enabledAnticipation">
          <strong>Taxa de Antecipação</strong>
          <div>
            <span>{{ contractedServices?.prePaymentFee | percent }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="contractedServices?.enabledAnticipation">
          <strong>Percentual de Reserva</strong>
          <div>
            <span>{{ contractedServices?.reservePercentage | percent }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="!contractedServices?.enabledAnticipation">
          <strong>MDR</strong>
          <div>
            <span>{{ contractedServices?.mdrFlex ? 'Flex' : 'Convencional' }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="contractedServices?.paymentDay">
          <strong>Prazo</strong>
          <div>
            <span>{{ contractedServices?.paymentDay }} dias</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="contractedServices?.factor1FlexRateFee">
          <strong>Fator 1</strong>
          <div>
            <span>{{ contractedServices?.factor1FlexRateFee | percent }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="contractedServices?.factor2FlexRateFee">
          <strong>Fator 2</strong>
          <div>
            <span>{{ contractedServices?.factor2FlexRateFee | percent }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="contractedServices?.edi">
          <strong>Extrato Eletrônico</strong>
          <div>
            <span>{{ contractedServices?.edi ? 'Sim' : 'Não' }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="contractedServices?.companyConciliator">
          <strong>Empresa Conciliadora</strong>
          <div>
            <span>{{ contractedServices?.companyConciliator }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="contractedServices?.otherAcquirerConciliator">
          <strong>Outro Adquerente no Conciliador</strong>
          <div>
            <span>{{ contractedServices?.otherAcquirerConciliator }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="contractedServices?.splitPaymentsDetails">
          <strong>Split de Pagamentos</strong>
          <div>
            <span>{{ contractedServices?.splitPaymentsDetails.ecType === 'EC_SECUNDARIO' ? 'EC Seller' : 'EC Master'}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-rate-data-table *ngIf="!(contractedServices?.splitPaymentsDetails && contractedServices?.splitPaymentsDetails.ecType === 'EC_SECUNDARIO')" [data]="rateData"></app-rate-data-table>
</mat-card>
