import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MdrFlexFeeModel } from 'src/app/shared/models/response/response-mdr-flex-fee.model';
import { environment } from 'src/environments/environment.local';
import { CampaignSimulatorFilterModel, CampaignSimulatorModel } from '../../../../shared/models/CampaignSimulatorFilterModel';
import { RateDataDetails } from '../../../../start/components/proposal-detail/models/child-models/rate-data-details.model';
import {
  ParamAvailableTechnologyDTO,
  TechnologyPriceDataModel,
} from '../../../../start/components/campaign-simulator/models/technology-price-data.model';
import { Constants } from '../../../constants/constants';
import { DataStoreService } from '../../../store/data-store.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignSimulatorService {
  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {}

  public getCampaignsByFilter(filter: CampaignSimulatorFilterModel): Observable<CampaignSimulatorModel[]> {
    let agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
    return this.http.post<CampaignSimulatorModel[]>(`${environment.apiUrls.feeSimulator}/campaigns`, filter);
  }

  public getMdrRates(filter: CampaignSimulatorFilterModel): Observable<RateDataDetails[]> {
    return this.http.post<RateDataDetails[]>(`${environment.apiUrls.feeSimulator}/rates/mdr`, filter);
  }

  public getTechnologyPrice(filter: ParamAvailableTechnologyDTO): Observable<TechnologyPriceDataModel[]> {
    return this.http.post<TechnologyPriceDataModel[]>(`${environment.apiUrls.feeSimulator}/available-technology`, filter);
  }

  public getPrepaymentRates(filter: CampaignSimulatorFilterModel) {
    return this.http.post<any>(`${environment.apiUrls.feeSimulator}/rates/prepayment`, filter);
  }

  public getMdrFlexRates(filter: CampaignSimulatorFilterModel): Observable<MdrFlexFeeModel[]> {
    return this.http.post<any>(`${environment.apiUrls.feeSimulator}/rates/mdrflex`, filter);
  }

  public getFactorFlexRates(filter: CampaignSimulatorFilterModel) {
    return this.http.post<any>(`${environment.apiUrls.feeSimulator}/rates/factor`, filter);
  }
}
