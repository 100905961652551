import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percent',
})
export class PercentPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  public transform(value: any): any {
    return value ? `${this._decimalPipe.transform(value, '1.2-2')}%` : '-';
  }
}
