import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { finalize, takeWhile, tap } from 'rxjs/operators';
import { MessageModalComponent, MessageModalDialogData } from 'src/app/order/components/modals/message-modal/message-modal.component';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseAddressModel } from 'src/app/shared/models/proposal';
import { CepResponseModel } from 'src/app/shared/models/response/response-address.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ModalDefinitions } from '../../enums/modal-definitions.enum';
import { AddressMask } from '../../masks/address-mask';
import { ComplementMask } from '../../masks/complement-mask';
import { NumberAddressMask } from '../../masks/number-address-mask';
import { OnlyNumberMask } from '../../masks/only-number-mask';
import { ZipcodeMask } from '../../masks/zipcode-mask';
import { Messages } from '../../messages/order.messages';
import { CepService } from '../../services/external/cep/cep.service';
import { DialogService } from '../../services/internal/dialog/dialog.service';
import { LoadingService } from '../../services/internal/loading/loading.service';
import { customAddressValidators } from '../registration-data/address/registration-data-address.validations';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnDestroy, OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public alive: boolean = true;
  public genericZipCode: boolean;
  public successCepCall: boolean;
  public isDisabled: boolean = true;

  public withoutNumber: Item = {
    value: 'S/N',
    label: 'S/N',
  };
  private readonly CEP_REGEX = /[0-9]{8}/;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private cepService: CepService,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.fields = this.createFields();
    this.formGroup = this.createFormGroup();
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      zipCode: ['', customAddressValidators.zipCode],
      address: ['', customAddressValidators.address],
      addressNumber: ['', customAddressValidators.number],
      complement: ['', customAddressValidators.complement],
      neighborhood: ['', customAddressValidators.neighborhood],
      city: ['', customAddressValidators.city],
      state: ['', customAddressValidators.state],
      noNumberFlag: [''],
    });
  }

  public getFields(): FdFieldConfigs {
    return this.fields;
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public setAddress(address: BaseAddressModel): void {
    this.setFieldsState(false);
    if (address) {
      this.formControls.zipCode.setValue(address.zipCode);
      this.formControls.address.setValue(address.address);
      this.formControls.addressNumber.setValue(address.addressNumber);
      this.formControls.complement.setValue(address.complement);
      this.formControls.neighborhood.setValue(address.neighborhood);
      this.formControls.city.setValue(address.city);
      this.formControls.state.setValue(address.state);
      this.formControls.noNumberFlag.setValue(address.noNumberFlag);

      this.withoutNumber.selected = address.noNumberFlag;

      this.successCepCall = true;
      this.isDisabled = false;

      if (address.genericZipCode) {
        this.setFieldsState(true);
      } else {
        this.formControls.complement.enable();
      }

      if (address.noNumberFlag) {
        this.formControls.addressNumber.disable();
      } else {
        this.formControls.addressNumber.enable();
      }
    }
  }

  public createFields(): FdFieldConfigs {
    return {
      zipCode: {
        label: 'CEP',
        controlName: 'zipCode',
        type: InputType.TEL,
        mask: ZipcodeMask,
        isNumber: true,
        maskCharsReplace: /[- ]/g,
        messages: {
          required: 'Informe o CEP',
          invalid: 'CEP inválido',
          min: 'CEP inválido',
        },
      },
      address: {
        label: 'Endereço',
        controlName: 'address',
        messages: {
          required: 'Informe o endereço',
          invalid: 'Endereço inválido',
        },
      },
      addressNumber: {
        label: 'Número',
        mask: OnlyNumberMask,
        type: InputType.TEL,
        controlName: 'addressNumber',
        messages: {
          maxlength: 'Número inválido',
          required: 'Informe o número',
          invalid: 'Número inválido',
        },
      },
      complement: {
        label: 'Complemento',
        mask: ComplementMask,
        controlName: 'complement',
      },
      neighborhood: {
        label: 'Bairro',
        controlName: 'neighborhood',
        messages: {
          required: 'Informe o bairro',
          invalid: 'Bairro inválido',
        },
      },
      city: {
        label: 'Cidade',
        controlName: 'city',
        messages: {
          required: 'Informe a cidade',
          invalid: 'Cidade inválida',
        },
      },
      state: {
        label: 'UF',
        controlName: 'state',
        messages: {
          required: 'Informe o estado',
          invalid: 'Estado inválido',
        },
      },
    };
  }

  public setCepValues(cepResponse: CepResponseModel) {
    this.formControls.address.setValue(cepResponse.address);
    this.formControls.neighborhood.setValue(cepResponse.neighborhood);
    this.formControls.city.setValue(cepResponse.city);
    this.formControls.state.setValue(cepResponse.state);
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public setFieldsState(enable: boolean) {
    if (enable) {
      this.formControls.address.enable();

      if (this.withoutNumber.selected) {
        this.formControls.addressNumber.disable();
      } else {
        this.formControls.addressNumber.enable();
      }

      this.formControls.complement.enable();
      this.formControls.neighborhood.enable();
      this.formControls.city.enable();
      this.formControls.state.enable();
      this.isDisabled = false;
    } else {
      this.formControls.address.disable();
      this.formControls.addressNumber.disable();
      this.formControls.complement.disable();
      this.formControls.neighborhood.disable();
      this.formControls.city.disable();
      this.formControls.state.disable();
      this.isDisabled = true;
    }
  }

  public setFieldsNeighborhoodState() {
    this.formControls.neighborhood.enable();
  }
  public setFieldsStreetState() {
    this.formControls.address.enable();
  }

  public handleError(error: any) {
    if (!error.status) {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
      console.error('[CEP] - Unspecified error when querying CEP');
    } else if (error.status === 404) {
      this.dialogService.openDialog(Messages.INVALID_CEP);
      console.error('[CEP] - CEP not found');
    } else {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
    }
  }

  public clearFieldsValues() {
    this.formControls.address.setValue('');
    this.formControls.addressNumber.setValue('');
    this.formControls.complement.setValue('');
    this.formControls.neighborhood.setValue('');
    this.formControls.city.setValue('');
    this.formControls.state.setValue('');
    this.withoutNumber.selected = false;
  }

  public cepChange(cepParam: string) {
    const cep = cepParam.replace(/\D+/g, '');
    if (this.CEP_REGEX.test(cep)) {
      this.cepService
        .validateCep(cep)
        .pipe(takeWhile(() => this.alive))

        .subscribe(
          (data) => {
            if (data) {
              this.setCepValues(data.cepResponse);
              if (!!data.cepResponse.genericZipCode) {
                this.genericZipCode = true;
                this.setFieldsState(true);
              } else {
                this.genericZipCode = false;
                this.setFieldsState(false);
                if (!data.cepResponse.neighborhood) {
                  this.setFieldsNeighborhoodState();
                }
                if (!data.cepResponse.address) {
                  this.setFieldsStreetState();
                }
                this.isDisabled = false;
                this.formControls.addressNumber.enable();
                this.formControls.complement.enable();
              }
              this.successCepCall = true;
            }
          },
          (error) => {
            this.successCepCall = false;
            this.handleError(error);
            this.clearFieldsValues();
          }
        );
    }
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.noNumberFlag.setValue(event.checked);
    if (event.checked) {
      this.formControls.addressNumber.disable();
      this.formControls.addressNumber.setValue(this.withoutNumber.value);
      this.formControls.addressNumber.clearValidators();
    } else {
      this.formControls.addressNumber.enable();
      this.formControls.addressNumber.setValidators(customAddressValidators.number.validators);
      this.formControls.addressNumber.setValue('');
    }
    this.formGroup.updateValueAndValidity();
  }
}
