<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Estabelecimento tem PIN Pad próprio?</h4>

    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasOwnPinPad">
      </fd-radio-button>
    </div>

    <div *ngIf="hasOwnPinPad">
      <fd-input [parentForm]="formGroup" [field]="fields.pinPadModel">
      </fd-input>
    </div>

    <h4 class="fd-subtitle">Estabelecimento tem PIN Pad de terceiros?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasThirdPartyPinPad">
      </fd-radio-button>
    </div>

    <div *ngIf="hasThirdPartyPinPad">
      <fd-input [parentForm]="formGroup" [field]="fields.thirdPartyPinPadModel">
      </fd-input>
    </div>

  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
