<h4 class="fd-subtitle">Dados bancários para recebimento de vendas à débito:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-select (selectionChange)="enableBank('DEBIT')" [parentForm]="formGroup" [field]="fields.accountTypeDebit"> </fd-select>
    </div>
    <div>
      <fd-select
        (selectionChange)="selectBank('DEBIT')"
        (loadItemsFn)="listBank($event, fields.bankDebit)"
        [parentForm]="formGroup"
        [field]="fields.bankDebit"
      >
      </fd-select>
    </div>

    <div *ngIf="!isCEF('debit')" fxLayoutGap="20px">
      <div fxFlex="50">
        <fd-input
          (focusin)="removeDigitChar($event)"
          (focusout)="insertDigitChar($event, 'AGENCY', 'DEBIT')"
          (change)="validateBankAndBankBranch('DEBIT')"
          [parentForm]="formGroup"
          [field]="fields.agencyDebit"
        >
        </fd-input>
      </div>
      <div fxFlex="50">
        <fd-input
          (focusin)="removeDigitChar($event)"
          (focusout)="insertDigitChar($event, 'ACCOUNT', 'DEBIT')"
          [parentForm]="formGroup"
          [field]="fields.accountDebit"
        >
        </fd-input>
      </div>
    </div>

    <div *ngIf="isCEF('debit')" fxLayoutGap="20px">
      <div *ngIf="isPf()">
        <div fxFlex="50">
          <fd-input
            (focusin)="removeDigitChar($event)"
            (focusout)="insertDigitChar($event, 'AGENCY', 'DEBIT')"
            (change)="validateBankAndBankBranch('DEBIT')"
            [parentForm]="formGroup"
            [field]="fields.agencyDebit"
          >
          </fd-input>
        </div>
        <div fxFlex="10" style="padding-left: 10px">
          <fd-select [parentForm]="formGroup" (selectionChange)="getBankRules('DEBIT')" [field]="fields.operationDebit"> </fd-select>
        </div>
        <div fxFlex="40" style="padding-left: 10px">
          <fd-input
            (focusin)="removeDigitChar($event)"
            (focusout)="insertDigitChar($event, 'ACCOUNT', 'DEBIT')"
            [parentForm]="formGroup"
            [field]="fields.accountDebit"
          >
          </fd-input>
        </div>
      </div>

      <div *ngIf="!isPf()">
        <div fxFlex="50">
          <fd-input
            (focusin)="removeDigitChar($event)"
            (focusout)="insertDigitChar($event, 'AGENCY', 'DEBIT')"
            (change)="validateBankAndBankBranch('DEBIT')"
            [parentForm]="formGroup"
            [field]="fields.agencyDebit"
          >
          </fd-input>
        </div>
        <div fxFlex="10" style="padding-left: 10px">
          <fd-select [parentForm]="formGroup" (selectionChange)="getBankRules('DEBIT')" [field]="fields.operationDebit"> </fd-select>
        </div>
        <div fxFlex="40" style="padding-left: 10px">
          <fd-input
            (focusin)="removeDigitChar($event)"
            (focusout)="insertDigitChar($event, 'ACCOUNT', 'DEBIT')"
            [parentForm]="formGroup"
            [field]="fields.accountDebit"
          >
          </fd-input>
        </div>
      </div>
    </div>

    <div>
      <h4 class="fd-subtitle">Utilizar mesma conta para recebimento de vendas à crédito?</h4>

      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.sameData"> </fd-radio-button>
    </div>

    <div *ngIf="!formControls.sameData.value">
      <h4 class="fd-subtitle">Dados bancários para recebimento de vendas à crédito:</h4>
      <div>
        <fd-select (selectionChange)="enableBank('CREDIT')" [parentForm]="formGroup" [field]="fields.accountTypeCredit"> </fd-select>
      </div>
      <div>
        <fd-select
          (selectionChange)="selectBank('CREDIT')"
          (loadItemsFn)="listBank($event, fields.bankCredit)"
          [parentForm]="formGroup"
          [field]="fields.bankCredit"
        >
        </fd-select>
      </div>

      <div fxLayoutGap="20px">
        <div *ngIf="!isCEF('credit')" fxLayoutGap="20px">
          <div fxFlex="50">
            <fd-input
              (focusin)="removeDigitChar($event)"
              (focusout)="insertDigitChar($event, 'AGENCY', 'CREDIT')"
              (change)="validateBankAndBankBranch('CREDIT')"
              [parentForm]="formGroup"
              [field]="fields.agencyCredit"
            >
            </fd-input>
          </div>
          <div fxFlex="50">
            <fd-input
              (focusin)="removeDigitChar($event)"
              (focusout)="insertDigitChar($event, 'ACCOUNT', 'CREDIT')"
              [parentForm]="formGroup"
              [field]="fields.accountCredit"
            >
            </fd-input>
          </div>
        </div>

        <div *ngIf="isCEF('credit')" fxLayoutGap="20px">
          <div *ngIf="isPf()">
            <div fxFlex="50">
              <fd-input
                (focusin)="removeDigitChar($event)"
                (focusout)="insertDigitChar($event, 'AGENCY', 'CREDIT')"
                (change)="validateBankAndBankBranch('CREDIT')"
                [parentForm]="formGroup"
                [field]="fields.agencyCredit"
              >
              </fd-input>
            </div>
            <div fxFlex="10" style="padding-left: 10px">
              <fd-select [parentForm]="formGroup" (selectionChange)="getBankRules('CREDIT')" [field]="fields.operationCredit"> </fd-select>
            </div>
            <div fxFlex="40" style="padding-left: 10px">
              <fd-input
                (focusin)="removeDigitChar($event)"
                (focusout)="insertDigitChar($event, 'ACCOUNT', 'CREDIT')"
                [parentForm]="formGroup"
                [field]="fields.accountCredit"
              >
              </fd-input>
            </div>
          </div>

          <div *ngIf="!isPf()">
            <div fxFlex="50">
              <fd-input
                (focusin)="removeDigitChar($event)"
                (focusout)="insertDigitChar($event, 'AGENCY', 'CREDIT')"
                (change)="validateBankAndBankBranch('CREDIT')"
                [parentForm]="formGroup"
                [field]="fields.agencyCredit"
              >
              </fd-input>
            </div>
            <div fxFlex="10" style="padding-left: 10px">
              <fd-select [parentForm]="formGroup" (selectionChange)="getBankRules('CREDIT')" [field]="fields.operationCredit"> </fd-select>
            </div>
            <div fxFlex="40" style="padding-left: 10px">
              <fd-input
                (focusin)="removeDigitChar($event)"
                (focusout)="insertDigitChar($event, 'ACCOUNT', 'CREDIT')"
                [parentForm]="formGroup"
                [field]="fields.accountCredit"
              >
              </fd-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'próxima etapa'" id="btnNext" (trigger)="checkSameData()"> </fd-button>
  </div>
</form>
