import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { CampaignConfigModel, CampaignModel } from 'src/app/shared/models/response/response-campaing.model';
import { CnaeModel } from 'src/app/shared/models/response/response-cnae.model';
import { RevenueRangeModel } from 'src/app/shared/models/response/response-revenue-range.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class CnaeService {
  public agentChannel: string;

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
  }

  public getCnaeList(filterValue: string, isPf: boolean): Observable<Item[]> {
    const headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    let cnaeFilter = '';
    if (filterValue && !/\d/.test(filterValue)) {
      cnaeFilter = `?partial-cnae=${filterValue}`;
    } else {
      cnaeFilter = `?partial-cnae=${parseInt(filterValue)}`;
    }
    const filterQuery = filterValue ? cnaeFilter : '';
    let url = `${environment.apiUrls.cnae}${filterQuery}`;
    if (isPf) {
      url = `${environment.apiUrls.cnae}/physical-person-cnaes${filterQuery}`;
    }

    return this.http
      .get<BaseConfigApiResponse<CnaeModel[]>>(url, { headers })
      .pipe(map((x) => (this.invalidResponse(x) ? null : this.mapToCnaeModels(x.data))));
  }

  public validateUnwantedSecondaryCnae(document: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.cnae}/validate-all-cnaes/${document}`);
  }

  public getCampaignList(): Observable<Item[]> {
    return this.http
      .get<CampaignModel[]>(`${environment.apiUrls.campaign}`)
      .pipe(map((x) => (this.invalidAPIResponse(x) ? null : this.mapToCampaignModels(x))));
  }

  public getCampaignListFromConfig(serviceContract: number): Observable<CampaignConfigModel[]> {
    return this.http
      .get<CampaignConfigModel[]>(`${environment.apiUrls.configCampaign}/service-contract/${serviceContract}`)
      .pipe(map((x) => (this.invalidAPIResponse(x) ? null : x)));
  }

  public getCampaignListWithCnpj(cnpj: string): Observable<Item[]> {
    return this.http
      .get<CampaignModel[]>(`${environment.apiUrls.campaign}?cnpj=${cnpj}`)
      .pipe(map((x) => (this.invalidAPIResponse(x) ? null : this.mapToCampaignModels(x))));
  }

  public getRevenueRangeList(campaignId: string, cnaeNumber: string): Observable<Item[]> {
    return this.http
      .get<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${campaignId}?cnae=${cnaeNumber}`)
      .pipe(tap((x) => x.sort((a, b) => a.minAnnualBilling - b.minAnnualBilling)))
      .pipe(map((x) => (this.invalidAPIResponse(x) ? null : this.mapToRevenueRangeModels(x))));
  }

  public getRevenueRangeListObject(campaignId: string, cnaeNumber: string): Observable<RevenueRangeModel[]> {
    return this.http.get<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${campaignId}?cnae=${cnaeNumber}`);
  }

  public validatePrimaryCnaeByCnpj(cnaeNumber: string, document: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.cnae}/validate-primary-cnae/${cnaeNumber}/${document}`);
  }

  private mapToCampaignModels(response: CampaignModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.idCampaign,
      label: value.description,
    }));
  }

  private mapToCnaeModels(response: CnaeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.cnaeNumber,
      label: value.cnaeNumber.toString() + ' - ' + value.cnaeDescription,
      additionalProperties: {
        status: value.status,
        ecommerceExceptionCnae: value.ecommerceExceptionCnae,
        mcc: value.mcc,
      },
    }));
  }

  private mapToRevenueRangeModels(response: RevenueRangeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.idRange,
      label: `R$ ${this.convertToCurrency(value.minAnnualBilling)} - R$ ${this.convertToCurrency(value.maxAnnualBilling)}`,
    }));
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<CnaeModel[]>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || !baseApiResponse.data;
  }
  private invalidAPIResponse(baseApiResponse: any): boolean {
    return !baseApiResponse;
  }

  private convertToCurrency(value: number) {
    let valueFixed = value.toFixed(2);
    return valueFixed.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}
