import { FocusMonitor } from '@angular/cdk/a11y';
import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeWhile, tap } from 'rxjs/internal/operators';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-currency-input',
  templateUrl: 'fd-currency-input.component.html',
  styleUrls: ['fd-currency-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FdCurrencyInputComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  },
})
export class FdCurrencyInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    this.cdr.detectChanges();
    return this.hasError;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl && this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get shouldLabelFloat() {
    return this.focused;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get required(): boolean {
    const validator = this.relatedFormControl.validator({} as AbstractControl);

    return validator && validator.required;
  }
  public static nextId = 0;
  public alive = true;

  @Input()
  public field: FdCurrencyInputConfig;

  @Output()
  public input = new EventEmitter<string>();

  @Input()
  public parentForm: FormGroup;

  @Output()
  public blur = new EventEmitter<string>();

  public stateChanges = new Subject<void>();
  public focused = false;
  public errorState = false;
  public controlType = 'fd-currency-input';
  public describedBy = '';
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  private _placeholder: string;

  constructor(
    formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    focusMonitor
      .monitor(elementRef, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe((origin) => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  public onChange = (_: any) => {};
  public onTouched = () => {};

  public unmask(control: AbstractControl, maskCharsReplace: RegExp): string {
    let unmaskedValue = '';
    if (!control) {
      return;
    }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
        })
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    return unmaskedValue;
  }

  public ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if (!this.relatedFormControl || !this.relatedFormControl.value) {
      this.relatedFormControl.setValue(0);
    }
  }

  public setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public handleKeyDown(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 189 && !this.field.allowNegative) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public handleFocus(event: Event) {
    const field = this.elementRef.nativeElement.querySelector('input');

    if (field && field.value) {
      setTimeout(() => {
        field.setSelectionRange(field.value.length, field.value.length);
      }, 0);
    }
  }

  public getMaxLength() {
    const value = this.relatedFormControl.value;

    if (!this.field || !value) {
      return;
    }

    if (!this.field.maxLength) {
      return 14;
    }

    return this.field && this.field.maxLength + 2;
  }

  public handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }

  public handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

  public getOptions(customOptions: any) {
    if (customOptions) {
      return customOptions;
    }

    if (this.field) {
      if (!this.field.isPercent) {
        return { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' };
      } else {
        return { prefix: '', suffix: ' %', thousands: '.', decimal: ',', align: 'left' };
      }
    }
  }
}

export type Mask = (RegExp | string)[];

export class FdCurrencyInputConfig {
  public controlName: string;
  public label: string;
  public maxLength: number;
  public isPercent: boolean;
  public allowNegative?: boolean = false;
  public disabled?: boolean;
  public maskCharsReplace?: RegExp;
  public messages?: { [key: string]: string };
  public error?: boolean;
  public customOptions?: any;
}
