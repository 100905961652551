import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';

import { ResponseNationalityModel } from 'src/app/shared/models/response/nationality-response';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class NationalityService {
  constructor(private http: HttpClient) {}

  public getNationalityList(): Observable<Item[]> {
    return this.http
      .get<ResponseNationalityModel[]>(`${environment.apiUrls.nationality}`)
      .pipe(map((x) => (this.invalidResponse(x) ? null : this.mapNationalityListModels(x))));
  }

  private invalidResponse(baseApiResponse: ResponseNationalityModel[]): boolean {
    return !baseApiResponse;
  }

  private mapNationalityListModels(response: ResponseNationalityModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.nationality,
      label: value.nationality,
    }));
  }
}
