import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { sanitizeSearchFilter } from 'src/app/order/utils/sanitize-search-filter';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-select',
  templateUrl: './fd-select.component.html',
  styleUrls: ['./fd-select.component.scss'],
})
export class FdSelectComponent extends FdFieldBaseComponent implements OnInit {
  get searchable() {
    return this.field && this.field.searchable;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }

  @Input()
  public field: FdSelectConfig;

  @Input()
  public parentForm: FormGroup;

  @Output()
  public loadItemsFn = new EventEmitter<string>();

  @Output() public selectionChange = new EventEmitter();

  @ViewChild('binding') public binding: ElementRef;

  @Output()
  public infiniteScroll = new EventEmitter<void>();

  public limit = 10;
  public offset = 0;
  public controlName: string;
  public context: any;
  public label: string;
  public accessibilityLabel?: string;
  public placeholder?: string;
  public items: Item[];
  public hint?: string;
  public messages?: { [key: string]: string };

  public focusedItem?: Item;

  private _items: Item[];
  private _filter: string;
  private _loadError: boolean;

  constructor(private spinner: NgxSpinnerService) {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public async ngOnInit() {
    if (this.loadItemsFn) {
      this.loadItemsFn.emit();
    }

    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }
  }

  public getIfSelected() {
    if (this.field && this.field.items) {
      const selectedItems = this.field.items.filter((x) => x.selected);

      if (selectedItems && selectedItems.length) {
        this.relatedFormControl.setValue(selectedItems[0].value);
      }
    }
    return null;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onSelectionChange($event: any) {
    this.updateRequiredField($event);
    this.selectionChange.emit($event);
  }

  private updateRequiredField($event: any) {
    if ($event && this.field?.items?.length > 0 && this.field?.required) {
      this.field.required = false;
    }
  }

  public handleSpace(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 32 || event.keyCode === 35 || event.keyCode === 36) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  public filter(event: KeyboardEvent) {
    this.handleSpace(event);

    const target = event.target as HTMLInputElement;

    const searchValue = sanitizeSearchFilter(target.value);

    this.loadItemsFn.emit(searchValue);
  }
}

export class Item {
  public label: string;
  public value: any;
  public selected?: boolean;
  public markAll?: boolean;
  public additionalProperties?: any;
}

export interface FdSelectConfig {
  controlName: string;
  disabled?: boolean;
  required?: boolean;
  label: string;
  searchPlaceholder?: string;
  context?: any;
  messages?: { [key: string]: string };
  searchable?: boolean;
  items: Item[];
}
