import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdValueEditorConfig } from 'src/app/shared/fd-form-components/fd-value-editor/fd-value-editor.component';

@Component({
  selector: 'app-fee-editor-modal',
  templateUrl: './fee-editor-modal.component.html',
  styleUrls: ['./fee-editor-modal.component.scss'],
})
export class FeeEditorModalComponent implements OnInit {
  public formGroup: FormGroup;
  public innerValueControl: AbstractControl;

  get minValueDisplay() {
    if (this.data.minValue === null || this.data.minValue === undefined) {
      return;
    }

    return getValueWithDecimalPlaces(this.data.minValue);
  }

  get maxValueDisplay() {
    if (this.data.maxValue === null || this.data.maxValue === undefined) {
      return;
    }

    return getValueWithDecimalPlaces(this.data.maxValue);
  }

  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<FeeEditorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: FeeEditorDialogData) {}

  public async ngOnInit() {
    if (!this.data) {
      console.error('No data present on modal object');
      return;
    }
    this.formGroup = this.data.formGroup;
    this.innerValueControl = this.formGroup.get(this.data.field.childControl.controlName);
    if (this.data.initialValue) {
      this.innerValueControl.setValue(this.data.initialValue);
    }
    this.setValidator();
  }

  public setValidator() {
    this.innerValueControl.setValidators([Validators.required]);
    this.innerValueControl.markAsUntouched();
  }

  get relatedFormControl(): AbstractControl {
    return this.data.formControl;
  }

  get inputField(): FdInputConfig {
    return this.data.field.childControl;
  }

  public hasSplitTech(): boolean {
    return this.data.hasSplitTech;
  }

  public handleCancelClick(): void {
    this.innerValueControl.setErrors(null);
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn(this.data);
    }
    this.dialogRef.close();
  }

  public handleConfirmClick(): void {
    this.innerValueControl.markAsTouched();
    if (this.innerValueControl.valid) {
      if (this.data.confirmCallbackFn) {
        this.data.confirmCallbackFn(this.data);
      }
      this.dialogRef.close();
    }
  }
}

export interface FeeEditorDialogData {
  title: string;
  itemDescription?: string;
  rowIndex?: number;
  minValue?: number;
  maxValue?: number;
  initialValue?: number;
  formControl: AbstractControl;
  formGroup: FormGroup;
  field: FdValueEditorConfig;
  cancelCallbackFn?: (data?: any) => any;
  confirmCallbackFn?: (data?: any) => any;
  hasSplitTech: boolean;
}
