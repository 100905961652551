import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup } from '@angular/forms';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-label',
  templateUrl: './fd-label.component.html',
  styleUrls: ['./fd-label.component.scss'],
})
export class FdLabelComponent extends FdFieldBaseComponent implements ControlValueAccessor {
  @Input()
  public label: string;

  @Input()
  public text: string;

  constructor() {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};
}

export interface FdLabelConfig {
  label: string;
  controlName: string;
  text?: string;
}
