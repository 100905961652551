import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompareTypeEnum } from 'src/app/order/enums/compare-type.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { hourOptions } from 'src/app/order/utils/hour-options';
import { weekDayOptions } from 'src/app/order/utils/week-day-options';
import { CompareValidator } from 'src/app/order/validators/compare-validator';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DeliveryHoursModel, ProductModel, Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TechnologyGroupEnum } from 'src/app/order/enums/technology-group.enum';
import { ModalService } from 'src/app/order/services/external/modal/modal.service';
import { TechnologyService } from 'src/app/order/services/external/technology/technology.service';
import { ErrorService } from 'src/app/order/services/internal/error/error.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Modal } from 'src/app/shared/models/response/modal-response';
import { TechnologyModel } from 'src/app/shared/models/response/response-technology-model';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ConciliatorService } from 'src/app/order/services/external/conciliator/conciliator.service';
import { ConciliatorEnum, EdiAdditionalDataModel } from '../../complementary-information/edi/models/edi-additional-data.model';

@Component({
  selector: 'app-delivery-hours',
  templateUrl: './delivery-hours.component.html',
  styleUrls: ['./delivery-hours.component.scss'],
})
export class DeliveryHoursComponent extends FormStep implements OnInit, OnDestroy {
  get isRestrictedCnae() {
    return this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.restrictedCnaeSelected;
  }

  get hasECommerce() {
    return this.proposal && this.proposal.productSelection && !!this.proposal.productSelection.hasECommerce;
  }

  get hasTef() {
    return this.proposal && this.proposal.productSelection && !!this.proposal.productSelection.hasTef;
  }

  get hasEdi() {
    return this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasEdi;
  }

  public deliveryHoursModel: DeliveryHoursModel;
  public ediAdditionalData: EdiAdditionalDataModel;
  public proposal: Proposal;
  public techsList: TechnologyModel[];
  public showdeliveryTypeField: boolean;
  public subscription = new Subscription();
  public conciliatorList: Item[];
  public hasCloverTech: boolean = false;
  public deliveryTypesList: Item[] = [
    {
      label: 'Convencional',
      value: 'CONVENTIONAL',
      selected: true,
    },
  ];

  protected STEP_NUMBER = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private errorService: ErrorService,
    private modalService: ModalService,
    protected conciliatorService: ConciliatorService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.listenDeliveryType();
    this.appendValidators();
    this.checkDeliveryOpt();
    this.manageHourInputs();
    this.checkEdiStepJump();
  }

  public checkHasPos(): boolean {
    const products = this.proposal.productSelection.products;

    for (const p of products) {
      if (p.technologyGroup === TechnologyGroupEnum.POS) {
        return true;
      }
    }
    return false;
  }

  public checkDeliveryOpt() {
    this.showdeliveryTypeField = false;

    if (this.formControls.deliveryType.value === null || this.formControls.deliveryType.value === '') {
      this.formControls.deliveryType.setValue('CONVENTIONAL');
    }

    if (!this.checkHasPos()) {
      this.formControls.deliveryType.setValue('CONVENTIONAL');
      return;
    }

    this.configurationService.getConfiguration().subscribe(
      (response) => {
        if (response === null) {
          this.dialogService.openDialog(Messages.CONFIG_PROPOSAL_LOAD_FAIL);
          return;
        }
        let cfg = new ConfigurationModel();
        cfg = response;
        this.showdeliveryTypeField = cfg.deliveryType;

        if (cfg.deliveryType) {
          this.deliveryTypesList.push({ label: 'Pronta Entrega', value: 'IMMEDIATE_DELIVERY' });
        }

        if (cfg.machineAgency) {
          this.findModal();
        }
      },
      (error: HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.CONFIG_PROPOSAL_LOAD_FAIL)
    );
  }

  public async persistData(): Promise<boolean> {
    const rawValue = this.formGroup.getRawValue();

    if (this.hasCloverTech) {
      let ediAdditionalData = this.ediAdditionalData;

      await this.dataStore.updateProposal({ ediAdditionalData });
    }

    let deliveryHours = new DeliveryHoursModel();
    await this.dataStore.updateProposal({ deliveryHours });

    deliveryHours = {
      days: this.formControls.days.value as Item[],
      fromHour: this.formControls.fromHour.value,
      untilHour: this.formControls.untilHour.value,
      deliveryType: this.formControls.deliveryType.value,
      modal: this.formControls.agency.value,
    };
    return await this.dataStore.updateProposal({ deliveryHours });
  }

  public updateFields(proposal: Proposal) {
    this.deliveryHoursModel = proposal.deliveryHours;

    if (this.deliveryHoursModel) {
      this.formControls.days.setValue(this.deliveryHoursModel.days);
      this.formControls.fromHour.setValue(this.deliveryHoursModel.fromHour);
      this.formControls.untilHour.setValue(this.deliveryHoursModel.untilHour);
      this.formControls.deliveryType.setValue(this.deliveryHoursModel.deliveryType);
      (this.fields.fromHour as FdSelectConfig).items.map((item) => (item.selected = item.value === this.deliveryHoursModel.fromHour));
      (this.fields.untilHour as FdSelectConfig).items.map((item) => (item.selected = item.value === this.deliveryHoursModel.untilHour));
      this.manageHourInputs();

      if (this.deliveryHoursModel.deliveryType === 'IN_AGENCY') {
        this.findModal();
        this.formControls.agency.setValue(this.deliveryHoursModel.modal);
      }
    }
  }

  public daysSelected() {
    return this.formGroup && this.formControls.days.value && this.formControls.days.value.length > 0;
  }

  public createFields(): FdFieldConfigs {
    const hourFieldDefinitions = {
      items: hourOptions,
      searchable: true,
      searchPlaceholder: 'Digite o horário',
      messages: {
        required: 'Informe um horário',
        lessThan: 'O horário de abertura não pode ser maior ou igual ao de fechamento',
        greaterThan: 'O horário de fechamento não pode ser menor ou igual ao de abertura',
      },
    };

    return {
      days: {
        label: 'Dias',
        controlName: 'days',
        items: weekDayOptions,
        modalTitle: 'Selecione um ou mais dias para a entrega:',
        placeholder: 'Selecione os dias',
        messages: {
          required: 'Informe um dia para entrega',
        },
      },
      fromHour: {
        label: 'Das',
        controlName: 'fromHour',
        ...hourFieldDefinitions,
      },
      untilHour: {
        label: 'Às',
        controlName: 'untilHour',
        ...hourFieldDefinitions,
      },
      deliveryType: {
        label: 'Tipo Entrega',
        controlName: 'deliveryType',
        items: this.deliveryTypesList,
      },
      agency: {
        label: 'Agência',
        controlName: 'agency',
        placeholder: 'Selecione a agência',
        items: [],
        messages: {
          required: 'Informe a agência',
        },
      },
    };
  }

  public loadHours(filterValue: string, controlName: string) {
    const filteredItems = hourOptions.filter((item) => !filterValue || item.value.indexOf(filterValue) > -1);

    (this.fields[controlName] as FdSelectConfig).items = [];
    (this.fields[controlName] as FdSelectConfig).items = filteredItems;
  }

  public appendValidators() {
    this.formControls.fromHour.setValidators([
      RequiredIfValidator(() => this.formControls.days.value && this.formControls.days.value.length > 0),
      CompareValidator(this.formControls.untilHour, CompareTypeEnum.LESS_THAN),
    ]);
    this.formControls.untilHour.setValidators([
      RequiredIfValidator(() => this.formControls.days.value && this.formControls.days.value.length > 0),
      CompareValidator(this.formControls.fromHour, CompareTypeEnum.GREATER_THAN),
    ]);

    this.formControls.agency.setValidators([RequiredIfValidator(() => this.formControls.deliveryType.value === 'IN_AGENCY')]);
  }

  public modalClosed(event) {
    this.manageHourInputs();
  }

  public manageHourInputs() {
    if (this.daysSelected()) {
      this.formControls.fromHour.enable();
      this.formControls.untilHour.enable();
    } else {
      this.formControls.fromHour.disable();
      this.formControls.untilHour.disable();
      this.formControls.fromHour.setValue('');
      this.formControls.untilHour.setValue('');
    }
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      days: [],
      fromHour: [''],
      untilHour: [''],
      deliveryType: [''],
      agency: [],
    });
  }

  public updateValueAndValidity() {
    this.formControls.days.updateValueAndValidity();
    this.formControls.fromHour.updateValueAndValidity();
    this.formControls.untilHour.updateValueAndValidity();
    this.formControls.agency.updateValueAndValidity();
  }

  public next() {
    this.updateValueAndValidity();
    super.next();
  }

  public async checkEdiStepJump() {
    const products = this.proposal.productSelection.products;

    this.hasCloverTech = products.some((tech) => tech.technology === '353' || tech.technology === '354');
    if (this.hasCloverTech) {
      this.setStepJump(
        orderRoutingDefinitions.ediComplementaryInformation.stepNumber,
        orderRoutingDefinitions.ediComplementaryInformation.routes.EDI_ADDITIONAL_DATA.order,
        this.hasCloverTech
      );

      this.conciliatorService.getEmailsConciliatorById(ConciliatorEnum.CONCILIATOR_SE).subscribe(
        (item) => {
          this.ediAdditionalData = item;
          this.ediAdditionalData.idConciliatorCompany = 1;
        },
        () => this.dialogService.openDialog(Messages.GENERAL_ERROR)
      );
    }
  }

  public findModal() {
    if ((this.fields.agency as FdSelectConfig).items.length > 1) {
      this.enableAgency();
      return;
    }
    this.modalService
      .getModal(this.proposal.deliveryAddress.zipCode)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.setModal(response);
          this.enableAgency();
        },
        (error: HttpErrorResponse) => this.errorService.handleXHRError(error, this.handleModal(error))
      );
  }

  public setModal(modal: Modal) {
    const modais: Item[] = [];
    modal.serviceProviders.forEach((item) => modais.push({ label: item.name, value: item.modals[0].code }));
    (this.fields.agency as FdSelectConfig).items = modais;
  }

  public handleModal(error: HttpErrorResponse): Messages {
    if (error.status === 428) {
      return Messages.MODAL_NOT_CONFIGURED_ACCREDITATION_TYPE;
    } else {
      return Messages.MODAL_NOT_INTEGRATION;
    }
  }

  public listenDeliveryType() {
    const subscribe = this.formControls.deliveryType.valueChanges.subscribe((value) => {
      this.cleanForm();
    });

    this.subscription.add(subscribe);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public cleanForm() {
    this.formControls.days.setValue([]);
    this.formControls.fromHour.setValue('');
    this.formControls.untilHour.setValue('');
    this.formControls.agency.setValue('');
  }
  protected proposalGetCallback(): void {}

  enableAgency() {
    if (
      (this.fields.agency as FdSelectConfig).items.length > 0 &&
      !this.deliveryTypesList.map((item) => item.value).includes('IN_AGENCY')
    ) {
      this.deliveryTypesList.push({ label: 'Na agência', value: 'IN_AGENCY' });
      this.showdeliveryTypeField = true;
    }
  }
}
