import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { cloneDeep } from 'lodash';
import { take, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { FactorIndexEnum } from 'src/app/order/enums/factor-index.enum';
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { MdrFlexModalityEnum } from 'src/app/order/enums/mdr-flex-modality.enum';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { ClientService } from 'src/app/order/services/external/client-validation/client-validation.service';
import { FeeService } from 'src/app/order/services/external/fee/fee.service';
import { ProposalDraftService } from 'src/app/order/services/external/proposal-draft/proposal-draft.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { SimulationService } from 'src/app/order/services/external/simulation/simulation.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';
import { FdFeeEditorConfig } from 'src/app/shared/fd-form-components/fd-fee-editor/fd-fee-editor.component';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { FeeCalculationModel } from 'src/app/shared/models/fee-calculation.model';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalDraftQueryModel } from 'src/app/shared/models/proposal-draft-query.model';
import { Rate, RatesClientValidation } from 'src/app/shared/models/rates-client-validation.model';
import { FactorFeeModel } from 'src/app/shared/models/response/response-factor-fee.model';
import { MdrFlexFeeModel } from 'src/app/shared/models/response/response-mdr-flex-fee.model';
import { SimulationFeesModel, SimulationModel, SimulationServicesModel } from 'src/app/shared/models/simulation.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { FeeEditorDialogData, FeeEditorModalComponent } from '../../modals/fee-editor-modal/fee-editor-modal.component';

@Component({
  selector: 'app-mdr-flex-fee-edit',
  templateUrl: './mdr-flex-fee-edit.component.html',
  styleUrls: ['./mdr-flex-fee-edit.component.scss'],
})
export class MdrFlexFeeEditComponent extends FormStep implements OnInit {
  get isCPF() {
    return (
      this.proposal &&
      this.proposal.registrationDataPersonalInfo &&
      this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF
    );
  }

  get isCNPJ() {
    return (
      this.proposal &&
      this.proposal.registrationDataPersonalInfo &&
      this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CNPJ
    );
  }

  get isCnaeLoterica(): boolean {
    return this.proposal.offerDataDetails.cnae.toString() === '8299706';
  }

  private get factorFeeFormsArray(): FormArray {
    return this.formControls.factorFeeForms as FormArray;
  }

  private get mdrFlexFeeFormsArray(): FormArray {
    return this.formControls.mdrFlexFeeForms as FormArray;
  }

  private set mdrFlexFeeFormsArray(value: FormArray) {
    this.formControls.mdrFlexFeeForms = value;
  }

  public get factorFeeFormsControls() {
    return this.factorFeeFormsArray.controls;
  }

  public get mdrFlexFeeFormsControls() {
    return this.mdrFlexFeeFormsArray.controls as FormGroup[];
  }

  @ViewChild('factorRow') public factorRow: ElementRef;
  @ViewChild('mdrFlexRow') public mdrFlexRow: ElementRef;

  public factor1: number;
  public factor2: number;
  public proposal: Proposal;
  public editedModel: MdrFlexFeeModel[] = [];
  public proposalMdrFlexModel: MdrFlexFeeModel[] = [];
  public proposalFactorModel: FactorFeeModel[] = [];
  public dialogClosed: boolean = true;
  public readonly UNDER_MINIMUM_LIMIT_TEXT =
    'Devido ao fato da taxa informada ser menor que a taxa mínima, a proposta poderá não ser submetida ou aprovada';

  public displayedFactorColumns = ['factorFlag', 'factorTransaction', 'factorFee'];

  public displayedMdrFlexColumns = ['mdrFlexFlag', 'mdrFlexTransaction', 'mdrFlexFee', 'calculatedFee'];

  public displayedMdrFlexColumnsLottery = ['mdrFlexFlag', 'mdrFlexTransaction', 'mdrFlexFee', 'calculatedFee', 'tariffValue'];

  public feeCalculation: FeeCalculationModel[] = [];

  public STEP_NUMBER = 0;
  public isCEF = this.dataStoreService.getCEFFlag();

  public factorFees: FactorFeeModel[];
  public mdrFlexFees: MdrFlexFeeModel[];

  public mdrFlexDataSource: MatTableDataSource<MdrFlexFeeModel> = new MatTableDataSource();
  public factorDataSource: MatTableDataSource<FactorFeeModel> = new MatTableDataSource();

  @ViewChild('tooltip') public tooltip: MatTooltip;

  constructor(
    private formBuilder: FormBuilder,
    private feeService: FeeService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private clientService: ClientService,
    private simulationService: SimulationService,
    private proposalDraftService: ProposalDraftService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );

    const timer = setInterval(() => {
      if (this.factorRow && this.factorRow.nativeElement !== null && this.mdrFlexRow && this.mdrFlexRow.nativeElement !== null) {
        clearInterval(timer);
      }
    }, 50);
  }

  public getLabel() {
    return this.isCEF ? 'Taxas MDR com Antecipação' : 'Taxas MDR Flex';
  }

  public saveSimulation() {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
    this.persistData().then((persisted) => {
      if (persisted) {
        this.dataStore
          .getProposal()
          .pipe(take(1))
          .pipe(takeWhile(() => this.alive))
          .toPromise()
          .then(
            (proposal) => {
              this.proposal = proposal;
              this.submitSimulation();
            },
            (error) => {}
          );
      }
    });
  }

  public buildFeeObject(): SimulationFeesModel[] {
    return this.proposal.mdrFlexFeeModel.map(
      (item) =>
        ({
          idService: item.serviceId && item.serviceId.toString(),
          minimumMdrFlexFee: item.minimumMdrFlexFee,
          sentMdrFlexFee: item.mdrFlexFeeEdit,
          suggestedMdrFlexFee: item.suggestedMdrFlexFee,
          modality: item.modality,
          transactionDescription: item.transaction,
        } as SimulationFeesModel)
    );
  }

  public buildServiceObject(): SimulationServicesModel[] {
    return this.proposal.productSelection.products.map(
      (item) =>
        ({
          idService: item.technology,
          technologyAmount: item.amount,
          technologyPrice: Number(item.price.toString().replace(/[Rr$\s]/g, '')),
        } as SimulationServicesModel)
    );
  }

  public deleteDraft() {
    const activeDraft = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    this.proposalDraftService
      .deleteDraft(this.createRequestObject(activeDraft))

      .subscribe(
        (_) => {
          this.dialogService.openDialog(Messages.SIMULATION_SAVE_SUCCESS, () => this.routingService.navigateToHome());
        },
        (error) => {
          let errorArg = error;
          if (error.error && typeof error.error === 'object') {
            errorArg = error.error;

            if (errorArg.field) {
              errorArg = errorArg.field;
            } else if (error.error instanceof ProgressEvent) {
              errorArg = error.message;
            }
          }
          this.dialogService.openErrorDialog(Messages.SIMULATION_SAVE_ERROR, errorArg, () => this.routingService.navigateToHome());
        }
      );
  }

  public createRequestObject(externalDraftUuid): ProposalDraftQueryModel {
    return {
      externalDraftUuid,
    };
  }

  public submitSimulation() {
    const userData = this.dataStoreService.getUserData();

    if (!userData) {
      return;
    }

    const model: SimulationModel = {
      agentCpfCnpj: userData.cpfCnpj,
      billingValue: this.proposal.offerDataDetails.annualRevenue,
      cnaeNumber: Number(this.proposal.offerDataDetails.cnae),
      cpfCnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      email: this.isCPF ? this.proposal.registrationDataPersonalInfo.personEmail : this.proposal.registrationDataPersonalInfo.companyEmail,
      fees: this.buildFeeObject(),
      services: this.buildServiceObject(),
    };

    this.proposal.factorFeeModel.map((item) => {
      (model.factorType = item.factor),
        (model.minimumFactorFee = item.minimumFactorFee),
        (model.suggestedFactorFee = item.suggestedFactorFee);
    });

    this.simulationService.saveSimulation(model).subscribe(
      (_) => {
        this.deleteDraft();
      },
      (error) => {
        let errorArg = error;
        if (error.error && typeof error.error === 'object') {
          errorArg = error.error;

          if (errorArg.field) {
            errorArg = errorArg.field;
          } else if (error.error instanceof ProgressEvent) {
            errorArg = error.message;
          }
        }
        this.dialogService.openErrorDialog(Messages.SIMULATION_SAVE_ERROR, errorArg, () => this.routingService.navigateToHome());
      }
    );
  }
  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
  }

  public shouldShowAlert(i: number) {
    const formGroup = this.mdrFlexFeeFormsControls[i];
    return (
      this.dialogClosed &&
      formGroup &&
      formGroup.controls &&
      formGroup.controls.suggestedMdrFlexFee &&
      formGroup.controls.suggestedMdrFlexFee.value !== null &&
      formGroup.controls.suggestedMdrFlexFee.value !== undefined &&
      formGroup.controls.suggestedMdrFlexFee.value !== '' &&
      formGroup.controls.minimumMdrFlexFee &&
      formGroup.controls.suggestedMdrFlexFee.value < formGroup.controls.minimumMdrFlexFee.value
    );
  }

  public onMdrFlexModalConfirm(data: FeeEditorDialogData) {
    const relatedFormGroup = this.mdrFlexFeeFormsArray.controls[data.rowIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.suggestedMdrFlexFee) {
        const item = this.mdrFlexFees.find((val, idx, arr) => idx === data.rowIndex);
        data.formControl.setValue(data.formGroup.controls.mdrFlexFeeEdit.value);

        const feeToCalculate: FeeCalculationModel = {
          codeFlag: item.codeFlag,
          factor1: this.factor1,
          factor2: this.factor2,
          fee: data.formControl.value,
          modality: item.modality,
        };

        if (!this.editedModel || !this.editedModel.length) {
          let model = [];
          if (!this.proposalMdrFlexModel || !this.proposalMdrFlexModel.length) {
            model = this.mdrFlexFees;
          } else {
            model = this.proposalMdrFlexModel;
          }
          this.editedModel = cloneDeep(model);
        }

        const editedItem = this.editedModel.find(
          (edited) => feeToCalculate.codeFlag === edited.codeFlag && edited.modality === feeToCalculate.modality
        );

        if (editedItem) {
          editedItem.suggestedMdrFlexFee = feeToCalculate.fee;
        }

        this.calculateFees([feeToCalculate], this.editedModel);
      }
    }
  }

  public onFactorModalConfirm(data: FeeEditorDialogData) {
    const relatedFormGroup = this.factorFeeFormsArray.controls[data.rowIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.suggestedFactorFee !== null && rawValue.suggestedFactorFee !== undefined) {
        const item = this.factorDataSource.data.find((val, idx, arr) => idx === data.rowIndex);
        data.formControl.setValue(data.formGroup.controls.factorFeeEdit.value);

        if (item.factor === FactorIndexEnum.FACTOR_ONE) {
          this.factor1 = data.formControl.value;
        } else {
          this.factor2 = data.formControl.value;
        }

        const feesToCalculate = this.mdrFlexFees.map((x: MdrFlexFeeModel): FeeCalculationModel => {
          return {
            codeFlag: x.codeFlag,
            factor1: this.factor1,
            factor2: this.factor2,
            fee: x.suggestedMdrFlexFee,
            modality: x.modality,
          };
        });

        (this.fieldsArray[data.rowIndex].suggestedFactorFee as FdFeeEditorConfig).isEdited = this.isFactorEditedWithValue(
          item,
          data.formControl.value
        );

        this.calculateFees(feesToCalculate, this.mdrFlexDataSource.data);
      }
    }
  }

  public updateFields(proposal: Proposal) {
    this.proposalMdrFlexModel = proposal.mdrFlexFeeModel;
    this.proposalFactorModel = proposal.factorFeeModel;

    if (this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.cnaeChanged) {
      this.proposalMdrFlexModel = [];
      this.proposalFactorModel = [];
    }

    if (this.proposalMdrFlexModel && this.proposalMdrFlexModel.length && this.proposalFactorModel && this.proposalFactorModel.length) {
      this.feeService
        .getFactorFees(
          this.proposal.offerDataDetails.cnae,
          this.proposal.offerDataDetails.revenueRange,
          this.proposal.offerDataDetails.campaign,
          this.proposal.feeTypeModel.receivingDeadline
        )
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            this.factorFees = response;
            this.processFactorFees(this.proposalFactorModel);

            this.feeService
              .getMdrFlexFees(
                this.proposal.offerDataDetails.cnae,
                this.proposal.registrationDataAddress.state,
                this.proposal.offerDataDetails.campaign,
                this.proposal.offerDataDetails.revenueRange,
                this.proposal.feeTypeModel.receivingDeadline,
                this.proposal.registrationDataPersonalInfo.documentCategory === 'CPF' ? 'P' : 'L'
              )
              .pipe(takeWhile(() => this.alive))
              .subscribe(
                (response) => {
                  if (response && response.length) {
                    this.mdrFlexFees = response;

                    const model = this.proposalMdrFlexModel.map((item: MdrFlexFeeModel): FeeCalculationModel => {
                      return {
                        codeFlag: item.codeFlag,
                        factor1: this.factor1,
                        factor2: this.factor2,
                        fee: item.suggestedMdrFlexFee,
                        modality: item.modality,
                      };
                    });

                    this.calculateFees(model, this.proposalMdrFlexModel);
                  }
                },
                (err: HttpErrorResponse) => {
                  const serializedError = err.error ? (err.error as ApiResultModel) : null;

                  if (serializedError && serializedError.message) {
                    if (this.isCEF) {
                      this.dialogService.openDialogWithMessage(serializedError.message, () => this.routingService.navigateToHome());
                      return;
                    }
                    this.dialogService.openDialogWithMessage(serializedError.message, () => this.back(false));
                  } else {
                    if (this.isCEF) {
                      this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.routingService.navigateToHome());
                      return;
                    }
                    this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.back(false));
                  }
                  return;
                }
              );
          },
          (err: HttpErrorResponse) => {
            const serializedError = err.error ? (err.error as ApiResultModel) : null;

            if (serializedError && serializedError.message) {
              this.dialogService.openDialogWithMessage(serializedError.message, () => this.back());
            } else {
              this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FACTORS, () => this.back());
            }
            return;
          }
        );
    } else {
      this.feeService
        .getFactorFees(
          this.proposal.offerDataDetails.cnae,
          this.proposal.offerDataDetails.revenueRange,
          this.proposal.offerDataDetails.campaign,
          this.proposal.feeTypeModel.receivingDeadline
        )
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            if (response && response.length) {
              this.factorFees = response;
              this.processFactorFees(response);
              this.getMdrFlexFees();
            } else {
              if (this.isCEF) {
                this.dialogService.openDialog(Messages.NO_FACTORS_AVAILABLE, () => this.routingService.navigateToHome());
                return;
              }
              this.dialogService.openDialog(Messages.NO_FACTORS_AVAILABLE, () => this.back());
            }
          },
          (err: HttpErrorResponse) => {
            const serializedError = err.error ? (err.error as ApiResultModel) : null;

            if (serializedError && serializedError.message) {
              this.dialogService.openDialogWithMessage(serializedError.message, () => this.back());
            } else {
              this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FACTORS, () => this.back());
            }
            return;
          }
        );
    }
  }

  public persistData() {
    const rawValue = this.formGroup.getRawValue();

    const mdrFlexFeeModel: MdrFlexFeeModel[] = rawValue.mdrFlexFeeForms;

    const factorFeeModel: FactorFeeModel[] = rawValue.factorFeeForms;

    return this.dataStore.updateProposal({ mdrFlexFeeModel, factorFeeModel });
  }

  public openFactorFeeEditorModal(dialogData: FeeEditorDialogData, factorIndex: number) {
    const relatedFormGroup = this.factorFeeFormsArray.controls[factorIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.suggestedFactorFee !== null && rawValue.suggestedFactorFee !== undefined) {
        const item = this.factorFees.find((val, idx, arr) => idx === factorIndex);

        if (item) {
          dialogData.title = dialogData.field.modalTitle;
          dialogData.itemDescription = `${item.flag} - ${item.transaction}`;
          dialogData.initialValue = dialogData.formControl.value || item.suggestedFactorFee;
          dialogData.maxValue = item.suggestedFactorFee;
          dialogData.minValue = item.minimumFactorFee;
          dialogData.rowIndex = factorIndex;
          dialogData.confirmCallbackFn = this.onFactorModalConfirm.bind(this);

          const dialogRef = this.dialog.open(FeeEditorModalComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData,
          });

          return;
        }
      }
    }
  }

  public isFactorEdited(itemParam: Partial<FactorFeeModel>) {
    if (itemParam) {
      const arrItem = this.factorFees.find(
        (val, idx, arr) => val.flag === itemParam.flag && val.factor === itemParam.factor && val.transaction === itemParam.transaction
      );

      if (!itemParam || !itemParam.suggestedFactorFee || !arrItem || !arrItem.suggestedFactorFee) {
        return false;
      }

      return itemParam.suggestedFactorFee !== arrItem.suggestedFactorFee;
    }
  }

  public isFactorEditedWithValue(itemParam: Partial<FactorFeeModel>, value: number) {
    if (itemParam) {
      const arrItem = this.factorFees.find(
        (val, idx, arr) => val.factor === itemParam.factor && val.flag === itemParam.flag && val.transaction === itemParam.transaction
      );

      return value !== arrItem.suggestedFactorFee;
    }
  }

  public isMdrFlexEdited(itemParam: Partial<MdrFlexFeeModel>) {
    if (itemParam) {
      const arrItem = this.mdrFlexFees.find((val, idx, arr) => val.codeFlag === itemParam.codeFlag && val.modality === itemParam.modality);

      if (!itemParam || !itemParam.suggestedMdrFlexFee || !arrItem || !arrItem.suggestedMdrFlexFee) {
        return false;
      }

      return itemParam.suggestedMdrFlexFee !== arrItem.suggestedMdrFlexFee;
    }
  }

  public openMdrFlexFeeEditorModal(dialogData: FeeEditorDialogData, mdrFlexIndex: number) {
    const relatedFormGroup = this.mdrFlexFeeFormsArray.controls[mdrFlexIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.suggestedMdrFlexFee !== null && rawValue.suggestedMdrFlexFee !== undefined) {
        const item = this.mdrFlexFees.find((val, idx, arr) => idx === mdrFlexIndex);

        if (item) {
          dialogData.title = dialogData.field.modalTitle;
          dialogData.itemDescription = `${item.flag} - ${item.transaction}`;
          dialogData.maxValue = item.suggestedMdrFlexFee;
          dialogData.minValue = item.minimumMdrFlexFee;
          dialogData.initialValue = this.getEditedOrSuggestedFee(dialogData, item);
          dialogData.rowIndex = mdrFlexIndex;
          dialogData.confirmCallbackFn = this.onMdrFlexModalConfirm.bind(this);

          const dialogRef = this.dialog.open(FeeEditorModalComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData,
          });

          dialogRef.afterClosed().subscribe((_) => {
            this.dialogClosed = true;
          });

          dialogRef.afterOpened().subscribe((_) => {
            this.dialogClosed = false;
          });

          return;
        }
      }
    }
  }

  public getEditedOrSuggestedFee(dialogData: FeeEditorDialogData, item: MdrFlexFeeModel) {
    if (dialogData && dialogData.formControl) {
      return dialogData.formControl.value;
    } else if (item) {
      return item.suggestedMdrFlexFee;
    } else {
      return null;
    }
  }

  public createFields() {
    return {
      suggestedFactorFee: {
        controlName: 'suggestedFactorFee',
        maskCharsReplace: /[% ]/,
        modalTitle: 'Indique a taxa do produto',
        childControl: {
          controlName: 'factorFeeEdit',
          label: 'Taxa solicitada',
          isPercent: true,
          maxLength: 4,
          messages: {
            required: 'Informe o valor',
            invalid: 'Valor inválido',
            valueTooLow: 'O valor deve ser maior que o mínimo especificado',
            valueTooHigh: 'O valor deve ser menor que o máximo especificado',
          },
        },
      },
      suggestedMdrFlexFee: {
        controlName: 'suggestedMdrFlexFee',
        maskCharsReplace: /[% ]/,
        modalTitle: 'Indique a taxa do produto',
        childControl: {
          controlName: 'mdrFlexFeeEdit',
          label: 'Taxa solicitada',
          isPercent: true,
          maxLength: 4,
          messages: {
            required: 'Informe o valor',
            invalid: 'Valor inválido',
            valueTooLow: 'O valor deve ser maior que o mínimo especificado',
            valueTooHigh: 'O valor deve ser menor que o máximo especificado',
          },
        },
      },
      calculatedMdrFlexFee: {
        controlName: 'calculatedMdrFlexFee',
        maskCharsReplace: /[% ]/,
        modalTitle: 'Indique a taxa do produto',
        childControl: {
          controlName: 'mdrFlexFeeEdit',
          label: 'Taxa solicitada',
          isPercent: true,
          maxLength: 4,
          messages: {
            required: 'Informe o valor',
            invalid: 'Valor inválido',
            valueTooLow: 'O valor deve ser maior que o mínimo especificado',
            valueTooHigh: 'O valor deve ser menor que o máximo especificado',
          },
        },
      },
    };
  }

  public addFactorFeeForm(factors: Partial<FactorFeeModel>[] = [{}]) {
    factors.forEach((factor, idx, arr) => {
      this.fieldsArray.push(this.createFields());
      this.factorFeeFormsArray.push(this.createFactorForm(factor));
    });
  }

  public addMdrFlexFeeForm(fees: Partial<MdrFlexFeeModel>[] = [{}]) {
    this.fieldsArray = [];
    this.mdrFlexFeeFormsArray = this.formBuilder.array([]);

    fees.forEach((item, idx, arr) => {
      this.fieldsArray.push(this.createFields());
      this.mdrFlexFeeFormsArray.push(this.createMdrFlexForm(item));
      (this.fieldsArray[idx].suggestedMdrFlexFee as FdFeeEditorConfig).isEdited = this.isMdrFlexEdited(item);
    });
  }

  public clearMdrFlexFormArray() {
    this.formControls.mdrFlexFeeForms = this.formBuilder.array([]);
  }

  public calculateFees(feesToCalculate: FeeCalculationModel[], mdrFlexFeeModel: MdrFlexFeeModel[]) {
    this.feeService
      .calculateFees(
        feesToCalculate,
        this.proposal.offerDataDetails.cnae,
        this.proposal.registrationDataAddress.state,
        this.proposal.offerDataDetails.revenueRange,
        this.proposal.offerDataDetails.campaign,
        this.proposal.feeTypeModel.receivingDeadline,
        this.proposal.registrationDataPersonalInfo.documentCategory === 'CPF' ? 'P' : 'L'
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (calculated) => {
          calculated.forEach((calc) => {
            const item = mdrFlexFeeModel.find((model) => calc.codeFlag === model.codeFlag && calc.modality === model.modality);

            if (item) {
              item.calculatedMdrFlexFee = calc.fee;
            }
          });

          this.mdrFlexDataSource.data = mdrFlexFeeModel;
          this.addMdrFlexFeeForm(mdrFlexFeeModel);
        },
        (err: HttpErrorResponse) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;

          if (serializedError && serializedError.message) {
            if (this.isCEF) {
              this.dialogService.openDialogWithMessage(serializedError.message, () => this.routingService.navigateToHome());
              return;
            }
            this.dialogService.openDialogWithMessage(serializedError.message, () => this.back());
          } else {
            if (this.isCEF) {
              this.dialogService.openDialog(Messages.FAILED_TO_CALCULATE_FEES, () => this.routingService.navigateToHome());
              return;
            }
            this.dialogService.openDialog(Messages.FAILED_TO_CALCULATE_FEES, () => this.back());
          }
          return;
        }
      );
  }

  public getMdrFlexFees() {
    this.feeService
      .getMdrFlexFees(
        this.proposal.offerDataDetails.cnae,
        this.proposal.registrationDataAddress.state,
        this.proposal.offerDataDetails.campaign,
        this.proposal.offerDataDetails.revenueRange,
        this.proposal.feeTypeModel.receivingDeadline,
        this.proposal.registrationDataPersonalInfo.documentCategory === 'CPF' ? 'P' : 'L'
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response && response.length) {
            this.processMdrFlexFees(response);
          } else {
            if (this.isCEF) {
              this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.routingService.navigateToHome());
              return;
            }
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.back());
          }
        },
        (err: HttpErrorResponse) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;

          if (serializedError && serializedError.message) {
            if (this.isCEF) {
              this.dialogService.openDialogWithMessage(serializedError.message, () => this.back());
              return;
            }
            this.dialogService.openDialogWithMessage(serializedError.message, () => this.back());
          } else {
            if (this.isCEF) {
              this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.routingService.navigateToHome());
              return;
            }
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES, () => this.back());
          }
          return;
        }
      );
  }

  public processMdrFlexFees(response: MdrFlexFeeModel[]) {
    const feesToCalculate: FeeCalculationModel[] = [];
    response.forEach((item) => {
      if (item.feeType == 'CURRENCY' && this.isCnaeLoterica) {
        item.tariffValue = item.suggestedMdrFlexFee;
      }
      feesToCalculate.push({
        codeFlag: item.codeFlag,
        factor1: this.factor1,
        factor2: this.factor2,
        fee: item.suggestedMdrFlexFee,
        modality: item.modality,
      });
    });

    this.mdrFlexFees = response;
    this.calculateFees(feesToCalculate, response);
  }

  public getValueWithDecimalPlaces(value: any) {
    return getValueWithDecimalPlaces(value);
  }

  public processFactorFees(response: FactorFeeModel[]) {
    response.forEach((factorFee) => {
      if (factorFee.factor === FactorIndexEnum.FACTOR_ONE) {
        this.factor1 = factorFee.suggestedFactorFee;
      } else if (factorFee.factor === FactorIndexEnum.FACTOR_TWO) {
        this.factor2 = factorFee.suggestedFactorFee;
      }
    });
    this.factorDataSource.data = response;
    this.addFactorFeeForm(response);
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public canEdit(modality: MdrFlexModalityEnum): boolean {
    return modality !== MdrFlexModalityEnum.ADDITIONAL_PER_INSTALLMENT;
  }

  public next(): void {
    this.submitted = true;
    if (this.dataStoreService.isBinServiceContractProposal(this.proposal.serviceContract)) {
      const data: RatesClientValidation = this.buildValidationRatesData();

      this.clientService
        .validateRates(data)

        .subscribe(
          (response) => {
            if (!response) {
              this.dialogService.openDialog(Messages.RATES_INVALID_SIPAG);
            } else {
              super.next();
            }
          },
          (err) => {
            let message = '';
            let obj: any = err.error.details;
            if (err.error && err.error.details) {
              if (obj instanceof Object) {
                const details = err.error.details;
                if (details && details.mensagem) {
                  message += details.mensagem;
                  obj = '';
                } else {
                  message += JSON.stringify(err.error.details);
                }
              } else {
                message += obj;
              }
            } else if (err.error && err.error.message) {
              message += err.error.message;
            }

            if (message.length > 0) {
              if (obj instanceof Object) {
                if (this.isCEF) {
                  this.dialogService.openErrorDialog(Messages.GENERIC_ERROR, JSON.stringify(err.error.details), () =>
                    this.routingService.navigateToHome()
                  );
                  return;
                }
                this.dialogService.openErrorDialog(Messages.GENERIC_ERROR, JSON.stringify(err.error.details));
              } else {
                if (this.isCEF) {
                  this.dialogService.openDialogWithMessage(message, () => this.routingService.navigateToHome());
                  return;
                }
                this.dialogService.openDialogWithMessage(message);
              }
            } else if (this.isCEF) {
              this.dialogService.openDialog(Messages.GENERAL_ERROR, () => this.routingService.navigateToHome());
              return;
            }
            this.dialogService.openDialog(Messages.GENERAL_ERROR);
          }
        );
    } else {
      super.next();
    }
  }

  public buildValidationRatesData(): RatesClientValidation {
    const ratesList: Rate[] = new Array();
    const rawValue = this.formGroup.getRawValue();
    const mdrFlexFeeModel: MdrFlexFeeModel[] = rawValue.mdrFlexFeeForms;

    mdrFlexFeeModel.forEach((mdrFlex) => {
      const rate: Rate = {
        acquiringServiceId: mdrFlex.serviceId ? mdrFlex.serviceId.toString() : undefined,
        codeFlag: mdrFlex.codeFlag,
        modality: mdrFlex.modality,
        rate: mdrFlex.calculatedMdrFlexFee ? mdrFlex.calculatedMdrFlexFee.toString() : mdrFlex.suggestedMdrFlexFee.toString(),
      };
      ratesList.push(rate);
    });

    const data: RatesClientValidation = {
      cnae: this.proposal.offerDataDetails.cnae,
      document: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      rateCategory: FeeTypeEnum.FLEX,
      subChannel: this.proposal.subChannel,
      ratesList,
    };
    return data;
  }
  protected proposalGetCallback(): void {}

  protected createFormGroup() {
    return this.formBuilder.group({
      factorFeeForms: this.formBuilder.array([]),
      mdrFlexFeeForms: this.formBuilder.array([]),
    });
  }

  private createFactorForm(fee: Partial<FactorFeeModel>): FormGroup {
    return this.formBuilder.group({
      suggestedFactorFee: [fee.suggestedFactorFee],
      factorFeeEdit: [''],
      minimumFactorFee: [fee.minimumFactorFee],
      factor: [fee.factor],
      transaction: [fee.transaction],
      flag: [fee.flag],
    });
  }

  private createMdrFlexForm(fee: Partial<MdrFlexFeeModel>): FormGroup {
    return this.formBuilder.group({
      suggestedMdrFlexFee: [fee.suggestedMdrFlexFee],
      calculatedMdrFlexFee: [fee.calculatedMdrFlexFee],
      mdrFlexFeeEdit: [fee.suggestedMdrFlexFee],
      codeFlag: [fee.codeFlag],
      flag: [fee.flag],
      minimumMdrFlexFee: [fee.minimumMdrFlexFee],
      transaction: [fee.transaction],
      modality: [fee.modality],
      serviceId: [fee.serviceId],
      feeType: [fee.feeType],
    });
  }
}
