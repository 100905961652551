export class ECommerceFunctionalitiesModel {
  public eCommerceTechnologyTypes: ECommerceTechnologyTypeEnum[];
  public eCommerceFunctionalities: ECommerceFunctionalitiesEnum[];
  public cnpjMid?: string;
  public link: string;
  public ecType?: string;

  public eCommerceFlag: boolean;
  public appFlag: boolean;
  public linkPayment: boolean;
  public splitPayment: boolean;
  public recurrence: boolean;
  public virtualStore: boolean;
}

export enum ECommerceTechnologyTypeEnum {
  E_COMMERCE = 'E_COMMERCE',
  APP = 'APP',
}

export enum ECommerceFunctionalitiesEnum {
  PAYMENT_LINK = 'PAYMENT_LINK',
  PAYMENT_SPLIT = 'PAYMENT_SPLIT',
  RECURRENCE = 'RECURRENCE',
  VIRTUAL_SHOP = 'VIRTUAL_SHOP',
}
