<div>
  <div style="margin: 0 auto; width: 100%">
    <h4 class="fd-subtitle">Nota fiscal</h4>

    <h5 style="text-align: center">
      Insira notas fiscais com no máximo 90 dias de emissão e que totalizem no mínimo R$400,00 em valor de compra.
    </h5>

    <mat-table [dataSource]="invoices" #myTable>
      <!-- User name Definition -->
      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell *matHeaderCellDef> Nro NF </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.invoiceNumber }} </mat-cell>
      </ng-container>

      <!-- Title Definition -->
      <ng-container matColumnDef="productType">
        <mat-header-cell *matHeaderCellDef> Produto </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.productType }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ formatDate(row.invoiceDate) }} </mat-cell>
      </ng-container>

      <!-- Age Definition -->
      <ng-container matColumnDef="invoiceValue">
        <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.invoiceValue | number: '2.2-5' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acao">
        <mat-header-cell *matHeaderCellDef> Ação </mat-header-cell>
        <mat-cell *matCellDef="let row"> <button (click)="remove(row.invoiceNumber)">x</button> </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="['invoiceNumber', 'productType', 'invoiceDate', 'invoiceValue', 'acao']"> </mat-header-row>
      <mat-row
        style="text-align: center"
        *matRowDef="let row; columns: ['invoiceNumber', 'productType', 'invoiceDate', 'invoiceValue', 'acao']"
      >
      </mat-row>
    </mat-table>

    <br />
    <div style="text-align: right">Total: {{ invoiceValuesTotal | number: '1.2-5' }}</div>
    <div style="text-align: right">Máximo Notas: {{ invoices.length | number: '1.0' }} / 3</div>
  </div>
  <ng-container *ngIf="fields">
    <fd-input [parentForm]="formGroup" [field]="fields.invoiceNumber"> </fd-input>

    <fd-input [parentForm]="formGroup" [field]="fields.productType"> </fd-input>

    <fd-input [parentForm]="formGroup" [field]="fields.invoiceDate"></fd-input>

    <fd-currency-input [parentForm]="formGroup" [field]="fields.invoiceValue"> </fd-currency-input>
  </ng-container>

  <div *ngIf="invoices.length < 3">
    <fd-button fdSecondary [content]="'incluir'" id="btnInclude" (trigger)="include()"> </fd-button>
  </div>

  <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
  <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
</div>
