import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Messages } from 'src/app/order/messages/order.messages';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { environment } from 'src/environments/environment.local';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private loadingService: LoadingService,
    private dataStoreService: DataStoreService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.match('login') !== null || request.url.match('password-recovery') !== null) {
      request = request.clone({
        setHeaders: {
          'Api-Key': `${environment.apiKey}`,
        },
      });
      return next.handle(request);
    }

    const currentUserData = this.authService.getStorageData();
    const expirationToken = this.authService.isTokenExpirated();

    if (expirationToken) {
      this.dataStoreService.clearSessionInformation();
      this.routingService.navigateToLogin(false, false);
    }

    if (currentUserData && this.authService.isLoggedIn()) {
      request = request.clone({
        setHeaders: {
          'Api-Key': `${environment.apiKey}`,
          'x-online-accreditation': `Bearer ${currentUserData.sessionToken}`,
          'agent-id': `${this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData())}`,
        },
      });
      return next.handle(request).pipe(catchError((x) => this.handleAuthError(x)));
    } else {
      this.routingService.navigateToLogin(false, false);
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.dialogService.openOnlyOneDialog(Messages.SESSION_TIMED_OUT.description, () => this.routingService.navigateToLogin(true, false));
      //this.routingService.navigateToLogin(false, true);
      this.dataStoreService.clearSessionInformation();
      return of(err.message);
    }
    return throwError(err);
  }
}
