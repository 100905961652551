import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { MobileOperatorModel } from 'src/app/shared/models/response/response-mobile-operator.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class MobileOperatorService {
  constructor(private http: HttpClient) {}

  public getMobileOperatorList(): Observable<Item[]> {
    return this.http
      .get<BaseConfigApiResponse<MobileOperatorModel[]>>(`${environment.apiUrls.mobileOperators}?isSales=true`)
      .pipe(map((x) => (this.invalidResponse(x) ? null : this.mapToMobileOperatorModels(x.data))));
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<MobileOperatorModel[]>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || !baseApiResponse.data;
  }

  private mapToMobileOperatorModels(response: MobileOperatorModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.id,
      label: value.name,
    }));
  }
}
