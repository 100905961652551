import { Component, Input, OnInit } from '@angular/core';
import { CommercialConditionsDetails } from '../../models/child-models/commercial-conditions-details.model';
import { RateDataDetails } from '../../models/child-models/rate-data-details.model';
import { TechnologyDataDetails } from '../../models/child-models/technology-data-details.model';

@Component({
  selector: 'app-commercial-conditions-data-detail',
  templateUrl: './commercial-conditions-data-detail.component.html',
  styleUrls: ['./commercial-conditions-data-detail.component.scss'],
})
export class CommercialConditionsDataDetailComponent {
  @Input()
  public commercialConditions: CommercialConditionsDetails;

  @Input()
  public technologyData: TechnologyDataDetails[];

  constructor() {}
}
