<div>
  <div fxFlex="95">
    <fd-search-bar (input)="filterProposals($event)" [field]="fields.search"></fd-search-bar>
  </div>
  <div fxFlex="5" class="fd-proposal-history__filter-icon-wrapper" (click)="openFilterModal()">
    <fa-icon icon="filter"></fa-icon>
  </div>
</div>

<div class="fd-proposal-history__applied-filters-wrapper">
  <fd-button *ngFor="let filter of appliedFilters; let i = index" size="thin" fdTertiary autoWidth
    [content]="addFilterButton(filter)" (trigger)="removeFromFilter(i)" class="fd-proposal-history__applied-filters">
  </fd-button>
</div>

<ng-container *ngIf="filteredProposals && filteredProposals.length">
  <fd-proposal-card *ngFor="let proposal of filteredProposals" [proposalData]="proposal"></fd-proposal-card>
</ng-container>

<div *ngIf="filteredProposals && filteredProposals.length && shouldShowLoadMore" class="fd-welcome-screen__history-button-container">
  <fd-button fdSecondary fullWidth [content]="'Ver mais'" (trigger)="loadMore()">
  </fd-button>
</div>

<div *ngIf="!filteredProposals || !filteredProposals.length" class="fd-proposal-history__no-data">
  Nenhum registro encontrado. Tente buscar com filtros diferentes
</div>
