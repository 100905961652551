import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseClientSerproV2CnpjModel } from 'src/app/order/components/proposal-data/partner-data/models/response-client-serpro-v2-cnpj.model';
import { SerproPartnerValidationModel } from 'src/app/order/components/proposal-data/partner-data/models/serpro-partner-validation.model';
import { SerproV2PartnerValidationModel } from 'src/app/order/components/proposal-data/partner-data/models/serpro-v2-partner-validation.model';
import { SerproValidationResponseModel } from 'src/app/order/components/proposal-data/partner-data/models/serpro-validation-response.model';
import {
  SerproCnpjValidationRequestModel,
  SerproCnpjValidationWithCnaeRequestModel,
} from 'src/app/order/components/registration-data/personal-info/models/serpro-cnpj-validation-request.model';
import {
  SerproCpfRequestModel,
  SerproCpfRequestModelV2,
} from 'src/app/order/components/registration-data/personal-info/models/serpro-cpf-request.model';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { ResponseClientSerproCnpjModel } from 'src/app/shared/models/response/response-client-serpro-cnpj.model';
import { ResponseClientSerproModel } from 'src/app/shared/models/response/response-client-serpro-situation.model';
import { ResponseClientSerproCpfModel } from 'src/app/shared/models/response/response-client-serpro-cpf.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class SerproService {
  public userData: UserDataModel;
  private HEADER_SERVICE_CONTRACT: string = 'service-contract';
  private HEADER_INSTITUTION: string = 'institution';

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.userData = this.dataStoreService.getUserData();
  }

  public validatePartner(model: SerproPartnerValidationModel): Observable<SerproValidationResponseModel> {
    let headers = new HttpHeaders().set(this.HEADER_SERVICE_CONTRACT, model.socio.codigoContrato.toString());
    headers = headers.set(this.HEADER_INSTITUTION, model.socio.instituicao);
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .post<BaseApiResponse<SerproValidationResponseModel>>(`${environment.apiUrls.serproPartnerValidation}`, model, { headers })
      .pipe(map((returnValue) => (returnValue ? returnValue.response : null)));
  }

  public validatePartnerV2(model: SerproV2PartnerValidationModel): Observable<ResponseClientSerproModel> {
    const headers = new HttpHeaders()
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http
      .post<ResponseClientSerproModel>(`${environment.apiUrls.serproPartnerValidationV2}`, model, { headers })
      .pipe(map((returnValue) => (returnValue ? returnValue : null)));
  }

  public getCnpjInformationV2(cnpj: string): Observable<ResponseClientSerproV2CnpjModel> {
    const headers = new HttpHeaders()
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http
      .get<ResponseClientSerproV2CnpjModel>(`${environment.apiUrls.serproCnpjInformationV2}/${cnpj}`, { headers })
      .pipe(map((returnValue) => (returnValue ? returnValue : null)));
  }

  public validateCpf(model: SerproCpfRequestModelV2): Observable<ResponseClientSerproModel> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http.post<ResponseClientSerproModel>(`${environment.apiUrls.serproCpfValidationV2}`, model, { headers });
  }

  public validateCnpj(model: SerproCnpjValidationRequestModel): Observable<ResponseClientSerproModel> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http.post<ResponseClientSerproModel>(`${environment.apiUrls.serproCnpjValidationV2}`, model, { headers });
  }

  public validateCnpjWithCnae(model: SerproCnpjValidationWithCnaeRequestModel): Observable<ResponseClientSerproModel> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http.post<ResponseClientSerproModel>(`${environment.apiUrls.serproCnpjValidationWithCnaeV2}`, model, { headers });
  }

  public getCnpjInformation(cnpj: string, institution: string, serviceContract: number): Observable<ResponseClientSerproCnpjModel> {
    let headers = new HttpHeaders().set(this.HEADER_SERVICE_CONTRACT, serviceContract.toString());
    headers = headers.set(this.HEADER_INSTITUTION, institution);
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<BaseApiResponse<ResponseClientSerproCnpjModel>>(`${environment.apiUrls.serproCnpjInformation}/${cnpj}`, { headers })
      .pipe(map((returnValue) => (returnValue ? returnValue.response : null)));
  }

  public validateMinimumAge(cpf: string, birthDate: string): Observable<boolean> {
    return this.http
      .get<BaseApiResponse<boolean>>(`${environment.apiUrls.validateMinimumAge}/${cpf}?birth-date=${birthDate}`)
      .pipe(map((returnValue) => (returnValue ? returnValue.response : null)));
  }

  public validateSituation(cpfCnpj: string, institution: string, serviceContract: number): Observable<ResponseClientSerproModel> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Institution', this.userData.institution)
      .set('ServiceContract', this.userData.serviceContract.toString())
      .set('Requestor', 'FISERV_ONLINE');

    return this.http.get<ResponseClientSerproModel>(`${environment.apiUrls.validateSituationV2}/${cpfCnpj}`, { headers });
  }
}
