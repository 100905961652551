<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Estabelecimento tem posto de combustível?</h4>

    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasGasStation">
      </fd-radio-button>
    </div>

    <h4 class="fd-subtitle">Usa módulo GoodCard? Se sim, selecione uma opção</h4>
    <div *ngFor="let item of goodCardModuleOptions" class="checkbox-container">
      <mat-checkbox (change)="onCheckboxChange(item, $event)" [value]="item.value" [checked]="item.selected"
        class="fd-checkbox" [id]="item.value">{{item.label}}</mat-checkbox>
    </div>

  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
