import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseUrlBlackListModel } from 'src/app/shared/models/response/response-url-blacklist.model';
import { UrlModel } from 'src/app/shared/models/url.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private http: HttpClient) {}

  public validateStructure(data: UrlModel): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrls.url}/structure/validate`, data);
  }
}
