import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Messages } from 'src/app/order/messages/order.messages';
import { getOrderByRoute, getRouteNameByOrder, orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { StepInfo, WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Proposal } from 'src/app/shared/models/proposal';

@Injectable({
  providedIn: 'root',
})
export class StartGuard implements CanActivate {
  constructor(
    private dataStore: DataStore,
    private wizardService: WizardService,
    private dialogService: DialogService,
    private authService: AuthService,
    private routingService: RoutingService,
    private router: Router,
    private dataStoreService: DataStoreService
  ) {}

  public canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return (
      of(currentRoute)
        // mapeia a rota atual, o p(asso atual e a proposta para as seguintes variáveis:
        .pipe(
          map((route: ActivatedRouteSnapshot) => {
            let canActivate = true;

            const isCEF = this.dataStoreService.getCEFFlag();
            if (!this.authService.isLoggedIn()) {
              this.routingService.navigateToLogin(false, false);
              canActivate = false;
            }

            if (isCEF) {
              this.routingService.navigateWithParentTo(
                orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.parentRoute,
                orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.name
              );
              return;
            }

            return canActivate;
          })
        )
    );
  }
}
