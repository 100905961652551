import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { NameValidator } from 'src/app/order/validators/name-validator';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';

@Component({
  selector: 'fd-pep',
  templateUrl: './fd-pep.component.html',
  styleUrls: ['./fd-pep.component.scss'],
})
export class FdPepComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('tooltip')
  tooltip: MatTooltip;

  @Input()
  formGroup: FormGroup;

  @Input()
  politicalExposition: any;

  @Input()
  pepName: any;

  @Input()
  pepRole: any;

  @Input()
  pepGovernmentOrganization: any;

  @Input()
  legalRepresentative: boolean = false;

  pepEnable: boolean;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.pepEnable = !this.politicalExposition?.disabled;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup?.controls;
  }

  getPepTooltipText(): string {
    return 'Pessoa Exposta Politicamente (PEP), é aquela que desempenha ou desempenhou nos últimos 5 anos,\
    no Brasil ou no exterior, cargo, emprego ou função pública relevante, incluindo políticos (eleitos ou candidatos)\
    e dirigentes de partidos políticos, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.';
  }

  showTooltipAndStopPropagation(): void {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  onChange(isPoliticalExposition: boolean): void {
    this.pepEnable = isPoliticalExposition;
    if (isPoliticalExposition) {
      this.setPoliticalExpositionValidation();
    } else {
      this.removeValidators();
    }
  }

  private removeValidators(): void {
    if (this.legalRepresentative) {
      this.removePepLegalRepresentativeValidators();
    } else {
      this.removePepValidators();
    }
  }

  private removePepValidators(): void {
    this.formControls.pepName.setValue('');
    this.formControls.pepRole.setValue('');
    this.formControls.pepGovernmentOrganization.setValue('');

    this.formControls.pepName.setErrors(null);
    this.formControls.pepRole.setErrors(null);
    this.formControls.pepGovernmentOrganization.setErrors(null);

    this.formControls.pepName.clearValidators();
    this.formControls.pepRole.clearValidators();
    this.formControls.pepGovernmentOrganization.clearValidators();
  }

  private removePepLegalRepresentativeValidators(): void {
    this.formControls.legalRepresentativePepName.setValue('');
    this.formControls.legalRepresentativePepRole.setValue('');
    this.formControls.legalRepresentativePepGovernmentOrganization.setValue('');

    this.formControls.legalRepresentativePepName.setErrors(null);
    this.formControls.legalRepresentativePepRole.setErrors(null);
    this.formControls.legalRepresentativePepGovernmentOrganization.setErrors(null);

    this.formControls.legalRepresentativePepName.clearValidators();
    this.formControls.legalRepresentativePepRole.clearValidators();
    this.formControls.legalRepresentativePepGovernmentOrganization.clearValidators();
  }

  private setPoliticalExpositionValidation(): void {
    if (this.legalRepresentative) {
      this.formControls.legalRepresentativePepName.setValidators([Validators.required, NameValidator]);
      this.formControls.legalRepresentativePepRole.setValidators([Validators.required]);
      this.formControls.legalRepresentativePepGovernmentOrganization.setValidators([Validators.required]);
    } else {
      this.formControls.pepName.setValidators([Validators.required, NameValidator]);
      this.formControls.pepRole.setValidators([Validators.required]);
      this.formControls.pepGovernmentOrganization.setValidators([Validators.required]);
    }
  }
}
