/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ATENÇÃO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Não esquecer de equalizar todas as URL's nos demais arquivos de enviromment.
 *
 */
import 'zone.js';
import * as ApiBaseUat from './api-base/api-base-uat';
export const apiBaseUat = ApiBaseUat.apiBase;
export const configApiBase = ApiBaseUat.configApiBase;
export const apiBase = `${ApiBaseUat.apiBase}/bwa`;
export const environment = {
  profile: 'UAT',
  firstOffer10k30k: {
    campaignId: 925,
    feeType: FeeTypeEnum.FLEX,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  secondOffer10k30k: {
    campaignId: 929,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  firstOffer30k85k: {
    campaignId: 926,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  secondOffer30k85k: {
    campaignId: 930,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  firstOffer85k250k: {
    campaignId: 927,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  secondOffer85k250k: {
    campaignId: 931,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  firstOffer3kkTo30kk: {
    campaignId: 928,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 30.000.000,00',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOffer3kkTo30kk: {
    campaignId: 932,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 30.000.000,00',
    monthBillingStart: '',
    monthBillingEnd: '',
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  firstOffer30kkTo100kk: {
    campaignId: 1043,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 30.000.000,01',
    billingRangeEnd: 'R$ 100.000.000,00',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOffer30kkTo100kk: {
    campaignId: 1045,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 30.000.000,01',
    billingRangeEnd: 'R$ 100.000.000,00',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  firstOfferPlus100kk: {
    campaignId: 1044,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 100.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOfferPlus100kk: {
    campaignId: 1046,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 100.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  /*firstOfferPlus250k: {
    campaignId: 928,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOfferPlus250k: {
    campaignId: 932,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },*/
  production: true,
  development: false,
  apiKey: 'IeBgvvjzmqQU47qXRyKwYX0ohFucum0H',
  saveSensitiveDataOnLocalStorage: false,
  ADMIN_URL: ApiBaseUat.adminUrl,
  apiUrls: {
    fees: `${apiBase}/credenciamento/online/rates`,
    authentication: `${apiBase}/credenciamento/online/auth`,
    blackList: `${apiBase}/credenciamento/online/blacklist`,
    formConstitution: `${apiBase}/credenciamento/online/form-constitution`,
    validateClient: `${apiBase}/credenciamento/online/client-validator`,
    validateTechnologyGroup: `${apiBase}/credenciamento/online/client-validator/technology-validation`,
    serproPartnerValidation: `${apiBase}/credenciamento/online/serpro/validate-partner`,
    serproPartnerValidationV2: `${apiBase}/credenciamento/online/serpro/v2/validate-partner`,
    serproCpfValidation: `${apiBase}/credenciamento/online/serpro/cpf`,
    serproCnpjValidation: `${apiBase}/credenciamento/online/serpro/cnpj-partial`,
    serproCnpjInformation: `${apiBase}/credenciamento/online/serpro/cnpj-information`,
    serproCnpjInformationV2: `${apiBase}/credenciamento/online/serpro/v2/cnpj-information`,

    serproCpfValidationV2: `${apiBase}/credenciamento/online/serpro/v2/cpf`,
    serproCnpjValidationV2: `${apiBase}/credenciamento/online/serpro/v2/cnpj-partial`,
    serproCnpjValidationWithCnaeV2: `${apiBase}/credenciamento/online/serpro/v2/cnpj-partial-with-cnae`,
    validateSituationV2: `${apiBase}/credenciamento/online/serpro/v2/situation-check`,

    validateMinimumAge: `${apiBase}/credenciamento/online/serpro/minimum-age-check`,
    mobileOperators: `${apiBase}/credenciamento/config/mobile-operator`,
    zipCode: `${apiBase}/credenciamento/online/zipcode`,
    cnae: `${apiBase}/credenciamento/online/cnae`,
    technologies: `${apiBase}/credenciamento/online/service/capture-solution`,
    bankList: `${apiBase}/credenciamento/config/bank`,
    bankValidate: `${apiBase}/credenciamento/online/bank`,
    proposal: `${apiBase}/credenciamento/online/proposal`,
    payment: `${apiBase}/credenciamento/online/purchase`,
    revenueRange: `${apiBase}/credenciamento/online/campaign/billing-range`,
    campaign: `${apiBase}/credenciamento/online/campaign`,
    rangeByTechnology: `${apiBase}/credenciamento/online/campaign/price-technology`,
    generalConfig: `${apiBase}/credenciamento/online/config-general`,
    configCampaign: `${apiBase}/credenciamento/config/campaigns`,
    registrationDataDetail: `${apiBase}/credenciamento/online/proposal/registration-data`,
    offerDataDetail: `${apiBase}/credenciamento/online/proposal/offer-data`,
    proposalDataDetail: `${apiBase}/credenciamento/online/proposal/proposal-data`,
    deliveryInformationDataDetail: `${apiBase}/credenciamento/online/proposal/delivery-information-data`,
    proposalNote: `${apiBase}/credenciamento/online/proposal/proposal-note`,
    validateSituation: `${apiBase}/credenciamento/online/serpro/situation-check`,
    terminalAmount: `${apiBase}/credenciamento/online/number-terminals`,
    terminalCheck: `${apiBase}/credenciamento/online/number-terminals/check-terminals`,
    apiStatus: `${apiBase}/credenciamento/online/api-status`,
    professionalLicenseStatus: `${apiBase}/credenciamento/online/professional-license`,
    validateByHierarchyChannel: `${apiBase}/credenciamento/online/client-validator/hierarchy-validation`,
    validateByRates: `${apiBase}/credenciamento/online/client-validator/rates-validation`,
    validadeCheckPrepaymentRate: `${apiBase}/credenciamento/online/campaign/check-has-prepayment-rate`,
    conciliator: `${apiBase}/credenciamento/online/conciliator`,
    proposalDraft: `${apiBase}/credenciamento/online/proposal-draft`,
    optIn: `${apiBase}/credenciamento/online/operation-table`,
    conciliatorCompany: `${apiBase}/credenciamento/online/conciliator-company`,
    simulation: `${apiBase}/credenciamento/online/simulation`,
    negociation: `${apiBase}/credenciamento/online/fee-simulator`,
    url: `${apiBase}/credenciamento/online/url`,
    splitTech: `${apiBase}/credenciamento/online/config-general/split-techs`,
    nationality: `${apiBase}/credenciamento/config/nationality`,
    cnaeCreditRisk: `${apiBase}/credenciamento/online/cnae-credit-risk`,
    modal: `${apiBase}/credenciamento/online/modal`,
    feeSimulator: `${apiBase}/credenciamento/online/fee-simulator`,
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone relEated error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
