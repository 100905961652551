<mat-card class="fd-commercial-conditions-data-detail__card-container">
  <mat-card-title class="fd-commercial-conditions-data-detail__card-title">Condições Comerciais</mat-card-title>
  <ng-container>
    <div class="fd-commercial-conditions-data-detail__card-margin" fxLayout="column" fxLayoutAlign="center stretch">
      <div
        fxLayout.xs="column"
        fxFlexFill
        class="fd-commercial-conditions-data-detail__data_row"
        fxLayoutGap="10px"
        *ngIf="commercialConditions || technologyData"
      >
        <div fxFlex="25">
          <strong>Campanha</strong>
          <div>
            <span>{{ commercialConditions?.campaignName }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Faturamento Total dos Cartões</strong>
          <div>
            <span>{{ commercialConditions?.totalCardBilling | currency: 'BRL' }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Faturamento Anual</strong>
          <div>
            <span>{{ commercialConditions?.annualBilling | currency: 'BRL' }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Ticket Médio</strong>
          <div>
            <span>{{ commercialConditions?.averageTicket | currency: 'BRL' }}</span>
          </div>
        </div>
      </div>
      <div
        fxLayout.xs="column"
        fxFlexFill
        *ngFor="let technology of technologyData; let i = index"
        class="fd-commercial-conditions-data-detail__data_row"
        fxLayoutGap="10px"
      >
        <div fxFlex="25">
          <strong>Tecnologia</strong>
          <div>
            <span>{{ technology?.technologyName }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Quantidade</strong>
          <div>
            <span>{{ technology?.quantity }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Valor Unitário</strong>
          <div>
            <span>{{ technology?.value | currency: 'BRL' }}</span>
          </div>
        </div>
        <div fxFlex="25" *ngIf="commercialConditions?.economicGroup">
          <strong>Grupo Econômico</strong>
          <div>
            <span>{{ commercialConditions.economicGroup }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
