import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take, takeWhile } from 'rxjs/operators';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdTableDefinition } from 'src/app/shared/fd-form-components/fd-table/fd-table.component';
import { ProductModel, Proposal } from 'src/app/shared/models/proposal';
import { CaptureSolutionModel } from 'src/app/start/components/proposal-detail/models/child-models/capture-solution.model';
import { ProposalDetailModel } from 'src/app/start/components/proposal-detail/models/proposal-detail.model';
import { Constants } from '../../constants/constants';
import { DialogService } from '../../services/internal/dialog/dialog.service';

@Component({
  selector: 'app-order-resume',
  templateUrl: './order-resume.component.html',
  styleUrls: ['./order-resume.component.scss'],
})
export class OrderResumeComponent implements OnDestroy {
  public proposal: Proposal;
  public tableDefinition: FdTableDefinition;
  public orderResumeEnabled = true;
  public products: ProductModel[];
  public alive = true;

  constructor(
    private dataStore: DataStore,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private wizardService: WizardService,
    private currencyPipe: CurrencyPipe
  ) {
    this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(takeWhile(() => this.alive))
      .subscribe((proposal) => {
        if (
          dataStore.isObjectEmpty(proposal) ||
          !proposal.productSelection ||
          !proposal.productSelection.products ||
          !proposal.productSelection.products.length
        ) {
          const storageProducts = sessionStorage.getItem(Constants.PROPOSAL_PRODUCTS_KEY);

          if (storageProducts) {
            const parsedItems = JSON.parse(storageProducts) as ProposalDetailModel;

            this.products = parsedItems.product.map((item: CaptureSolutionModel) => ({
              technologyName: item.serviceName,
              technology: item.serviceId,
              amount: item.qtCaptureSolution,
              price: !item.salesValue ? item.value : item.salesValue,
              technologyGroup: item.technologyGroup,
            }));
            this.setTableDefinition();
            return;
          }

          this.orderResumeEnabled = false;
          return;
          // this.dialogService.openDialog(Messages.FAILED_TO_GET_PROPOSAL_DATA, this.wizardService.previousStep.bind(this));
        }
        this.products = proposal.productSelection.products;
        this.setTableDefinition();
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public setTableDefinition() {
    this.tableDefinition = {
      columnNames: ['Tecnologia', 'Quantidade', 'Valor unitário'],
      items: this.products.map((x) => ({
        technology: x.technologyName,
        amount: x.amount,
        price: x.price,
      })),
      footerContent: this.getTotalPrice(),
    };
  }

  public getTotalPrice() {
    const total = this.products.reduce((prev, curr) => {
      let price = Number(curr.price.toString().replace(/[Rr$\s]/g, ''));
      if (isNaN(price)) {
        price = Number(
          curr.price
            .toString()
            .replace(/[Rr$\s]/g, '')
            .replace(/\./g, '')
            .replace(/\,/g, '.')
        );
      }
      price = price * curr.amount;
      return prev + price;
    }, 0);
    return `<span>Valor total: <strong>${this.currencyPipe.transform(total, 'BRL')}</strong></span>`;
  }
}
