import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { take, takeWhile } from 'rxjs/operators';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Proposal } from 'src/app/shared/models/proposal';
import { PrepaymentFeeModel } from 'src/app/shared/models/response/response-prepayment-fee.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { FeeEditorDialogData, FeeEditorModalComponent } from '../../../modals/fee-editor-modal/fee-editor-modal.component';

@Component({
  selector: 'app-prepayment-table',
  templateUrl: './prepayment-table.component.html',
  styleUrls: ['./prepayment-table.component.scss'],
})
export class PrepaymentTableComponent implements OnInit, OnDestroy {
  protected get institution() {
    return this.proposal && this.proposal.institution ? this.proposal.institution : this.userData.institution;
  }

  protected get serviceContract() {
    return this.proposal && this.proposal.serviceContract ? this.proposal.serviceContract : this.userData.serviceContract;
  }

  protected get channel() {
    return this.proposal && this.proposal.channel ? this.proposal.channel : this.userData.channel;
  }

  protected get subChannel() {
    return this.proposal && this.proposal.subChannel
      ? this.proposal.subChannel
      : this.dataStoreService.getUserDataSubChannel(this.userData);
  }

  protected get channelType() {
    return this.proposal && this.proposal.channelType ? this.proposal.channelType : this.userData.channelType;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public alive = true;
  public proposal: Proposal;
  public userData: UserDataModel;

  @Output()
  public backAction: EventEmitter<void> = new EventEmitter();

  public displayedColumns = ['billingRange', 'suggestedPrepaymentFee'];

  public STEP_NUMBER = 0;

  public prepaymentFee: PrepaymentFeeViewModel = new PrepaymentFeeViewModel();
  public proposalPrepaymentFeeModel: PrepaymentFeeViewModel;

  public dataSource: MatTableDataSource<PrepaymentFeeViewModel> = new MatTableDataSource();

  constructor(
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    private dataStoreService: DataStoreService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  public async ngOnInit() {
    this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(takeWhile(() => this.alive))
      .subscribe((proposal: Proposal) => {
        this.proposal = proposal;

        this.userData = this.dataStoreService.getUserData();
      });
  }

  public onModalConfirm(data: FeeEditorDialogData) {
    data.formControl.setValue(data.formGroup.controls.feeEdit.value);
    this.dataSource.data = this.dataSource.data.reverse();
  }

  public updatePrepayment(response: PrepaymentFeeModel, proposal: Proposal) {
    if (!proposal.feeTypeModel || !proposal.feeTypeModel.enablePrepayment) {
      return;
    }

    this.proposalPrepaymentFeeModel = proposal.prepaymentFeeModel as unknown as PrepaymentFeeViewModel;

    this.prepaymentFee.suggestedPrepaymentFee = response.suggestedAutoPrepayment;
    this.prepaymentFee.minimumPrepaymentFee = response.minAutoPrepayment;
    this.prepaymentFee.billingRange = proposal.offerDataDetails.revenueRangeDescription;

    if (
      this.proposalPrepaymentFeeModel &&
      this.proposalPrepaymentFeeModel.suggestedPrepaymentFee !== null &&
      this.proposalPrepaymentFeeModel.suggestedPrepaymentFee !== undefined
    ) {
      if (this.proposalPrepaymentFeeModel.feeEdit) {
        this.formControls.feeEdit.setValue(this.proposalPrepaymentFeeModel.feeEdit);
      }
      this.formControls.suggestedPrepaymentFee.setValue(this.proposalPrepaymentFeeModel.suggestedPrepaymentFee);
      this.dataSource.data = [
        {
          billingRange: proposal.offerDataDetails.revenueRangeDescription,
          minimumPrepaymentFee: response.minAutoPrepayment,
          suggestedPrepaymentFee: this.proposalPrepaymentFeeModel.suggestedPrepaymentFee,
          feeEdit: this.proposalPrepaymentFeeModel.feeEdit,
        },
      ];
    } else {
      this.formControls.suggestedPrepaymentFee.setValue(this.prepaymentFee.suggestedPrepaymentFee);
      this.dataSource.data = [this.prepaymentFee];
    }
  }

  public updateFields(proposal: Proposal) {
    this.proposalPrepaymentFeeModel = proposal.prepaymentFeeModel as unknown as PrepaymentFeeViewModel;

    if (!proposal.feeTypeModel || !proposal.feeTypeModel.enablePrepayment) {
      return;
    }
  }

  public persistData() {
    return true;
  }

  public openPrepaymentFeeEditorModal(dialogData: FeeEditorDialogData) {
    dialogData.title = dialogData.field.modalTitle;
    dialogData.itemDescription = `${this.prepaymentFee.billingRange}`;
    dialogData.maxValue = this.prepaymentFee.suggestedPrepaymentFee;
    dialogData.initialValue = this.getEditedOrSuggestedFee(dialogData, this.prepaymentFee);
    dialogData.minValue = this.prepaymentFee.minimumPrepaymentFee;
    dialogData.confirmCallbackFn = this.onModalConfirm.bind(this);

    this.dialog.open(FeeEditorModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });
  }

  public getEditedOrSuggestedFee(dialogData: FeeEditorDialogData, viewModel: PrepaymentFeeViewModel) {
    if (dialogData && dialogData.formControl) {
      return dialogData.formControl.value;
    } else if (viewModel) {
      return viewModel.suggestedPrepaymentFee;
    } else {
      return null;
    }
  }

  public getFields(): FdFieldConfigs {
    return this.fields;
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public createFields(): FdFieldConfigs {
    return {
      suggestedPrepaymentFee: {
        controlName: 'suggestedPrepaymentFee',
        maskCharsReplace: /[% ]/,
        label: 'Valor unitário',
        modalTitle: 'Indique a taxa do produto',
        childControl: {
          controlName: 'feeEdit',
          label: 'Taxa solicitada',
          itemDescription: 'teste',
          isPercent: true,
          maxLength: 4,
          messages: {
            required: 'Informe o valor',
            invalid: 'Valor inválido',
            valueTooLow: 'O valor deve ser maior que o mínimo especificado',
            valueTooHigh: 'O valor deve ser menor que o máximo especificado',
          },
        },
      },
    };
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      suggestedPrepaymentFee: [''],
      feeEdit: [''],
    });
  }
}

export class PrepaymentFeeViewModel {
  public suggestedPrepaymentFee: number;
  public minimumPrepaymentFee: number;
  public billingRange: string;
  public feeEdit: string;
}
