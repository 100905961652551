import { CardTypeEnum } from '../enums/card-type.enum';
import { isCreditCardAmex } from '../utils/card-utils';

export function CardNumberMask(value: string) {

    if (!value || (value && value.length < 6)) {
        return CardTypeEnum.GENERIC.numberMask;
    }

    if (isCreditCardAmex(value)) {
        return CardTypeEnum.AMEX.numberMask;
    } else {
        return CardTypeEnum.GENERIC.numberMask;
    }
}
