import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent, ErrorModalDialogData } from 'src/app/order/components/modals/error-modal/error-modal.component';
import {
  InformationModalComponent,
  InformationModalDialogData,
} from 'src/app/order/components/modals/information-modal/information-modal.component';
import { MessageModalComponent, MessageModalDialogData } from 'src/app/order/components/modals/message-modal/message-modal.component';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { ConfirmDialogData, FdConfirmComponent } from 'src/app/shared/fd-form-components/fd-confirm/fd-confirm.component';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private loadingService: LoadingService) {}

  public openConfirmDialog(
    message: Messages,
    confirmCallbackFn?: () => any,
    cancelCallbackFn?: () => any,
    size: ModalDefinitions = ModalDefinitions.DEFAULT_MODAL_WIDTH
  ): void {
    const dialogData: ConfirmDialogData = {
      description: message.description,
      title: message.title,
      confirmCallbackFn,
      cancelCallbackFn,
    };

    const dialogRef = this.dialog.open(FdConfirmComponent, {
      disableClose: true,
      width: size,
      data: dialogData,
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  public openDialog(
    message: Messages,
    callbackFn?: () => any,
    buttonText?: string,
    size: ModalDefinitions = ModalDefinitions.DEFAULT_MODAL_WIDTH
  ): void {
    const dialogData: MessageModalDialogData = {
      description: message.description,
      title: message.title,
      buttonText,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: size,
      data: dialogData,
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  public openDialogWithMessage(message: string, callbackFn?: () => any, buttonText?: string): void {
    const dialogData: MessageModalDialogData = {
      description: message,
      title: 'Atenção',
      buttonText,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  public openDialogWithHtmlMessage(message: string, callbackFn?: () => any, buttonText?: string): void {
    const dialogData: MessageModalDialogData = {
      description: message,
      title: 'Atenção',
      html: true,
      buttonText,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });
  }

  public openDialogWithHtmlMessageWithTitle(title: string, message: string, callbackFn?: () => any, buttonText?: string): void {
    const dialogData: MessageModalDialogData = {
      description: message,
      title: title,
      html: true,
      buttonText,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });
  }

  public openErrorDialog(
    message: Messages,
    errorDetails: string,
    callbackFn?: () => any,
    buttonText?: string,
    alert?: string,
    size: ModalDefinitions = ModalDefinitions.DEFAULT_MODAL_WIDTH
  ): void {
    const dialogData: ErrorModalDialogData = {
      description: message.description,
      title: message.title,
      errorDetails,
      buttonText,
      alert,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(ErrorModalComponent, {
      disableClose: true,
      width: size,
      data: dialogData,
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  public openInformationDialog(message: string): void {
    const dialogData: InformationModalDialogData = {
      description: message,
    };

    const dialogRef = this.dialog.open(InformationModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });
  }

  public openOnlyOneDialog(message: string, callbackFn?: () => any, buttonText?: string): void {
    const dialogData: MessageModalDialogData = {
      description: message,
      title: 'Atenção',
      buttonText,
      callbackFn,
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    if (this.dialog.openDialogs.length < 1) {
      this.dialog.open(MessageModalComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: dialogData,
      });
    }

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}
