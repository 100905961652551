<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Empresa possui filiais?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasBranchOffices"> </fd-radio-button>
    </div>

    <div *ngIf="!!formControls.hasBranchOffices.value" [formArrayName]="'branchCnpjForms'">
      <form [formGroupName]="i" *ngFor="let childFormGroup of branchCnpjFormsControls; let i = index">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <div>
            <fd-input [parentForm]="childFormGroup" [field]="fieldsArray[i].cnpj"> </fd-input>
          </div>
          <div>
            <fd-button
              size="thin"
              *ngIf="shouldShowRemoveButton(i)"
              fdSecondary
              [content]="removeButtonHTML"
              (trigger)="removeBranchCnpjForm(i)"
            >
            </fd-button>
          </div>
        </div>
      </form>
      <fd-button
        size="thin"
        *ngIf="branchCnpjFormsControls && branchCnpjFormsControls.length < 4"
        fdTertiary
        [content]="addButtonHTML"
        (trigger)="addBranchCnpjForm()"
      >
      </fd-button>
    </div>
  </div>

  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
