import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../order/services/internal/dialog/dialog.service';
import { RoutingService } from '../order/services/internal/routing/routing.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent {
  constructor(
    private location: Location,
    private currentRoute: ActivatedRoute,
    private dialogService: DialogService,
    private routingService: RoutingService
  ) {}

  public goToHome() {
    const route = this.getLastRoutePath();
    if (route !== 'welcome') {
      this.routingService.navigateToHome(true);
    }
  }

  private getLastRoutePath(): any {
    if (document && document.location && document.location.href) {
      const arr = document.location.href.split('/');
      return arr[arr.length - 1];
    }
  }
}
