<div class="proposal-screen">

  <div *ngIf="scheduledMaintenanceActive" class="proposal-screen__alert">
    <fa-icon icon="exclamation-triangle"></fa-icon>
    <span class="proposal-screen__alert-message">{{scheduledMaintenanceMessage}}</span>
  </div>

  <ng-container *ngIf="conclusionPhase === 'approved'">
    <app-approved-proposal></app-approved-proposal>
  </ng-container>
  <ng-container *ngIf="conclusionPhase === 'rejected'">
    <app-rejected-proposal></app-rejected-proposal>
  </ng-container>
  <ng-container *ngIf="conclusionPhase === 'pending'">
    <app-pending-proposal></app-pending-proposal>
  </ng-container>
  <ng-container *ngIf="conclusionPhase === 'compliance_manual_analysis'">
    <app-manual-analysis-proposal></app-manual-analysis-proposal>
  </ng-container>
  <ng-container *ngIf="conclusionPhase === 'credit_manual_analysis'">
    <app-credit-manual-analysis-proposal></app-credit-manual-analysis-proposal>
  </ng-container>

  <ng-container *ngIf="isDev">
    <fd-button [content]="'copiar JSON da proposta para a área de transferência'" (trigger)="getProposalJSON()"></fd-button>
    <fd-button [content]="'enviar outra proposta com os mesmos dados'" (trigger)="resendWithSameData()"></fd-button>
  </ng-container>

</div>
