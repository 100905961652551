<!-- <mat-card class="fd-business-data-detail__card-container"> -->
<div class="fd-business-data-detail__card-container width45" *ngIf="data">
  <table mat-table [dataSource]="dataSource" class="fd-table">
    <!-- ID Column -->

    <ng-container matColumnDef="minAnnualBilling" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Valor mínimo</th>
      <td mat-cell *matCellDef="let rowElement">{{ rowElement.minAnnualBilling | currency: 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="maxAnnualBilling" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Valor máximo</th>
      <td mat-cell *matCellDef="let rowElement">{{ rowElement.maxAnnualBilling | currency: 'BRL' }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="suggestedAutoPrepayment" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Taxa</th>
      <td class="align-center" mat-cell *matCellDef="let rowElement">{{ rowElement.suggestedAutoPrepayment | percent }}</td>
    </ng-container>

    <!-- Color Column -->

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<!-- </mat-card> -->
