import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Messages } from 'src/app/order/messages/order.messages';
import { getParentRouteByOrder, getRouteNameByOrder } from 'src/app/order/routing/routing-definitions';
import { DialogService } from '../dialog/dialog.service';
import { StepInfo } from '../wizard/wizard.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private router: Router, private dialogService: DialogService) {}

  public navigate(stepInfo: StepInfo) {
    const parentRoute = `/${getParentRouteByOrder(stepInfo.step, stepInfo.subStep)}/`;
    const path = getRouteNameByOrder(stepInfo.step, stepInfo.subStep);
    this.navigateTo(parentRoute.concat(path));
  }

  public navigateWithParentTo(parentRoute: string, childRoute: string, replaceUrl: boolean = false): void {
    this.router.navigate([`/${parentRoute}/`.concat(childRoute)], {
      replaceUrl,
    });
  }

  public navigateTo(path: string, replaceUrl: boolean = false): void {
    this.router.navigate([path], { replaceUrl });
  }

  public navigateWithQueryParamsTo(path: string, queryParams: any, replaceUrl: boolean = false): void {
    this.router.navigate([path], { replaceUrl, queryParams });
  }

  public navigateToHome(reload: boolean = true) {
    if (reload) {
      document.location.href = '/';
    } else {
      this.router.navigate(['/']);
    }
  }

  public navigateToLogin(reload: boolean = true, showTimedOutModal: boolean = true) {
    if (showTimedOutModal) {
      this.dialogService.openDialog(Messages.SESSION_TIMED_OUT);
    }
    if (reload) {
      document.location.href = '/login';
    } else {
      this.router.navigate(['/login']);
    }
  }

  public navigateToLoginCEF(reload: boolean = true, showTimedOutModal: boolean = true) {
    if (showTimedOutModal) {
      this.dialogService.openDialog(Messages.SESSION_TIMED_OUT);
    }
    this.navigateToLogin(reload);
  }
}
