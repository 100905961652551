import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take, takeWhile } from 'rxjs/operators';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { DeliveryContactModel, PartnerDataModel, Proposal, ReceivingDataModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { customDeliveryContactValidators } from '../delivery-contact/delivery-contact.validators';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
@Component({
  selector: 'app-receiving-data',
  templateUrl: './receiving-data.component.html',
  styleUrls: ['./receiving-data.component.scss'],
})
export class ReceivingDataComponent extends FormStep implements OnInit {
  get documentCategory() {
    return this.proposal.registrationDataPersonalInfo.documentCategory;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public receivingDataModel: ReceivingDataModel;
  public deliveryContact: DeliveryContactModel;
  public fields: FdFieldConfigs;
  public proposal: Proposal;

  protected STEP_NUMBER = 6;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public getReceivingData() {
    this.setReceivingData(this.deliveryContact);
  }

  public setReceivingData(deliveryContact: DeliveryContactModel): void {
    if (deliveryContact) {
      this.formControls.name.setValue(deliveryContact.contactName);
      this.formControls.fixedNumber.setValue(deliveryContact.fixedNumberRequired);
    }
  }

  public createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome',
        controlName: 'name',
        messages: {
          required: 'Informe o nome',
          invalid: 'Nome inválido',
        },
      },
      fixedNumber: {
        label: 'Telefone 1',
        controlName: 'fixedNumber',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        messages: {
          required: 'Informe o telefone',
          invalid: 'Telefone inválido',
          pattern: 'Telefone inválido',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', customDeliveryContactValidators.name],
      fixedNumber: ['', customDeliveryContactValidators.phoneNumber],
    });
  }
  protected proposalGetCallback(): void {
    if (
      this.dataStore.isObjectEmpty(this.proposal) ||
      !this.proposal.deliveryContact ||
      !this.proposal.registrationDataAddress ||
      !this.proposal.registrationDataPersonalInfo
    ) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Registro de Entrega',
        () => this.back(),
        null,
        'error'
      );
    }
    this.deliveryContact = this.proposal.deliveryContact;
  }

  protected persistData() {
    const receivingDataModel: ReceivingDataModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ receivingDataModel });
  }

  protected updateFields(proposal: Proposal) {
    this.receivingDataModel = proposal.receivingDataModel;

    if (!this.receivingDataModel) {
      return;
    }

    this.formControls.name.setValue(this.receivingDataModel.name);
    this.formControls.fixedNumber.setValue(this.receivingDataModel.fixedNumber);
  }
}
