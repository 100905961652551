import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take, takeWhile } from 'rxjs/operators';
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { MobileOperatorService } from 'src/app/order/services/external/mobile-operator/mobile-operator.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FdTableDefinition } from 'src/app/shared/fd-form-components/fd-table/fd-table.component';
import { Proposal, SalesDataModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { OfferTypeEnum } from '../../../enums/offer-type.enum';
import { FormStep } from '../../form-step';

import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';

@Component({
  selector: 'app-sales-data',
  templateUrl: './sales-data.component.html',
  styleUrls: ['./sales-data.component.scss'],
})
export class SalesDataComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 6;
  public tableDef: FdTableDefinition;
  public proposal: Proposal;
  public salesDataModel: SalesDataModel;
  public configuration: ConfigurationModel;
  public feeType: FeeTypeEnum;

  constructor(
    private formBuilder: FormBuilder,
    private mobileOperatorService: MobileOperatorService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public isMDRFlex(): boolean {
    return this.proposal.feeTypeModel.feeType === FeeTypeEnum.FLEX;
  }

  public isSale(): boolean {
    return this.proposal.offerDataType.offerType === OfferTypeEnum.SALES;
  }

  public getMobileOperators() {
    const operatorDropdown = this.fields.mobileOperator as FdSelectConfig;
    this.mobileOperatorService
      .getMobileOperatorList()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (operatorList) => {
          if (operatorList !== null) {
            if (this.isSale() && !operatorList.some((x) => x.value === MobileOperatorEnum.ALGAR)) {
              this.dialogService.openDialog(Messages.ALGAR_CHIP_NOT_FOUND, () => this.back());
            }
            operatorDropdown.items = [];
            operatorDropdown.items.push(...operatorList);
          }
        },
        (error) => {
          operatorDropdown.items = [];
        }
      );
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.setMobileOperator(this.isSale());
  }

  public setMobileOperator(isSales: boolean) {
    if (isSales) {
      const operatorDropdown = this.fields.mobileOperator as FdSelectConfig;
      if (operatorDropdown && operatorDropdown.items) {
        operatorDropdown.items.map((x) => (x.value === MobileOperatorEnum.ALGAR ? (x.selected = true) : (x.selected = false)));
        this.formControls.mobileOperator.setValue(MobileOperatorEnum.ALGAR);
      }
    }
  }

  public createFormGroup() {
    const group = this.formBuilder.group({
      mobileOperator: ['', Validators.required],
    });

    group.controls.mobileOperator.disable();
    return group;
  }

  public persistData() {
    const salesDataModel: SalesDataModel = {
      ...this.formGroup.getRawValue(),
    };

    salesDataModel.receivingDeadline = this.proposal.feeTypeModel.receivingDeadline.toString();
    if (!this.isSale()) {
      delete salesDataModel.mobileOperator;
    }

    return this.dataStore.updateProposal({ salesDataModel });
  }

  public getPaymentDays() {
    if (this.configuration && this.configuration.termPaymentDays) {
      const items: Item[] = [];
      this.configuration.termPaymentDays.forEach((item) => {
        const dayLabel = item === 1 ? 'dia' : 'dias';
        items.push({ value: item, label: `${item} ${dayLabel}` });
      });

      return items;
    }
  }

  public getFixedPaymentDays() {
    return [
      { value: 2, label: '2 dias' },
      { value: 14, label: '14 dias' },
      { value: 30, label: '30 dias' },
    ];
  }

  public createFields(): FdFieldConfigs {
    return {
      mobileOperator: {
        label: 'Escolha a operadora do Chip',
        controlName: 'mobileOperator',
        items: [],
        messages: {
          required: 'Informe a operadora',
          invalid: 'operadora inválida',
        },
      },
    };
  }

  public updateFields(proposal: Proposal) {
    this.salesDataModel = proposal.salesDataModel;
    if (this.salesDataModel) {
      this.formControls.mobileOperator.setValue(this.salesDataModel.mobileOperator);
      (this.fields.mobileOperator as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.salesDataModel.mobileOperator)
      );
    }
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.feeTypeModel || !this.proposal.feeTypeModel.feeType) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Vendas',
        () => this.back(),
        null,
        'error'
      );
    }
    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
    this.feeType = this.proposal.feeTypeModel.feeType;
  }
}

export enum MobileOperatorEnum {
  VIVO = '02',
  TIM = '03',
  CLARO = '04',
  OI = '05',
  ALGAR = '06',
}
