<mat-form-field class="fd-select__container" color="accent" [formGroup]="parentForm">
  <mat-label>{{ field?.label }}</mat-label>
  <mat-select
    [id]="field?.controlName"
    [formControl]="relatedFormControl"
    [required]="field?.required"
    (selectionChange)="onSelectionChange($event)"
  >
    <input
      autocomplete="off"
      *ngIf="field?.searchable"
      matInput
      class="fd-select__search-input caret-color-black"
      (keyup)="filter($event)"
      (keydown)="handleSpace($event)"
      [placeholder]="field?.searchPlaceholder"
    />
    <mat-option *ngIf="!field.items || field.items.length === 0">Nenhuma opção encontrada</mat-option>
    <mat-option *ngFor="let item of field.items" [value]="item.value">{{ item.label }}</mat-option>
  </mat-select>
  <mat-error *ngIf="hasError"
    ><div *ngFor="let errorMessage of errorMessages">{{ errorMessage }}</div></mat-error
  >
</mat-form-field>
