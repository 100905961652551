<mat-toolbar color="primary" class="fd-accreditation-navbar no-print">
     <div *ngIf="!isCEF" class="fd-accreditation-navbar__left-arrow" (click)="triggerBackAction()">
          <fa-icon icon="arrow-left"></fa-icon>
     </div>
     <h1 class="fd-accreditation-navbar__title">{{getButtonLabel()}}</h1>
     <div class="fd-accreditation-navbar__user-wrapper">
          <div class="fd-accreditation-navbar__subchannel-dropdown-container">
               <fd-select class="fd-accreditation-navbar__subchannel-dropdown" (loadItemsFn)="loadSubChannels()"
                    *ngIf="hasHierarchyDropdown && hasMoreThanOneHierarchy" [parentForm]="formGroup"
                    [field]="fields.subChannel" (selectionChange)='setSelectedSubChannel()'>
               </fd-select>
          </div>
          <div class="fd-accreditation-navbar__subchannel-dropdown-container">
               <fd-select class="fd-accreditation-navbar__subchannel-dropdown"
                    *ngIf="hasHierarchyDropdown && hasMoreThanOneHierarchy && hasMoreThanOneAgentIdBySubChannel" [parentForm]="formGroup"
                    [field]="fields.agentChannel" (selectionChange)='setSelectedAgentChannel()'>
               </fd-select>
          </div>
          <button mat-button [matMenuTriggerFor]="menu" class="fd-accreditation-navbar__user">
               Olá, {{name}}
               <fa-icon icon="user-circle" class="user-circle"></fa-icon>
               <fa-icon icon="sort-down" class="user-down"></fa-icon>
          </button>
          <mat-menu #menu="matMenu">
               <button mat-menu-item (click)="logout()">
                    <fa-icon icon="sign-out-alt" class="sign-out-alt"></fa-icon> Sair
               </button>
          </mat-menu>
     </div>
</mat-toolbar>
