<!-- <mat-card class="fd-business-data-detail__card-container"> -->
<div class="fd-business-data-detail__card-container" *ngIf="data">
  <table mat-table [dataSource]="dataSource" class="fd-table">
    <!-- ID Column -->
    <ng-container matColumnDef="flagName" class="fd-table__cell" *ngIf="!viewFlagImg">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let rowElement">{{ rowElement.flagName }}</td>
    </ng-container>

    <ng-container matColumnDef="flagName" class="fd-table__cell" *ngIf="viewFlagImg">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let rowElement">
        <img [class]="rowElement.flagName" [src]="getFlagImageURL(rowElement.flagName)" [alt]="rowElement.flagName"/>
      </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="credit" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Crédito</th>
      <td class="align-center" mat-cell *matCellDef="let rowElement">{{ rowElement.credit | percent }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="debit" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Débito</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ rowElement.debit | percent }}</td>
    </ng-container>

    <ng-container matColumnDef="lessTo6Installments" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Parcelamento em até 6x</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ rowElement.lessTo6Installments | percent }}</td>
    </ng-container>

    <ng-container matColumnDef="more6Installments" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Parcelamento mais de 6x</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ rowElement.more6Installments | percent }}</td>
    </ng-container>

    <ng-container matColumnDef="installmentPlan" class="fd-table__cell mat-column-installmentPlan">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Crediário</th>
      <td class="align-center" mat-cell #row *matCellDef="let rowElement">{{ rowElement.installmentPlan | percent }}</td>
    </ng-container>

    <!-- Color Column -->

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<!-- </mat-card> -->
