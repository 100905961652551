<div [formGroup]="parentForm">
  <input [formControlName]="field?.controlName" [id]="field?.controlName" type="hidden" tabindex="0">
  <span (click)="canEdit ? openValueEditorDialog() : null" class="fd-fee-editor__display-value" [ngClass]="{
      'fd-fee-editor__display-value--edited': feeColor === 'green' || field?.isEdited, 
      'fd-fee-editor__display-value--read-only': !canEdit, 
      'fd-fee-editor__display-value--initial': feeColor === 'blue' && !field?.isEdited && canEdit}">{{displayValue}}%</span>

  <fa-icon *ngIf="canEdit" (click)="canEdit ? openValueEditorDialog() : null" class="fd-fee-editor__edit-icon"
    icon="pencil-alt" matSuffix>
  </fa-icon>
</div>