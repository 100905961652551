import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { BusinessAffiliationsModel } from '../models/business-affiliations.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-business-affiliations',
  templateUrl: './business-affiliations.component.html',
  styleUrls: ['./business-affiliations.component.scss'],
})
export class BusinessAffiliationsComponent extends FormStep implements OnInit {
  get hasPepPartners() {
    return !!this.formControls.hasPepPartners.value;
  }

  get hasRelatives() {
    return !!this.formControls.hasPartnersFiservRelation.value;
  }

  get hasPublicOrganizationParticipation() {
    return !!this.formControls.hasPublicOrganizationParticipation.value;
  }

  get hasReceivedPublicCapital() {
    return !!this.formControls.hasReceivedPublicCapital.value;
  }

  get hasGovernmentBenefits() {
    return !!this.formControls.hasGovernmentBenefits.value;
  }

  @ViewChild('tooltip') public tooltip: MatTooltip;

  public STEP_NUMBER = 0;
  public businessAffiliationsModel: BusinessAffiliationsModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.setConditionalValidators();
  }

  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  public getPepPartnersText() {
    return 'Alguma das pessoas listadas nos passos anteriores, são ou foram consideradas\
            Pessoas Expostas Politicamente (PEP)?';
  }

  public getPepTooltipText() {
    return 'Pessoa Exposta Politicamente (PEP), é aquela que desempenha ou desempenhou nos últimos 5 anos,\
    no Brasil ou no exterior, cargo, emprego ou função pública relevante, incluindo políticos (eleitos ou candidatos)\
    e dirigentes de partidos políticos, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.';
  }

  public getFiservRelationText() {
    return 'Alguma das pessoas listadas nos passos anteriores possuem relação de\
    parentesco ou afinidade com funcionários, administradores ou prestadores de\
    serviços da First Data?';
  }

  public getPublicOrganizationParticipationText() {
    return 'Algum governo, agência governamental ou entidade pública, empresa de capital\
    misto, controlada total ou parcialmente pelo governo, algum funcionário do governo \
    atual ou ex-funcionário, partido político, candidato a cargo político (eleito ou não)\
    ou parente de tais pessoas pública, partido político ou organização internacional\
    possui participação acionária, direta ou indireta, em sua empresa?';
  }

  public getGovernmentBenefitsText() {
    return 'Além dos relacionamentos descritos nos itens acima, há algum benefício\
    que um governo, dirigente partidário ou candidato político poderia ganhar como\
    resultado do engajamento proposto à sua empresa?';
  }

  public getReceivedPublicCapitalText() {
    return 'A empresa recebeu algum recurso público por meio da entrada de capital, aporte\
    de recursos ou contribuições de capital de giro, com ou sem participação\
    acionária?';
  }

  public setConditionalValidators() {
    this.formControls.pepDetails.setValidators([RequiredIfValidator(() => !!this.hasPepPartners)]);

    this.formControls.publicOrganizationParticipationDetails.setValidators([
      RequiredIfValidator(() => !!this.hasPublicOrganizationParticipation),
    ]);
    this.formControls.publicCapitalDetails.setValidators([RequiredIfValidator(() => !!this.hasReceivedPublicCapital)]);
    this.formControls.governmentBenefitsDetails.setValidators([RequiredIfValidator(() => !!this.hasGovernmentBenefits)]);

    this.formControls.relativeName.setValidators([RequiredIfValidator(() => !!this.hasRelatives)]);
    this.formControls.relativeArea.setValidators([RequiredIfValidator(() => !!this.hasRelatives)]);
    this.formControls.relativeRole.setValidators([RequiredIfValidator(() => !!this.hasRelatives)]);
    this.formControls.relativeRelationship.setValidators([RequiredIfValidator(() => !!this.hasRelatives)]);
  }

  public next() {
    if (!this.hasPepPartners) {
      this.formControls.pepDetails.setErrors(null);
    }

    if (!this.hasPublicOrganizationParticipation) {
      this.formControls.publicOrganizationParticipationDetails.setErrors(null);
    }

    if (!this.hasReceivedPublicCapital) {
      this.formControls.publicCapitalDetails.setErrors(null);
    }
    if (!this.hasGovernmentBenefits) {
      this.formControls.governmentBenefitsDetails.setErrors(null);
    }

    if (!this.hasRelatives) {
      this.formControls.relativeName.setErrors(null);
      this.formControls.relativeArea.setErrors(null);
      this.formControls.relativeRole.setErrors(null);
      this.formControls.relativeRelationship.setErrors(null);
    }

    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const businessAffiliations: BusinessAffiliationsModel = {
      ...this.formGroup.getRawValue(),
    };
    return this.dataStore.updateProposal({ businessAffiliations });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      hasPepPartners: ['', Validators.required],
      pepDetails: [''],
      hasPublicOrganizationParticipation: ['', Validators.required],
      hasReceivedPublicCapital: ['', Validators.required],
      hasGovernmentBenefits: ['', Validators.required],
      hasPartnersFiservRelation: ['', Validators.required],
      relativeName: [''],
      relativeArea: [''],
      relativeRole: [''],
      relativeRelationship: [''],
      publicOrganizationParticipationDetails: [''],
      publicCapitalDetails: [''],
      governmentBenefitsDetails: [''],
    });
  }
  protected proposalGetCallback(): void {
    if (
      (this.proposal &&
        this.proposal.registrationDataPersonalInfo &&
        this.proposal.registrationDataPersonalInfo.politicallyExposedPerson) ||
      (this.proposal && this.proposal.partnerDataModel && this.proposal.partnerDataModel.some((x) => x.politicalExposition))
    ) {
      this.formControls.hasPepPartners.setValue(true);
      this.formControls.hasPepPartners.disable();
    } else {
      if (this.proposal && this.proposal.businessAffiliations) {
        this.formControls.hasPepPartners.setValue(this.proposal.businessAffiliations.hasPepPartners);
      } else {
        this.formControls.hasPepPartners.setValue('');
        this.formControls.hasPepPartners.enable();
      }
    }
  }

  protected updateFields(proposal: Proposal) {
    this.businessAffiliationsModel = proposal.businessAffiliations;

    if (this.businessAffiliationsModel) {
      this.formControls.hasPepPartners.setValue(this.businessAffiliationsModel.hasPepPartners);
      this.formControls.pepDetails.setValue(this.businessAffiliationsModel.pepDetails);

      this.formControls.hasPublicOrganizationParticipation.setValue(this.businessAffiliationsModel.hasPublicOrganizationParticipation);
      this.formControls.publicOrganizationParticipationDetails.setValue(
        this.businessAffiliationsModel.publicOrganizationParticipationDetails
      );

      this.formControls.hasReceivedPublicCapital.setValue(this.businessAffiliationsModel.hasReceivedPublicCapital);
      this.formControls.publicCapitalDetails.setValue(this.businessAffiliationsModel.publicCapitalDetails);

      this.formControls.hasGovernmentBenefits.setValue(this.businessAffiliationsModel.hasGovernmentBenefits);
      this.formControls.governmentBenefitsDetails.setValue(this.businessAffiliationsModel.governmentBenefitsDetails);

      this.formControls.hasPartnersFiservRelation.setValue(this.businessAffiliationsModel.hasPartnersFiservRelation);
      this.formControls.relativeName.setValue(this.businessAffiliationsModel.relativeName);
      this.formControls.relativeArea.setValue(this.businessAffiliationsModel.relativeArea);
      this.formControls.relativeRole.setValue(this.businessAffiliationsModel.relativeRole);
      this.formControls.relativeRelationship.setValue(this.businessAffiliationsModel.relativeRelationship);
    }
  }

  protected createFields(): FdFieldConfigs {
    const commonBooleanOptions = {
      items: [],
      messages: {
        required: 'Informe uma opção',
      },
    };
    return {
      hasPepPartners: {
        controlName: 'hasPepPartners',
        ...commonBooleanOptions,
      },
      pepDetails: {
        label: 'Detalhes',
        maxLength: 3500,
        controlName: 'pepDetails',
        messages: {
          required: 'Informe os detalhes',
        },
      },
      hasPublicOrganizationParticipation: {
        controlName: 'hasPublicOrganizationParticipation',
        ...commonBooleanOptions,
      },
      publicOrganizationParticipationDetails: {
        label: 'Detalhes',
        maxLength: 3500,
        controlName: 'publicOrganizationParticipationDetails',
        messages: {
          required: 'Informe os detalhes',
        },
      },

      hasReceivedPublicCapital: {
        controlName: 'hasReceivedPublicCapital',
        ...commonBooleanOptions,
      },
      publicCapitalDetails: {
        label: 'Detalhes',
        maxLength: 3500,
        controlName: 'publicCapitalDetails',
        messages: {
          required: 'Informe os detalhes',
        },
      },
      hasGovernmentBenefits: {
        controlName: 'hasGovernmentBenefits',
        ...commonBooleanOptions,
      },
      governmentBenefitsDetails: {
        label: 'Detalhes',
        maxLength: 3500,
        controlName: 'governmentBenefitsDetails',
        messages: {
          required: 'Informe os detalhes',
        },
      },

      hasPartnersFiservRelation: {
        controlName: 'hasPartnersFiservRelation',
        ...commonBooleanOptions,
      },
      relativeName: {
        label: 'Nome',
        maxLength: 3500,
        controlName: 'relativeName',
        messages: {
          required: 'Informe o nome',
        },
      },
      relativeArea: {
        label: 'Área',
        maxLength: 3500,
        controlName: 'relativeArea',
        messages: {
          required: 'Informe a área',
        },
      },
      relativeRole: {
        label: 'Cargo',
        maxLength: 3500,
        controlName: 'relativeRole',
        messages: {
          required: 'Informe o cargo',
        },
      },
      relativeRelationship: {
        label: 'Grau de parentesco/afinidade',
        maxLength: 3500,
        controlName: 'relativeRelationship',
        messages: {
          required: 'Informe o grau de parentesco/afinidade',
        },
      },
    };
  }
}
