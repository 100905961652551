import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {TechnologyPriceDataModel} from "../models/technology-price-data.model";

@Component({
  selector: 'app-technology-price-table',
  templateUrl: './technology-price-table.component.html',
  styleUrls: ['./technology-price-table.component.scss'],
})
export class TechnologyPriceTableComponent implements OnInit {
  @Input()
  public data: TechnologyPriceDataModel[];

  public dataSource: MatTableDataSource<TechnologyPriceDataModel> = new MatTableDataSource();
  public displayedColumns = ['technology', 'price'];

  constructor() {}

  public ngOnInit() {
    this.dataSource.data = this.data;
  }
}
