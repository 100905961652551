<div *ngIf="!isCEF"  class="fd-accreditation-wizard__container no-print">
  <div class="fd-accreditation-wizard__step-container"  *ngFor="let step of steps; let index = index"
    [@stepCollapse]="step.collapsed ? 'collapse' : 'uncollapse'">
    <div class="fd-accreditation-wizard__step" [@ballColor]="step.active ? 'active' : 'inactive'"></div>
    <div class="fd-accreditation-wizard__substep-container" (@subStepCollapse.done)="triggerNextAction($event, index)"
      [@subStepCollapse]="getStepStatus(index)">
      <ng-container *ngIf="!isLastStep">
        <div *ngFor="let subStep of step.subSteps" class="fd-accreditation-wizard__substep"
          [@ballColor]="subStep.active ? 'active' : 'inactive'">
        </div>
      </ng-container>
    </div>
  </div>
  <div class="fd-accreditation-wizard__line" (@lineCollapse.done)="triggerStepCollapse($event)"
    [@lineCollapse]="getLineCollapseStatus()"></div>
</div>

<div class="fd-accreditation-wizard__text-container">
  <span class="fd-accreditation-wizard__step-name">{{stepName}}
    <ng-container *ngIf="!isLastStep && !isCEF">
      ({{currentSubStep}}/{{totalSubSteps}})
    </ng-container>
  </span>
</div>
