import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { FdButtonComponent } from 'src/app/shared/fd-form-components/fd-button/fd-button.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { Constants } from '../constants/constants';
import { ModalDefinitions } from '../enums/modal-definitions.enum';
import { Messages } from '../messages/order.messages';
import { orderRoutingDefinitions } from '../routing/routing-definitions';
import { ProposalService } from '../services/external/proposal/proposal.service';
import { DialogService } from '../services/internal/dialog/dialog.service';
import { LoadingService } from '../services/internal/loading/loading.service';
import { ScrollService } from '../services/internal/scroll/scroll.service';
import { WizardService } from '../services/internal/wizard/wizard.service';
import { DataStoreService } from '../store/data-store.service';
import { DataStore } from '../store/data.store';
import { FormBase } from './form-base';

@Directive()
export abstract class FormStep extends FormBase implements OnInit, OnDestroy {
  protected get institution() {
    return this.proposal && this.proposal.institution ? this.proposal.institution : this.userData.institution;
  }

  protected get serviceContract(): number {
    return this.proposal && this.proposal.serviceContract ? this.proposal.serviceContract : this.userData.serviceContract;
  }

  protected get channel() {
    return this.proposal && this.proposal.channel ? this.proposal.channel : this.userData.channel;
  }

  protected get subChannel() {
    return this.proposal && this.proposal.subChannel
      ? this.proposal.subChannel
      : this.dataStoreService.getUserDataSubChannel(this.userData);
  }

  protected get channelType() {
    return this.proposal && this.proposal.channelType ? this.proposal.channelType : this.userData.channelType;
  }
  public submitted = false;
  public proposal: Proposal;
  public userData: UserDataModel;
  public configuration: ConfigurationModel;
  public afterProposalSend = false;

  public fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  public fields: FdFieldConfigs;
  protected alive = true;

  protected abstract readonly STEP_NUMBER: number;

  constructor(
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected dialog: MatDialog,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(scrollService);
    this.wizardService.getCurrentStep().subscribe((step) => {
      this.afterProposalSend = step.name === 'submitted-proposal' || step.name === 'payment-data';
    });

    if (this.dataStoreService.getCEFFlag()) {
      return;
    }

    this.configurationService
      .getConfiguration()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response) {
            if (!response.ableMdrBillingCharge && !response.ableMdrFlexBillingCharge) {
              this.dialogService.openDialog(Messages.MDR_OR_MDR_FLEX_CONFIG_NOT_SET, () => this.routingService.navigateToHome());
              return;
            }

            this.configurationService.setConfigurationOnSessionStorage(response);
            this.configuration = this.configurationService.getConfigurationFromSessionStorage();
            return;
          }

          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Configuração Geral da Proposta',
            () => this.routingService.navigateToHome(),
            null,
            'error'
          );
        },
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.dialogService.openDialog(Messages.SERVICE_CONTRACT_CONFIG_NOT_SET, () => this.routingService.navigateToHome());
            return;
          }
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Configuração Geral da Proposta',
            () => this.routingService.navigateToHome(),
            null,
            'error'
          );
        }
      );
  }

  public async getProposal() {
    this.loadingService.adicionaLoadingEvent('getProposal');
    return await this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(finalize(() => this.loadingService.removeLoadingEvent('getProposal')))
      .pipe(takeWhile(() => this.alive))
      .toPromise()
      .then((proposal: Proposal) => {
        this.proposal = proposal;
        this.fields = this.createFields();
        this.formGroup = this.createFormGroup();
        this.proposalGetCallback();

        this.userData = this.dataStoreService.getUserData();

        this.updateFields(this.proposal);

        this.scrollService.scrollToTitle();
      });
  }

  public async ngOnInit(): Promise<void> {
    await this.getProposal();
  }

  public proposalHasUserData(proposal: Proposal) {
    return (
      proposal &&
      !!proposal.serviceContract &&
      !!proposal.channel &&
      !!proposal.subChannel &&
      !!proposal.agentChannel &&
      !!proposal.institution &&
      !!proposal.channelType
    );
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

  public setStepJump(step: number, order: number, status: boolean = true) {
    const savedJumpSteps = this.wizardService.setStepJumpInternal(step, order, status);
    this.dataStore.setLocalStorageObject(savedJumpSteps, Constants.JUMP_STEPS_LOCALSTORAGE_KEY);
  }

  public submitProposalBtnBlock(btnSubmit: FdButtonComponent, btnCancelar: FdButtonComponent) {
    this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (proposal) => {
          if (this.dataStore.isObjectEmpty(proposal) || !proposal.offerDataType || !proposal.offerDataType.offerType) {
            this.dialogService.openErrorDialog(
              Messages.FAILED_TO_GET_PROPOSAL_DATA,
              'Api Fiserv Online - Configuração Geral da Proposta',
              () => this.back(),
              null,
              'error'
            );

            btnSubmit.disabled = false;
            btnCancelar.disabled = false;
            return;
          }

          if (!proposal.externalDraftUuid) {
            const draftUuid = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);
            if (draftUuid) {
              proposal.externalDraftUuid = draftUuid;
            }
          }

          btnSubmit.disabled = true;
          btnCancelar.disabled = true;
          this.proposalService
            .submitProposal(proposal)

            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                this.dataStore.deleteDraftInformationFromLocalStorage();
                if (response && response.proposalNumber) {
                  localStorage.setItem(Constants.PROPOSAL_NUMBER, response.proposalNumber);
                  this.wizardService.goToSpecificStep(
                    orderRoutingDefinitions.submittedProposal.stepNumber,
                    orderRoutingDefinitions.submittedProposal.routes.SUBMITTED_PROPOSAL.order
                  );
                } else {
                  btnSubmit.disabled = false;
                  btnCancelar.disabled = false;
                  this.dialogService.openErrorDialog(
                    Messages.PROPOSAL_SUBMIT_ERROR_WITH_DETAILS_BUTTON,
                    JSON.stringify(response),
                    null,
                    null,
                    null,
                    ModalDefinitions.LARGE_MODAL_WIDTH
                  );
                }
              },
              (error) => {
                btnSubmit.disabled = false;
                btnCancelar.disabled = false;
                this.checkError(error.error);
              }
            );
        },
        (error) => {
          btnSubmit.disabled = false;
          btnCancelar.disabled = false;
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Configuração Geral da Proposta',
            null,
            'error'
          );
        }
      );
  }

  public submitProposal() {
    this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (proposal) => {
          if (this.dataStore.isObjectEmpty(proposal) || !proposal.offerDataType || !proposal.offerDataType.offerType) {
            this.dialogService.openErrorDialog(
              Messages.FAILED_TO_GET_PROPOSAL_DATA,
              'Api Fiserv Online - Configuração Geral da Proposta',
              () => this.back(),
              null,
              'error'
            );

            return;
          }

          if (!proposal.externalDraftUuid) {
            const draftUuid = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);
            if (draftUuid) {
              proposal.externalDraftUuid = draftUuid;
            }
          }

          this.proposalService
            .submitProposal(proposal)

            .pipe(takeWhile(() => this.alive))
            .subscribe(
              (response) => {
                this.dataStore.deleteDraftInformationFromLocalStorage();
                if (response && response.proposalNumber) {
                  localStorage.setItem(Constants.PROPOSAL_NUMBER, response.proposalNumber);
                  this.wizardService.goToSpecificStep(
                    orderRoutingDefinitions.submittedProposal.stepNumber,
                    orderRoutingDefinitions.submittedProposal.routes.SUBMITTED_PROPOSAL.order
                  );
                } else {
                  this.dialogService.openErrorDialog(
                    Messages.PROPOSAL_SUBMIT_ERROR_WITH_DETAILS_BUTTON,
                    JSON.stringify(response),
                    null,
                    null,
                    null,
                    ModalDefinitions.LARGE_MODAL_WIDTH
                  );
                }
              },
              (error) => {
                this.checkError(error.error);
              }
            );
        },
        (error) => {
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Configuração Geral da Proposta',
            null,
            'error'
          );
        }
      );
  }

  public checkError(error: any) {
    if (error && error.details && error.details.message === 'No items found for the given filters') {
      this.dialogService.openErrorDialog(
        Messages.PROPOSAL_SUBMIT_ERROR_CNAE_SERVICE_FAILED,
        JSON.stringify(error, null, 1),
        null,
        null,
        null,
        ModalDefinitions.LARGE_MODAL_WIDTH
      );
    } else if (error && error.details && error.details[0] && error.details[0].error === 'This service is priced incorrectly') {
      this.dialogService.openDialog(Messages.TECHNOLOGIES_PRICES_LOWER_THAN_ALLOWED);
    } else if (error && error.details && error.details[0] && error.details[0].error === 'Fee lower than allowed') {
      this.dialogService.openDialog(Messages.FEES_LOWER_THAN_ALLOWED);
    } else {
      if (error) {
        this.dialogService.openErrorDialog(
          Messages.PROPOSAL_SUBMIT_ERROR_WITH_DETAILS_BUTTON,
          JSON.stringify(error, null, 1),
          null,
          null,
          null,
          ModalDefinitions.LARGE_MODAL_WIDTH
        );
        return;
      }
      this.dialogService.openErrorDialog(Messages.PROPOSAL_SUBMIT_ERROR, 'Unspecified error');
    }
  }

  public back(saveData: boolean = true): void {
    if (this.isFormGroupValid() && saveData) {
      this.persistData();
    }
    this.wizardService.previousStep();
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  protected abstract createFields(): FdFieldConfigs;
  protected abstract persistData(): Promise<boolean>;
  protected abstract proposalGetCallback(): void;
  protected abstract updateFields(proposal: Proposal): void;
}
