import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const CustomRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (Validators.required(control)) {
    return { required: true };
  }

  return {};
};

export function RequiredIfValidator(condition: () => boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (condition()) {
      return CustomRequiredValidator(control);
    } else {
      return null;
    }
  };
}
