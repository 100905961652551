import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {Constants} from 'src/app/order/constants/constants';
import {ProposalStatusEnum} from 'src/app/order/enums/proposal-status.enum';
import {Messages} from 'src/app/order/messages/order.messages';
import {ProposalService} from 'src/app/order/services/external/proposal/proposal.service';
import {DialogService} from 'src/app/order/services/internal/dialog/dialog.service';
import {LoadingService} from 'src/app/order/services/internal/loading/loading.service';
import {RoutingService} from 'src/app/order/services/internal/routing/routing.service';
import {ProposalStatusModel} from 'src/app/shared/models/response/response-proposal-status.model';
import {ProposalDetails} from './models/proposal-details.model';
import {ProposalDetailService} from './services/proposal-detail.service';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss'],
})
export class ProposalDetailComponent implements OnInit, OnDestroy {

  //TODO: Para Habilitar o novo perfil de vendedor, deve-se descomentar este trecho de código e remover o trecho acima
  public proposalNumber: string;
  public proposalStatusModel: ProposalStatusModel;
  public imagePath: string;
  public cardTitle: string;
  public alive = true;
  public detailedProposal: ProposalDetails;
  public success: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private proposalService: ProposalService,
    private routingService: RoutingService,
    private router: Router,
    private location: Location,
    private loadingService: LoadingService,
    private proposalDetailService: ProposalDetailService
  ) {
    const proposalNumber = localStorage.getItem(Constants.PROPOSAL_NUMBER);
    if (!proposalNumber) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Detalhes da Proposta',
        () => this.routingService.navigateToHome(),
        null,
        'error'
      );
      return;
    }
    this.proposalNumber = proposalNumber;
  }

  public navigateToHome() {
    this.location.back();
  }

  public deniedOfferDescrition(proposalStatusModel: ProposalStatusModel): string {
    const statusCode = proposalStatusModel.statusCode;
    const title = 'Rejeitado por Políticas Internas de Crédito e Risco';
    switch (statusCode) {
      case '02':
        return title + ' - ' + this.proposalStatusModel.proposalStatusDetailedPtBr;
      default:
        return title;
    }
  }

  public getCssClass() {}

  public ngOnDestroy() {
    this.alive = false;
  }

  public getText() {}

  public async ngOnInit() {
    this.getPageData();
  }

  private getPageData() {
    const proposalResponse = this.proposalService.getProposalStatus(this.proposalNumber);
    const detailResponse = this.proposalDetailService.getDetailedProposal(this.proposalNumber);

    forkJoin([proposalResponse, detailResponse])
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => {
          this.proposalStatusModel = res[0];
          this.detailedProposal = res[1];
          this.setCardImage();
          this.setCardTitle();
          this.success = true;
        },
        (err) => {
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_PROPOSAL_DATA,
            'Api Fiserv Online - Detalhes da Proposta',
            () => this.routingService.navigateToHome(),
            null,
            'error'
          );
          return;
        }
      );
  }

  private setCardTitle(): void {
    if (this.proposalStatusModel && this.proposalStatusModel.proposalStatusDetailedPtBr) {
      if (this.proposalStatusModel.proposalStatusDetailedPtBr === 'Rejeitado Crédito') {
        this.cardTitle = this.deniedOfferDescrition(this.proposalStatusModel);
    } else {
      this.cardTitle = this.proposalStatusModel.proposalStatusDetailedPtBr;
    } 
  } else {
      this.cardTitle = 'Em análise';
    }
  }

  private setCardImage(): void {
    const baseUrl = '/assets/images/proposal-status/';

    switch (this.proposalStatusModel.proposalStatus) {
      case ProposalStatusEnum.APPROVED:
        this.imagePath = baseUrl + 'approved.png';
        break;

      case ProposalStatusEnum.DENIED:
        this.imagePath = baseUrl + 'rejected.png';
        break;

      case ProposalStatusEnum.DENIED_INTEGRATOR:
        this.imagePath = baseUrl + 'rejected.png';
        break;

      case ProposalStatusEnum.IN_ANALYSIS:
        this.imagePath = baseUrl + 'pending.png';
        break;

      case ProposalStatusEnum.AWAITING_PAYMENT:
        if (this.proposalStatusModel.paymentStatus === 'NOT_APPLICABLE') {
          this.imagePath = baseUrl + 'pending.png';
          break;
        }
        this.imagePath = baseUrl + 'awaiting-payment.png';
        break;

      default:
        this.imagePath = baseUrl + 'pending.png';
        break;
    }
  }
}
