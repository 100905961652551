import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take, takeWhile } from 'rxjs/operators';
import { BuildingTypeEnum } from 'src/app/order/enums/building-type-enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { CepService } from 'src/app/order/services/external/cep/cep.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DeliveryAddressModel, MailingAddressModel, Proposal, RegistrationDataAddressModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { AddressComponent } from '../../address/address.component';
import { FormStep } from '../../form-step';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-mailing-address',
  templateUrl: './mailing-address.component.html',
  styleUrls: ['./mailing-address.component.scss'],
})
export class MailingAddressComponent extends FormStep implements OnInit, AfterContentChecked {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public mailingAddressModel: MailingAddressModel;
  public deliveryAddress: DeliveryAddressModel;
  public commercialAddress: RegistrationDataAddressModel;
  public fields: FdFieldConfigs;
  public lastZipCodeValue: string;
  public successCepCall = false;
  public genericZipCode = false;
  public proposal: Proposal;

  @ViewChild(AddressComponent)
  public addressComponent: AddressComponent;

  protected STEP_NUMBER = 0;
  private readonly CEP_REGEX = /[0-9]{8}/;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private cdRef: ChangeDetectorRef
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public createFields(): FdFieldConfigs {
    return {
      ...this.addressComponent.getFields(),
      referencePoint: {
        label: 'Ponto de referência',
        controlName: 'referencePoint',
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      addressForm: this.addressComponent.getFormGroup(),
    });
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.registrationDataAddress || !this.proposal.deliveryAddress) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Registro de Entrega',
        () => this.back(),
        null,
        'error'
      );
    }
    this.commercialAddress = this.proposal.registrationDataAddress;
    this.deliveryAddress = this.proposal.deliveryAddress;
  }

  protected persistData() {
    if (!this.addressComponent.successCepCall) {
      return Promise.resolve(false);
    }

    const rawValue = this.formGroup.getRawValue();

    const mailingAddress: MailingAddressModel = {
      ...rawValue.addressForm,
      genericZipCode: this.addressComponent.genericZipCode,
    };
    return this.dataStore.updateProposal({ mailingAddress });
  }

  protected updateFields(proposal: Proposal) {
    this.addressComponent.setAddress(proposal.mailingAddress);
  }
}
