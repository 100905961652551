import { Component, Input, OnInit } from '@angular/core';
import { BusinessDataDetails } from '../../models/child-models/business-data-details.model';
import { CommercialAddressDetails } from '../../models/child-models/commercial-address-details.model';

@Component({
  selector: 'app-business-data-detail',
  templateUrl: './business-data-detail.component.html',
  styleUrls: ['./business-data-detail.component.scss'],
})
export class BusinessDataDetailComponent {
  @Input()
  public businessData: BusinessDataDetails;

  @Input()
  public commercialAddressDetails: CommercialAddressDetails;

  constructor() {}
}
