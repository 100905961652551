<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Contato de TI</h4>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.contactName">
      </fd-input>
    </div>

    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.contactMobileNumber">
      </fd-input>
    </div>

  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
