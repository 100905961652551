export class BranchOfficesModel {
  public hasBranchOffices: boolean;
  public cnpjBranch: string;
  public cnpjBranch2: string;
  public cnpjBranch3: string;
  public cnpjBranch4: string;
}

// export class BranchOfficeCnpj {
//   cnpj: string;
// }
