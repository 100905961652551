import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { finalize, takeWhile, tap } from 'rxjs/operators';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { OfferDataDetailsModel, OfferDataTypeModel, Proposal } from 'src/app/shared/models/proposal';
import { ActivitiesStatusEnum } from 'src/app/shared/models/response/response-cnae.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

import { OfferTypeEnum } from 'src/app/order/enums/offer-type.enum';
import { PrepaymentService } from 'src/app/order/services/external/prepayment/prepayment.service';
import { ErrorService } from 'src/app/order/services/internal/error/error.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { CheckPrepaymentModel } from 'src/app/shared/models/response/response-check-prepayment.model';
import { RevenueRangeModel } from 'src/app/shared/models/response/response-revenue-range.model';
import { ConfigurationModel, SerproValidationStrategyEnum } from 'src/app/start/models/configuration/configuration.model';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent extends FormStep implements OnInit {
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get cnaeDropdown() {
    return this.fields.cnae as FdSelectConfig;
  }

  public fields: FdFieldConfigs;
  public documentCategory: DocumentCategory;
  public proposal: Proposal;
  public isCnaeLoaded: Subject<boolean> = new Subject();
  public offerDataDetailsModel: OfferDataDetailsModel;
  public revenueRangeList: Item[];
  public restrictedCnaeSelected = false;
  public eCommerceExceptionCnaeSelected = false;

  public includeProfessionalLicence: boolean;

  public revenueRangeModel: RevenueRangeModel[];

  protected STEP_NUMBER = 2;

  public configuration: ConfigurationModel;

  constructor(
    private formBuilder: FormBuilder,
    private cnaeService: CnaeService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private errorService: ErrorService,
    private prepaymentService: PrepaymentService,
    private _cdr: ChangeDetectorRef
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }
  public async ngOnInit() {
    await super.ngOnInit();

    this.configuration = this.configurationService.getConfigurationFromSessionStorage();

    this._cdr.detectChanges();

    this.getCampaignList();
    this.verifyIfCnaeSelectShouldBeDisabled();
    this.getRevenueRangeList();

    this.isProfessionalLicenceMandatory();
    this.isPf();
  }

  public checkJumpAllECommerceSteps() {
    if (!this.eCommerceExceptionCnaeSelected) {
      this.dataStore.deleteECommerceProperties();
    }
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.E_COMMERCE_FUNCTIONALITIES.order,
      this.eCommerceExceptionCnaeSelected
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.GATEWAY_ANTIFRAUD_INFORMATION.order,
      this.eCommerceExceptionCnaeSelected
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.BRANCH_OFFICES.order,
      this.eCommerceExceptionCnaeSelected
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.BUSINESS_PROSPECTION.order,
      this.eCommerceExceptionCnaeSelected
    );

    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.ENTERPRISE_ADDITIONAL_QUESTIONS.order,
      this.eCommerceExceptionCnaeSelected
    );
  }

  public checkJumpAllProfessionalLicenseSteps() {
    const noSelected = !this.includeProfessionalLicence;

    if (noSelected) {
      this.dataStore.deleteProfessionalLicenseProperties();
    }
    this.setStepJump(
      orderRoutingDefinitions.offer.stepNumber,
      orderRoutingDefinitions.offer.routes.PROFESSIONAL_LICENSE_REGISTER.order,
      noSelected
    );

    this.setStepJump(orderRoutingDefinitions.offer.stepNumber, orderRoutingDefinitions.offer.routes.OPERATING_LICENSE.order, noSelected);

    this.setStepJump(orderRoutingDefinitions.offer.stepNumber, orderRoutingDefinitions.offer.routes.INVOICE.order, noSelected);

    this.setStepJump(orderRoutingDefinitions.offer.stepNumber, orderRoutingDefinitions.offer.routes.PROFESSIONAL_COUNCIL.order, noSelected);
  }

  public checkJumpAllCnaeSteps() {
    if (!this.restrictedCnaeSelected) {
      this.dataStore.deleteComplianceProperties();
    }
    this.setStepJump(
      orderRoutingDefinitions.enterpriseComplementaryInformation.stepNumber,
      orderRoutingDefinitions.enterpriseComplementaryInformation.routes.HISTORY_PARTNER_ADMIN_AND_LEGAL_ISSUES.order,
      !this.restrictedCnaeSelected
    );
    this.setStepJump(
      orderRoutingDefinitions.enterpriseComplementaryInformation.stepNumber,
      orderRoutingDefinitions.enterpriseComplementaryInformation.routes.BUSINESS_AFFILIATIONS.order,
      !this.restrictedCnaeSelected
    );
  }

  public async next() {
    this.submitted = true;
    if (this.isFormGroupValid()) {
      if (this.formControls.cnae.value) {
        const dropdownItems = this.dataStore.getDropdownItems(this.fields.cnae.controlName);
        const document = this.proposal.registrationDataPersonalInfo.cpfCnpj;
        const flagCnae = this.configuration.unwantedSecondaryCnaeValidationFlg;

        if (flagCnae && document.length === 14) {
          const cnaeValidationResult = await this.cnaeService.validateUnwantedSecondaryCnae(document).toPromise();
          if (!cnaeValidationResult) {
            this.dialogService.openDialog(Messages.UNWANTED_CNAE_SELECTED);
            return;
          }
        } else {
          const unwantedCnaeSelected = dropdownItems.filter(
            (x) =>
              x.value === this.formControls.cnae.value &&
              x.additionalProperties &&
              x.additionalProperties.status === ActivitiesStatusEnum.UNWANTED
          );
          if (unwantedCnaeSelected && unwantedCnaeSelected.length) {
            this.dialogService.openDialog(Messages.UNWANTED_CNAE_SELECTED);
            return;
          }
        }

        const restrictedCnaeSelected = dropdownItems.filter(
          (x) =>
            x.value === parseInt(this.formControls.cnae.value, 10) &&
            x.additionalProperties &&
            x.additionalProperties.status === ActivitiesStatusEnum.RESTRICTED
        );
        this.restrictedCnaeSelected = restrictedCnaeSelected && !!restrictedCnaeSelected.length;

        const eCommerceExceptionCnaeSelected = dropdownItems.filter(
          (x) => x.value === this.formControls.cnae.value && x.additionalProperties && x.additionalProperties.ecommerceExceptionCnae
        );
        this.eCommerceExceptionCnaeSelected = eCommerceExceptionCnaeSelected && !!eCommerceExceptionCnaeSelected.length;

        if (this.formControls.annualRevenue.value < 0.9999) {
          this.dialogService.openDialog(Messages.ANNUAL_REVENUE_MIN);
          return;
        }

        if (this.formControls.annualVolumeSalesCardGroup.value < 0.9999) {
          this.dialogService.openDialog(Messages.ANNUAL_VOLUME_SALES_CARD_GROUP);
          return;
        }

        if (this.formControls.averageTicket.value < 0.9999) {
          this.dialogService.openDialog(Messages.AVERANGE_TICKET);
          return;
        }

        if (this.formControls.averageTicket.value > this.formControls.annualRevenue.value) {
          this.dialogService.openDialog(Messages.AVERANGE_TICKET_BIGGER_THAN_ANNUAL_REVENUE);
          return;
        }

        if (this.revenueRangeModel && this.revenueRangeModel.length > 0) {
          const revenueRangeModel: RevenueRangeModel = this.revenueRangeModel.filter(
            (item) => item.idRange === this.formControls.revenueRange.value
          )[0];

          if (this.formControls.annualRevenue.value < revenueRangeModel.minAnnualBilling) {
            this.dialogService.openDialog(Messages.ANNUAL_REVENUE_INVALID);
            return;
          }
        }
      }
      this.persistDataFunction();
    }
  }

  public persistDataFunction() {
    this.persistData().then((persisted) => {
      if (persisted) {
        this.checkJumpAllCnaeSteps();
        this.checkJumpAllProfessionalLicenseSteps();
        this.checkJumpAllECommerceSteps();
        this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);

        this.wizardService.nextStep();
      }
    });
  }

  public validateIfHasPrepaymentRate(campaignId: string, idRange: string, cnaeNumber: string): void {
    this.prepaymentService
      .callCheckIfHasPrepayment(campaignId, idRange, cnaeNumber)
      .pipe(takeWhile(() => this.alive))

      .subscribe(
        (response: CheckPrepaymentModel) => {
          if (!response || !response.hasPrepaymentRate) {
            this.dialogService.openDialog(Messages.PREPAYMENT_VALIDATION_NOT_FOUND_RATE);
            return;
          } else {
            this.persistDataFunction();
          }
        },
        (error: HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.PREPAYMENT_CHECK_ERROR)
      );
  }

  public isPf(): boolean {
    return this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF;
  }

  public isProfessionalLicenceMandatory() {
    if (!this.isPf()) {
      this.includeProfessionalLicence = false;
      return;
    }

    this.proposalService.isProfessionalLicenceMandatory(this.proposal.serviceContract).subscribe(
      (response) => {
        if (response === null) {
          this.dialogService.openDialog(Messages.CONFIG_PROFESSIONAL_LOAD_FAIL);
          return;
        }
        this.includeProfessionalLicence = response.details;
      },
      (error: HttpErrorResponse) => this.errorService.handleXHRError(error, Messages.CONFIG_PROFESSIONAL_LOAD_FAIL)
    );
  }

  public getCnaeList(filterValue) {
    if (!filterValue || !filterValue.length) {
      return;
    }

    if (filterValue.length < 3) {
      this.cnaeDropdown.items = [];
      return;
    }

    if (!isNaN(filterValue)) {
      filterValue = Number(filterValue);
    }

    this.cnaeService
      .getCnaeList(filterValue, this.isPf())
      .pipe(takeWhile(() => this.alive))
      .pipe(tap((items) => this.dataStore.appendDropdownItems(this.fields.cnae.controlName, items)))
      .subscribe(
        (cnaeList) => {
          if (cnaeList !== null) {
            this.cnaeDropdown.items = [];
            this.cnaeDropdown.items.push(...cnaeList);
            this.isCnaeLoaded.next(true);
          }
        },
        () => {
          this.cnaeDropdown.items = [];
        }
      );
  }

  public getCampaignList() {
    const cpfCnpj = this.proposal.registrationDataPersonalInfo.cpfCnpj;
    let callGetCampignList: Observable<Item[]>;

    if (cpfCnpj && cpfCnpj.length === 14) {
      callGetCampignList = this.cnaeService.getCampaignListWithCnpj(cpfCnpj);
    } else {
      callGetCampignList = this.cnaeService.getCampaignList();
    }

    callGetCampignList
      .pipe(tap((items) => this.dataStore.appendDropdownItems(this.fields.campaign.controlName, items)))
      .pipe(takeWhile(() => this.alive))

      .subscribe(
        (data) => {
          if (data !== null) {
            (this.fields.campaign as FdSelectConfig).items = [];
            (this.fields.campaign as FdSelectConfig).items.push(...data);
          }
        },
        (error: HttpErrorResponse) => {
          const serializeError = error.error ? (error.error as ApiResultModel) : null;

          if (serializeError && serializeError.message) {
            this.dialogService.openDialogWithMessage(serializeError.message, () => this.back());
          } else {
            this.dialogService.openDialog(Messages.CAMPAIGN_NOT_FOUND, () => this.back());
          }
          return;
        }
      );
  }

  public async getRevenueRangeList() {
    const serproValidationStrategy = await this.getSerproValidationStrategy();
    if (!this.isPf() && serproValidationStrategy === 'PERSONAL_INFO_PAGE' && this.configuration?.cnaeValidation) {
      this.formGroup.value.cnae = this.proposal.registrationDataPersonalInfo.cnaeList[0].cnaeNumber;
    }
    if (!this.formGroup.value.campaign || !this.formGroup.value.cnae) {
      return;
    }

    this.cnaeService
      .getRevenueRangeList(this.formGroup.value.campaign, this.formGroup.value.cnae)
      .pipe(takeWhile(() => this.alive))
      .pipe(tap((items) => this.dataStore.appendDropdownItems(this.fields.revenueRange.controlName, items)))
      .subscribe(
        (data) => {
          if (data !== null) {
            this.revenueRangeList = data;
            (this.fields.revenueRange as FdSelectConfig).items = [];
            (this.fields.revenueRange as FdSelectConfig).items.push(...data);
          }
        },
        () => {
          (this.fields.revenueRange as FdSelectConfig).items = [];
        }
      );
    this.loadRevenueRangeListObject();
  }

  public loadRevenueRangeListObject() {
    this.loadingService.adicionaLoadingEvent('getRevenueRangeListObject');
    this.cnaeService
      .getRevenueRangeListObject(this.formGroup.value.campaign, this.formGroup.value.cnae)
      .pipe(finalize(() => this.loadingService.removeLoadingEvent('getRevenueRangeListObject')))
      .subscribe((response) => {
        this.revenueRangeModel = response;
      });
  }

  public createFields(): FdFieldConfigs {
    return {
      cnae: {
        label: 'Selecione o CNAE',
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        controlName: 'cnae',
        messages: {
          required: 'Informe o CNAE',
          invalid: 'CNAE inválido',
        },
      },
      annualRevenue: {
        label: 'Informe o faturamento anual',
        controlName: 'annualRevenue',
        messages: {
          required: 'Informe o faturamento anual',
          invalid: 'Faturamento inválido',
        },
      },
      averageTicket: {
        label: 'Informe o ticket médio',
        controlName: 'averageTicket',
        messages: {
          required: 'Informe o ticket médio',
          invalid: 'Ticket médio inválido',
        },
      },
      annualVolumeSalesCardGroup: {
        label:
          'Informe o faturamento total de Cartões do Grupo da Empresa (Caso esse dado não se aplique, informe apenas o valor deste\
             Estabelecimento)',
        controlName: 'annualVolumeSalesCardGroup',
        messages: {
          required: 'Informe o faturamento total de cartão do Grupo',
          invalid: 'Faturamento total de cartão do Grupo inválido',
        },
      },
      campaign: {
        label: 'Selecione a Campanha',
        controlName: 'campaign',
        items: [],
        messages: {
          required: 'Selecione uma campanha',
          invalid: 'Campanha inválida',
        },
      },
      revenueRange: {
        label: 'Faturamento anual no Cartão de Crédito',
        controlName: 'revenueRange',
        items: [],
        messages: {
          required: 'Selecione um range',
          invalid: 'Range inválido',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      cnae: ['', Validators.required],
      annualRevenue: ['', Validators.required],
      campaign: ['', Validators.required],
      revenueRange: ['', Validators.required],
      averageTicket: ['', Validators.required],
      annualVolumeSalesCardGroup: ['', Validators.required],
    });
  }

  public async checkJumpOfferType() {
    let offerDataType: OfferDataTypeModel;

    await this.configurationService
      .getConfiguration()
      .toPromise()
      .then((data: ConfigurationModel) => {
        if (!data.ableSaleAccreditationType || !data.ableRentAccreditationType) {
          this.setStepJump(orderRoutingDefinitions.offer.stepNumber, orderRoutingDefinitions.offer.routes.OFFER_TYPE.order);

          if (data.ableSaleAccreditationType) {
            offerDataType = {
              offerType: OfferTypeEnum.SALES,
            };
          } else {
            offerDataType = {
              offerType: OfferTypeEnum.RENTAL,
            };
          }
        } else {
          this.setStepJump(orderRoutingDefinitions.offer.stepNumber, orderRoutingDefinitions.offer.routes.OFFER_TYPE.order, false);
        }
      });

    return offerDataType;
  }

  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.registrationDataPersonalInfo) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Dados Cadastrais',
        () => this.back(),
        null,
        'error'
      );
      return;
    }
    this.documentCategory = this.proposal.registrationDataPersonalInfo.documentCategory;
  }

  protected async persistData(): Promise<boolean> {
    const offerDataDetails: OfferDataDetailsModel = {
      ...this.formGroup.getRawValue(),
      documentCategory: this.documentCategory,
      restrictedCnaeSelected: this.restrictedCnaeSelected,
      eCommerceExceptionCnaeSelected: this.eCommerceExceptionCnaeSelected,
    };

    let mdrFeeModel = this.proposal.mdrFeeModel;

    if (this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.cnae) {
      offerDataDetails.cnaeChanged = true;
      if (this.proposal.offerDataDetails.cnae !== offerDataDetails.cnae) {
        mdrFeeModel = null;
        this.proposal.mdrFeeModel = mdrFeeModel;
      }
    }

    if (this.revenueRangeList && this.revenueRangeList.length) {
      offerDataDetails.revenueRangeDescription = this.revenueRangeList.filter((x) => x.value === offerDataDetails.revenueRange)[0].label;
    }

    let professionalLicense = this.proposal.professionalLicense;
    if (this.includeProfessionalLicence === false) {
      this.proposal.professionalLicense = null;
      professionalLicense = this.proposal.professionalLicense;
    }

    const offerDataType: OfferDataTypeModel = await this.checkJumpOfferType();

    return this.dataStore.updateProposal({
      offerDataDetails,
      professionalLicense,
      offerDataType,
      mdrFeeModel,
    });
  }

  protected updateFields(proposal: Proposal) {
    this.offerDataDetailsModel = proposal.offerDataDetails;

    const cnaeList: Item[] = this.dataStore.getDropdownItems(this.fields.cnae.controlName);

    if (
      cnaeList &&
      this.offerDataDetailsModel &&
      this.offerDataDetailsModel.cnae &&
      this.offerDataDetailsModel.campaign &&
      this.offerDataDetailsModel.annualRevenue &&
      this.offerDataDetailsModel.revenueRange
    ) {
      if (cnaeList.length === 0) {
        this.getCnaeList(this.offerDataDetailsModel.cnae.toString());
      } else {
        this.cnaeDropdown.items = cnaeList;
      }

      this.formControls.cnae.setValue(this.offerDataDetailsModel.cnae);

      const cnae = this.cnaeDropdown.items.find((x) => x.value === this.offerDataDetailsModel.cnae);
      if (cnae) {
        cnae.selected = true;
      }

      this.formControls.annualRevenue.setValue(this.offerDataDetailsModel.annualRevenue);
      this.formControls.campaign.setValue(this.offerDataDetailsModel.campaign);
      this.formControls.revenueRange.setValue(this.offerDataDetailsModel.revenueRange);
      this.formControls.averageTicket.setValue(this.offerDataDetailsModel.averageTicket);
      this.formControls.annualVolumeSalesCardGroup.setValue(this.offerDataDetailsModel.annualVolumeSalesCardGroup);
    }

    this.autoCompleteCnae(proposal);
  }

  private autoCompleteRevenueRange() {
    if (this.formControls.annualRevenue.value && this.revenueRangeModel) {
      const revenueRangeModel: RevenueRangeModel = this.revenueRangeModel.filter(
        (item) =>
          this.formControls.annualRevenue.value >= item.minAnnualBilling && this.formControls.annualRevenue.value <= item.maxAnnualBilling
      )[0];

      if (revenueRangeModel) {
        this.formControls.revenueRange.setValue(revenueRangeModel.idRange);
      } else {
        this.formControls.revenueRange.setValue('');
      }
    }
  }

  private async autoCompleteCnae(proposal: Proposal) {
    // Caso o tipo de pessoa tenha sido alterado, limpa a lista de cnaes e o valor do formcontrol.
    if (!this.proposal.offerDataDetails || this.documentCategory !== this.proposal.offerDataDetails.documentCategory) {
      this.cnaeDropdown.items = [];
      this.formControls.cnae.setValue('');
      this.getCnaeList('');
    }

    if (proposal.registrationDataPersonalInfo.cnaeList.length === 0) {
      return;
    }

    const hasCnaeValue = this.formControls.cnae.value;
    const serproValidationStrategy = await this.getSerproValidationStrategy();

    if (serproValidationStrategy === SerproValidationStrategyEnum.PERSONAL_INFO_PAGE) {
      this.cnaeService
        .getCnaeList(this.proposal.registrationDataPersonalInfo.cnaeList[0].cnaeNumber.toString(), this.isPf())
        .pipe(takeWhile(() => this.alive))
        .pipe(tap((items) => this.dataStore.appendDropdownItems(this.fields.cnae.controlName, items)))
        .subscribe(
          (cnaeList) => {
            if (cnaeList !== null) {
              const selectedCnae = this.cnaeDropdown.items?.filter((cnae) => cnae?.selected);
              const principalCnae = cnaeList[0].value;

              if (!hasCnaeValue || selectedCnae !== principalCnae) {
                this.dataStore.clearProposalKeyFromLocalStorage(this.fields.cnae.controlName);
                this.formControls.cnae.setValue(principalCnae);
                this.cnaeDropdown.items = cnaeList;
                cnaeList[0].selected = true;
                this.isCnaeLoaded.next(true);
              }
            }
          },
          () => (this.cnaeDropdown.items = [])
        );
    }
  }

  private async getSerproValidationStrategy(): Promise<string> {
    const configurationFromLocalStorage = this.dataStoreService.getConfiguration();

    if (configurationFromLocalStorage) {
      return JSON.parse(configurationFromLocalStorage)?.serproValidationStrategy;
    }

    const configuration = await this.configurationService.getConfiguration().toPromise();
    return configuration.serproValidationStrategy;
  }

  public async verifyIfCnaeSelectShouldBeDisabled() {
    const serproValidationStrategy = await this.getSerproValidationStrategy();
    if (!this.isPf() && serproValidationStrategy === 'PERSONAL_INFO_PAGE' && this.configuration?.cnaeValidation) {
      this.formControls.cnae.disable();
    }
  }
}
