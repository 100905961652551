import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefBusinessInformationModel } from '../models/tef-business-information.model';
import { GoodCardModuleEnum } from 'src/app/order/enums/card-module.enum';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-tef-business-information',
  templateUrl: './tef-business-information.component.html',
  styleUrls: ['./tef-business-information.component.scss'],
})
export class TefBusinessInformationComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;
  public tefBusinessInformationModel: TefBusinessInformationModel;

  public goodCardModuleOptions: Item[] = [
    {
      label: 'POS',
      value: GoodCardModuleEnum.POS,
    },
    {
      label: 'TEF',
      value: GoodCardModuleEnum.TEF,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.goodCardModule.setValue(this.goodCardModuleOptions.filter((x) => x.selected).map((x) => x.value));
  }

  public next(): void {
    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefBusinessInformation: TefBusinessInformationModel = {
      ...this.formGroup.getRawValue(),
      goodCardModule: this.goodCardModuleOptions.filter((x) => x.selected).map((x) => x.value),
    };

    return this.dataStore.updateProposal({ tefBusinessInformation });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      hasGasStation: ['', Validators.required],
      goodCardModule: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefBusinessInformationModel = proposal.tefBusinessInformation;

    if (this.tefBusinessInformationModel) {
      this.formControls.hasGasStation.setValue(this.tefBusinessInformationModel.hasGasStation);
      this.formControls.goodCardModule.setValue(this.tefBusinessInformationModel.goodCardModule);
      this.goodCardModuleOptions.map((x) => (x.selected = this.tefBusinessInformationModel.goodCardModule.indexOf(x.value) > -1));
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      hasGasStation: {
        controlName: 'hasGasStation',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      goodCardModule: {
        controlName: 'goodCardModule',
      },
    };
  }
}
