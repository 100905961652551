import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {PrepaymentDataModel} from "../models/prepayment-data.model";

@Component({
  selector: 'app-prepayment-table',
  templateUrl: './prepayment-table.component.html',
  styleUrls: ['./prepayment-table.component.scss'],
})
export class PrepaymentTableComponent implements OnInit {
  @Input()
  public data: PrepaymentDataModel[];

  public dataSource: MatTableDataSource<PrepaymentDataModel> = new MatTableDataSource();
  public displayedColumns = ['minAnnualBilling', 'maxAnnualBilling', 'suggestedAutoPrepayment'];

  constructor() {}

  public ngOnInit() {
    this.dataSource.data = this.data;
  }
}
