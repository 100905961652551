import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from './order/order.component';
import { LoginComponent } from './start/components/login/login.component';
import { PasswordRecoveryComponent } from './start/components/password-recovery/password-recovery.component';
import { LoginGuard } from './start/guards/login-guard';
import { StartComponent } from './start/start.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start/welcome',
    pathMatch: 'full',
  },
  {
    path: 'order',
    component: OrderComponent,
  },
  {
    path: 'start',
    component: StartComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    canActivate: [LoginGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
