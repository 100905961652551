import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/order/constants/constants';
import { environment } from 'src/environments/environment.local';
import { ConfigurationModel } from '../models/configuration/configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  public getConfiguration(): Observable<ConfigurationModel> {
    return this.http.get<ConfigurationModel>(`${environment.apiUrls.generalConfig}`);
  }

  public getAbleExternalSchedule(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.generalConfig}/external-schedule/able`);
  }

  public getConfigurationFromSessionStorage(): ConfigurationModel {
    return JSON.parse(localStorage.getItem(Constants.CONFIGURATION_KEY)) as ConfigurationModel;
  }

  public getAblePriceSimulator(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrls.generalConfig}/price-simulator/able`);
  }

  public setConfigurationOnSessionStorage(data: ConfigurationModel) {
    localStorage.setItem(Constants.CONFIGURATION_KEY, JSON.stringify(data));
  }
}
