import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-fd-confirm',
  templateUrl: './fd-confirm.component.html',
  styleUrls: ['./fd-confirm.component.scss'],
})
export class FdConfirmComponent {
  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<FdConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

  public handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn();
    }
    this.dialogRef.close();
  }

  public handleConfirmClick(): void {
    if (this.data.confirmCallbackFn) {
      this.data.confirmCallbackFn();
    }
    this.dialogRef.close();
  }
}

export interface ConfirmDialogData {
  title: string;
  description: string;
  confirmCallbackFn?: () => any;
  cancelCallbackFn?: () => any;
}

export enum ModalDefinitions {
  DEFAULT_MODAL_WIDTH = '450px',
}
