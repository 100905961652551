import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface FdTableDefinition {
  columnNames: string[];
  footerContent?: string;
  items: any[];
}

@Component({
  selector: 'fd-table',
  templateUrl: './fd-table.component.html',
  styleUrls: ['./fd-table.component.scss'],
})
export class FdTableComponent implements OnInit, OnChanges {
  @Input()
  public tableDefinition: FdTableDefinition;

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public footerHTML: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  public getCellContent(columnIndex: number, element: any) {
    const cellContentArr = Object.values(element).filter((val, index, arr) => index === columnIndex);

    if (cellContentArr && cellContentArr.length) {
      return cellContentArr[0];
    } else {
      throw new Error('Error when trying to get cell content. Check if "columnNames" and "items" match on size');
    }
  }

  get footerText() {
    return this.tableDefinition.footerContent;
  }

  get hasFooter() {
    return this.tableDefinition && this.tableDefinition.footerContent;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.tableDefinition && changes.tableDefinition.currentValue) {
      this.tableDefinition = changes.tableDefinition.currentValue;
      this.bindItems();
    }
  }

  public bindItems() {
    if (!this.tableDefinition || !this.tableDefinition.items) {
      return;
    }

    this.dataSource = new MatTableDataSource(this.tableDefinition.items);
    this.footerHTML = this.sanitizer.bypassSecurityTrustHtml(this.tableDefinition.footerContent);

    if (this.tableDefinition.columnNames && this.tableDefinition.columnNames.length) {
      this.displayedColumns = this.tableDefinition.columnNames;
    } else {
      this.tableDefinition.items.forEach((row) => {
        this.displayedColumns = Object.keys(row);
      });
    }
  }

  public async ngOnInit() {
    this.bindItems();
  }
}
