<table mat-table [dataSource]="dataSource" class="fd-table">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  <ng-container *ngFor="let column of displayedColumns; let columnIndex = index" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef class="fd-table__header"> {{column}} </th>
    <td mat-cell class="fd-table__cell" *matCellDef="let element" [innerHTML]="getCellContent(columnIndex, element)"></td>
    <ng-container *ngIf="hasFooter">
      <td mat-footer-cell class="fd-table__footer-cell" [ngClass]="{'hidden' : columnIndex > 0}"
        [attr.colspan]='columnIndex === 0 ? displayedColumns.length : null' *matFooterCellDef>
        <div [innerHTML]="footerHTML"></div>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-container *ngIf="hasFooter">
    <tr mat-footer-row *matFooterRowDef="displayedColumns" class="fd-table__footer-row"></tr>
  </ng-container>
</table>