<h4 class="fd-subtitle">Informe o endereço comercial:</h4>
<app-address></app-address>

<ng-container *ngIf="fields">
  <div>
    <fd-select [parentForm]="formGroup" [field]="fields.buildingType"></fd-select>
  </div>
  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.referencePoint"></fd-input>
  </div>
  <h4 class="fd-subtitle">Deseja utilizar esse endereço para receber a maquininha?</h4>
  <div>
    <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.useAsDeliveryAddress"> </fd-radio-button>
  </div>

  <h4 class="fd-subtitle">Deseja utilizar esse endereço para correspondência?</h4>
  <div>
    <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.useAsMailingAddress"> </fd-radio-button>
  </div>
</ng-container>

<fd-button fdSecondary id="btnCancel" [content]="'cancelar'" (trigger)="back()"> </fd-button>
<fd-button [disabled]="!addressComponent?.successCepCall" id="btnNext" [content]="'avançar'" (trigger)="next()"> </fd-button>
