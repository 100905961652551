import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { getRouteByOrder, getRouteNameByOrder, orderRoutingDefinitions } from '../../../routing/routing-definitions';
import { RoutingService } from '../routing/routing.service';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  get currentStep(): StepInfo {
    return this._currentStep;
  }

  set currentStep(stepInfo: StepInfo) {
    this._currentStep = stepInfo;
    this._currentStep$.next(this._currentStep);
  }

  get grayScale(): boolean {
    return this._grayScale;
  }
  public readonly PROFILE_SUBSTEP = 1;
  public readonly PROFILE_ADDITIONAL_SUBSTEP = 2;
  public stepsInfo: StepInfo[] = [];
  public stepInfoIndex: number = 0;

  private _currentStep: StepInfo;
  private _grayScale: boolean = false;

  private _currentStep$: BehaviorSubject<StepInfo>;

  constructor(private routingService: RoutingService, private dataStoreService: DataStoreService) {
    this.createStepsInfo();
    this._currentStep = this.stepsInfo[this.stepInfoIndex];
    this._currentStep$ = new BehaviorSubject(this._currentStep);
  }

  public getStepsInfo(): StepInfo[] {
    return this.stepsInfo;
  }

  public createStepsInfo() {
    this.stepsInfo = [];
    Object.values(orderRoutingDefinitions).forEach((step) => {
      Object.values(step.routes).forEach((route) => {
        this.stepsInfo.push({
          step: step.stepNumber,
          subStep: route.order,
          name: route.name,
        });
      });
    });
  }

  public updateGrayScale(): boolean {
    this._grayScale = !this._grayScale;
    return this._grayScale;
  }

  public getCurrentStep(): Observable<StepInfo> {
    return this._currentStep$;
  }

  public resumeStep(): void {
    this.currentStep = this.currentStep;
    this.routingService.navigate(this.currentStep);
  }

  public setStepJumpInternal(step: number, order: number, status: boolean = true): RouteJump[] {
    const routeProps = getRouteByOrder(step, order);

    const savedJumpSteps = this.dataStoreService.getLocalStorageObject(Constants.JUMP_STEPS_LOCALSTORAGE_KEY) || [];

    if (savedJumpSteps && savedJumpSteps.length) {
      const routeItem = savedJumpSteps.find((item) => item.route === routeProps.name);

      if (routeItem) {
        routeItem.jump = status;
        return savedJumpSteps;
      }
    }

    savedJumpSteps.push({
      route: routeProps.name,
      jump: status,
    });
    return savedJumpSteps;
  }

  public nextStep(): StepInfo {
    if (this.stepInfoIndex < this.stepsInfo.length - 1) {
      this.stepInfoIndex++;
      this.currentStep = this.stepsInfo[this.stepInfoIndex];

      const routeProps = getRouteByOrder(this._currentStep.step, this._currentStep.subStep);

      const jumpSteps = this.dataStoreService.getLocalStorageObject(Constants.JUMP_STEPS_LOCALSTORAGE_KEY);

      if (jumpSteps && jumpSteps.some((x) => x.route === routeProps.name && !!x.jump)) {
        this.nextStep();
        return;
      }

      this.routingService.navigate(this.currentStep);
      return this.currentStep;
    }
  }

  public previousStep(): StepInfo {
    if (this.stepInfoIndex > 0) {
      this.stepInfoIndex--;
      this.currentStep = this.stepsInfo[this.stepInfoIndex];

      const routeProps = getRouteByOrder(this._currentStep.step, this._currentStep.subStep);

      const jumpSteps = this.dataStoreService.getLocalStorageObject(Constants.JUMP_STEPS_LOCALSTORAGE_KEY);

      if (jumpSteps && jumpSteps.some((x) => x.route === routeProps.name && !!x.jump)) {
        this.previousStep();
      }

      this.routingService.navigate(this.currentStep);
      return this.currentStep;
    }
  }

  public updateStep(url: string) {
    const name = url.replace('/', '');
    const stepInfo = this.stepsInfo.find((si) => si.name === name);

    this.stepInfoIndex = this.stepsInfo.findIndex((si) => si.name === name);

    if (stepInfo) {
      this.currentStep = stepInfo;
    }
  }

  public goToSpecificStep(step: number, order: number): void {
    this.stepInfoIndex = this.stepsInfo.findIndex((si) => si.subStep === order && si.step === step);
    this.currentStep = this.stepsInfo[this.stepInfoIndex];
    this.routingService.navigate(this.currentStep);
  }

  public goToClosure(): void {
    this._currentStep = this.stepsInfo[this.stepsInfo.length - 1];
    this._currentStep$.next(this._currentStep);

    const path = getRouteNameByOrder(this._currentStep.step, this._currentStep.subStep);
    this.routingService.navigateTo(path);
  }
}

export interface StepInfo {
  step: number;
  hasSensitiveData?: boolean;
  subStep: number;
  name: string;
}

export interface RouteJump {
  route: string;
  jump: boolean;
}
