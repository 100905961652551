import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProfessionalTypeEnum } from 'src/app/order/enums/professional-type.enum';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

@Component({
  selector: 'app-professional-license-register',
  templateUrl: './professional-license-register.component.html',
  styleUrls: ['./professional-license-register.component.scss'],
})
export class ProfessionalLicenseRegisterComponent extends FormStep implements OnInit {
  get profTypeSelect() {
    return this.formControls.licenseTypeName.value;
  }

  public options: Item[] = [
    {
      label: 'Nota Fiscal',
      value: ProfessionalTypeEnum.INVOICE,
    },
    {
      label: 'Alvará de Funcionamento',
      value: ProfessionalTypeEnum.OPERATING_LICENSE,
    },
    {
      label: 'Registro Profissional',
      value: ProfessionalTypeEnum.PROFESSIONAL_COUNCIL,
    },
  ];

  protected STEP_NUMBER: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public updateFields(proposal: Proposal) {
    if (proposal && proposal.professionalLicense) {
      this.formControls.licenseTypeName.setValue(proposal.professionalLicense.licenseTypeName);
    }
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.setStepJump(
        orderRoutingDefinitions.offer.stepNumber,
        orderRoutingDefinitions.offer.routes.INVOICE.order,
        this.profTypeSelect !== ProfessionalTypeEnum.INVOICE
      );
      this.setStepJump(
        orderRoutingDefinitions.offer.stepNumber,
        orderRoutingDefinitions.offer.routes.PROFESSIONAL_COUNCIL.order,
        this.profTypeSelect !== ProfessionalTypeEnum.PROFESSIONAL_COUNCIL
      );
      this.setStepJump(
        orderRoutingDefinitions.offer.stepNumber,
        orderRoutingDefinitions.offer.routes.OPERATING_LICENSE.order,
        this.profTypeSelect !== ProfessionalTypeEnum.OPERATING_LICENSE
      );

      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }
  protected proposalGetCallback(): void {}

  protected createFields(): FdFieldConfigs {
    return {
      licenseTypeName: {
        items: this.options,
        controlName: 'licenseTypeName',
        messages: {
          required: 'Informe o tipo de licença profissional',
          invalid: 'Tipo de de licença profissional inválido',
        },
      },
    };
  }
  protected persistData(): Promise<boolean> {
    let professionalLicense;

    if (this.proposal && this.proposal.professionalLicense) {
      professionalLicense = this.proposal.professionalLicense;
      professionalLicense.licenseTypeName = this.formControls.licenseTypeName.value;
    } else {
      professionalLicense = {
        ...this.formGroup.getRawValue(),
      };
    }

    return this.dataStore.updateProposal({ professionalLicense });
  }

  protected createFormGroup(): FormGroup {
    return this.formBuilder.group({
      licenseTypeName: ['', Validators.required],
    });
  }
}
