<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div class="gateway-description-container">
      <h4 class="gateway-subtitle fd-subtitle">Utiliza gateway ou integrador?</h4>
      <fa-icon
        matTooltipClass="fd-input__tooltip-dialog"
        class="tooltip-icon"
        icon="info-circle"
        size="1x"
        mat-button
        [matTooltip]="getGatewayTooltipText()"
        #tooltip="matTooltip"
        (click)="showTooltipAndStopPropagation()"
      ></fa-icon>
    </div>

    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasGateway"> </fd-radio-button>
    </div>

    <div *ngIf="hasGateway">
      <fd-input [parentForm]="formGroup" [field]="fields.gatewayDescription"> </fd-input>
    </div>

    <h4 class="fd-subtitle">Estabelecimento possui antifraude contratado?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasAntifraud"> </fd-radio-button>
    </div>

    <div *ngIf="hasAntifraud">
      <fd-input [parentForm]="formGroup" [field]="fields.antifraudDescription"> </fd-input>
    </div>

    <div *ngIf="hasAntifraud">
      <h4 class="fd-subtitle">O seguro antifraude contratado cobre chargeback?</h4>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasChargebackCoverage"> </fd-radio-button>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
