import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseBlackListModel } from 'src/app/shared/models/response/response-black-list.model';
import { environment } from 'src/environments/environment.local';
import { BlackListModel } from '../../../../shared/models/BlackList.model';

@Injectable({
  providedIn: 'root',
})
export class BlackListService {
  constructor(private http: HttpClient) {}

  public validateBlackList(data: BlackListModel): Observable<ResponseBlackListModel[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<ResponseBlackListModel[]>(`${environment.apiUrls.blackList}`, data);
  }
}
