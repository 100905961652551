import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefBrowserItContactModel } from '../models/tef-browser-it-contact.model';
import { GoodCardModuleEnum } from 'src/app/order/enums/card-module.enum';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { PhoneValidator } from 'src/app/order/validators/phone-validator';

@Component({
  selector: 'app-tef-browser-it-contact',
  templateUrl: './tef-browser-it-contact.component.html',
  styleUrls: ['./tef-browser-it-contact.component.scss'],
})
export class TefBrowserItContactComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public tefBrowserItContactModel: TefBrowserItContactModel;

  public goodCardModuleOptions: Item[] = [
    {
      label: 'POS',
      value: GoodCardModuleEnum.POS,
    },
    {
      label: 'TEF',
      value: GoodCardModuleEnum.TEF,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.goodCardModule.setValue(this.goodCardModuleOptions.filter((x) => x.selected).map((x) => x.value));
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefBrowserItContact: TefBrowserItContactModel = {
      ...this.formGroup.getRawValue(),
      goodCardModule: this.goodCardModuleOptions.filter((x) => x.selected).map((x) => x.value),
      contactMobileNumbers: [this.formControls.contactMobileNumber1.value],
    };

    return this.dataStore.updateProposal({ tefBrowserItContact });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      contactName: ['', Validators.required],
      contactMobileNumber1: ['', [Validators.required, PhoneValidator]],
      hasGasStation: ['', Validators.required],
      goodCardModule: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefBrowserItContactModel = proposal.tefBrowserItContact;

    if (this.tefBrowserItContactModel) {
      this.formControls.contactName.setValue(this.tefBrowserItContactModel.contactName);
      this.formControls.contactMobileNumber1.setValue(this.tefBrowserItContactModel.contactMobileNumbers[0]);
      this.formControls.hasGasStation.setValue(this.tefBrowserItContactModel.hasGasStation);
      this.formControls.goodCardModule.setValue(this.tefBrowserItContactModel.goodCardModule);
      this.goodCardModuleOptions.map((x) => (x.selected = this.tefBrowserItContactModel.goodCardModule.indexOf(x.value) > -1));
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      contactName: {
        label: 'Nome',
        controlName: 'contactName',
        messages: {
          required: 'Informe o nome do contato',
        },
      },
      contactMobileNumber1: {
        label: 'Celular',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'contactMobileNumber1',
        messages: {
          required: 'Informe o celular',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
      hasGasStation: {
        controlName: 'hasGasStation',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
      goodCardModule: {
        controlName: 'goodCardModule',
      },
    };
  }
}
