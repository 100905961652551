export class CardDefinitions {
  public static readonly GENERIC_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  public static readonly GENERIC_CVV = [/[0-9]/, /[0-9]/, /[0-9]/];
  public static readonly AMEX_CVV = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public static readonly AMEX_MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
}

export class CardTypeEnum {
  public static readonly GENERIC = {
    numberMask: CardDefinitions.GENERIC_MASK,
    cvvMask: CardDefinitions.GENERIC_CVV,
  };

  public static readonly AMEX = {
    name: 'AMEX',
    numberMask: CardDefinitions.AMEX_MASK,
    cvvMask: CardDefinitions.AMEX_CVV,
    imagePath: '/assets/images/card-flags/amex.png',
  };

  public static readonly VISA = {
    name: 'VISA',
    imagePath: '/assets/images/card-flags/visa.png',
  };

  public static readonly CABAL = {
    name: 'CABAL',
    imagePath: '/assets/images/card-flags/cabal.png',
  };
  public static readonly SOROCRED = {
    name: 'SOROCRED',
    imagePath: '/assets/images/card-flags/sorocred.png',
  };
  public static readonly BANESCARD = {
    name: 'BANESCARD',
    imagePath: '/assets/images/card-flags/banescard.png',
  };
  public static readonly BANESE = {
    name: 'BANESE',
    imagePath: '/assets/images/card-flags/banese.png',
  };
  public static readonly ELO = {
    name: 'ELO',
    imagePath: '/assets/images/card-flags/elo.png',
  };
  public static readonly HIPERCARD = {
    name: 'HIPERCARD',
    imagePath: '/assets/images/card-flags/hipercard.png',
  };
  public static readonly MASTERCARD = {
    name: 'MASTERCARD',
    imagePath: '/assets/images/card-flags/mastercard.png',
  };
}
