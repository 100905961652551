import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { Item } from '../fd-select/fd-select.component';

@Component({
  selector: 'fd-radio-button',
  templateUrl: './fd-radio-button.component.html',
  styleUrls: ['./fd-radio-button.component.scss'],
})
export class FdRadioButtonComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit {
  get hasError() {
    return this.relatedFormControl?.errors !== null && this.relatedFormControl?.touched;
  }

  get errorMessages() {
    const test = Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);

    return test;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm?.get(this.field?.controlName);
  }

  public yesNoItems: Item[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];

  @Input()
  public field: FdRadioButtonConfig;

  @Input()
  public inlineRadios: boolean = false;

  @Input()
  public yesOrNotOptions = false;

  @Input()
  public parentForm: FormGroup;

  @Output()
  public change = new EventEmitter<any>();

  constructor() {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngOnInit() {
    if (this.yesOrNotOptions) {
      this.field.items = this.yesNoItems;
    }
  }

  public handleChange(event: MatRadioChange) {
    if (event && typeof event.value !== undefined) {
      this.change.emit(event.value);
    }
  }

  public getValues() {
    const item = this.field.items?.find((i) => i?.value === this.relatedFormControl?.value);
    if (item) {
      item.selected = true;
    }
    return this.field.items;
  }
}

export interface FdRadioButtonConfig {
  controlName: string;
  label?: string;
  items?: Item[];
  disabled?: boolean;
  messages?: { [key: string]: string };
  error?: boolean;
}
