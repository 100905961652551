<div class="fd-proposal-card" [title]="'Clique para ver detalhes'">
  <div class="fd-proposal-card__title">
    <span class="fd-proposal-card__title-text">Não finalizada</span>

    <div (click)="navigateToStep()">
      <a class="fd-proposal-card__pay-hyphen">-</a>
      <a class="fd-proposal-card__pay-button">Continuar preenchendo</a>
    </div>

    <div>
      <span class="fd-proposal-card__pay-hyphen">-</span>
      <span class="fd-proposal-card__title-text">{{getDraftAvailabilityText()}}</span>
    </div>

    <div title="Excluir proposta" (click)="promptDeleteDraft()" class="fd-proposal-card__delete-icon">
      <fa-icon icon="trash"></fa-icon>
    </div>

  </div>
  <div class="fd-proposal-card__card-data">
    <div class="fd-proposal-card__information-wrapper">
      <div class="fd-proposal-card__information-title"><strong>{{ isCPF ? 'CPF' : isCNPJ ? 'CNPJ' : "CPF/CNPJ"}}</strong></div>
      <div class="fd-proposal-card__information">{{proposalData.cpfCnpj}}</div>
    </div>
    <div class="fd-proposal-card__information-wrapper">
      <div class="fd-proposal-card__information-title"><strong>{{ isCPF ? 'Nome' : isCNPJ ? 'Razão Social' : "Nome/Razão Social"}}</strong></div>
      <div class="fd-proposal-card__information">{{proposalData.description}}</div>
    </div>
  </div>
</div>
