import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { WeekDayEnum } from '../enums/week-day.enum';

export const businessDayOptions: Item[] = [
    {
        label: 'Segunda-feira',
        value: WeekDayEnum.MONDAY,
    },
    {
        label: 'Terça-feira',
        value: WeekDayEnum.TUESDAY
    },
    {
        label: 'Quarta-feira',
        value: WeekDayEnum.WEDNESDAY
    },
    {
        label: 'Quinta-feira',
        value: WeekDayEnum.THURSDAY
    },
    {
        label: 'Sexta-feira',
        value: WeekDayEnum.FRIDAY
    },
    {
        label: 'Todos',
        value: WeekDayEnum.ALL,
        markAll: true
    }
]
