import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { cnpjMask } from 'src/app/order/masks/document-masks';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { EmailRegExp } from 'src/app/order/validators/email-compare-validator';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { EdiAdditionalDataModel } from '../../models/edi-additional-data.model';

@Component({
  selector: 'app-other-edi-data-modal',
  templateUrl: './other-edi-data-modal.component.html',
  styleUrls: ['./other-edi-data-modal.component.scss'],
})
export class OtherEdiDataModalComponent implements OnInit {
  public STEP_NUMBER = 0;

  public ediAdditionalDataModel: EdiAdditionalDataModel = new EdiAdditionalDataModel();
  public Conciliador: any = 'Conciliador';
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    public dialogRef: MatDialogRef<OtherEdiDataModalComponent>
  ) {}

  public ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  public createFormGroup() {
    return this.formBuilder.group({
      conciliatorEnterpriseDescription: ['CAIXA PROPRIA', [Validators.required]],
      conciliatorCnpj: [undefined],
      ediLayout: ['Layout Fiserv', [Validators.required]],
      conciliatorEmail: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
      conciliatorEmail2: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
    });
  }

  public cancel() {
    this.dialogRef.close({ conciliator: undefined });
  }

  public save() {
    if (!this.formGroup.valid) {
      return;
    }

    this.ediAdditionalDataModel.conciliatorEnterpriseDescription = 'CAIXA PROPRIA';
    this.ediAdditionalDataModel.conciliatorEmail = this.formGroup.value.conciliatorEmail;
    this.ediAdditionalDataModel.conciliatorEmail2 = this.formGroup.value.conciliatorEmail2;
    this.ediAdditionalDataModel.conciliatorCnpj = this.formGroup.value.conciliatorCnpj;
    this.ediAdditionalDataModel.ediLayout = this.formGroup.value.ediLayout;
    this.dialogRef.close({ conciliator: this.ediAdditionalDataModel });
  }

  protected createFields(): FdFieldConfigs {
    return {
      conciliator: {
        label: 'Empresa Conciliadora',
        items: [],
        controlName: 'conciliator',
        searchPlaceholder: 'Buscar',
        searchable: true,
        messages: {
          required: 'Informe a Empresa Conciliadora',
          invalid: 'Empresa Conciliadora inválido',
          pattern: 'Empresa Conciliadora inválido',
        },
      },
      ediLayout: {
        label: 'Layout Conciliadora',
        controlName: 'ediLayout',
        messages: {},
      },
      conciliatorEnterpriseDescription: {
        disabled: true,
        label: 'Descreva',
        controlName: 'conciliatorEnterpriseDescription',
        messages: {},
      },
      conciliatorCnpj: {
        label: 'CNPJ',
        maskCharsReplace: /[.\/ -]/g,
        mask: cnpjMask,
        controlName: 'conciliatorCnpj',
        messages: {},
      },
      conciliatorEmail: {
        label: 'E-mail 1',
        controlName: 'conciliatorEmail',
        messages: {
          required: 'Campo obrigatório',
          pattern: 'E-mail inválido',
        },
      },
      conciliatorEmail2: {
        label: 'E-mail 2',
        controlName: 'conciliatorEmail2',
        messages: {
          required: 'Campo obrigatório',
          pattern: 'E-mail inválido',
        },
      },
    };
  }
}
