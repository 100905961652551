import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { PayzenStatusEnum } from 'src/app/order/enums/payzen-status.enum';
import { CardNumberMask } from 'src/app/order/masks/card-number-mask';
import { CvvMask } from 'src/app/order/masks/cvv-mask';
import { DateMask } from 'src/app/order/masks/date-mask';
import { MonthYearDateMask } from 'src/app/order/masks/month-year-date-mask';
import { NameMask } from 'src/app/order/masks/name-mask';
import { SurnameMask } from 'src/app/order/masks/surname-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { PaymentService } from 'src/app/order/services/external/payment/payment.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { ErrorService } from 'src/app/order/services/internal/error/error.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { getCardFlag, getCardFlagPath, isCreditCardAmex } from 'src/app/order/utils/card-utils';
import { installmentOptions } from 'src/app/order/utils/installment-options';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { PaymentSubmitModel } from 'src/app/shared/models/response/payment-submit.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ProposalDetailModel } from 'src/app/start/components/proposal-detail/models/proposal-detail.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';

import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss'],
})
export class PaymentDataComponent extends FormStep implements OnInit {
  public STEP_NUMBER: 9;
  public proposal: Proposal;
  public proposalNumber: string;
  public isAmex = false;
  public comingFromInitialScreen = false;

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private sanitizer: DomSanitizer,
    private location: Location,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public back() {
    this.persistData();
    if (this.proposal.deliveryHours) {
      this.wizardService.previousStep();
    } else {
      this.location.back();
    }
  }

  public checkCardScheme(value: string) {
    value = value.trim().replace(/\D/g, '');
    if (value.length >= 6) {
      this.isAmex = isCreditCardAmex(value);
      (this.fields.cvv as FdInputConfig).mask = CvvMask(this.isAmex);
      (this.fields.cardNumber as FdInputConfig).iconHTML = this.generateCardFlagHTML(getCardFlagPath(value));

      let cvvLength = 3;

      if (this.isAmex) {
        cvvLength = 4;
      }
      this.formControls.cvv.setValue(this.formControls.cvv.value.trim());
      this.formControls.cvv.clearValidators();
      this.formControls.cvv.setValidators([Validators.required, Validators.minLength(cvvLength), Validators.maxLength(cvvLength)]);
    } else {
      (this.fields.cardNumber as FdInputConfig).iconHTML = null;
    }
  }

  public generateCardFlagHTML(path: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(`<img src="${path}" />`);
  }

  public createFields(): FdFieldConfigs {
    return {
      cardholderName: {
        label: 'Titular do cartão',
        controlName: 'cardholderName',
        mask: SurnameMask,
        messages: {
          required: 'Informe o titular do cartão',
          invalid: 'Informe o nome completo',
        },
      },
      cardNumber: {
        label: 'Número do cartão',
        controlName: 'cardNumber',
        mask: CardNumberMask,
        messages: {
          required: 'Informe o número do cartão',
          invalid: 'Número do cartão inválido',
          minlength: 'Número do cartão inválido',
          maxlength: 'Número do cartão inválido',
        },
      },
      expirationDate: {
        label: 'Validade',
        controlName: 'expirationDate',
        mask: MonthYearDateMask,
        messages: {
          required: 'Informe a validade',
          invalid: 'Data inválida',
        },
      },
      cvv: {
        label: 'CVV',
        controlName: 'cvv',
        mask: CvvMask(this.isAmex),
        messages: {
          required: 'Informe o CVV',
          invalid: 'CVV inválido',
          minlength: 'CVV inválido',
          maxlength: 'CVV inválido',
        },
      },
      installments: {
        label: 'Parcelas',
        controlName: 'installments',
        items: installmentOptions,
        messages: {
          required: 'Informe as parcelas',
          invalid: 'Parcelas inválidas',
        },
      },
    };
  }

  public persistData() {
    return Promise.resolve(true);
  }

  public createFormGroup() {
    return this.formBuilder.group({
      cardholderName: ['', [Validators.required]],
      cardNumber: ['', [Validators.required, Validators.maxLength(19)]],
      expirationDate: ['', [Validators.required, dateValidator(true, false, true)]],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      installments: ['', [Validators.required]],
    });
  }

  public updateFields() {}

  public removeSpaces(value: string) {
    if (!value) {
      return;
    }

    return value.replace(/\s/g, '');
  }

  public submitPaymentInformation() {
    const model = new PaymentSubmitModel();

    model.proposalNumber = this.proposalNumber;
    model.cardNumber = this.removeSpaces(this.formControls.cardNumber.value);
    model.cardOwner = this.formControls.cardholderName.value;
    model.validate = this.formControls.expirationDate.value;
    model.securityCode = this.formControls.cvv.value;
    model.numberOfInstallments = this.formControls.installments.value;

    const cardFlag = getCardFlag(this.removeSpaces(this.formControls.cardNumber.value));

    if (cardFlag) {
      model.brandCard = cardFlag.name;
    }

    this.paymentService
      .submitPayment(model)

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response && response.status === PayzenStatusEnum.SUCCESS) {
            this.wizardService.goToSpecificStep(
              orderRoutingDefinitions.submittedProposal.stepNumber,
              orderRoutingDefinitions.submittedProposal.routes.SUBMITTED_PROPOSAL.order
            );
          } else {
            this.dialogService.openDialog(Messages.PAYMENT_SUBMIT_ERROR, () => this.getCloseAction());
          }
        },
        (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.PAYMENT_SUBMIT_ERROR, () => this.getCloseAction())
      );
  }

  public getCloseAction() {
    if (this.comingFromInitialScreen) {
      this.routingService.navigateToHome(false);
      return;
    }
  }

  public next() {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.submitPaymentInformation();
        }
      });
    }
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.proposalNumber) {
      const storageItem = localStorage.getItem(Constants.PROPOSAL_PRODUCTS_KEY);

      if (storageItem) {
        const parsedItem = JSON.parse(storageItem) as ProposalDetailModel;
        this.proposalNumber = parsedItem.proposalNumber;
        this.comingFromInitialScreen = true;
        return;
      }
    }
    this.proposalNumber = this.proposal.proposalNumber;

    if (!this.proposalNumber) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Pagamentos',
        () => this.routingService.navigateToHome(),
        null,
        'error'
      );
    }
  }
}
