import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import * as moment from 'moment';
import { take, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { CompareTypeEnum } from 'src/app/order/enums/compare-type.enum';
import { ProposalStatusEnum } from 'src/app/order/enums/proposal-status.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStore } from 'src/app/order/store/data.store';
import { sanitizeSearchFilter } from 'src/app/order/utils/sanitize-search-filter';
import { DateCompareValidator } from 'src/app/order/validators/date-compare-validator';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalQueryModel } from 'src/app/shared/models/response/proposal-query.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ProposalFilterDialogData, ProposalFilterModalComponent } from '../modals/proposal-filter-modal/proposal-filter-modal.component';
import { Item } from './../../../shared/fd-form-components/fd-select/fd-select.component';

@Component({
  selector: 'app-proposal-history',
  templateUrl: './proposal-history.component.html',
  styleUrls: ['./proposal-history.component.scss'],
})
export class ProposalHistoryComponent implements OnInit, OnDestroy {
  get formControls() {
    return this.formGroup.controls;
  }

  get shouldShowLoadMore() {
    return this.totalPages > 1 && (this.page === 0 || this.page < this.totalPages - 1);
  }

  public alive = true;
  public storagefilter: StorageFilter;

  public proposal: Proposal;
  public page: number = 0;
  public size: number = 20;
  public totalPages: number;
  public totalItens: number;
  public allProposals: ProposalQueryModel[] = [];
  public filteredProposals: ProposalQueryModel[] = [];
  public fields: FdFieldConfigs;
  public formGroup: FormGroup;
  public appliedFilters: AppliedFilter[] = [];
  public statusFilterOptions: Item[] = [
    {
      label: 'Em análise',
      value: ProposalStatusEnum.IN_ANALYSIS,
      selected: false,
    },
    {
      label: 'Rejeitado',
      value: ProposalStatusEnum.DENIED,
      selected: false,
    },
    {
      label: 'Pronto para pagamento',
      value: ProposalStatusEnum.AWAITING_PAYMENT,
      selected: false,
    },
    {
      label: 'Aprovado',
      value: ProposalStatusEnum.APPROVED,
      selected: false,
    },
  ];

  // TODO: Trazer propostas do mesmo dia.
  constructor(
    private proposalService: ProposalService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private dataStore: DataStore
  ) {
    this.dataStore
      .getProposal()
      .pipe(take(1))
      .pipe(takeWhile(() => this.alive))
      .subscribe((proposal) => {
        this.proposal = proposal;
      });
  }

  public addFilterButton(filter: AppliedFilter) {
    return this.renderCloseIcon() + ` <span>${filter.label}</span>`;
  }

  public async ngOnInit() {
    this.fields = this.createFields();
    this.formGroup = this.createFormGroup();
    this.storagefilter = this.getSessionStorage();
    this.formControls.initialDate.setValue(
      this.convertDataSyle(this.storagefilter.startDate !== null ? this.storagefilter.startDate : moment(new Date()).format('DD/MM/YYYY'))
    );
    this.formControls.endDate.setValue(
      this.convertDataSyle(this.storagefilter.endDate !== null ? this.storagefilter.endDate : moment(new Date()).format('DD/MM/YYYY'))
    );
    if (this.storagefilter.startDate !== null && this.storagefilter.endDate !== null) {
      this.appliedFilters.push({
        label: `De ${this.convertDataSyle(this.storagefilter.startDate)} até ${this.convertDataSyle(this.storagefilter.endDate)}`,
        type: FilterType.DATE,
        startDate: this.formControls.initialDate.value,
        endDate: this.formControls.endDate.value,
      });
    }
    this.addFilterStatusLabel();

    this.getRecentProposals(this.storagefilter.startDate, this.storagefilter.endDate, this.storagefilter.statusList);
    this.setDateValidations();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public addFilterStatusLabel(): void {
    if (this.storagefilter.statusList !== null) {
      this.storagefilter.statusList.forEach((item) => {
        let label;
        if (item === ProposalStatusEnum.IN_COMPLIANCE_MANUAL_ANALYSIS || item === ProposalStatusEnum.IN_CREDIT_MANUAL_ANALYSIS) {
        } else {
          switch (item) {
            case ProposalStatusEnum.AWAITING_PAYMENT:
              label = 'Aguardando pag.';
              break;
            case ProposalStatusEnum.DENIED:
              label = 'Rejeitado';
              break;
            case ProposalStatusEnum.IN_ANALYSIS:
              label = 'Em análise';
              break;
            case ProposalStatusEnum.APPROVED:
              label = 'Aprovado';
              break;
            default:
              label = item;
              break;
          }

          this.appliedFilters.push({
            label,
            type: FilterType.STATUS,
            value: item,
          });

          this.statusFilterOptions.forEach((element) => {
            if (element.value === item) {
              element.selected = true;
            }
          });
        }
      });
    }
  }

  public setDateValidations() {
    this.formControls.initialDate.setValidators([dateValidator(false, true), Validators.required]);

    this.formControls.endDate.setValidators([
      dateValidator(true, true),
      Validators.required,
      DateCompareValidator(this.formControls.initialDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
    ]);
  }

  public openFilterModal() {
    const dialogData: ProposalFilterDialogData = {
      title: 'Filtrar por',
      initialDateField: this.fields.initialDate,
      endDateField: this.fields.endDate,
      appliedFilters: this.appliedFilters,
      relatedFormGroup: this.formGroup,
      statusOptions: this.statusFilterOptions,
    };

    const dialogRef = this.dialog.open(ProposalFilterModalComponent, {
      disableClose: true,
      width: '500ṕx',
      data: dialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: ProposalFilterDialogData) => {
        if (data) {
          this.appliedFilters = [];
          this.page = 0;
          this.allProposals = [];
          this.filteredProposals = [];
          if (this.formControls.initialDate.value && this.formControls.endDate.value) {
            this.appliedFilters.push({
              label: `De ${this.formControls.initialDate.value} até ${this.formControls.endDate.value}`,
              type: FilterType.DATE,
              startDate: this.formControls.initialDate.value,
              endDate: this.formControls.endDate.value,
            });
          }
          this.statusFilterOptions
            .filter((x) => !!x.selected)
            .forEach((item) => {
              let label;
              switch (item.value) {
                case ProposalStatusEnum.AWAITING_PAYMENT:
                  label = 'Aguardando pag.';
                  break;
                case ProposalStatusEnum.DENIED:
                  label = 'Rejeitado';
                  break;
                default:
                  label = item.label;
                  break;
              }

              this.appliedFilters.push({
                label,
                type: FilterType.STATUS,
                value: item.value,
              });
            });

          this.search();
        }
      });
  }

  public removeFromFilter(index: number) {
    this.appliedFilters.splice(index, 1);
    this.allProposals = [];
    this.filteredProposals = [];
    this.search();
  }

  public search() {
    const statusList: ProposalStatusEnum[] = [];
    let startDate: string;
    let endDate: string;
    this.appliedFilters.forEach((filter) => {
      if (filter.type === FilterType.STATUS) {
        if (filter.value === ProposalStatusEnum.IN_ANALYSIS) {
          statusList.push(ProposalStatusEnum.IN_COMPLIANCE_MANUAL_ANALYSIS);
          statusList.push(ProposalStatusEnum.IN_CREDIT_MANUAL_ANALYSIS);
        }
        statusList.push(filter.value);
      } else if (filter.type === FilterType.DATE) {
        startDate = moment(filter.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        endDate = moment(filter.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
    });

    if (!this.appliedFilters || !this.appliedFilters.length) {
      this.getRecentProposals(startDate, endDate, statusList, this.size, this.page);
      return;
    }

    this.getRecentProposals(startDate, endDate, statusList, this.size, this.page);
    this.setSessionStorage(startDate, endDate, statusList);
  }

  public setSessionStorage(startDate: string, endDate: string, statusList: ProposalStatusEnum[]) {
    const storageItens: StorageFilter = {
      startDate,
      endDate,
      statusList,
    };
    localStorage.setItem(Constants.PROPOSAL_FILTER_SEARCH_DATA_SESSIONSTORAGE_KEY, JSON.stringify(storageItens));
  }

  public getSessionStorage(): StorageFilter {
    let storageItens: StorageFilter = null;
    if (localStorage.getItem(Constants.PROPOSAL_FILTER_SEARCH_DATA_SESSIONSTORAGE_KEY)) {
      storageItens = JSON.parse(localStorage.getItem(Constants.PROPOSAL_FILTER_SEARCH_DATA_SESSIONSTORAGE_KEY));
    } else {
      const todayDate = moment(new Date());
      storageItens = {
        startDate: todayDate.format('YYYY-MM-DD'),
        endDate: todayDate.format('YYYY-MM-DD'),
        statusList: null,
      };
    }
    return storageItens;
  }

  public convertDataSyle(data: string): string {
    const dataSplit = data.split('-');
    return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
  }

  public filterProposals(value: string) {
    const searchTerm = sanitizeSearchFilter(value);
    this.filteredProposals = this.allProposals.filter(
      (x) => sanitizeSearchFilter(x.cpfCnpj).indexOf(searchTerm) > -1 || sanitizeSearchFilter(x.description).indexOf(searchTerm) > -1
    );
  }

  public getRecentProposals(startDate: string, endDate: string, statusList?: ProposalStatusEnum[], size?: number, page?: number) {
    this.proposalService
      .getRecentProposals(startDate, endDate, statusList, size, page)

      .pipe(takeWhile(() => this.alive))
      .subscribe((proposals) => {
        if (proposals && proposals.response) {
          const arr = proposals.response;
          this.page = proposals.page;
          this.totalPages = proposals.totalPages;
          this.totalItens = proposals.totalItens;
          this.allProposals.push(...arr);
          this.filteredProposals.push(...arr);
        }
      });
  }

  public loadMore() {
    this.page++;
    this.search();
  }

  public createFields() {
    return {
      search: {
        label: 'Pesquisar por CPF/CNPJ/Nome/R. Social',
        controlName: 'search',
      },
      initialDate: {
        label: 'De',
        controlName: 'initialDate',
        mask: DateMask,
        messages: {
          required: 'Informe uma data válida',
          invalid: 'Informe uma data válida',
          lessThan: 'A data inicial não pode ser maior que a data final.',
          greaterThan: 'A data inicial não pode ser maior que a data final.',
        },
      },
      endDate: {
        label: 'Até',
        controlName: 'endDate',
        mask: DateMask,
        messages: {
          required: 'Informe uma data válida',
          invalid: 'Informe uma data válida',
          lessThan: 'A data final não pode ser menor que a data inicial.',
          greaterThanOrEqual: 'A data final não pode ser menor que a data inicial.',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      initialDate: [''],
      endDate: [''],
    });
  }

  private renderCloseIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'times' });
    return iconDefinition.html;
  }
}

export interface AppliedFilter {
  label: string;
  type: FilterType;
  value?: ProposalStatusEnum;
  startDate?: string;
  endDate?: string;
}

export enum FilterType {
  DATE = 'DATE',
  STATUS = 'STATUS',
}

export interface StorageFilter {
  startDate?: string;
  endDate?: string;
  statusList: ProposalStatusEnum[];
}
