export class CnaeModel {
  public cnaeNumber: number;
  public cnaeDescription: string;
  public mcc: string;
  public status: ActivitiesStatusEnum;
  public ecommerceExceptionCnae: boolean;
}

export enum ActivitiesStatusEnum {
  UNWANTED = 'UNWANTED',
  RESTRICTED = 'RESTRICTED',
}
