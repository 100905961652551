import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent {
  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<InformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationModalDialogData
  ) {}
}

export interface InformationModalDialogData {
  description?: string;
  callbackFn?: () => any;
}
