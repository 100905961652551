import { Component, Input, OnInit } from '@angular/core';
import { ContactPersonDetails } from '../../models/child-models/contact-person-details.model';
import { OwnerDataDetails } from '../../models/child-models/owner-data-details.model';

@Component({
  selector: 'app-owner-data-detail',
  templateUrl: './owner-data-detail.component.html',
  styleUrls: ['./owner-data-detail.component.scss'],
})
export class OwnerDataDetailComponent {
  @Input()
  public ownerData: OwnerDataDetails[];

  @Input()
  public contactPerson: ContactPersonDetails[];

  constructor() {}
}
