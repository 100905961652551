import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { WeekDayEnum } from '../enums/week-day.enum';

export const businessDayOptionsDefault: Item[] = [
  {
    label: 'Segunda-feira',
    value: WeekDayEnum.MONDAY,
    selected: true,
  },
  {
    label: 'Terça-feira',
    value: WeekDayEnum.TUESDAY,
    selected: true,
  },
  {
    label: 'Quarta-feira',
    value: WeekDayEnum.WEDNESDAY,
    selected: true,
  },
  {
    label: 'Quinta-feira',
    value: WeekDayEnum.THURSDAY,
    selected: true,
  },
  {
    label: 'Sexta-feira',
    value: WeekDayEnum.FRIDAY,
    selected: true,
  },
];
