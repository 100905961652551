import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BackToHomeDialogData, BackToHomeModalComponent } from './components/modals/back-to-home-modal/back-to-home-modal.component';
import { ModalDefinitions } from './enums/modal-definitions.enum';
import { Messages } from './messages/order.messages';
import { DialogService } from './services/internal/dialog/dialog.service';
import { LoadingService } from './services/internal/loading/loading.service';
import { RoutingService } from './services/internal/routing/routing.service';
import { DataStoreService } from './store/data-store.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent {
  public formData: any;

  constructor(
    private location: Location,
    private dataStoreService: DataStoreService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private routingService: RoutingService
  ) {}

  public goToHome() {
    const route = this.getLastRoutePath();
    this.dialogService.openConfirmDialog(Messages.BACK_TO_HOME_CONFIRMATION, () => this.routingService.navigateToHome(true));
  }

  public discardDraft() {
    this.dataStoreService.removeAgentFromLocalStorage();
    this.routingService.navigateToHome(true);
  }

  public openBackToHomeDialog(
    message: Messages,
    discardDraftCallbackFn?: () => any,
    keepDraftCallbackFn?: () => any,
    cancelCallbackFn?: () => any
  ): void {
    const dialogData: BackToHomeDialogData = {
      description: message.description,
      title: message.title,
      discardDraftCallbackFn,
      keepDraftCallbackFn,
      cancelCallbackFn,
    };

    const dialogRef = this.dialog.open(BackToHomeModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  private getLastRoutePath(): any {
    if (document && document.location && document.location.href) {
      const arr = document.location.href.split('/');
      return arr[arr.length - 1];
    }
  }
}
