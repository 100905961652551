export enum ConstitutionFormEnum {
  PUBLIC_ENTERPRISE = '2011',
  MIXED_ECONOMY_SOCIETY = '2038',
  OPEN_ANONYMOUS_SOCIETY = '2046',
  CLOSED_ANONYMOUS_SOCIETY = '2054',
  LIMITED_ENTERPRISE_SOCIETY = '2062',
  COLLECTIVE_NAME_ENTERPRISE_SOCIETY = '2070',
  SIMPLE_COMMANDITE_ENTERPRISE_SOCIETY = '2089',
  ACTION_COMMANDITE_ENTERPRISE_SOCIETY = '2097',
  PARTICIPATION_ACCOUNT_SOCIETY = '2127',
  INDIVIDUAL_BUSINESSMAN = '2135',
  COOPERATIVE = '2143',
  SOCIETY_CONSORTIUM = '2151',
  SOCIETY_GROUP = '2160',
  FOREIGN_HOME_ENTERPRISE = '2216',
  PURE_SIMPLE_SOCIETY = '2232',
  SIMPLE_LIMITED_SOCIETY = '2240',
  COLLECTIVE_NAME_SIMPLE_SOCIETY = '2259',
  SIMPLE_COMMANDITE_SIMPLE_SOCIETY = '2267',
  SIMPLE_CONSORTIUM = '2291',
  ENTERPRISE_NATURE_EIRELI = '2305',
  SIMPLE_NATURE_EIRELI = '2313',
  CONSUMPTION_COOPERATIVE = '2330',
  PRIVATE_FOUNDATION = '3069',
  SINDICAL_ENTITY = '3131',
  FOREIGN_HOME_FOUNDATION_OR_ASSOCIATION = '3212',
  RELIGIOUS_ORGANIZATION = '3220',
  SOCIAL_ORGANIZATION = '3301',
  PRIVATE_ASSOCIATION = '3999',
  RURAL_PRODUCTOR = '4120'
}

export enum SocietaryTypeEnum {
  LTDA = 'LTDA',
  MEI = 'MEI',
  SA = 'SA',
  AUTONOMO = 'AUTONOMO',
  SSC = 'SSC',
  OTHERS = 'OTHERS',
  EIRELI = 'EIRELI',
  PUBLIC_ENTERPRISE = 'EMPRESA_PUBLICA',
  MIXED_ECONOMY_SOCIETY = 'SOCIEDADE_ECONOMIA_MISTA',
  COLLECTIVE_NAME_ENTERPRISE_SOCIETY = 'SOCIEDADE_EMPRESARIA_NOME_COLETIVO',
  SIMPLE_COMMANDITE_SIMPLE_SOCIETY = 'SOCIEDADE_SIMPLES_COMANDITA_SIMPLES',
  ACTION_COMMANDITE_ENTERPRISE_SOCIETY = 'SOCIEDADE_EMPRESARIA_COMANDITA_ACOES',
  PARTICIPATION_ACCOUNT_SOCIETY = 'SOCIEDADE_CONTA_PARTICIPACAO',
  SOCIETY_CONSORTIUM = 'CONSORCIO_DE_SOCIEDADES',
  SOCIETY_GROUP = 'GRUPO_DE_SOCIEDADES',
  FOREIGN_HOME_ENTERPRISE = 'EMPRESA_DOMICILIADA_NO_EXTERIOR',
  COLLECTIVE_NAME_SIMPLE_SOCIETY = 'SOCIEDADE_SIMPLES_NOME_COLETIVO',
  SIMPLE_COMMANDITE_ENTERPRISE_SOCIETY = 'SOCIEDADE_EMPRESARIA_COMANDITA_SIMPLES',
  SIMPLE_CONSORTIUM = 'CONSORCIO_SIMPLES',
  PRIVATE_FOUNDATION = 'FUNDACAO_PRIVADA',
  SINDICAL_ENTITY = 'ENTIDADE_SINDICAL',
  FOREIGN_HOME_FOUNDATION_OR_ASSOCIATION = 'FUNDACAO_OU_ASSOCIACAO_DOMICILIADA_EXTERIOR',
  RELIGIOUS_ORGANIZATION = 'ORGANIZACAO_RELIGIOSA',
  SOCIAL_ORGANIZATION = 'ORGANIZACAO_SOCIAL',
  PRIVATE_ASSOCIATION = 'ASSOCIACAO_PRIVADA',
}
