import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { RouteData } from 'src/app/order/store/data-store-models';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { HeaderService } from 'src/app/shared/header/header.service';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalQueryModel } from 'src/app/shared/models/response/proposal-query.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { startRoutingDefinitions } from '../../routing/start-routing-definitions';
import { ApiStatusService } from '../../services/api-status.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  public RECENT_PROPOSAL_QUERY_AMOUNT = 3;

  public scheduledMaintenanceActive = false;
  public scheduledMaintenanceMessage = '';
  public idsApisStatusMaintenance = [2, 3, 8]; // Black-list; Serpro; Terminais
  public unfilledProposals: UnfilledProposalModel[] = [];
  public name: string;
  public recentProposals: ProposalQueryModel[];
  public userData: UserDataModel;
  public alive = true;
  public isCEF = this.dataStoreService.getCEFFlag();

  public hasCampaignSimulator: boolean = false;
  public changeHeader$: Subscription;

  constructor(
    private dataStore: DataStore,
    private dataStoreService: DataStoreService,
    private apiStatusService: ApiStatusService,
    private wizardService: WizardService,
    private loadingService: LoadingService,
    private routingService: RoutingService,
    private proposalService: ProposalService,
    private headerService: HeaderService,
    private configurationService: ConfigurationService
  ) {}

  public async ngOnInit() {
    this.name = this.dataStoreService.getUsername();
    this.userData = this.dataStoreService.getUserData();
    const startDate = moment(new Date(0)).format('YYYY-MM-DD');
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    this.getUnfilledProposal();
    this.getScheduledMaintenanceFlag();
    this.changeHeader$ = this.headerService.currentChange.subscribe((change) => this.getRecentProposals(startDate, todayDate));
    this.ablePriceSimulator();
  }

  public ablePriceSimulator() {
    this.configurationService.getAblePriceSimulator().subscribe(
      (response) => {
        this.hasCampaignSimulator = response;
      },
      () => (this.hasCampaignSimulator = false)
    );
  }

  public getButtonLabel() {
    return this.isCEF ? 'Nova simulação' : 'Novo credenciamento';
  }

  public getScheduledMaintenanceFlag() {
    this.apiStatusService.getApiStatus().subscribe((response) => {
      if (response && response.length) {
        this.scheduledMaintenanceActive = response.some(
          (x) => x.offlineStatus && x.showMessage && this.idsApisStatusMaintenance.indexOf(x.id) >= 0
        );
        if (this.scheduledMaintenanceActive) {
          this.scheduledMaintenanceMessage =
            'As propostas NÃO podem ser submetidas, pois nosso site está em manutenção. Tente novamente mais tarde.';
        } else {
          this.scheduledMaintenanceActive = response.some((x) => x.offlineStatus && x.showMessage);
          if (this.scheduledMaintenanceActive) {
            this.scheduledMaintenanceMessage =
              'Atenção, uma ou mais APIs estão em manutenção!\
               As propostas podem ser submetidas mas o fluxo de aprovação ocorrerá somente após a conclusão da manutenção.';
          }
        }
      }
    });
  }

  public ngOnDestroy() {
    this.alive = false;
    if (this.changeHeader$) {
      this.changeHeader$.unsubscribe();
    }
  }

  public goToProposalHistory() {
    this.routingService.navigateWithParentTo(
      startRoutingDefinitions.start.routes.PROPOSAL_HISTORY.parentRoute,
      startRoutingDefinitions.start.routes.PROPOSAL_HISTORY.name
    );
  }

  public goToCampaignSimulator() {
    this.routingService.navigateWithParentTo(
      startRoutingDefinitions.start.routes.CAMPAIGN_SIMULATOR.parentRoute,
      startRoutingDefinitions.start.routes.CAMPAIGN_SIMULATOR.name
    );
  }

  public getRecentProposals(startDate: string, endDate: string): Subscription {
    return this.proposalService
      .getRecentProposals(startDate, endDate)

      .pipe(takeWhile(() => this.alive))
      .subscribe((proposals) => {
        if (proposals && proposals.response) {
          this.recentProposals = proposals.response.filter((val, ind, arr) => ind <= 2);
        }
      });
  }

  public getLastFilledStep(localStorageRouteStatus: RouteData[]) {
    if (!localStorageRouteStatus || !localStorageRouteStatus.length) {
      return;
    }
    return Math.max(...localStorageRouteStatus.map((x) => x.step));
  }

  public getLastFilledSubStep(localStorageRouteStatus: RouteData[], step: number) {
    if (!localStorageRouteStatus || !localStorageRouteStatus.length) {
      return;
    }

    return Math.max(...localStorageRouteStatus.filter((x) => x.step === step).map((x) => x.subStep));
  }

  public convertToRemainingDays(expirationDate: string) {
    const start = moment();
    const end = moment(expirationDate);
    return end.diff(start, 'days');
  }

  public getUnfilledProposal() {
    const drafts = this.dataStore.getDraftProposals().subscribe((response) => {
      if (response && response.length) {
        // todo: implementar filtro para trazer o localstorageroutestatus por proposta.
        response.forEach((proposal) => {
          if (!proposal || !proposal.content || !JSON.parse(proposal.content)) {
            return null;
          }

          const localStorageRouteStatus = this.dataStoreService.getLocalStorageObject(
            Constants.ROUTE_STATUS_LOCALSTORAGE_KEY,
            proposal.externalDraftUuid
          );
          const lastFilledStep = this.getLastFilledStep(localStorageRouteStatus);

          const parsedProposal = JSON.parse(proposal.content) as Proposal;

          this.unfilledProposals.push({
            cpfCnpj: this.checkProposalCpfCnpj(parsedProposal),
            step: lastFilledStep,
            subStep: this.getLastFilledSubStep(localStorageRouteStatus, lastFilledStep),
            expirationDate: proposal.draftExpirationDate,
            remainingDays: this.convertToRemainingDays(proposal.draftExpirationDate),
            externalDraftUuid: proposal.externalDraftUuid,
            description: this.checkProposalName(parsedProposal),
          });
        });
      }
    });
  }

  public checkProposalCpfCnpj(proposal: Proposal) {
    return proposal && proposal.registrationDataPersonalInfo && proposal.registrationDataPersonalInfo.cpfCnpj
      ? proposal.registrationDataPersonalInfo.cpfCnpj
      : 'Ainda não definido';
  }

  public checkProposalName(proposal: Proposal) {
    return proposal &&
      proposal.registrationDataPersonalInfo &&
      (proposal.registrationDataPersonalInfo.name || proposal.registrationDataPersonalInfo.socialReason)
      ? proposal.registrationDataPersonalInfo.name || proposal.registrationDataPersonalInfo.socialReason
      : 'Ainda não definido';
  }

  public navigateToForm() {
    this.proceedNavigation();
  }

  public setSimulatorStepJump(status: boolean) {
    const savedJumpSteps = this.wizardService.setStepJumpInternal(
      orderRoutingDefinitions.simulationData.stepNumber,
      orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.order,
      status
    );
    this.dataStore.setLocalStorageObject(savedJumpSteps, Constants.JUMP_STEPS_LOCALSTORAGE_KEY);
  }

  public proceedNavigation() {
    localStorage.removeItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    this.dataStore.updateProposal(this.createDraft()).then((response) => {
      // this.dataStoreService.removeAgentFromLocalStorage();
      this.wizardService.goToSpecificStep(
        orderRoutingDefinitions.registrationData.stepNumber,
        orderRoutingDefinitions.registrationData.routes.PERSONAL_INFO.order
      );
    });
  }

  public createDraft(): Proposal {
    const userData = this.dataStoreService.getUserData();
    return {
      serviceContract: userData.serviceContract,
      channel: userData.channel,
      subChannel: this.dataStoreService.getUserDataSubChannel(userData),
      agentChannel: this.dataStoreService.getUserDataAgentChannel(userData),
      institution: userData.institution,
      channelType: userData.channelType,
    };
  }
}

export class UnfilledProposalModel {
  public cpfCnpj: string;
  public step: number;
  public subStep: number;
  public description: string;
  public remainingDays: number;
  public expirationDate: string;
  public externalDraftUuid: string;
}
