import { CardTypeEnum } from '../enums/card-type.enum';
import { isCreditCardAmex } from '../utils/card-utils';

export function CvvMask(isAmex: boolean) {
  if (isAmex) {
    return CardTypeEnum.AMEX.cvvMask;
  } else {
    return CardTypeEnum.GENERIC.cvvMask;
  }
}
