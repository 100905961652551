<div>
  <div mat-dialog-content class="fd-modal__title">
    <p>{{data.title}}</p>
  </div>


  <div mat-dialog-content class="fd-modal__subtitle">
    <p>Status</p>
  </div>
  <div fxLayout="column wrap" fxLayoutAlign="stretch" mat-dialog-content class="fd-modal__option-container">
    <div class="fd-modal__option" *ngFor="let item of items; let i = index">
      <mat-checkbox (change)="onCheckboxChange(item, $event)" [value]="item.value" [checked]="item.selected"
        class="fd-checkbox" [id]="item.value">{{item.label}}</mat-checkbox>
    </div>
  </div>

  <div mat-dialog-content class="fd-modal__subtitle">
    <p>Data</p>
  </div>
  <div fxLayoutGap="20px">
    <div fxFlex="50">
      <fd-input [parentForm]="data.relatedFormGroup" [field]="data.initialDateField"></fd-input>
    </div>
    <div fxFlex="50">
      <fd-input [parentForm]="data.relatedFormGroup" [field]="data.endDateField"></fd-input>
    </div>
  </div>

  <div class="fd-modal__button-container">
    <fd-button [content]="'avançar'" (trigger)="handleConfirmClick()">
    </fd-button>
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="handleCancelClick()">
    </fd-button>
  </div>
</div>