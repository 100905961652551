import { AbstractControl } from '@angular/forms';

export function NameSurnameFunction(regex: string | RegExp, control: AbstractControl) {
  let value: string = control.value;

  if (!value) {
    return {};
  }

  // check if value has repetitive characters
  if (/(.)\1{2,}/.test(value.toLowerCase())) {
    return { invalid: true };
  }

  value = value.trim();

  if (!value.match(regex)) {
    return { invalid: true };
  }

  // Avaliar se faz sentido essa função pois o caso de erro nunca ocorre
  const charArray = control.value.split('');

  const filtered = charArray.filter((char) => {
    const firstChar = charArray[0];
    return char.toLowerCase() === firstChar.toLowerCase();
  });

  if (filtered.length === charArray.length) {
    return { invalid: true };
  }

  return {};
}
