export class BlackListModel {
  public campo: string;
  public tipoCampo: string;
  public instituicao: string;
  public serviceContract: string;

  constructor(instituicao: string, serviceContract: string, campo: string, tipoCampo: string) {
    this.instituicao = instituicao;
    this.serviceContract = serviceContract;
    this.campo = campo;
    this.tipoCampo = tipoCampo;
  }
}
