import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { Item } from '../../../../../../shared/fd-form-components/fd-select/fd-select.component';
import { ConciliatorService } from '../../../../../services/external/conciliator/conciliator.service';
import { EdiAcquirerDTO } from '../../models/edi-additional-data.model';

@Component({
  selector: 'app-multi-acquirer',
  templateUrl: './multi-acquirer.component.html',
  styleUrls: ['./multi-acquirer.component.scss'],
})
export class MultiAcquirerComponent implements OnInit {
  public STEP_NUMBER = 0;

  public Conciliador: any = 'Conciliador';
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;

  public acquirers: Item[] = [];

  constructor(
    private formBuilder: FormBuilder,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected conciliatorService: ConciliatorService,
    protected matDialog: MatDialog,
    public dialogRef: MatDialogRef<MultiAcquirerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; acquires: EdiAcquirerDTO[] }
  ) {}

  public ngOnInit() {
    this.formGroup = this.createFormGroup();

    this.conciliatorService
      .getAcquirerByConciliatorById(this.data.id)

      .subscribe((item) => {
        this.acquirers = item.map((value) => ({
          value: value.id,
          label: value.name,
          selected: this.data.acquires.some((acquirer) => acquirer.idAcquirerConciliator === value.id),
        }));
        this.formControls.acquirers.setValue(this.acquirers.filter((x) => x.selected).map((x) => new EdiAcquirerDTO(x.value)));
      });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      acquirers: [[]],
    });
  }

  public cancel() {
    this.dialogRef.close({ acquires: undefined });
  }

  public save() {
    if (!this.formGroup.valid) {
      return;
    }
    this.dialogRef.close({ acquires: this.formControls.acquirers.value });
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.acquirers.setValue(this.acquirers.filter((x) => x.selected).map((x) => new EdiAcquirerDTO(x.value)));
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
