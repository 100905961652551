import { CommonModule, CurrencyPipe, PercentPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, OnDestroy } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OrderComponent } from './order.component';
import { OrderRoutingModule } from './routing/order.routing';

import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faBars, faCamera, faInfoCircle, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { AddressComponent } from './components/address/address.component';
import { BranchOfficesComponent } from './components/complementary-information/e-commerce/branch-offices/branch-offices.component';
import { BusinessProspectionComponent } from './components/complementary-information/e-commerce/business-prospection/business-prospection.component';
import { ECommerceFunctionalitiesComponent } from './components/complementary-information/e-commerce/e-commerce-functionalities/e-commerce-functionalities.component';
import { EnterpriseAdditionalQuestionsComponent } from './components/complementary-information/e-commerce/enterprise-additional-questions/enterprise-additional-questions.component';
import { GatewayAntifraudInformationComponent } from './components/complementary-information/e-commerce/gateway-antifraud-information/gateway-antifraud-information.component';
import { EdiAdditionalDataComponent } from './components/complementary-information/edi/edi-additional-data/edi-additional-data.component';
import { OtherEdiDataModalComponent } from './components/complementary-information/edi/edi-additional-data/modal/other-edi-data-modal.component';
import { MultiAcquirerComponent } from './components/complementary-information/edi/edi-additional-data/multi-acquirer/multi-acquirer.component';
import { BusinessAffiliationsComponent } from './components/complementary-information/restricted-cnae/business-affiliations/business-affiliations.component';
import { EnterprisePartnerDataComponent } from './components/complementary-information/restricted-cnae/enterprise-partner-data/enterprise-partner-data.component';
import { HistoryPartnerAdminAndLegalIssuesComponent } from './components/complementary-information/restricted-cnae/history-partner-admin-and-legal-issues/history-partner-admin-and-legal-issues.component';
import { OutOfMembershipPartnersComponent } from './components/complementary-information/restricted-cnae/out-of-membership-partners/out-of-membership-partners.component';
import { OwnerFormComponent } from './components/complementary-information/restricted-cnae/owner-form/owner-form.component';
import { PartnerFormComponent } from './components/complementary-information/restricted-cnae/partner-form/partner-form.component';
import { ActivationPeriodComponent } from './components/complementary-information/tef/activation-period/activation-period.component';
import { FirstDataPinPadComponent } from './components/complementary-information/tef/first-data-pin-pad/first-data-pin-pad.component';
import { PinPadInformationComponent } from './components/complementary-information/tef/pin-pad-information/pin-pad-information.component';
import { TefBusinessInformationComponent } from './components/complementary-information/tef/tef-business-information/tef-business-information.component';
import { TefIntegrationComponent } from './components/complementary-information/tef/tef-integration/tef-integration.component';
import { TefItContactComponent } from './components/complementary-information/tef/tef-it-contact/tef-it-contact.component';
import { TefProviderInformationComponent } from './components/complementary-information/tef/tef-provider-information/tef-provider-information.component';
import { ApprovedProposalComponent } from './components/conclusion/approved-proposal/approved-proposal.component';
import { ConclusionComponent } from './components/conclusion/conclusion.component';
import { CreditManualAnalysisProposalComponent } from './components/conclusion/credit-manual-analysis-proposal/credit-manual-analysis-proposal.component';
import { ManualAnalysisProposalComponent } from './components/conclusion/manual-analysis-proposal/manual-analysis-proposal.component';
import { PendingProposalComponent } from './components/conclusion/pending-proposal/pending-proposal.component';
import { RejectedProposalComponent } from './components/conclusion/rejected-proposal/rejected-proposal.component';
import { DeliveryHoursComponent } from './components/delivery-data/delivery-hours/delivery-hours.component';
import { WorkingHoursComponent } from './components/delivery-data/working-hours/working-hours.component';
import { BackToHomeModalComponent } from './components/modals/back-to-home-modal/back-to-home-modal.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { FeeEditorModalComponent } from './components/modals/fee-editor-modal/fee-editor-modal.component';
import { InformationModalComponent } from './components/modals/information-modal/information-modal.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';
import { OptionPickerModalComponent } from './components/modals/option-picker-modal/option-picker-modal.component';
import { ValueEditorModalComponent } from './components/modals/value-editor-modal/value-editor-modal.component';
import { FeeTypeComponent } from './components/offer/fee-type/fee-type.component';
import { MdrFeeEditComponent } from './components/offer/mdr-fee-edit/mdr-fee-edit.component';
import { PrepaymentTableComponent } from './components/offer/mdr-fee-edit/prepayment-table/prepayment-table.component';
import { MdrFlexFeeEditComponent } from './components/offer/mdr-flex-fee-edit/mdr-flex-fee-edit.component';
import { OfferDetailsComponent } from './components/offer/offer-details/offer-details.component';
import { OfferTypeComponent } from './components/offer/offer-type/offer-type.component';
import { ProductSelectionComponent } from './components/offer/product-selection/product-selection.component';
import { LicenseComponent } from './components/offer/professional-license-register/components/license/license.component';
import { NotaFiscalComponent } from './components/offer/professional-license-register/components/nota-fiscal/nota-fiscal.component';
import { ProfRegisterComponent } from './components/offer/professional-license-register/components/prof-register/prof-register.component';
import { ProfessionalLicenseRegisterComponent } from './components/offer/professional-license-register/professional-license-register.component';
import { SalesDataComponent } from './components/offer/sales-data/sales-data.component';
import { PaymentDataComponent } from './components/order-finishing-data/payment-data/payment-data.component';
import { OrderResumeComponent } from './components/order-resume/order-resume.component';
import { BankDataComponent } from './components/proposal-data/bank-data/bank-data.component';
import { DeliveryContactComponent } from './components/proposal-data/delivery-contact/delivery-contact.component';
import { PartnerDataComponent } from './components/proposal-data/partner-data/partner-data.component';
import { ReceivingDataComponent } from './components/proposal-data/receiving-data/receiving-data.component';
import { ProposalTermsAcceptanceComponent } from './components/proposal-terms-acceptance/proposal-terms-acceptance.component';
import { RegistrationDataAddressComponent } from './components/registration-data/address/registration-data-address.component';
import { DeliveryAddressComponent } from './components/registration-data/delivery-address/delivery-address.component';
import { MailingAddressComponent } from './components/registration-data/mailing-address/mailing-address.component';
import { RegistrationDataPersonalInfoComponent } from './components/registration-data/personal-info/registration-data-personal-info.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { OrderStepGuard } from './guards/step-guard';
import { BankService } from './services/external/bank/bank.service';
import { CepService } from './services/external/cep/cep.service';
import { ClientService } from './services/external/client-validation/client-validation.service';
import { CnaeService } from './services/external/cnae/cnae.service';
import { ModalService } from './services/external/modal/modal.service';
import { LoadingService } from './services/internal/loading/loading.service';
import { ScrollService } from './services/internal/scroll/scroll.service';
import { TefBrowserItContactComponent } from './components/complementary-information/tef-browser/tef-browser-it-contact/tef-browser-it-contact.component';
import { TefBrowserIntegrationComponent } from './components/complementary-information/tef-browser/tef-browser-integration/tef-browser-integration.component';
import { TefBrowserActivationPeriodComponent } from './components/complementary-information/tef-browser/tef-browser-activation-period/tef-browser-activation-period.component';

registerLocaleData(ptBr);

// TODO: Add Angular decorator.
@NgModule({
  declarations: [
    OrderComponent,
    WizardComponent,
    RegistrationDataAddressComponent,
    RegistrationDataPersonalInfoComponent,
    OfferDetailsComponent,
    OfferTypeComponent,
    ProductSelectionComponent,
    DeliveryAddressComponent,
    MailingAddressComponent,
    AddressComponent,
    WorkingHoursComponent,
    DeliveryContactComponent,
    ReceivingDataComponent,
    PartnerDataComponent,
    DeliveryHoursComponent,
    MessageModalComponent,
    ErrorModalComponent,
    OptionPickerModalComponent,
    BankDataComponent,
    ValueEditorModalComponent,
    SalesDataComponent,
    PaymentDataComponent,
    OrderResumeComponent,
    FeeTypeComponent,
    MdrFeeEditComponent,
    FeeEditorModalComponent,
    MdrFlexFeeEditComponent,
    PrepaymentTableComponent,
    ApprovedProposalComponent,
    PendingProposalComponent,
    RejectedProposalComponent,
    ConclusionComponent,
    HistoryPartnerAdminAndLegalIssuesComponent,
    InformationModalComponent,
    EnterprisePartnerDataComponent,
    PartnerFormComponent,
    ManualAnalysisProposalComponent,
    OwnerFormComponent,
    OutOfMembershipPartnersComponent,
    BusinessAffiliationsComponent,
    ECommerceFunctionalitiesComponent,
    GatewayAntifraudInformationComponent,
    BranchOfficesComponent,
    BusinessProspectionComponent,
    EnterpriseAdditionalQuestionsComponent,
    CreditManualAnalysisProposalComponent,
    TefItContactComponent,
    PinPadInformationComponent,
    TefBusinessInformationComponent,
    FirstDataPinPadComponent,
    TefProviderInformationComponent,
    TefIntegrationComponent,
    TefBrowserItContactComponent,
    TefBrowserIntegrationComponent,
    TefBrowserActivationPeriodComponent,
    ActivationPeriodComponent,
    EdiAdditionalDataComponent,
    OtherEdiDataModalComponent,
    BackToHomeModalComponent,
    ProposalTermsAcceptanceComponent,
    ProfessionalLicenseRegisterComponent,
    NotaFiscalComponent,
    LicenseComponent,
    ProfRegisterComponent,
    MultiAcquirerComponent,
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatGridListModule,
    FlexLayoutModule,
    SharedComponentsModule,
    FontAwesomeModule,
    MatSelectInfiniteScrollModule,
    NgxSpinnerModule,
    MatTableModule,
    MatExpansionModule,
  ],
  providers: [
    OrderStepGuard,
    ClientService,
    CepService,
    CnaeService,
    BankService,
    CurrencyPipe,
    PercentPipe,
    ModalService,
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
})
export class OrderModule {
  public alive = true;

  constructor(private spinner: NgxSpinnerService, private loadingService: LoadingService, private scrollService: ScrollService) {
    library.add(faArrowLeft, faCamera, faPlusCircle, faTimes, faBars, faInfoCircle);
  }
}
