import { BranchOfficesModel } from 'src/app/order/components/complementary-information/e-commerce/models/branch-offices.model';
import { BusinessProspectionModel } from 'src/app/order/components/complementary-information/e-commerce/models/business-prospection.model';
import { DeliveryPercentModel } from 'src/app/order/components/complementary-information/e-commerce/models/delivery-percent.model';
import { DeliveryRevenueDetailsModel } from 'src/app/order/components/complementary-information/e-commerce/models/delivery-revenue-details.model';
import { ECommerceFunctionalitiesModel } from 'src/app/order/components/complementary-information/e-commerce/models/e-commerce-funcionalities.model';
import { EnterpriseAdditionalQuestionsModel } from 'src/app/order/components/complementary-information/e-commerce/models/enterprise-additional-questions.model';
import { GatewayAntifraudInformationModel } from 'src/app/order/components/complementary-information/e-commerce/models/gateway-antifraud-information.model';
import { VisitContactSelectionModel } from 'src/app/order/components/complementary-information/e-commerce/models/visit-contact-selection.model';
import { VisitContactModel } from 'src/app/order/components/complementary-information/e-commerce/models/visit-contact.model';
import { EdiAdditionalDataModel } from 'src/app/order/components/complementary-information/edi/models/edi-additional-data.model';
import { BusinessAffiliationsModel } from 'src/app/order/components/complementary-information/restricted-cnae/models/business-affiliations.model';
import { EnterpriseOwnerDataModel } from 'src/app/order/components/complementary-information/restricted-cnae/models/enterprise-owner-data.model';
import { EnterprisePartnerDataModel } from 'src/app/order/components/complementary-information/restricted-cnae/models/enterprise-partner-data.model';
import { HistoryPartnerAdminAndLegalIssuesModel } from 'src/app/order/components/complementary-information/restricted-cnae/models/history-partner-admin-and-legal-issues.model';
import { TermsAcceptanceModel } from 'src/app/order/components/complementary-information/restricted-cnae/models/terms-acceptance.model';
import { FirstDataPinPadModel } from 'src/app/order/components/complementary-information/tef/models/first-data-pin-pad.model';
import { TefActivationPeriodModel } from 'src/app/order/components/complementary-information/tef/models/tef-activation-period.model';
import { TefBusinessInformationModel } from 'src/app/order/components/complementary-information/tef/models/tef-business-information.model';
import { TefIntegrationModel } from 'src/app/order/components/complementary-information/tef/models/tef-integration.model';
import { TefItContactModel } from 'src/app/order/components/complementary-information/tef/models/tef-it-contact.model';
import { TefPinPadInformationModel } from 'src/app/order/components/complementary-information/tef/models/tef-pin-pad-information.model';
import { TefProviderInformationModel } from 'src/app/order/components/complementary-information/tef/models/tef-provider-information.model';
import { TefBrowserActivationPeriodModel } from 'src/app/order/components/complementary-information/tef-browser/models/tef-browser-activation-period.model';
import { TefBrowserIntegrationModel } from 'src/app/order/components/complementary-information/tef-browser/models/tef-browser-integration.model';
import { TefBrowserItContactModel } from 'src/app/order/components/complementary-information/tef-browser/models/tef-browser-it-contact.model';
import { BuildingTypeEnum } from 'src/app/order/enums/building-type-enum';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { OfferTypeEnum } from 'src/app/order/enums/offer-type.enum';
import { Item } from '../fd-form-components/fd-select/fd-select.component';
import { CnaeModel } from './response/response-cnae.model';
import { FactorFeeModel } from './response/response-factor-fee.model';
import { MdrFeeModel } from './response/response-mdr-fee.model';
import { MdrFlexFeeModel } from './response/response-mdr-flex-fee.model';
import { PrepaymentFeeModel } from './response/response-prepayment-fee.model';

export class BaseAddressModel {
  public addressNumber: string;
  public city: string;
  public complement: string;
  public neighborhood: string;
  public state: string;
  public address: string;
  public zipCode: string;
  public genericZipCode?: boolean;
  public noNumberFlag: boolean;
}

export class RegistrationDataAddressModel extends BaseAddressModel {
  public useAsDeliveryAddress: boolean;
  public useAsMailingAddress: boolean;
  public buildingType: BuildingTypeEnum;
  public referencePoint: string;
}

export class DeliveryAddressModel extends BaseAddressModel {
  public buildingType: BuildingTypeEnum;
  public referencePoint: string;
  public useAsMailingAddress: boolean;
}

export class MailingAddressModel extends BaseAddressModel {}

export class RegistrationDataPersonalInfoModel {
  public cpfCnpj: string;
  public socialReason: string;
  public companyFantasyName: string;
  public personFantasyName: string;
  public openDate: string;
  public companyEmail: string;
  public constitutionForm: string;
  public constitutionFormCode: string;
  public companyMobileNumber: string;
  public name: string;
  public birthDate: string;
  public personEmail: string;
  public personMobileNumber: string;
  public fixedNumberOne: string;
  public fixedNumberTwo: string;
  public siteAddress: string;
  public motherName: string;
  public politicalExposition?: boolean;
  public politicallyExposedPerson?: PoliticallyExposedPersonModel;
  public successDocumentCall?: boolean;
  public useAsReceivingData?: boolean;
  public useAsDeliveryContact?: boolean;
  public documentCategory?: DocumentCategory;
  public cnaeList?: CnaeModel[];
  public onlyECommerce?: boolean;
  public partnerId?: string;
  public nationality: string;
  public rne: string;
  public notesCreditRisk: string;
}

export class PoliticallyExposedPersonModel {
  public name: string;
  public responsibility: string;
  public governmentAgency: string;
}

export class OfferDataDetailsModel {
  public cnae: string;
  public annualRevenue: number;
  public documentCategory?: string;
  public cnaeChanged?: boolean;
  public restrictedCnaeSelected?: boolean;
  public eCommerceExceptionCnaeSelected?: boolean;
  public campaign: string;
  public revenueRange: string;
  public revenueRangeDescription: string;
  public averageTicket: number;
  public annualVolumeSalesCardGroup: number;
}

export class OfferDataTypeModel {
  public offerType: OfferTypeEnum;
}

export class ProductModel {
  public technologyName?: string;
  public technology: string;
  public amount: number;
  public price: number;
  public technologyGroup: string;
}

export class SalesDataModel {
  public receivingDeadline: string;
  public mobileOperator: string;
}

export class ProductSelectionModel {
  public offerType: OfferTypeEnum;
  public hasECommerce?: boolean;
  public paymentLink?: boolean;
  public url?: string;
  public hasTef?: boolean;
  public hasTefBrowser?: boolean;
  public hasEdi?: boolean;
  public hasSplitTech: boolean;
  public hasEcMaster: boolean;
  public products: ProductModel[];
}

export class WorkingHoursModel {
  public weekOpeningHour: string;
  public weekClosingHour: string;
  public weekendOpeningHour: string;
  public weekendClosingHour: string;
  public lunchClosing: boolean;
  public lunchClosingHour: string;
  public lunchReopenHour: string;
  public businessDays: string;
}
export class DeliveryContactModel {
  public cpf: string;
  public contactName: string;
  public emailAddress: string;
  public siteAddress: string;
  public fixedNumberRequired: string;
  public fixedNumber: string;
  public mobileNumber: string;
}

export class ReceivingDataModel {
  public name: string;
  public fixedNumber: string;
}

export class PartnerDataModel {
  public cpfCnpj?: string;
  public name?: string;
  public birthDate?: string;
  public motherName?: string;
  public companyParticipation?: number = 0;
  public politicalExposition?: boolean;
  public politicallyExposedPerson?: PoliticallyExposedPersonModel;
  public nationality?: string;
  public rne?: string;
  public loadedPartners?: boolean;
  public rneEnabled?: boolean;
  public socialReason?: string;
  public openDate?: string;
  public legalRepresentative?: LegalRepresentative;
  public documentCategory?: string;
  public isLegalRepresentativeOptions?: boolean;
}

export class AccountsModel {
  public accountTypeCredit: string;
  public accountTypeDebit: string;
  public bankDebit: string;
  public bankCredit: string;
  public agencyDebit: string;
  public agencyCredit: string;
  public accountDebit: string;
  public accountCredit: string;
  public operationCredit: string;
  public operationDebit: string;
  public sameData: boolean;
}

export class Invoice {
  public invoiceNumber?: number;
  public invoiceValue?: number;
  public productType?: string;
  public invoiceDate?: string;
}

export class ProfessionalLicense {
  public licenseTypeName?: string;
  public processNumber?: number;
  public dmlNumber?: number;

  public numberDocument?: number;
  public motherName?: string;
  public nationality?: string;

  public professionalCouncilCategory?: string;

  public validityDate?: string;

  public invoiceValues?: Invoice[];
}

export class DeliveryHoursModel {
  public days: Item[];
  public fromHour: string;
  public untilHour: string;
  public deliveryType: string;
  public modal: string;
}

export class FeeTypeModel {
  public feeType: FeeTypeEnum;
  public enablePrepayment: boolean;
  public anticipationExternal: boolean;
  public receivingDeadline: number;
}

export interface Proposal {
  registrationDataAddress?: RegistrationDataAddressModel;
  registrationDataPersonalInfo?: RegistrationDataPersonalInfoModel;
  offerDataDetails?: OfferDataDetailsModel;
  offerDataType?: OfferDataTypeModel;
  productSelection?: ProductSelectionModel;
  deliveryAddress?: DeliveryAddressModel;
  deliveryContact?: DeliveryContactModel;
  mailingAddress?: MailingAddressModel;
  workingHours?: WorkingHoursModel;
  deliveryHours?: DeliveryHoursModel;
  receivingDataModel?: ReceivingDataModel;
  partnerDataModel?: PartnerDataModel[];
  accounts?: AccountsModel;
  salesDataModel?: SalesDataModel;
  feeTypeModel?: FeeTypeModel;
  prepaymentFeeModel?: PrepaymentFeeModel;
  mdrFeeModel?: MdrFeeModel[];
  mdrFlexFeeModel?: MdrFlexFeeModel[];
  factorFeeModel?: FactorFeeModel[];
  historyPartnerAdminAndLegalIssuesModel?: HistoryPartnerAdminAndLegalIssuesModel;
  enterprisePartnerDataModel?: EnterprisePartnerDataModel[];
  enterpriseOwnerDataModel?: EnterpriseOwnerDataModel[];
  outOfMembershipBoardPartners?: EnterprisePartnerDataModel[];
  outOfMembershipBoardOwners?: EnterpriseOwnerDataModel[];
  businessAffiliations?: BusinessAffiliationsModel;
  termsAcceptance?: TermsAcceptanceModel;
  optIn?: OptInAcceptanceModel;
  commerceFunctionalities?: ECommerceFunctionalitiesModel;
  gatewayAntifraudInformation?: GatewayAntifraudInformationModel;
  branchOffices?: BranchOfficesModel;
  visitContactSelection?: VisitContactSelectionModel;
  visitContact?: VisitContactModel;
  businessProspection?: BusinessProspectionModel;
  deliveryPercent?: DeliveryPercentModel;
  deliveryRevenueDetails?: DeliveryRevenueDetailsModel;
  enterpriseAdditionalQuestions?: EnterpriseAdditionalQuestionsModel;
  commerceTermsAcceptance?: TermsAcceptanceModel;

  tefItContact?: TefItContactModel;
  tefPinPadInformation?: TefPinPadInformationModel;
  tefBusinessInformation?: TefBusinessInformationModel;
  firstDataPinPadModel?: FirstDataPinPadModel;
  tefProviderInformation?: TefProviderInformationModel;
  tefIntegration?: TefIntegrationModel;
  tefActivationPeriod?: TefActivationPeriodModel;
  tefTermsAcceptance?: TermsAcceptanceModel;

  tefBrowserItContact?: TefBrowserItContactModel;
  tefBrowserIntegration?: TefBrowserIntegrationModel;
  tefBrowserActivationPeriod?: TefBrowserActivationPeriodModel;

  ediAdditionalData?: EdiAdditionalDataModel;

  idCampaign?: number;
  serviceContract?: number;
  channel?: string;
  subChannel?: string;
  agentChannel?: string;
  institution?: string;
  channelType?: string;

  proposalNumber?: string;

  professionalLicense?: ProfessionalLicense;

  externalDraftUuid?: string;
  paymentSplit?: PaymentSplit;
}

export class OptInAcceptanceModel {
  public accepted: boolean;
}

export class PaymentSplit {
  public ecType: string;
  public ecMasterDocument: string;
}

export class LegalRepresentative {
  public cpf: string;
  public name: string;
  public birthDate: string;
  public nationality: string;
  public rne: string;
  public rneEnabled: boolean;
  public politicalExposition: boolean;
  public politicallyExposedPerson: PoliticallyExposedPersonModel;
  public enabled: boolean = true;
}
