import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { pairwise, startWith, takeWhile } from 'rxjs/operators';
import { ValueEditorDialogData } from 'src/app/order/components/modals/value-editor-modal/value-editor-modal.component';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';
import { FdCurrencyInputConfig } from '../fd-currency-input/fd-currency-input.component';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { FdInputConfig, InputType } from '../fd-input/fd-input.component';

@Component({
  selector: 'fd-fee-editor',
  templateUrl: './fd-fee-editor.component.html',
  styleUrls: ['./fd-fee-editor.component.scss'],
})
export class FdFeeEditorComponent extends FdFieldBaseComponent implements OnInit, OnDestroy {
  get displayValue() {
    if (!this.relatedFormControl || this.relatedFormControl.value === null || this.relatedFormControl.value === undefined) {
      return;
    }

    return getValueWithDecimalPlaces(this.relatedFormControl.value);
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }

  @Input()
  public field: FdFeeEditorConfig;

  @Input()
  public parentForm: FormGroup;

  @Input()
  public isEdited?: boolean;

  @Input()
  public canEdit: boolean = true;

  @Output()
  public openDialogFn = new EventEmitter<ValueEditorDialogData>();

  @Output()
  public infiniteScroll = new EventEmitter<void>();

  public limit = 10;
  public offset = 0;
  public controlName: string;
  public context: any;
  public label: string;
  public accessibilityLabel?: string;
  public placeholder?: string;
  public hint?: string;
  public messages?: { [key: string]: string };
  public searchable = false;
  public alive = true;
  public initialValue: any;
  public feeColor: FeeColorEnum = FeeColorEnum.INITIAL;
  private _filter: string;
  private _loadError: boolean;

  constructor(private dialog: MatDialog, private renderer: Renderer2) {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public async ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    this.initialValue = this.relatedFormControl.value;

    if (!this.field || this.field.isEdited === null || this.field.isEdited === undefined) {
      this.relatedFormControl.valueChanges
        .pipe(startWith(null), pairwise())
        .pipe(takeWhile(() => this.alive))
        .subscribe(([prev, next]: [any, any]) => {
          this.feeColor = next === this.initialValue ? FeeColorEnum.INITIAL : FeeColorEnum.EDITED;
        });
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public openValueEditorDialog(): void {
    const dialogData: ValueEditorDialogData = {
      title: this.field.modalTitle,
      formControl: this.relatedFormControl,
      formGroup: this.parentForm,
      field: this.field,
    };

    if (this.openDialogFn) {
      this.openDialogFn.emit(dialogData);
    }
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

export interface FdFeeEditorConfig {
  controlName: string;
  label: string;
  modalTitle?: string;
  itemDescription?: string;
  type?: InputType;
  isEdited?: boolean;
  canEdit: boolean;
  valueEditorLabel?: string;
  childControl?: FdInputConfig | FdCurrencyInputConfig;
  disabled?: boolean;
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
}

export enum FeeColorEnum {
  EDITED = 'green',
  INITIAL = 'blue',
}
