<div *ngIf="fields">
  <h4 class="fd-subtitle">Carteira Profissional</h4>

  <fd-select [parentForm]="formGroup" [field]="fields.professionalCouncilCategory"> </fd-select>

  <fd-input [parentForm]="formGroup" [field]="fields.numberDocument"> </fd-input>

  <fd-input [parentForm]="formGroup" [field]="fields.validityDate"></fd-input>

  <fd-input [parentForm]="formGroup" [field]="fields.motherName"> </fd-input>

  <fd-input [parentForm]="formGroup" [field]="fields.nationality"> </fd-input>

  <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
  <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>

  <div></div>
</div>
