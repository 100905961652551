<form *ngIf="formGroup" [formGroup]="formGroup" (keyup.enter)="searchItems()">
  <h3 class="fd-subtitle">Simulador de Preço</h3>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-select [parentForm]="formGroup" [field]="fields.personType" (selectionChange)="selectPersonType()"> </fd-select>
    </div>
    <div>
      <fd-select (loadItemsFn)="getCnaeList($event)" [parentForm]="formGroup" [field]="fields.cnae" (selectionChange)="selectCnae($event)">
      </fd-select>
    </div>
    <div>
      <fd-select [parentForm]="formGroup" [field]="fields.state"> </fd-select>
    </div>
    <div>
      <fd-currency-input [parentForm]="formGroup" [field]="fields.annualBillingValue"></fd-currency-input>
    </div>
    <h4 class="fd-subtitle">Indique o tipo de taxa de desconto da transação (MDR)</h4>
    <div>
      <fd-radio-button [inlineRadios]="true" [parentForm]="formGroup" (change)="changeOptionOutOf($event)" [field]="fields.chargeModel">
      </fd-radio-button>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxFlex="20">
      <fd-button size="thin" [disabled]="formGroup.invalid" [content]="'consultar campanhas'" (trigger)="searchItems()"></fd-button>
    </div>
  </div>
</form>

<form *ngIf="viewControl.campaignTableData && formOfferSelection" [formGroup]="formOfferSelection">
  <h3 class="subTitle-font">Condições disponíveis</h3>
  <fd-radio-button (change)="onSelectRate($event)" [parentForm]="formOfferSelection" [field]="fields.offerOptions"> </fd-radio-button>
</form>

<h3 class="fd-subtitle" *ngIf="!isEmptyFactor()">Fator</h3>
<app-factor-table class="center-table" *ngIf="!isEmptyFactor()" [data]="factorData"></app-factor-table>
<h3 class="fd-subtitle" *ngIf="!isEmptyPrePayment()">
  {{ isAnticipationExternal ? 'Taxa de Antecipação Automática e Agenda Externa' : 'Taxa de Antecipação' }}
</h3>
<app-prepayment-table class="center-table" *ngIf="!isEmptyPrePayment()" [data]="prepaymentData"></app-prepayment-table>
<h3 class="fd-subtitle" *ngIf="!isEmptyRates()">Taxas</h3>
<app-rate-data-table *ngIf="!isEmptyRates()" [data]="rateData" [viewFlagImg]="true"></app-rate-data-table>
<h3 class="fd-subtitle" *ngIf="!isEmptyTechnology()">Tecnologias Disponiveis</h3>
<app-technology-price-table class="center-table" *ngIf="!isEmptyTechnology()" [data]="technologyData"></app-technology-price-table>

<div fxLayoutAlign="center stretch" *ngIf="!isEmptyRates()">
  <div fxFlex="40">
    <fd-button size="thin" [disabled]="formGroup.invalid" [content]="'Voltar para a página inicial'" (trigger)="initialPage()"></fd-button>
  </div>
  <div fxFlex="40">
    <fd-button
      size="thin"
      [disabled]="formGroup.invalid"
      [content]="'Seguir para credenciamento'"
      (trigger)="newAccreditation()"
    ></fd-button>
  </div>
</div>
