import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { TokenInformationModel } from 'src/app/shared/models/response/auth-service.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  public getDecodedAccessToken(token: string): TokenInformationModel {
    try {
      const decoded = jwt_decode(token) as TokenInformationModel;
      return decoded;
    } catch (Error) {
      return null;
    }
  }
}
