export class ConfigurationModel {
  public institution: string;
  public serviceContract: number;
  public reservePercent: number;
  public ableKeyedTransaction: boolean;
  public ableForeignCard: boolean;
  public numberTerminalsPf: number;
  public numberTerminalsPj: number;
  public ableAnticipation: boolean;
  public ableMdrBillingCharge: boolean;
  public ableMdrFlexBillingCharge: boolean;
  public ableComplianceData: boolean;
  public ableMotherName: boolean;
  public termPaymentDays: number[];
  public partnerId: boolean;
  public deliveryType: boolean;
  public ableOptin: boolean;
  public serproValidationStrategy: SerproValidationStrategyEnum;
  public ableSaleAccreditationType: boolean;
  public ableRentAccreditationType: boolean;
  public machineAgency: boolean;
  public ableExceptionFlow: boolean;
  public ablePriceSimulator: boolean;
  public unwantedSecondaryCnaeValidationFlg: boolean;
  public cnaeValidation: boolean;
}

export enum SerproValidationStrategyEnum {
  PERSONAL_INFO_PAGE = 'PERSONAL_INFO_PAGE',
  TERMS_ACCEPTANCE_PAGE = 'TERMS_ACCEPTANCE_PAGE',
}
