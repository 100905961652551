import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FactorDataModel} from "../models/factor-data.model";

@Component({
  selector: 'app-factor-table',
  templateUrl: './factor-table.component.html',
  styleUrls: ['./factor-table.component.scss'],
})
export class FactorTableComponent implements OnInit {
  @Input()
  public data: FactorDataModel[];

  public dataSource: MatTableDataSource<FactorDataModel> = new MatTableDataSource();
  public displayedColumns = ['transaction', 'suggestedFactorFee'];

  constructor() {}

  public ngOnInit() {
    this.dataSource.data = this.data;
  }
}
