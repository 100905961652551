import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {RateDataDetails} from '../../../models/child-models/rate-data-details.model';

@Component({
  selector: 'app-rate-data-table',
  templateUrl: './rate-data-table.component.html',
  styleUrls: ['./rate-data-table.component.scss'],
})
export class RateDataTableComponent implements OnInit {
  @Input()
  public data: RateDataDetails[];

  @Input()
  public viewFlagImg: boolean = false;

  public dataSource: MatTableDataSource<RateDataDetails> = new MatTableDataSource();
  public displayedColumns = ['flagName', 'credit', 'debit', 'lessTo6Installments', 'more6Installments', 'installmentPlan'];

  constructor() {}

  public ngOnInit() {
    if(!this.viewFlagImg) {
      this.dataSource.data = this.data;
    } else {
      let datasource: RateDataDetails[] = [];

      let visa = this.data.find(item => item.flagName === "VISA");
      if(visa) {
        datasource.push(visa);
      }

      let master = this.data.find(item => item.flagName === "MASTER");
      if(master) {
        datasource.push(master);
      }

      let elo = this.data.find(item => item.flagName === "ELO");
      if(elo) {
        datasource.push(elo)
      }

      let hiper = this.data.find(item => item.flagName === "HIPERCARD");
      if(hiper) {
        datasource.push(hiper)
      }

      let amex = this.data.find(item => item.flagName === "AMEX");
      if(amex) {
        datasource.push(amex)
      }

      let cabal = this.data.find(item => item.flagName === "CABAL");
      if(cabal) {
        datasource.push(cabal)
      }

      this.dataSource.data = datasource;
    }
  }

  getFlagImageURL(flagName: string) {
    return '/assets/images/simulator-flags/' + flagName + '.png';
  }
}
