import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { BusinessProspectionModel } from '../models/business-prospection.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-business-prospection',
  templateUrl: './business-prospection.component.html',
  styleUrls: ['./business-prospection.component.scss'],
})
export class BusinessProspectionComponent extends FormStep implements OnInit {
  get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }
  public STEP_NUMBER = 0;

  public prospectionTypeOptions: Item[] = [
    {
      label: 'Captação presencial',
      value: ProspectionTypeEnum.PRESENTIAL_INCOME,
    },
    {
      label: 'Telemarketing',
      value: ProspectionTypeEnum.TELEMARKETING,
    },
    {
      label: 'Parceiro',
      value: ProspectionTypeEnum.PARTNER,
    },
    {
      label: 'Banco/Cooperativa',
      value: ProspectionTypeEnum.BANK_OR_COOPERATIVE,
    },
    {
      label: 'EC já é cliente',
      value: ProspectionTypeEnum.EC_ALREADY_CLIENT,
    },
  ];

  public businessProspectionModel: BusinessProspectionModel;

  @ViewChild('tooltip') public tooltip: MatTooltip;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public getGatewayTooltipText() {
    return 'Descrever com detalhes os tipos de produtos/serviços comercializados. Não é o CNAE.';
  }
  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  public async ngOnInit() {
    await super.ngOnInit();
    if (this.hasSplitTech) {
      this.setValueDefaultSplitTech();
    }
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.prospectionTypes.setValue(this.prospectionTypeOptions.filter((x) => x.selected).map((x) => x.value));
  }

  public next(): void {
    this.submitted = true;

    if (!this.formControls.prospectionTypes.valid) {
      this.dialogService.openDialog(Messages.MISSING_PROSPECTION_TYPE);
      return;
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public mapTypeArrayToProperties(prospectionObj: BusinessProspectionModel) {
    prospectionObj.bankOrCooperative = false;
    prospectionObj.ecCustomer = false;
    prospectionObj.partner = false;
    prospectionObj.presentialCapture = false;
    prospectionObj.telemarketing = false;

    if (!prospectionObj.prospectionTypes || !prospectionObj.prospectionTypes.length) {
      return;
    }

    prospectionObj.prospectionTypes.forEach((type) => {
      switch (type) {
        case ProspectionTypeEnum.BANK_OR_COOPERATIVE:
          prospectionObj.bankOrCooperative = true;
          return;
        case ProspectionTypeEnum.EC_ALREADY_CLIENT:
          prospectionObj.ecCustomer = true;
          return;
        case ProspectionTypeEnum.PARTNER:
          prospectionObj.partner = true;
          return;
        case ProspectionTypeEnum.PRESENTIAL_INCOME:
          prospectionObj.presentialCapture = true;
          return;
        case ProspectionTypeEnum.TELEMARKETING:
          prospectionObj.telemarketing = true;
          return;
      }
    });
  }

  public persistData() {
    const businessProspection: BusinessProspectionModel = {
      ...this.formGroup.getRawValue(),
      prospectionTypes: this.formControls.prospectionTypes.value,
    };

    this.mapTypeArrayToProperties(businessProspection);

    return this.dataStore.updateProposal({ businessProspection });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      productTypeDescription: ['', Validators.required],
      productDeliveryDescription: ['', Validators.required],
      prospectionTypes: ['', Validators.required],
    });
  }

  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.businessProspectionModel = proposal.businessProspection;

    if (this.businessProspectionModel) {
      this.formControls.productTypeDescription.setValue(this.businessProspectionModel.productTypeDescription);
      this.formControls.productDeliveryDescription.setValue(this.businessProspectionModel.productDeliveryDescription);
      this.prospectionTypeOptions.map((x) => (x.selected = this.businessProspectionModel.prospectionTypes.indexOf(x.value) > -1));
      this.formControls.prospectionTypes.setValue(this.businessProspectionModel.prospectionTypes);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      productTypeDescription: {
        label: 'Descreva',
        controlName: 'productTypeDescription',
        maxLength: 3500,
        messages: {
          required: 'Informe a descrição',
        },
      },
      productDeliveryDescription: {
        label: 'Descreva',
        maxLength: 3500,
        controlName: 'productDeliveryDescription',
        messages: {
          required: 'Informe a descrição',
        },
      },
    };
  }

  private setValueDefaultSplitTech() {
    this.formControls.prospectionTypes.setValue([ProspectionTypeEnum.PRESENTIAL_INCOME]);
    this.formControls.productTypeDescription.setValue('-');
    this.formControls.productTypeDescription.disable();
    this.formControls.productDeliveryDescription.setValue('-');
    this.formControls.productDeliveryDescription.disable();
  }
}

export enum ProspectionTypeEnum {
  PRESENTIAL_INCOME = 'PRESENTIAL_INCOME',
  TELEMARKETING = 'TELEMARKETING',
  PARTNER = 'PARTNER',
  BANK_OR_COOPERATIVE = 'BANK_OR_COOPERATIVE',
  EC_ALREADY_CLIENT = 'EC_ALREADY_CLIENT',
}
