import { ConstitutionFormEnum, SocietaryTypeEnum } from '../enums/constitution-form.enum';

export function getConstitutionFormByCode(constitutionFormCode: string) {
  switch (constitutionFormCode) {
    case ConstitutionFormEnum.CLOSED_ANONYMOUS_SOCIETY:
    case ConstitutionFormEnum.OPEN_ANONYMOUS_SOCIETY:
      return SocietaryTypeEnum.SA;

    case ConstitutionFormEnum.PUBLIC_ENTERPRISE:
      return SocietaryTypeEnum.PUBLIC_ENTERPRISE;

    case ConstitutionFormEnum.MIXED_ECONOMY_SOCIETY:
      return SocietaryTypeEnum.MIXED_ECONOMY_SOCIETY;

    case ConstitutionFormEnum.LIMITED_ENTERPRISE_SOCIETY:
    case ConstitutionFormEnum.PURE_SIMPLE_SOCIETY:
    case ConstitutionFormEnum.SIMPLE_LIMITED_SOCIETY:
      return SocietaryTypeEnum.LTDA;

    case ConstitutionFormEnum.COLLECTIVE_NAME_ENTERPRISE_SOCIETY:
      return SocietaryTypeEnum.COLLECTIVE_NAME_ENTERPRISE_SOCIETY;

    case ConstitutionFormEnum.ACTION_COMMANDITE_ENTERPRISE_SOCIETY:
      return SocietaryTypeEnum.ACTION_COMMANDITE_ENTERPRISE_SOCIETY;

    case ConstitutionFormEnum.SIMPLE_COMMANDITE_ENTERPRISE_SOCIETY:
      return SocietaryTypeEnum.SIMPLE_COMMANDITE_ENTERPRISE_SOCIETY;

    case ConstitutionFormEnum.PARTICIPATION_ACCOUNT_SOCIETY:
      return SocietaryTypeEnum.PARTICIPATION_ACCOUNT_SOCIETY;

    case ConstitutionFormEnum.COOPERATIVE:
    case ConstitutionFormEnum.CONSUMPTION_COOPERATIVE:
      return SocietaryTypeEnum.SSC;

    case ConstitutionFormEnum.SOCIETY_CONSORTIUM:
      return SocietaryTypeEnum.SOCIETY_CONSORTIUM;

    case ConstitutionFormEnum.SOCIETY_GROUP:
      return SocietaryTypeEnum.SOCIETY_GROUP;

    case ConstitutionFormEnum.FOREIGN_HOME_ENTERPRISE:
      return SocietaryTypeEnum.FOREIGN_HOME_ENTERPRISE;

    case ConstitutionFormEnum.COLLECTIVE_NAME_SIMPLE_SOCIETY:
      return SocietaryTypeEnum.COLLECTIVE_NAME_SIMPLE_SOCIETY;

    case ConstitutionFormEnum.SIMPLE_CONSORTIUM:
      return SocietaryTypeEnum.SIMPLE_CONSORTIUM;

    case ConstitutionFormEnum.ENTERPRISE_NATURE_EIRELI:
    case ConstitutionFormEnum.SIMPLE_NATURE_EIRELI:
      return SocietaryTypeEnum.EIRELI;

    case ConstitutionFormEnum.PRIVATE_FOUNDATION:
      return SocietaryTypeEnum.PRIVATE_FOUNDATION;

    case ConstitutionFormEnum.SINDICAL_ENTITY:
      return SocietaryTypeEnum.SINDICAL_ENTITY;

    case ConstitutionFormEnum.FOREIGN_HOME_FOUNDATION_OR_ASSOCIATION:
      return SocietaryTypeEnum.FOREIGN_HOME_FOUNDATION_OR_ASSOCIATION;

    case ConstitutionFormEnum.RELIGIOUS_ORGANIZATION:
      return SocietaryTypeEnum.RELIGIOUS_ORGANIZATION;

    case ConstitutionFormEnum.SOCIAL_ORGANIZATION:
      return SocietaryTypeEnum.SOCIAL_ORGANIZATION;

    case ConstitutionFormEnum.PRIVATE_ASSOCIATION:
      return SocietaryTypeEnum.PRIVATE_ASSOCIATION;

    case ConstitutionFormEnum.RURAL_PRODUCTOR:
      return SocietaryTypeEnum.AUTONOMO;

    default:
      return SocietaryTypeEnum.OTHERS;
  }
}
