import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { PersonTypeEnum } from 'src/app/order/enums/person-type.enum';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { RangeByTechnologyModel } from 'src/app/shared/models/response/response-range-by-technology.model';
import { TechnologyModel } from 'src/app/shared/models/response/response-technology-model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class TechnologyService {
  public agentChannel: string;

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
  }

  public getServiceList(
    personType: PersonTypeEnum,
    idRange: number,
    serviceContract: number,
    idCampaign: string,
    modality: string,
    hideISO: boolean = false,
    technologyGroup?: string,
    cnae?: string
  ): Observable<TechnologyModel[]> {
    const technologyGroupQuery = technologyGroup ? `&technology-group=${technologyGroup}` : '';
    cnae = cnae ? cnae : '';
    // tslint:disable-next-line: max-line-length
    const query = `${environment.apiUrls.technologies}/${serviceContract}?modality=${modality}&ommitISO=${hideISO}&id-campaign=${idCampaign}${technologyGroupQuery}&id-range=${idRange}&person-type=${personType}&cnae=${cnae}`;
    return this.http.get<BaseConfigApiResponse<TechnologyModel[]>>(query).pipe(map((x) => (this.invalidResponse(x) ? null : x.data)));
  }

  public rangeByTechnology(
    personType: PersonTypeEnum,
    idRange: number,
    serviceId: string,
    idCampaign: string
  ): Observable<RangeByTechnologyModel[]> {
    return this.http.get<RangeByTechnologyModel[]>(
      `${environment.apiUrls.rangeByTechnology}/${idCampaign}?service-id=${serviceId}&id-range=${idRange}&person-type=${personType}`
    );
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<TechnologyModel[]>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || !baseApiResponse.data || !baseApiResponse.data.length;
  }
}
