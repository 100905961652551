import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// const REGEX_ACCOUNT = /^[0-9]{3,13}\-?[A-z0-9]{1,2}$/;
const REGEX_AGENCY = /^\d{4}$/;

// export const accountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
//   const accountValue = control.value;

//   if (accountValue) {
//     if (!REGEX_ACCOUNT.test(accountValue)) {
//       return { invalid: true };
//     }
//   }

//   return {};
// };

export function accountLengthValidator(minLength: number, maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value) {
      return {};
    }

    const value = control.value.replace(/\D/g, '');

    if (!value || !value.length) {
      return {};
    }

    if (value.length > maxLength) {
      return { maxlength: true };
    } else if (value.length < minLength) {
      return { minlength: true };
    } else {
      return {};
    }
  };
}

export const agencyValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const agencyValue = control.value;

  if (agencyValue) {
    if (!REGEX_AGENCY.test(agencyValue)) {
      return { invalid: true };
    }
  }

  return {};
};
