import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { BuildingTypeEnum } from 'src/app/order/enums/building-type-enum';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { DeliveryAddressModel, MailingAddressModel, Proposal, RegistrationDataAddressModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { AddressComponent } from '../../address/address.component';
import { FormStep } from '../../form-step';

@Component({
  selector: 'app-registration-data-address',
  templateUrl: './registration-data-address.component.html',
  styleUrls: ['./registration-data-address.component.scss'],
})
export class RegistrationDataAddressComponent extends FormStep implements OnInit, AfterContentChecked {
  get photoButtonHTML() {
    return this.renderCameraIcon() + ' <span>fotografar fachada</span>';
  }

  public registrationDataAddressModel: RegistrationDataAddressModel;
  public fields: FdFieldConfigs;
  public lastZipCodeValue: string;
  public genericZipCode = false;
  public buildingTypeItems: Item[] = [
    {
      label: 'Comercial',
      value: BuildingTypeEnum.COMMERCIAL,
    },
    {
      label: 'Móvel',
      value: BuildingTypeEnum.MOBILE,
    },
    {
      label: 'Residencial',
      value: BuildingTypeEnum.RESIDENTIAL,
    },
    {
      label: 'Sala',
      value: BuildingTypeEnum.ROOM,
    },
    {
      label: 'Shopping',
      value: BuildingTypeEnum.SHOPPING,
    },
  ];

  @ViewChildren(AddressComponent)
  public addressComponentList: QueryList<AddressComponent>;

  @ViewChild(AddressComponent)
  public addressComponent: AddressComponent;

  protected STEP_NUMBER = 2;
  show = false;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private cdRef: ChangeDetectorRef
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public createFields(): FdFieldConfigs {
    return {
      useAsDeliveryAddress: {
        controlName: 'useAsDeliveryAddress',
        items: [],
        messages: {
          required: 'Informe se deseja utilizar o mesmo endereço para entrega',
        },
      },
      useAsMailingAddress: {
        controlName: 'useAsMailingAddress',
        items: [],
        messages: {
          required: 'Informe se deseja utilizar o mesmo endereço para correspondência',
        },
      },
      buildingType: {
        label: 'Tipo do estabelecimento',
        items: this.buildingTypeItems,
        controlName: 'buildingType',
        messages: {
          required: 'Informe o tipo do estabelecimento',
        },
      },
      referencePoint: {
        label: 'Ponto de referência',
        controlName: 'referencePoint',
      },
      ...this.addressComponent.getFields(),
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      addressForm: this.addressComponent.getFormGroup(),
      useAsDeliveryAddress: ['', Validators.required],
      useAsMailingAddress: ['', Validators.required],
      buildingType: ['', Validators.required],
      referencePoint: [''],
    });
  }

  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.addressComponent.setFieldsState(false);
    this.registrationDataAddressModel = proposal.registrationDataAddress;
    if (this.registrationDataAddressModel) {
      this.addressComponent.setAddress(this.registrationDataAddressModel);
      this.formControls.useAsDeliveryAddress.setValue(proposal.registrationDataAddress.useAsDeliveryAddress);
      this.formControls.useAsMailingAddress.setValue(proposal.registrationDataAddress.useAsMailingAddress);
      (this.fields.buildingType as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === proposal.registrationDataAddress.buildingType)
      );
      this.formControls.buildingType.setValue(proposal.registrationDataAddress.buildingType);
      this.formControls.referencePoint.setValue(proposal.registrationDataAddress.referencePoint);
    } else {
      this.formControls.useAsDeliveryAddress.setValue(true);
      this.formControls.useAsMailingAddress.setValue(true);
    }
  }

  protected async persistData(): Promise<boolean> {
    if (!this.addressComponent.successCepCall) {
      return Promise.resolve(false);
    }

    const rawValue = this.formGroup.getRawValue();
    let deliveryAddress = new DeliveryAddressModel();
    let mailingAddress = new MailingAddressModel();

    if (this.formControls.useAsDeliveryAddress && !!this.formControls.useAsDeliveryAddress.value) {
      deliveryAddress = {
        ...rawValue.addressForm,
        referencePoint: rawValue.referencePoint,
        buildingType: rawValue.buildingType,
      };
      await this.dataStore.updateProposal({ deliveryAddress });
    }

    if (this.formControls.useAsMailingAddress && !!this.formControls.useAsMailingAddress.value) {
      mailingAddress = rawValue.addressForm;
      await this.dataStore.updateProposal({ mailingAddress });
    }

    const registrationDataAddress: RegistrationDataAddressModel = {
      ...rawValue.addressForm,
      genericZipCode: this.addressComponent.genericZipCode,
      useAsDeliveryAddress: rawValue.useAsDeliveryAddress,
      useAsMailingAddress: rawValue.useAsMailingAddress,
      referencePoint: rawValue.referencePoint,
      buildingType: rawValue.buildingType,
    };

    this.setStepJump(
      orderRoutingDefinitions.registrationData.stepNumber,
      orderRoutingDefinitions.registrationData.routes.DELIVERY_ADDRESS.order,
      !!rawValue.useAsDeliveryAddress
    );

    this.setStepJump(
      orderRoutingDefinitions.registrationData.stepNumber,
      orderRoutingDefinitions.registrationData.routes.MAILING_ADDRESS.order,
      !!rawValue.useAsMailingAddress
    );

    return this.dataStore.updateProposal({ registrationDataAddress });
  }

  private renderCameraIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'camera' });
    return iconDefinition.html;
  }
}
