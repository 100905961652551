<form [formGroup]="formGroup">
  <h4 class="fd-subtitle">Empresa conciliadora</h4>
  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.conciliatorEnterpriseDescription">
    </fd-input>
  </div>
  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.conciliatorEmail">
    </fd-input>
  </div>
  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.conciliatorEmail2">
    </fd-input>
  </div>

  <div class="button-container">
    <fd-button fdSecondary [content]="'Cancelar'" (trigger)="cancel()">
    </fd-button>
    <fd-button [content]="'Salvar'" (trigger)="save()">
    </fd-button>
  </div>
</form>
