import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { CompareTypeEnum } from '../enums/compare-type.enum';

function clearCompareErrors(control: AbstractControl) {
  if (control.hasError('greaterThan')) {
    delete control.errors.greaterThan;
    control.updateValueAndValidity();
  }
  if (control.hasError('lessThan')) {
    delete control.errors.lessThan;
    control.updateValueAndValidity();
  }
  if (control.hasError('greaterThanOrEqual')) {
    delete control.errors.greaterThanOrEqual;
    control.updateValueAndValidity();
  }
  if (control.hasError('lessThanOrEqual')) {
    delete control.errors.lessThanOrEqual;
    control.updateValueAndValidity();
  }
  if (control.hasError('equal')) {
    delete control.errors.equal;
    control.updateValueAndValidity();
  }
}

export function DateCompareValidator(compareControl: AbstractControl, compareTypeEnum: CompareTypeEnum): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!compareControl || !compareControl.value || !control || !control.value) {
      return {};
    }

    const date = moment(control.value, 'DD-MM-YYYY');
    const compareDate = moment(compareControl.value, 'DD-MM-YYYY');

    if (compareTypeEnum === CompareTypeEnum.GREATER_THAN) {
      if (date.isSameOrBefore(compareDate)) {
        return { greaterThan: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN) {
      if (date.isSameOrAfter(compareDate)) {
        return { lessThan: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.GREATER_THAN_OR_EQUAL) {
      if (date.isBefore(compareDate)) {
        return { greaterThanOrEqual: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN_OR_EQUAL) {
      if (date.isAfter(compareDate)) {
        return { lessThanOrEqual: true };
      }
    } else if (compareTypeEnum === CompareTypeEnum.EQUAL) {
      if (!date.isSame(compareDate)) {
        return { equal: true };
      }
    }
    clearCompareErrors(compareControl);
    return {};
  };
}
