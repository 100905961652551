import { Component } from '@angular/core';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';

@Component({
  selector: 'app-approved-proposal',
  templateUrl: './approved-proposal.component.html',
  styleUrls: ['./approved-proposal.component.scss'],
})
export class ApprovedProposalComponent {
  constructor(private routingService: RoutingService) {}

  public navigateToHome() {
    this.routingService.navigateToHome();
  }
}
