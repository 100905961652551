<mat-form-field class="fd-value-editor__container" color="accent">
  <div [formGroup]="parentForm">
    <input
      matInput
      readonly
      [formControlName]="field?.controlName"
      autocomplete="off"
      class="fd-value-editor__field"
      [placeholder]="field?.label"
      [textMask]="{ mask: field?.mask || false, guide: true, placeholderChar: '\u2000' }"
      [id]="field?.controlName"
      [required]="field?.required || null"
      aria-label="Area code"
      [type]="field?.type || 'text'"
      autocomplete="off"
      tabindex="0"
    />
  </div>
  <fa-icon *ngIf="!disabled" (click)="openValueEditorDialog()" class="fd-value-editor__edit-icon" icon="pencil-alt" matSuffix></fa-icon>
  <fa-icon *ngIf="disabled" class="fd-value-editor__edit-icon-disabled" icon="pencil-alt" matSuffix></fa-icon>

  <mat-error *ngIf="hasError">
    <div *ngFor="let errorMessage of errorMessages">{{ errorMessage }}</div>
  </mat-error>
</mat-form-field>
