<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Pin Pad FIRST DATA solicitado no Vapp:</h4>
    <div *ngFor="let item of pinPadFirstDataOptions; let i = index" class="checkbox-container">
      <mat-checkbox (change)="onCheckboxChange(item, $event)" [value]="item.value" [checked]="item.selected"
        class="fd-checkbox" [id]="item.value">{{item.label}}</mat-checkbox>
      <div *ngIf="i === 0 ? hasUsb : hasSerial">
        <fd-input [parentForm]="formGroup" [field]="i === 0 ? fields.amountUsb : fields.amountSerial">
        </fd-input>
      </div>
    </div>

  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>
</form>
