import { Pipe, PipeTransform } from '@angular/core';
import { WeekDayEnum } from 'src/app/order/enums/week-day.enum';

@Pipe({
  name: 'weekDay',
})
export class WeekDayPipe implements PipeTransform {
  public transform(value: WeekDayEnum, args?: any): string {
    switch (value) {
      case WeekDayEnum.SUNDAY:
        return 'Domingo';
      case WeekDayEnum.MONDAY:
        return 'Segunda-feira';
      case WeekDayEnum.TUESDAY:
        return 'Terça-feira';
      case WeekDayEnum.WEDNESDAY:
        return 'Quarta-feira';
      case WeekDayEnum.THURSDAY:
        return 'Quinta-feira';
      case WeekDayEnum.FRIDAY:
        return 'Sexta-feira';
      case WeekDayEnum.SATURDAY:
        return 'Sábado';
    }
  }
}
