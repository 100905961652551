<h4 class="fd-subtitle">Fator</h4>

<form *ngIf="formGroup" [formGroup]="formGroup">
  <table mat-table [dataSource]="factorDataSource" class="fd-table">
    <!-- ID Column -->
    <ng-container matColumnDef="factorFlag" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let row">{{ row.flag }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="factorTransaction" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Transação</th>
      <td mat-cell *matCellDef="let row">{{ row.transaction }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="factorFee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Taxa</th>
      <td mat-cell #factorRow *matCellDef="let row; let i = index">
        <div formArrayName="factorFeeForms">
          <div [formGroupName]="i">
            <fd-fee-editor
              (openDialogFn)="openFactorFeeEditorModal($event, i)"
              [parentForm]="factorFeeFormsControls[i]"
              [field]="fieldsArray[i].suggestedFactorFee"
            ></fd-fee-editor>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Color Column -->

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedFactorColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedFactorColumns"></tr>
  </table>

  <h4 class="fd-subtitle">{{ getLabel() }}</h4>

  <table mat-table [dataSource]="mdrFlexDataSource" class="fd-table">
    <!-- ID Column -->
    <ng-container matColumnDef="mdrFlexFlag" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Bandeira</th>
      <td mat-cell *matCellDef="let row">{{ row.flag ? row.flag : 'Todas' }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="mdrFlexTransaction" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Transação</th>
      <td mat-cell *matCellDef="let row">{{ row.transaction }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="mdrFlexFee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Taxa</th>
      <ng-container *matCellDef="let row; let i = index">
        <td mat-cell *ngIf="row.feeType === 'TAX' || !isCnaeLoterica">
          <div #mdrFlexRow formArrayName="mdrFlexFeeForms" class="fd-table__form-container">
            <div [formGroupName]="i">
              <fd-fee-editor
                [canEdit]="canEdit(row.modality)"
                (openDialogFn)="openMdrFlexFeeEditorModal($event, i)"
                [parentForm]="mdrFlexFeeFormsControls[i]"
                [field]="fieldsArray[i].suggestedMdrFlexFee"
              ></fd-fee-editor>

              <ng-container *ngIf="shouldShowAlert(i)">
                <fa-icon
                  matTooltipClass="fd-input__tooltip-dialog"
                  class="tooltip-icon"
                  icon="info-circle"
                  size="1x"
                  mat-button
                  [matTooltip]="UNDER_MINIMUM_LIMIT_TEXT"
                  #tooltip="matTooltip"
                  (click)="showTooltipAndStopPropagation()"
                >
                </fa-icon>
              </ng-container>
            </div>
          </div>
        </td>
        <td mat-cell #row *ngIf="row.feeType === 'CURRENCY' && isCnaeLoterica">--</td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="calculatedFee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Taxa Calc.</th>
      <ng-container *matCellDef="let row; let i = index">
        <td mat-cell *ngIf="row.feeType === 'TAX' || !isCnaeLoterica">
          <div formArrayName="mdrFlexFeeForms">
            <div [formGroupName]="i">
              {{ row?.calculatedMdrFlexFee ? getValueWithDecimalPlaces(row?.calculatedMdrFlexFee) + '%' : '------' }}
            </div>
          </div>
        </td>
        <td mat-cell #row *ngIf="row.feeType === 'CURRENCY' && isCnaeLoterica">--</td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isCnaeLoterica" matColumnDef="tariffValue" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Tarifa</th>
      <ng-container *matCellDef="let row">
        <td mat-cell *ngIf="row.feeType === 'CURRENCY'">R${{ row.tariffValue }}</td>
        <td mat-cell *ngIf="row.feeType === 'TAX'">--</td>
      </ng-container>
    </ng-container>

    <!-- Color Column -->

    <ng-container *ngIf="!isCnaeLoterica">
      <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedMdrFlexColumns"></tr>
      <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedMdrFlexColumns"></tr
    ></ng-container>

    <ng-container *ngIf="isCnaeLoterica">
      <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedMdrFlexColumnsLottery"></tr>
      <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedMdrFlexColumnsLottery"></tr>
    </ng-container>
  </table>
</form>

<div *ngIf="isCEF" class="cef-disclaimer">
  As condições comerciais apresentadas nesta simulação referem-se ao item 6 da Solicitação de Credenciamento à CAIXA Pagamentos/FISERV,
  estabelecidas de acordo com o faturamento informado para o novo domicílio e o ramo de atividade do cliente, as quais estão sujeitas a
  aprovação da credenciadora.
</div>

<fd-button *ngIf="!isCEF" fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
<fd-button *ngIf="!isCEF" [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
<fd-button *ngIf="isCEF" [content]="'finalizar'" id="btnSave" (trigger)="saveSimulation()"> </fd-button>
