import { Component, Input, OnInit } from '@angular/core';
import { ContractedServicesDetails } from '../../models/child-models/contracted-services-details.model';
import { RateDataDetails } from '../../models/child-models/rate-data-details.model';

@Component({
  selector: 'app-contracted-services-data-detail',
  templateUrl: './contracted-services-data-detail.component.html',
  styleUrls: ['./contracted-services-data-detail.component.scss'],
})
export class ContractedServicesDataDetailComponent {
  @Input()
  public contractedServices: ContractedServicesDetails;
  @Input()
  public rateData: RateDataDetails[];

  constructor() {}
}
