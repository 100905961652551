export class EdiAdditionalDataModel {
  public ediLayout: string;
  public conciliatorEnterpriseDescription: string;
  public conciliatorCnpj: string;
  public conciliatorEmail: string;
  public conciliatorEmail2: string;
  public idConciliatorCompany: number;
  public id: number;
  public acquirers: EdiAcquirerDTO[] = [];
}

export class EdiAcquirerDTO {
  public idAcquirerConciliator: number;

  constructor(idAcquirerConciliator: number) {
    this.idAcquirerConciliator = idAcquirerConciliator;
  }
}

export class AcquirerConciliator {
  public id: number;
  public name: string;
}

export enum ConciliatorEnum {
  CONCILIATOR_SE = 1,
  CAIXA_PROPRIA = -1,
}
