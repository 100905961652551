<mat-card class="fd-banking-data-detail__card-container" *ngIf="bankData">
  <mat-card-title class="fd-banking-data-detail__card-title">Dados Bancários</mat-card-title>
  <ng-container>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-banking-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>Tipo da Conta</strong>
          <div>
            <span>{{ bankData?.accountType }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Banco</strong>
          <div>
            <span>{{ bankData?.bankNumber }} - {{ bankData?.bankName }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Agência</strong>
          <div>
            <span>{{ bankData?.agencyNumber }}</span>
          </div>
        </div>
        <div fxFlex="20" *ngIf="bankData?.operationCode">
          <strong>Código de Operação</strong>
          <div>
            <span>{{ bankData?.operationCode }}</span>
          </div>
        </div>
        <div fxflexfill>
          <strong>Conta</strong>
          <div>
            <span>{{ bankData?.accountNumber }}-{{ bankData?.accountDigit }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
