<h4 class="fd-subtitle">Indique o tipo de taxa de desconto da transação (MDR):</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-radio-button [parentForm]="formGroup" (change)="onChangeFeeType($event)" [field]="fields.feeType"> </fd-radio-button>
    </div>
    <ng-container *ngIf="(feeType === 'MDR' && configuration && configuration.ableAnticipation) || hasSplitTech()">
      <h4 class="fd-subtitle">Habilitar antecipação automática de recebimentos?</h4>
      <div>
        <fd-radio-button [parentForm]="formGroup" (change)="onChangeEnablePrepayment($event)" [field]="fields.enablePrepayment">
        </fd-radio-button>
      </div>
    </ng-container>
    <div *ngIf="formControls.enablePrepayment.value && ableExternalSchedule">
      <h4 class="fd-subtitle">Selecione a opção de antecipação:</h4>
      <div>
        <fd-radio-button [parentForm]="formGroup" (change)="onChangeAnticipationExternal($event)" [field]="fields.anticipationExternal">
        </fd-radio-button>
      </div>
    </div>
    <ng-container *ngIf="feeType === 'MDRFLEX'">
      <h4 class="fd-subtitle">Prazo de recebimentos?</h4>
      <div>
        <fd-radio-button [parentForm]="formGroup" [field]="fields.receivingDeadline"> </fd-radio-button>
      </div>
    </ng-container>

    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
  </div>
</form>
