import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private changeSelectorSubChannelAgent = new BehaviorSubject<boolean>(false);

  public get currentChange() {
    return this.changeSelectorSubChannelAgent.asObservable();
  }

  public hasChangeSelectorHierarchyInformation() {
    this.changeSelectorSubChannelAgent.next(true);
  }
}
