<form *ngIf="formGroup && fields" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">PEP, afiliações e relacionamento com Órgãos do Governo</h4>

    <!-- PEP -->
    <div>
      <span class="fd-text">{{ getPepPartnersText() }}</span>
      <fa-icon
        matTooltipClass="fd-input__tooltip-dialog"
        class="tooltip-icon"
        icon="info-circle"
        size="1x"
        mat-button
        [matTooltip]="getPepTooltipText()"
        #tooltip="matTooltip"
        (click)="showTooltipAndStopPropagation()"
      ></fa-icon>
    </div>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasPepPartners"> </fd-radio-button>
    </div>
    <div *ngIf="hasPepPartners">
      <h4 class="fd-subtitle">Por favor, forneça detalhes:</h4>
      <fd-input [parentForm]="formGroup" [field]="fields.pepDetails"> </fd-input>
    </div>

    <!-- Fiserv relatives -->
    <span class="fd-text">{{ getFiservRelationText() }}</span>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasPartnersFiservRelation"> </fd-radio-button>
    </div>
    <div *ngIf="hasRelatives">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.relativeName"></fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.relativeArea"></fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.relativeRole"></fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.relativeRelationship"></fd-input>
      </div>
    </div>

    <!-- Public organizations -->
    <span class="fd-text">{{ getPublicOrganizationParticipationText() }}</span>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasPublicOrganizationParticipation">
      </fd-radio-button>
    </div>
    <div *ngIf="hasPublicOrganizationParticipation">
      <h4 class="fd-subtitle">Por favor, forneça detalhes:</h4>
      <fd-input [parentForm]="formGroup" [field]="fields.publicOrganizationParticipationDetails"> </fd-input>
    </div>

    <!-- Government Benefits -->
    <span class="fd-text">{{ getGovernmentBenefitsText() }}</span>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasGovernmentBenefits"> </fd-radio-button>
    </div>
    <div *ngIf="hasGovernmentBenefits">
      <h4 class="fd-subtitle">Por favor, forneça detalhes:</h4>
      <fd-input [parentForm]="formGroup" [field]="fields.governmentBenefitsDetails"> </fd-input>
    </div>

    <!-- Public capital -->
    <span class="fd-text">{{ getReceivedPublicCapitalText() }}</span>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.hasReceivedPublicCapital"> </fd-radio-button>
    </div>
    <div *ngIf="hasReceivedPublicCapital">
      <h4 class="fd-subtitle">Por favor, forneça detalhes:</h4>
      <fd-input [parentForm]="formGroup" [field]="fields.publicCapitalDetails"> </fd-input>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'próxima etapa'" (trigger)="next()"> </fd-button>
  </div>
</form>
