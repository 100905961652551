<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Integrador</h4>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.integratorEnterpriseName"> </fd-input>
    </div>

    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.contactPhoneNumber1"> </fd-input>
    </div>

    <h4 class="fd-subtitle">Responsável pelo TI da integradora</h4>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.itResponsibleName"> </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.itResponsiblePhoneNumber1"> </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.itResponsibleEmail"> </fd-input>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.itResponsibleEmail2"> </fd-input>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
