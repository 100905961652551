import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { ConciliatorEnum, EdiAcquirerDTO, EdiAdditionalDataModel } from '../models/edi-additional-data.model';

import { finalize } from 'rxjs/operators';
import { cnpjMask } from 'src/app/order/masks/document-masks';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { FdSelectConfig, Item } from '../../../../../shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from '../../../../messages/order.messages';
import { ConciliatorService } from '../../../../services/external/conciliator/conciliator.service';
import { OtherEdiDataModalComponent } from './modal/other-edi-data-modal.component';
import { MultiAcquirerComponent } from './multi-acquirer/multi-acquirer.component';

@Component({
  selector: 'app-edi-additional-data',
  templateUrl: './edi-additional-data.component.html',
  styleUrls: ['./edi-additional-data.component.scss'],
})
export class EdiAdditionalDataComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public conciliatorValue: Item = {
    label: 'Conciliador Software Express',
    value: ConciliatorEnum.CONCILIATOR_SE,
  };

  public ediAdditionalDataModel: EdiAdditionalDataModel = new EdiAdditionalDataModel();
  public Conciliador: any = 'Conciliador';

  public conciliatorList: Item[];

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    protected conciliatorService: ConciliatorService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.getConciliators();
  }

  public getConciliators() {
    this.conciliatorService.getConciliators().subscribe(
      (item) => {
        this.conciliatorList = this.mapEdiToModels(item);
        this.listConciliators('', this.fields.conciliator);
      },
      () => this.dialogService.openDialog(Messages.GENERAL_ERROR)
    );
  }

  public persistData() {
    const ediAdditionalData: EdiAdditionalDataModel = {
      ...this.formGroup.getRawValue(),
    };

    if (this.ediAdditionalDataModel) {
      ediAdditionalData.idConciliatorCompany = this.ediAdditionalDataModel.idConciliatorCompany;
      ediAdditionalData.acquirers = this.ediAdditionalDataModel.acquirers;
    }

    return this.dataStore.updateProposal({ ediAdditionalData });
  }

  public setValuesConciliator(conciliator: EdiAdditionalDataModel) {
    this.formControls.conciliatorEnterpriseDescription.setValue(conciliator.conciliatorEnterpriseDescription);
    this.formControls.conciliatorCnpj.setValue(conciliator.conciliatorCnpj);
    this.formControls.conciliatorEmail.setValue(conciliator.conciliatorEmail);
    this.formControls.conciliatorEmail2.setValue(conciliator.conciliatorEmail2);
    this.formControls.ediLayout.setValue(conciliator.ediLayout);
  }

  public selectConciliator() {
    if (!this.formGroup.controls.conciliator.value) {
      return;
    }
    this.ediAdditionalDataModel = new EdiAdditionalDataModel();
    this.setValuesConciliator(this.ediAdditionalDataModel);
    this.ediAdditionalDataModel.idConciliatorCompany = this.formGroup.controls.conciliator.value;
    if (this.formGroup.controls.conciliator.value === ConciliatorEnum.CAIXA_PROPRIA) {
      this.openOtherConciliatorModal();
    } else {
      this.getConciliatorById();
    }
  }

  public listConciliators(text: string = '', field: FdFieldConfig) {
    if (!this.conciliatorList || !this.conciliatorList.length) {
      return;
    }
    if (field) {
      (field as FdSelectConfig).items = [];

      const itemsToAdd = this.conciliatorList
        .filter((item) => item.label.match(text.toUpperCase()))
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));
      (field as FdSelectConfig).items.push(...itemsToAdd);
    }
  }

  public createFormGroup() {
    return this.formBuilder.group({
      ediLayout: [''],
      conciliatorEnterpriseDescription: ['', [Validators.required]],
      conciliatorCnpj: [''],
      conciliatorEmail: [''],
      conciliatorEmail2: [''],
      conciliator: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.ediAdditionalDataModel = proposal.ediAdditionalData;
    if (this.ediAdditionalDataModel) {
      this.formControls.ediLayout.setValue(this.ediAdditionalDataModel.ediLayout);
      this.formControls.conciliatorEnterpriseDescription.setValue(this.ediAdditionalDataModel.conciliatorEnterpriseDescription);
      this.formControls.conciliatorCnpj.setValue(this.ediAdditionalDataModel.conciliatorCnpj);
      this.formControls.conciliatorEmail.setValue(this.ediAdditionalDataModel.conciliatorEmail);
      this.formControls.conciliatorEmail2.setValue(this.ediAdditionalDataModel.conciliatorEmail2);
      if (this.ediAdditionalDataModel.idConciliatorCompany) {
        this.formControls.conciliator.setValue(this.ediAdditionalDataModel.idConciliatorCompany);
        if (this.ediAdditionalDataModel.idConciliatorCompany !== ConciliatorEnum.CAIXA_PROPRIA) {
          this.getConciliatorById();
        }
      }
    } else {
      this.ediAdditionalDataModel = new EdiAdditionalDataModel();
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      conciliator: {
        label: 'Empresa Conciliadora',
        items: [],
        controlName: 'conciliator',
        searchPlaceholder: 'Buscar',
        searchable: true,
        messages: {
          required: 'Informe a Empresa Conciliadora',
          invalid: 'Banco inválido',
          pattern: 'Banco inválido',
        },
      },
      ediLayout: {
        label: 'Descreva',
        controlName: 'ediLayout',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      conciliatorEnterpriseDescription: {
        label: 'Descreva',
        controlName: 'conciliatorEnterpriseDescription',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      conciliatorCnpj: {
        label: 'CNPJ',
        maskCharsReplace: /[.\/ -]/g,
        mask: cnpjMask,
        controlName: 'conciliatorCnpj',
        messages: {
          invalidCnpj: 'CNPJ inválido',
        },
      },
      conciliatorEmail: {
        label: 'E-mail 1',
        controlName: 'conciliatorEmail',
        messages: {
          pattern: 'E-mail inválido',
        },
      },
      conciliatorEmail2: {
        label: 'E-mail 2',
        controlName: 'conciliatorEmail2',
        messages: {
          pattern: 'E-mail inválido',
        },
      },
    };
  }

  private mapEdiToModels(response: EdiAdditionalDataModel[]): Item[] {
    if (!response) {
      return [];
    }
    const items = response.map((value) => ({
      value: value.id,
      label: value.conciliatorEnterpriseDescription,
    }));

    items.push({
      value: ConciliatorEnum.CAIXA_PROPRIA,
      label: 'CAIXA PROPRIA',
    });
    return items;
  }

  private getConciliatorById() {
    this.conciliatorService
      .getEmailsConciliatorById(this.formGroup.controls.conciliator.value)

      .subscribe(
        (conciliator) => {
          this.setValuesConciliator(conciliator);
          if (conciliator.id === ConciliatorEnum.CONCILIATOR_SE) {
            this.openMultiAcquirerModal(ConciliatorEnum.CONCILIATOR_SE, this.ediAdditionalDataModel.acquirers);
          }
        },
        () => this.dialogService.openDialog(Messages.GENERAL_ERROR)
      );
  }

  private openMultiAcquirerModal(id: number, acquires: EdiAcquirerDTO[]) {
    const dialogRef = this.dialog.open(MultiAcquirerComponent, {
      width: '40%',
      height: '65%',
      data: { id, acquires },
    });

    dialogRef.afterClosed().subscribe((item) => {
      if (item && item.acquires) {
        this.ediAdditionalDataModel.acquirers = item.acquires;
      }
    });
  }

  private openOtherConciliatorModal() {
    const dialogRef = this.dialog.open(OtherEdiDataModalComponent, {
      width: '40%',
      height: '65%',
      data: { undefined },
    });

    dialogRef.afterClosed().subscribe((item) => {
      if (item && item.conciliator) {
        this.setValuesConciliator(item.conciliator);
      }
    });
  }
}
