<h4 class="fd-subtitle">Endereço de entrega da maquininha:</h4>
<app-address></app-address>
<div *ngIf="formGroup"  [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="center stretch">
  <div *ngIf="fields">
    <div>
      <fd-select [parentForm]="formGroup" [field]="fields.buildingType"></fd-select>
    </div>
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.referencePoint"></fd-input>
    </div>
    <h4 class="fd-subtitle">Deseja utilizar esse endereço para correspondência?</h4>
    <div>
      <fd-radio-button [yesOrNotOptions]="true" [parentForm]="formGroup" [field]="fields.useAsMailingAddress">
      </fd-radio-button>
    </div>
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()">
    </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()">
    </fd-button>
  </div>

</div>
