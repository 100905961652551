import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ValueEditorDialogData } from 'src/app/order/components/modals/value-editor-modal/value-editor-modal.component';
import { FdFeeEditorConfig } from '../fd-fee-editor/fd-fee-editor.component';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { FdInputConfig, InputType, Mask } from '../fd-input/fd-input.component';

@Component({
  selector: 'fd-value-editor',
  templateUrl: './fd-value-editor.component.html',
  styleUrls: ['./fd-value-editor.component.scss'],
})
export class FdValueEditorComponent extends FdFieldBaseComponent implements OnInit {
  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    this.cdr.detectChanges();
    return this.hasError;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }

  @Input()
  public field: FdValueEditorConfig;

  @Input()
  public parentForm: FormGroup;

  @Output()
  public openDialogFn = new EventEmitter<ValueEditorDialogData>();

  @Output()
  public infiniteScroll = new EventEmitter<void>();

  public limit = 10;
  public offset = 0;
  public controlName: string;
  public context: any;
  public label: string;
  public accessibilityLabel?: string;
  public placeholder?: string;
  public hint?: string;
  public messages?: { [key: string]: string };
  public searchable = false;
  @Input()
  public disabled = false;

  private _filter: string;
  private _loadError: boolean;

  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog) {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public async ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public openValueEditorDialog(): void {
    const dialogData: ValueEditorDialogData = {
      title: this.field.modalTitle,
      formControl: this.relatedFormControl,
      formGroup: this.parentForm,
      field: this.field,
    };

    if (this.openDialogFn) {
      this.openDialogFn.emit(dialogData);
    }
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

export interface FdValueEditorConfig {
  controlName: string;
  label: string;
  required?: boolean;
  type?: InputType;
  mask?: Mask | ((value: string) => Mask);
  modalTitle?: string;
  itemDescription?: string;
  valueEditorLabel?: string;
  childControl?: FdInputConfig | FdFeeEditorConfig;
  disabled?: boolean;
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
}
