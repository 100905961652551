<div class="fd-proposal-card" [title]="'Clique para ver detalhes'">
  <div class="fd-proposal-card__title">
    <div class="fd-proposal-card__status-all">
      <img class="fd-proposal-card__status-img" [src]='imagePath'>
      <span class="fd-proposal-card__title-text" [ngClass]="{'fd-proposal-card__title-text--orange': proposalData.generalProposalStatus === 'AWAITING PAYMENT' && proposalData.paymentStatusCode !== '04',
                          'fd-proposal-card__title-text--green': proposalData.generalProposalStatus === 'APPROVED',
                          'fd-proposal-card__title-text--red': proposalData.generalProposalStatus === 'DENIED' || proposalData.generalProposalStatus === 'DENIED - INTEGRATOR' ,
                          'fd-proposal-card__title-text--yellow': proposalData.generalProposalStatus === 'IN ANALYSIS' ||  (proposalData.generalProposalStatus === 'AWAITING PAYMENT' && proposalData.paymentStatusCode === '04') || proposalData.generalProposalStatus === 'IN CREDIT MANUAL ANALYSIS' || proposalData.generalProposalStatus === 'IN COMPLIANCE MANUAL ANALYSIS'
                          || proposalData.generalProposalStatus === 'IN PRICE MANUAL ANALYSIS'  || proposalData.generalProposalStatus === 'IN ANALYSIS SERPRO UNAVALIABLE'
                        }"> {{ cardTitle }}</span>

      <div (click)="navigateToPaymentData()" *ngIf="proposalData.generalProposalStatus === 'AWAITING PAYMENT' && proposalData.paymentStatusCode !== PaymentStatusCodeEnum.NOT_APPLICABLE">
        <a class="fd-proposal-card__pay-hyphen">-</a>
        <a class="fd-proposal-card__pay-button">Pagar agora</a>
      </div>

      <div (click)="navigateToPaymentData()" *ngIf="proposalData.generalProposalStatus === 'NOT FINISHED'">
        <a class="fd-proposal-card__pay-hyphen">-</a>
        <a class="fd-proposal-card__pay-button">Continuar preenchendo</a>
      </div>
    </div>
    <div *ngIf="proposalData.merchantId" class="fd-proposal-card__merchantid">
      <div class="fd-proposal-card__information">{{proposalData.merchantId}} </div>
    </div>

  </div>
  <div class="fd-proposal-card__card-data" (click)="navigateToDetail()">

    <div class="fd-proposal-card__information-wrapper">
      <div class="fd-proposal-card__information-title"><strong>{{ isCPF ? 'CPF' : 'CNPJ'}}</strong></div>
      <div class="fd-proposal-card__information">{{proposalData.cpfCnpj}}</div>
    </div>
    <div class="fd-proposal-card__information-wrapper">
      <div class="fd-proposal-card__information-title"><strong>{{ isCPF ? 'Nome' : 'Razão Social'}}</strong></div>
      <div class="fd-proposal-card__information">{{proposalData.description}}</div>
    </div>

    <div class="fd-proposal-card__information-wrapper">
      <div class="fd-proposal-card__information-title"><strong>Data do pedido</strong></div>
      <div class="fd-proposal-card__information">{{proposalData.submissionDate}}</div>

    </div>

  </div>
</div>
