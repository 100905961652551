import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConclusionPhaseEnum } from 'src/app/order/enums/conclusion-phase.enum';

@Injectable({
  providedIn: 'root'
})
export class ConclusionPhaseService {
  private conclusionPhase: ConclusionPhaseEnum;
  private conclusionPhase$: BehaviorSubject<ConclusionPhaseEnum>;

  constructor() {
    this.conclusionPhase = ConclusionPhaseEnum.PENDING;
    this.conclusionPhase$ = new BehaviorSubject(this.conclusionPhase);
  }

  public getPhase(): ConclusionPhaseEnum {
    return this.conclusionPhase$.getValue();
  }

  public setPhase(phase: ConclusionPhaseEnum) {
    this.conclusionPhase = phase;
    this.conclusionPhase$.next(this.conclusionPhase);
  }
}
