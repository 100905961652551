import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { InputType, Mask } from '../fd-input/fd-input.component';

@Component({
  selector: 'fd-search-bar',
  templateUrl: './fd-search-bar.component.html',
  styleUrls: ['./fd-search-bar.component.scss'],
})
export class FdSearchBarComponent extends FdFieldBaseComponent implements OnDestroy {
  get required(): boolean {
    return false;
  }

  get hasIcon(): boolean {
    return this.field && !!this.field.iconHTML;
  }
  public static nextId = 0;
  public alive = true;

  @Input()
  public field: FdInputConfig;

  @Output()
  public input = new EventEmitter<string>();

  @Output()
  public blur = new EventEmitter<string>();

  public stateChanges = new Subject<void>();
  public focused = false;
  public errorState = false;
  public controlType = 'fd-input';
  public describedBy = '';
  private readonly REQUIRED_VALIDATOR_KEY = 'required';

  // get relatedFormControl(): AbstractControl{
  //   return this.parentForm && this.parentForm.get(this.field.controlName);
  // }

  // get hasError(){
  //   return this.relatedFormControl && this.relatedFormControl.errors !== null;
  // }

  // get errorMessages(){
  //   return Object.keys(this.field.messages)
  //                   .filter(val => this.relatedFormControl.errors[val])
  //                   .map(key => this.field.messages[key]);
  // }

  constructor(
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    focusMonitor
      .monitor(elementRef, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe((origin) => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  public onChange = (_: any) => {};
  public onTouched = () => {};

  // unmask(control: AbstractControl, maskCharsReplace: RegExp) : string {
  //   let unmaskedValue = '';
  //   control.valueChanges
  //     .pipe(takeWhile(() => this.alive))
  //     .pipe(
  //       tap((value: string = '') => {
  //         if (value) {
  //           unmaskedValue = value.replace(maskCharsReplace, '').trim();
  //           control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
  //         }
  //     }))
  //     .subscribe();

  //   return unmaskedValue;
  // }

  public ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }

  public handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }
}

export interface FdInputConfig {
  controlName: string;
  label: string;
  hint?: string;
  iconHTML?: SafeHtml;
  maxLength?: number;
  disabled?: boolean;
  type?: InputType;
  mask?: Mask | ((value: string) => Mask);
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
}
