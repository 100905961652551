<h4 class="fd-subtitle">Selecione o tipo de credenciamento:</h4>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-radio-button [parentForm]="formGroup" [field]="fields.offerType"> </fd-radio-button>
    </div>
    <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
  </div>
</form>
