import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefProviderInformationModel } from '../models/tef-provider-information.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-tef-provider-information',
  templateUrl: './tef-provider-information.component.html',
  styleUrls: ['./tef-provider-information.component.scss'],
})
export class TefProviderInformationComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public connectionTypes: Item[] = [
    {
      label: 'TEF IP',
      value: ConnectionTypeEnum.TEF_IP,
    },
    {
      label: 'TEF X25',
      value: ConnectionTypeEnum.TEF_X25,
    },
  ];

  public tefProviderTypes: Item[] = [
    {
      label: 'SiTef (SoftwareExpress)',
      value: TefProviderEnum.SITEF,
    },
    {
      label: 'SCOPE (Itautec/OKI Brasil)',
      value: TefProviderEnum.SCOPE,
    },
    {
      label: 'Direção (Linx)',
      value: TefProviderEnum.DIRECTION,
    },
    {
      label: 'CTF (auttar)',
      value: TefProviderEnum.CTF,
    },
    {
      label: 'Pay&Go (NTK)',
      value: TefProviderEnum.PAY_AND_GO,
    },
    {
      label: 'VBI',
      value: TefProviderEnum.VBI,
    },
  ];

  public tefProviderInformationModel: TefProviderInformationModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public onConnectionTypeCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.connectionTypes.setValue(this.connectionTypes.filter((x) => x.selected).map((x) => x.value));
  }

  public onProviderTypeCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
    this.formControls.tefProviderTypes.setValue(this.tefProviderTypes.filter((x) => x.selected).map((x) => x.value));
  }

  public next(): void {
    this.submitted = true;

    if (!this.formControls.connectionTypes.valid) {
      this.dialogService.openDialog(Messages.MISSING_TEF_CONNECTION_TYPE);
      return;
    }

    if (!this.formControls.tefProviderTypes.valid) {
      this.dialogService.openDialog(Messages.MISSING_TEF_PROVIDER_TYPE);
      return;
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefProviderInformation: TefProviderInformationModel = {
      ...this.formGroup.getRawValue(),
      connectionTypes: this.formControls.connectionTypes.value,
      tefProviderTypes: this.formControls.tefProviderTypes.value,
    };

    return this.dataStore.updateProposal({ tefProviderInformation });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      connectionTypes: ['', Validators.required],
      tefProviderTypes: ['', Validators.required],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefProviderInformationModel = proposal.tefProviderInformation;

    if (this.tefProviderInformationModel) {
      this.connectionTypes.map((x) => (x.selected = this.tefProviderInformationModel.connectionTypes.indexOf(x.value) > -1));
      this.tefProviderTypes.map((x) => (x.selected = this.tefProviderInformationModel.tefProviderTypes.indexOf(x.value) > -1));
      this.formControls.connectionTypes.setValue(this.tefProviderInformationModel.connectionTypes);
      this.formControls.tefProviderTypes.setValue(this.tefProviderInformationModel.tefProviderTypes);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {};
  }
}

export enum ConnectionTypeEnum {
  TEF_IP = 'TEF_IP',
  TEF_X25 = 'TEF_X25',
}

export enum TefProviderEnum {
  SITEF = 'SITEF',
  SCOPE = 'SCOPE',
  DIRECTION = 'DIRECTION',
  CTF = 'CTF',
  PAY_AND_GO = 'PAY_AND_GO',
  VBI = 'VBI',
}
