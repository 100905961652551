import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const subStepCollapse: AnimationTriggerMetadata = trigger('subStepCollapse', [
  state(
    'stepInactive',
    style({
      transform: 'translateX(-100%)',
      opacity: 0,
    })
  ),
  state(
    'stepActive',
    style({
      transform: 'translateX(0%)',
      opacity: 1,
    })
  ),
  state(
    'stepDone',
    style({
      transform: 'translateX(-100%)',
      opacity: 0,
    })
  ),
  transition('* <=> stepActive', [animate('1s ease-in-out')]),
  transition('stepActive <=> stepDone', [animate('1s ease-in-out')]),
]);

export const ballColor: AnimationTriggerMetadata = trigger('ballColor', [
  state(
    'active',
    style({
      'background-color': '#43b02a',
    })
  ),
  state(
    'inactive',
    style({
      'background-color': '#ffffff',
    })
  ),
  transition('active <=> inactive', [animate('0.5s ease-in-out')]),
]);

export const lineCollapse: AnimationTriggerMetadata = trigger('lineCollapse', [
  state(
    'collapse',
    style({
      left: 0,
    })
  ),
  state(
    'end',
    style({
      left: 'auto',
      right: '50%',
    })
  ),
  transition('* <=> collapse', [animate('1.5s ease-in-out')]),
  transition('* <=> end', [animate('5s ease-in-out')]),
]);

export const stepCollapse: AnimationTriggerMetadata = trigger('stepCollapse', [
  state(
    'collapse',
    style({
      left: 0,
      'max-width': 0,
      'min-width': 0,
      width: 0,
    })
  ),
  transition('* <=> collapse', [
    animate(
      '0.5s ease-in-out',
      style({
        opacity: 0,
      })
    ),
    animate(
      '1.5s ease-in-out',
      style({
        width: 0,
        'min-width': 0,
        'max-width': 0,
      })
    ),
  ]),
]);

export const clockRotate: AnimationTriggerMetadata = trigger('clockRotate', [
  transition('void => *', [
    style({
      opacity: '0',
    }),
    animate(
      '600ms',
      style({
        opacity: '1',
      })
    ),
  ]),
  transition('* => loop-state', [
    style({
      transform: 'rotate(0deg)',
    }),
    animate(
      '600ms',
      style({
        transform: 'rotate(90deg)',
      })
    ),
    animate(
      '600ms',
      style({
        transform: 'rotate(180deg)',
      })
    ),
    animate(
      '600ms',
      style({
        transform: 'rotate(270deg)',
      })
    ),
    animate(
      '600ms',
      style({
        transform: 'rotate(360deg)',
      })
    ),
  ]),
]);
