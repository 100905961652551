import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { ProposalStatusEnum } from 'src/app/order/enums/proposal-status.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalDraftService } from 'src/app/order/services/external/proposal-draft/proposal-draft.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { UnfilledProposalModel } from 'src/app/start/components/welcome/welcome.component';
import { ProposalDraftQueryModel } from '../../models/proposal-draft-query.model';

@Component({
  selector: 'fd-unfilled-proposal-card',
  templateUrl: './fd-unfilled-proposal-card.component.html',
  styleUrls: ['./fd-unfilled-proposal-card.component.scss'],
})
export class FdUnfilledProposalCardComponent implements OnDestroy {
  get isCPF() {
    return this.proposalData && this.proposalData && this.proposalData.cpfCnpj && this.proposalData.cpfCnpj.length === 11;
  }

  get isCNPJ() {
    return this.proposalData && this.proposalData && this.proposalData.cpfCnpj && this.proposalData.cpfCnpj.length === 14;
  }

  public proposalStatus: ProposalStatusEnum;
  public alive = true;

  @Input()
  public proposalData: UnfilledProposalModel;

  constructor(
    private wizardService: WizardService,
    private dataStore: DataStore,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private proposalDraftService: ProposalDraftService,
    private routingService: RoutingService
  ) {}

  public getDraftAvailabilityText() {
    if (!this.proposalData) {
      return;
    }

    switch (this.proposalData.remainingDays) {
      case 0:
        return `Disponível até às 23:59 do dia de hoje`;
      case 1:
        return `Disponível por mais ${this.proposalData.remainingDays} dia`;
      default:
        return `Disponível por mais ${this.proposalData.remainingDays} dias`;
    }
  }

  public navigateToStep() {
    localStorage.setItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY, this.proposalData.externalDraftUuid);
    const step = this.proposalData && this.proposalData.step ? this.proposalData.step : 2;
    const subStep = this.proposalData && this.proposalData.subStep ? this.proposalData.subStep : 1;
    this.wizardService.goToSpecificStep(step, subStep);
  }

  public promptDeleteDraft() {
    this.dialogService.openConfirmDialog(Messages.DRAFT_DELETE_CONFIRMATION, () => this.deleteDraft(this.proposalData.externalDraftUuid));
  }

  public deleteDraft(externalDraftUuid: string) {
    if (!externalDraftUuid) {
      return;
    }

    this.proposalDraftService
      .deleteDraft(this.createRequestObject(externalDraftUuid))

      .subscribe(
        (_) => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.routingService.navigateToHome(true));
        },
        (error) => {
          this.dialogService.openErrorDialog(Messages.EDIT_SAVE_ERROR, error);
        }
      );
  }

  public createRequestObject(externalDraftUuid): ProposalDraftQueryModel {
    return {
      externalDraftUuid,
    };
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
