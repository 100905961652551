import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneMask',
})
export class PhoneMaskPipe implements PipeTransform {
  public transform(ddd: string, phone: string): string {
    let maskedDDD = `${ddd}`,
      maskedPhone = `${phone}`;

    if (maskedDDD.length === 2) {
      maskedDDD = maskedDDD.replace(/\D/g, '').replace(/(\d{2})/, '(0$1)');
    } else if (maskedDDD.length === 3) {
      maskedDDD = maskedDDD.replace(/\D/g, '').replace(/(\d{3})/, '($1)');
    }

    if (maskedPhone.length === 8) {
      maskedPhone = maskedPhone.replace(/\D/g, '').replace(/(\d{4})(\d{4})/, '$1-$2');
    } else if (maskedPhone.length === 9) {
      maskedPhone = maskedPhone.replace(/\D/g, '').replace(/(\d{5})(\d{4})/, '$1-$2');
    }

    return maskedDDD + maskedPhone;
  }
}
