import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BankDataModel } from 'src/app/shared/models/bank-data.model';
import { BankRuleModel } from 'src/app/shared/models/bank-rule.model';
import { BankValidateDataModel } from 'src/app/shared/models/bank-validade-data.model';
import { BankModel } from 'src/app/shared/models/bank.model';
import { ResponseAllBankModelV2 } from 'src/app/shared/models/response/response-all-bank-v2.model';
import { ResponseBankRuleModel } from 'src/app/shared/models/response/response-bank-rule.model';
import { ResponseBankValidModel } from 'src/app/shared/models/response/response-bank-valid.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  public userData: UserDataModel;
  public requestor: string;

  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {
    this.userData = this.dataStoreService.getUserData();
    this.requestor = 'FISERV_ONLINE';
  }

  public validateBankAndBankBranch(data: BankModel): Observable<ResponseBankValidModel> {
    return this.http.post<ResponseBankValidModel>(`${environment.apiUrls.bankValidate}/bank-validate`, data);
  }

  public validateBankData(data: BankDataModel): Observable<ResponseBankValidModel> {
    return this.http.post<ResponseBankValidModel>(`${environment.apiUrls.bankValidate}/bank-data-validate`, data);
  }

  public validateBankDataV2(data: BankValidateDataModel): Observable<ResponseBankValidModel> {
    const headers = new HttpHeaders()
      .set(Constants.SERVICE_CONTRACT_HEADER, this.userData.serviceContract.toString())
      .set(Constants.INSTITUTION_HEADER, this.userData.institution)
      .set(Constants.CHANNEL_TYPE_HEADER, this.userData.channelType)
      .set(Constants.CHANNEL_HEADER, this.userData.channel)
      .set(Constants.SUBCHANNEL_HEADER, this.userData.hierarchyComplementaryInformation[0].subChannel)
      .set(Constants.REQUESTOR_HEADER, this.requestor);
    return this.http.post<ResponseBankValidModel>(`${environment.apiUrls.bankValidate}/bank-data-validate-v2`, data, { headers });
  }

  public getBankRules(data: BankRuleModel): Observable<ResponseBankRuleModel> {
    return this.http.post<ResponseBankRuleModel>(`${environment.apiUrls.bankValidate}/rules`, data);
  }

  public getBankListByServiceContractAndInstitution(): Observable<Item[]> {
    return this.http
      .get<ResponseAllBankModelV2[]>(
        `${environment.apiUrls.bankList}` +
          '/service-contract/' +
          this.userData.serviceContract +
          '/institution/' +
          this.userData.institution
      )
      .pipe(map((x) => (this.invalidResponse(x) ? null : this.mapBankListModels(x))));
  }

  private invalidResponse(baseApiResponse: ResponseAllBankModelV2[]): boolean {
    return !baseApiResponse;
  }

  private mapBankListModels(response: ResponseAllBankModelV2[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map((value) => ({
      value: value.bankCode,
      label: value.bankCode.toString() + ' - ' + value.socialReason,
    }));
  }
}
