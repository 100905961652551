import {Injectable} from "@angular/core";
import {Constants} from "../../../order/constants/constants";
import {orderRoutingDefinitions} from "../../../order/routing/routing-definitions";
import {DataStore} from "../../../order/store/data.store";
import {Proposal} from "../../../shared/models/proposal";
import {WizardService} from "../../../order/services/internal/wizard/wizard.service";
import {DataStoreService} from "../../../order/store/data-store.service";

@Injectable({
  providedIn: 'root',
})

export class WelcomeService {

  constructor(private dataStore: DataStore,
              private wizardService: WizardService,
              private dataStoreService: DataStoreService) {
  }


  public newAccreditation() {
    localStorage.removeItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    this.dataStore.updateProposal(this.createDraft()).then(() => {

      // this.dataStoreService.removeAgentFromLocalStorage();
      this.wizardService.goToSpecificStep(
        orderRoutingDefinitions.registrationData.stepNumber,
        orderRoutingDefinitions.registrationData.routes.PERSONAL_INFO.order
      );
    });
  }

  public setSimulatorStepJump(status: boolean) {
    const savedJumpSteps = this.wizardService.setStepJumpInternal(
      orderRoutingDefinitions.simulationData.stepNumber,
      orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.order,
      status
    );
    this.dataStore.setLocalStorageObject(savedJumpSteps, Constants.JUMP_STEPS_LOCALSTORAGE_KEY);
  }

  public createDraft(): Proposal {
    const userData = this.dataStoreService.getUserData();
    return {
      serviceContract: userData.serviceContract,
      channel: userData.channel,
      subChannel: this.dataStoreService.getUserDataSubChannel(userData),
      agentChannel: this.dataStoreService.getUserDataAgentChannel(userData),
      institution: userData.institution,
      channelType: userData.channelType,
    };
  }
}
