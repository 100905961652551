import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormStep } from 'src/app/order/components/form-step';
import { ProfessionalTypeEnum } from 'src/app/order/enums/professional-type.enum';
import { DateMask } from 'src/app/order/masks/date-mask';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { dateValidator } from 'src/app/order/validators/date-validator';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ProfessionalLicense, Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
@Component({
  selector: 'app-prof-register',
  templateUrl: './prof-register.component.html',
  styleUrls: ['./prof-register.component.scss'],
})
export class ProfRegisterComponent extends FormStep implements OnInit {
  public docProfessionalList: Item[] = [
    {
      label: 'Carteirinha CRO',
      value: 'CRO',
    },
    {
      label: 'Carteirinha OAB',
      value: 'OAB',
    },
    {
      label: 'Carteirinha CRM',
      value: 'CRM',
    },
    {
      label: 'Carteirinha CONDUTAX',
      value: 'CONDUTAX',
    },
  ];

  protected STEP_NUMBER: number;

  constructor(
    private formBuilder: FormBuilder,
    private cnaeService: CnaeService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }
  protected proposalGetCallback(): void {}

  protected createFields(): FdFieldConfigs {
    return {
      licenseTypeName: {
        label: 'Número do Processo',
        controlName: 'licenseTypeName',
      },
      professionalCouncilCategory: {
        label: 'Escolha o documento',
        items: this.docProfessionalList,
        controlName: 'professionalCouncilCategory',
        messages: {
          required: 'Informe o emissor de carteira profissional',
          invalid: 'Informe o emissor de carteira profissional',
        },
      },
      numberDocument: {
        label: 'Número do Documento',
        controlName: 'numberDocument',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe o número do documento',
        },
        tooltipMessage: 'Informe o número do documento que consta na carteira',
        maxLength: 30,
      },
      validityDate: {
        label: 'Validade do Documento',
        controlName: 'validityDate',
        maskCharsReplace: /[/\//]/g,
        mask: DateMask,
        messages: {
          required: 'Informe a data de validade do documento',
          invalid: 'Data inválida',
        },
        tooltipMessage: 'Informe a data de validade do documento',
      },
      motherName: {
        label: 'Nome da Mãe',
        controlName: 'motherName',
        messages: {
          required: 'Informe nome da mãe, pai ou responsável legal',
        },
        tooltipMessage: 'Informe nome da mãe pai ou responsável legal',
      },
      nationality: {
        label: 'Nacionalidade',
        controlName: 'nationality',
        messages: {
          required: 'Informe sua nacionalidade',
        },
        tooltipMessage: 'Informe sua nacionalidade. Exemplo: brasileiro, argentino, etc',
      },
    };
  }

  protected async persistData(): Promise<boolean> {
    const professionalLicense: ProfessionalLicense = {
      ...this.formGroup.getRawValue(),
    };

    return this.dataStore.updateProposal({ professionalLicense });
  }

  protected updateFields(proposal: Proposal): void {
    if (proposal && proposal.professionalLicense) {
      const prof = this.proposal.professionalLicense;
      this.formControls.professionalCouncilCategory.setValue(prof.professionalCouncilCategory);
      this.formControls.licenseTypeName.setValue(prof.licenseTypeName);
      this.formControls.validityDate.setValue(prof.validityDate);
      this.formControls.motherName.setValue(prof.motherName);
      this.formControls.nationality.setValue(prof.nationality);
      this.formControls.numberDocument.setValue(prof.numberDocument);
      this.formControls.licenseTypeName.setValue(ProfessionalTypeEnum.PROFESSIONAL_COUNCIL);
    }
  }
  protected createFormGroup(): FormGroup {
    return this.formBuilder.group({
      licenseTypeName: ['', Validators.required],
      professionalCouncilCategory: ['', Validators.required],
      numberDocument: ['', Validators.required],
      validityDate: ['', [Validators.required, dateValidator(true, false)]],
      motherName: ['', Validators.required],
      nationality: ['', Validators.required],
    });
  }
}
