<div *ngIf="fields">
  <h4 class="fd-subtitle">Alvará de Funcionamento:</h4>
  <fd-input [parentForm]="formGroup" [field]="fields.processNumber"> </fd-input>

  <fd-input [parentForm]="formGroup" [field]="fields.dmlNumber"> </fd-input>

  <fd-input [parentForm]="formGroup" [field]="fields.validityDate"></fd-input>

  <fd-button fdSecondary [content]="'cancelar'" id="btnCancel" (trigger)="back()"> </fd-button>
  <fd-button [content]="'avançar'" id="btnNext" (trigger)="next()"> </fd-button>
</div>
