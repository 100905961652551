import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import { FdButtonComponent } from 'src/app/shared/fd-form-components/fd-button/fd-button.component';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { OptInAcceptanceModel, PartnerDataModel, ProductSelectionModel, Proposal } from 'src/app/shared/models/proposal';
import { ResponseClientSerproCnpjModel } from 'src/app/shared/models/response/response-client-serpro-cnpj.model';
import { ResponseClientSerproCpfModel } from 'src/app/shared/models/response/response-client-serpro-cpf.model';
import {
  SerproDocumentErrorCodesv2,
  SerproInconsistenciesModelv2,
} from 'src/app/shared/models/response/response-client-serpro-situation.model';
import { CnaeModel } from 'src/app/shared/models/response/response-cnae.model';
import { SerproCnpjInformationModel } from 'src/app/shared/models/response/response-serpro-cnpj-information.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { Constants } from '../../constants/constants';
import { ConstitutionFormEnum } from '../../enums/constitution-form.enum';
import { DocumentCategory } from '../../enums/document-category.enum';
import { DocumentStatusEnum } from '../../enums/document-status.enum';
import { ModalDefinitions } from '../../enums/modal-definitions.enum';
import { Messages } from '../../messages/order.messages';
import { orderRoutingDefinitions } from '../../routing/routing-definitions';
import { OptInService } from '../../services/external/opt-in/opt-in.service';
import { ProposalService } from '../../services/external/proposal/proposal.service';
import { SerproService } from '../../services/external/serpro/serpro.service';
import { DialogService } from '../../services/internal/dialog/dialog.service';
import { LoadingService } from '../../services/internal/loading/loading.service';
import { RoutingService } from '../../services/internal/routing/routing.service';
import { ScrollService } from '../../services/internal/scroll/scroll.service';
import { WizardService } from '../../services/internal/wizard/wizard.service';
import { DataStoreService } from '../../store/data-store.service';
import { DataStore } from '../../store/data.store';
import { getConstitutionFormByCode } from '../../utils/constitution-form';
import { TermsAcceptanceModel } from '../complementary-information/restricted-cnae/models/terms-acceptance.model';
import { FormStep } from '../form-step';
import { ResponseClientSerproV2CnpjModel } from '../proposal-data/partner-data/models/response-client-serpro-v2-cnpj.model';
import { SerproPartnerValidationModel } from '../proposal-data/partner-data/models/serpro-partner-validation.model';
import { SerproValidationResponseModel } from '../proposal-data/partner-data/models/serpro-validation-response.model';
import {
  SerproCnpjValidationRequestModel,
  SerproCnpjValidationWithCnaeRequestModel,
} from '../registration-data/personal-info/models/serpro-cnpj-validation-request.model';
import { SerproCpfRequestModel, SerproCpfRequestModelV2 } from '../registration-data/personal-info/models/serpro-cpf-request.model';
import {
  LegalRepresentativeDTO,
  PartnerV2ValidationDTO,
  SerproV2PartnerValidationModel,
} from '../proposal-data/partner-data/models/serpro-v2-partner-validation.model';
import { CnaeService } from '../../services/external/cnae/cnae.service';

@Component({
  selector: 'app-proposal-terms-acceptance',
  templateUrl: './proposal-terms-acceptance.component.html',
  styleUrls: ['./proposal-terms-acceptance.component.scss'],
})
export class ProposalTermsAcceptanceComponent extends FormStep implements OnInit {
  get hasECommerce() {
    return this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasECommerce;
  }

  get hasTef() {
    return this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasTef;
  }

  get hasEdi() {
    return this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasEdi;
  }

  get isCPF() {
    return (
      this.proposal &&
      this.proposal.registrationDataPersonalInfo &&
      this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF
    );
  }

  get isCNPJ() {
    return (
      this.proposal &&
      this.proposal.registrationDataPersonalInfo &&
      this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CNPJ
    );
  }

  get isOptinEnabled(): boolean {
    return this.configuration.ableOptin;
  }

  get isOptinEdiEnabled(): boolean {
    return this.proposal.productSelection.hasEdi;
  }

  get isAntecipationEnabled(): boolean {
    return this.proposal.feeTypeModel.enablePrepayment;
  }

  public CURRENT_DOCUMENT_CALL_TRY = 0;
  public configuration: ConfigurationModel;
  public serproCnpjInformation: SerproCnpjInformationModel;
  public cnaeList: CnaeModel[] = [];
  public constitutionFormCode: string;
  public loadedPartners: boolean;

  public proposal: Proposal;
  public termsAcceptanceModel: TermsAcceptanceModel;
  public optInAcceptanceModel: OptInAcceptanceModel;
  public accepted: boolean;
  public acceptedOptIn: boolean;
  public acceptedOptInEdi: boolean;
  public optInAvailable: boolean = true;

  public STEP_NUMBER = 6;
  public booleanOptions: Item[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];

  public labelOptinExtratoEdi: string;

  @ViewChild('btnSubmit')
  public btnSubmit: FdButtonComponent;

  @ViewChild('btnCancelar')
  public btnCancelar: FdButtonComponent;

  private readonly MAX_DOCUMENT_CALL_LIMIT = 3;

  constructor(
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer,
    private optInService: OptInService,
    private serproService: SerproService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    protected cnaeService: CnaeService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    if (this.proposal.institution === '00000007' && this.proposal.serviceContract === 125) {
      this.fields.accepted.label = 'Concordo com todas as declarações, aceites e autorizações indicadas acima.';
    }

    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
    if (this.isOptinEdiEnabled) {
      this.labelOptinExtratoEdi = 'O estabelecimento autoriza a Fiserv BIN a enviar o Extrato EDI para a conciliadora '.concat(
        this.proposal.ediAdditionalData.conciliatorEnterpriseDescription
      );
      this.fields.optInEdi.label = this.labelOptinExtratoEdi;
    }
    if (this.configuration.ableOptin) {
      this.optInService
        .getOptInProperties({
          cpfCnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
          institution: this.proposal.institution,
          serviceContract: this.proposal.serviceContract,
        })
        .subscribe(
          (response) => {
            if (response && response.content) {
              (this.fields.optIn as FdInputConfig).label = response.content;
            }
          },
          (error) => {
            this.optInAvailable = false;
          }
        );
    }
  }

  public getTermsText() {
    if (this.proposal.institution === '00000007' && this.proposal.serviceContract === 125) {
      return this.domSanitizer.bypassSecurityTrustHtml(
        '<strong>(i)</strong> As informações prestadas e conferidas por mim estão corretas e condizem com a verdade.\
          <br><br><strong>(ii)</strong> Consinto expressa e inequivocamente que as informações prestadas implicam em \
          tratamentos de Dados Pessoais (conforme definidos na LGPD), incluindo informações financeiras e dados pessoais \
          de sócios e representantes legais, e que o tratamento dessas informações tem a finalidade e propósitos na \
          execução do contrato de credenciamento e ainda está sujeito à legislação brasileira aplicável, em especial, \
          a Constituição Federal Brasileira, o Código Civil (Lei nº 10.406/2002), o Marco Civil da Internet \
          (Lei nº 12.965/2014), a LC 105/2001 (Lei Sigilo Bancário) e a Lei Geral de Proteção de Dados Pessoais \
          (Lei nº 13.709/2018 ou “LGPD”) ou qualquer regulação emanada pelo Banco Central do Brasil ou autoridade governamental: \
          <br><br><strong>a)</strong> As informações e Dados Pessoais contidas no formulário poderão ser utilizadas, acessadas\
           ou transferidas por funcionários e áreas da Fiserv e suas subsidiárias e afiliadas no Brasil e no exterior e/ou \
           a terceiros autorizados pela Fiserv para fins de avaliações financeiras, reputacional, consultas a bases e bureau \
           de crédito e dados públicos e para oferecimento de produtos e serviços, de acordo com meu perfil, dentre outros.\
          <br><br><strong>b)</strong> As informações e Dados Pessoais serão mantidas, divulgadas ou transferidas apenas na medida \
          e enquanto forem necessárias para cumprimento correto dos requisitos relacionados aos propósitos específicos, \
          execução de contrato ou cumprimento de obrigação conforme previsto na LGPD.\
          <br><br><strong>(iii)</strong> Deste modo, meu consentimento neste formulário corresponde: \
          <br><br><strong>a)</strong> à confirmação e autorização para efetivação do credenciamento pelo agente comercial;\
          <br><br><strong>b)</strong> à leitura e aceitação do conteúdo do <a href="https://www.bin.com.br/content/dam/firstdata/bin/pt_br/pdf/doc/Fiserv_Contrato_de_Adesao_e_Credenciamento_4_RTD_SP.pdf" target="_blank">Contrato de Credenciamento</a> sendo que o Estabelecimento Comercial está ciente que referido contrato atualmente está disponível nos sites da <a href="https://www.fiserv.com.br/" target="_blank">Fiserv</a> <b>concordando o Estabelecimento que estará a eles sujeitos, na hipótese de aprovação pela Fiserv no processo de credenciamento;</b> \
          <br><br><strong>c)</strong> na hipótese de contratação de uma máquina Clover, à leitura e aceitação de: (i) <a href="https://br.clover.com/termos-de-uso" target="_blank">Termos e Condições de Uso Clover</a>; (ii) <a href="https://br.clover.com/politica-de-cookies" target="_blank">Termos e Condições de Uso da Loja de Aplicativos Fiserv</a>; (iii) <a href="https://br.clover.com/politica-privacidade" target="_blank">Aviso de Privacidade</a>, sendo que o Estabelecimento Comercial está ciente que referidos instrumentos atualmente se encontram disponíveis nos sites da <a href="https://www.fiserv.com.br/" target="_blank">Fiserv</a> e/ou Clover, <b>concordando o Estabelecimento que estará a eles sujeitos, na hipótese de aprovação pela Fiserv no processo de credenciamento.</b>'
      );
    } else {
      return this.domSanitizer.bypassSecurityTrustHtml(
        '<strong>(i)</strong> As informações prestadas e conferidas por mim estão corretas e condizem com a verdade.\
          <br><br><strong>(ii)</strong> Consinto expressa e inequivocamente que as informações prestadas implicam em \
          tratamentos de Dados Pessoais (conforme definidos na LGPD), incluindo informações financeiras e dados pessoais \
          de sócios e representantes legais, e que o tratamento dessas informações tem a finalidade e propósitos na \
          execução do contrato de credenciamento e ainda está sujeito à legislação brasileira aplicável, em especial, \
          a Constituição Federal Brasileira, o Código Civil (Lei nº 10.406/2002), o Marco Civil da Internet \
          (Lei nº 12.965/2014), a LC 105/2001 (Lei Sigilo Bancário) e a Lei Geral de Proteção de Dados Pessoais \
          (Lei nº 13.709/2018 ou “LGPD”) ou qualquer regulação emanada pelo Banco Central do Brasil ou autoridade governamental: \
          <br><br><strong>a)</strong> As informações e Dados Pessoais contidas no formulário poderão ser utilizadas, acessadas\
           ou transferidas por funcionários e áreas da Fiserv e suas subsidiárias e afiliadas no Brasil e no exterior e/ou \
           a terceiros autorizados pela Fiserv para fins de avaliações financeiras, reputacional, consultas a bases e bureau \
           de crédito e dados públicos e para oferecimento de produtos e serviços, de acordo com meu perfil, dentre outros.\
          <br><br><strong>b)</strong> As informações e Dados Pessoais serão mantidas, divulgadas ou transferidas apenas na medida \
          e enquanto forem necessárias para cumprimento correto dos requisitos relacionados aos propósitos específicos, \
          execução de contrato ou cumprimento de obrigação conforme previsto na LGPD.\
          <br><br><strong>(iii)</strong> Deste modo, meu consentimento neste formulário corresponde à confirmação e autorização\
           para efetivação do credenciamento pelo agente comercial.'
      );
    }
  }

  public processSubmitProposal() {
    if (!this.formControls.accepted.value) {
      this.formControls.accepted.setErrors({ required: true });
      this.formControls.accepted.markAsTouched();
      return;
    } else {
      this.formControls.accepted.setErrors(null);
    }

    this.btnBlockToggle(true);
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.startSerproRoutines();
        } else {
          console.error('erro submissão final serpro');
        }
      });
    }
  }

  public next() {
    if (this.isAcceptedPrepaymentAndFIDC() && this.optInAvailable) {
      this.showModalAboutTermFidc();
      return;
    }
    if (this.isOptinEdiEnabled && !this.formControls.optInEdi.value) {
      this.dialogService.openConfirmDialog(
        Messages.SUBMIT_NOT_EDI,
        () => {
          this.formControls.optInEdi.setValue(true);
          this.processSubmitProposal();
        },
        () => this.updateEdiAndSubmitProposal(),
        ModalDefinitions.LARGE_MODAL_WIDTH
      );
    } else {
      this.processSubmitProposal();
    }
  }

  public updateEdiAndSubmitProposal() {
    const productSelection: ProductSelectionModel = this.proposal.productSelection;
    productSelection.hasEdi = false;
    this.dataStore.updateProposal({ productSelection });
    this.processSubmitProposal();
  }

  public isSerproSituationValid() {
    this.serproService
      .validateSituation(this.proposal.registrationDataPersonalInfo.cpfCnpj, this.proposal.institution, this.proposal.serviceContract)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(response, () => this.checkValidationFn());
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          this.btnBlockToggle(false);
          return false;
        }
      );
  }

  public handleAdditionalPartners(response: SerproValidationResponseModel) {
    const partnerList = [];

    this.proposal.partnerDataModel.forEach((item) => partnerList.push(item));

    response.socios
      .filter((x) => x.qualificacao === '10' && !partnerList.find((partner) => partner === x.nome.trim().toLowerCase()))
      .forEach((socio, ind, arr) => {
        if (ind <= 1) {
          const model: PartnerDataModel = {
            name: socio.nome,
          };

          partnerList.push(model);
          model.loadedPartners = true;
          this.loadedPartners = true;
        }
      });

    this.proposal.partnerDataModel = partnerList;
  }

  public checkCpfOnSocialReason(socialReason: string) {
    const match = socialReason.match(/[0-9]{11}/);
    if (match) {
      socialReason = socialReason.replace(match[0], '');
    }
    return socialReason;
  }

  public validateCnpj() {
    const startDate = moment(this.proposal.registrationDataPersonalInfo.openDate, 'DDMMYYYY');
    const currenDate = moment(new Date()).format('DD/MM/YYYY');
    const endDate = moment(currenDate, 'DD/MM/YYYY');
    const days = endDate.diff(startDate, 'days');

    if (!days) {
      this.dialogService.openDialog(Messages.OPEN_DATE_LEGAL_PERSON_ERROR);

      return;
    }

    this.validSocialReason(this.serproCnpjInformation?.businessName, this.proposal.registrationDataPersonalInfo.socialReason).then(
      (socialReason) => {
        const model: SerproCnpjValidationWithCnaeRequestModel = {
          cnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
          codigoContrato: this.proposal.serviceContract.toString(),
          instituicao: this.proposal.institution,
          dataFundacao: moment(this.proposal.registrationDataPersonalInfo.openDate, 'DDMMYYYY').format('DD-MM-YYYY'),
          razaoSocial: socialReason as string,
          cnae: this.proposal.offerDataDetails.cnae.toString(),
        };

        const registrationDataPersonalInfo = this.proposal.registrationDataPersonalInfo;
        registrationDataPersonalInfo.socialReason = model.razaoSocial;
        this.dataStore.updateProposal({ registrationDataPersonalInfo });

        this.serproService
          .validateCnpjWithCnae(model)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (response) => {
              this.handleResponse(response, () => {
                localStorage.setItem(Constants.SERPRO_SUCCESSFUL_DOCUMENTS_SESSIONSTORAGE_KEY, model.cnpj);

                this.validatePartner();
              });
            },
            (error) => {
              this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);

              return false;
            }
          );
      }
    );
  }

  public validatePartner() {
    const partnerData = this.proposal.partnerDataModel && this.proposal.partnerDataModel.length ? this.proposal.partnerDataModel[0] : null;
    if (!partnerData) {
      return;
    }
    const model: SerproPartnerValidationModel = {
      cnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      socio: {
        codigoContrato: this.proposal.serviceContract.toString(),
        cpf: partnerData.cpfCnpj,
        dataNascimento: partnerData.birthDate,
        instituicao: this.proposal.institution,
        nome: partnerData.name,
      },
    };

    let serproV2PartnerValidationModel: SerproV2PartnerValidationModel;
    serproV2PartnerValidationModel = this.createPartnerValidationModel(this.proposal);

    this.serproService
      .validatePartnerV2(serproV2PartnerValidationModel)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(
            response,
            () => {
              this.submitProposalBtnBlock(this.btnSubmit, this.btnCancelar);
            },
            true
          );
        },
        (error) => {
          this.btnCancelar.disabled = false;
          this.handleError(error, 'Serpro');
        }
      );
  }

  private createPartnerValidationModel(proposal: Proposal): SerproV2PartnerValidationModel {
    const partnerValidationModel = new SerproV2PartnerValidationModel();

    proposal.partnerDataModel.forEach((partner, index) => {
      const partnerValidationDTO = new PartnerV2ValidationDTO();
      const legalRepresentative = new LegalRepresentativeDTO();

      partnerValidationDTO.isPep = partner.politicalExposition;
      partnerValidationDTO.porcentagemParticipacao = partner.companyParticipation;

      this.createPartnerCPFModel(partnerValidationDTO, partner, legalRepresentative);
      this.createPartnerCNPJModel(partnerValidationDTO, legalRepresentative, partner);

      partnerValidationModel.cnpj = this.proposal?.registrationDataPersonalInfo?.cpfCnpj;
      partnerValidationModel.socios.push(partnerValidationDTO);
    });

    return partnerValidationModel;
  }

  private createPartnerCPFModel(
    partnerDTO: PartnerV2ValidationDTO,
    partner: PartnerDataModel,
    legalRepresentative: LegalRepresentativeDTO
  ): void {
    if (partner.cpfCnpj.length <= 11) {
      partnerDTO.cpf = partner.cpfCnpj;
      partnerDTO.nome = partner.name;
      partnerDTO.dataNascimento = partner.birthDate;
      legalRepresentative.nome = partner?.legalRepresentative?.name;
      legalRepresentative.cpf = partner?.legalRepresentative?.cpf;
      partnerDTO.representanteLegal = legalRepresentative;
    }
  }

  private createPartnerCNPJModel(
    partnerDTO: PartnerV2ValidationDTO,
    legalRepresentative: LegalRepresentativeDTO,
    partner: PartnerDataModel
  ): void {
    if (partner.cpfCnpj.length > 11) {
      partnerDTO.nome = partner.socialReason;
      partnerDTO.cnpj = partner.cpfCnpj;
      partnerDTO.dataFundacao = partner.openDate;
      legalRepresentative.nome = partner?.legalRepresentative?.name;
      legalRepresentative.cpf = partner?.legalRepresentative?.cpf;
      partnerDTO.representanteLegal = legalRepresentative;
    }
  }

  public isSA() {
    return (
      this.constitutionFormCode &&
      (this.constitutionFormCode === ConstitutionFormEnum.OPEN_ANONYMOUS_SOCIETY ||
        this.constitutionFormCode === ConstitutionFormEnum.CLOSED_ANONYMOUS_SOCIETY)
    );
  }

  public shouldTheCnpjHaveAgeValidation() {
    return !(
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.CLOSED_ANONYMOUS_SOCIETY ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.OPEN_ANONYMOUS_SOCIETY ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.ENTERPRISE_NATURE_EIRELI ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.SIMPLE_NATURE_EIRELI ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.LIMITED_ENTERPRISE_SOCIETY ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.PURE_SIMPLE_SOCIETY ||
      this.serproCnpjInformation?.legalNatureCode === ConstitutionFormEnum.SIMPLE_LIMITED_SOCIETY
    );
  }

  public getCnpjInformation() {
    this.serproService
      .getCnpjInformationV2(this.proposal.registrationDataPersonalInfo.cpfCnpj)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          const validResponse = this.canProceed(response);

          if (!validResponse) {
            this.btnBlockToggle(false);
            return;
          }

          if (response.ni) {
            this.serproCnpjInformation = {
              businessName:
                this.checkCpfOnSocialReason(response.nomeEmpresarial) || this.proposal.registrationDataPersonalInfo.socialReason,
              fantasyName: response.nomeFantasia || this.proposal.registrationDataPersonalInfo.companyFantasyName,
              openDate: response.dataAbertura || this.proposal.registrationDataPersonalInfo.openDate,
              legalNatureCode: response.naturezaJuridica.codigo,
              legalNatureDescription: response.naturezaJuridica.descricao,
            };

            this.proposal.registrationDataPersonalInfo.constitutionFormCode =
              this.serproCnpjInformation.legalNatureCode || this.proposal.registrationDataPersonalInfo.constitutionFormCode;
            this.proposal.registrationDataPersonalInfo.constitutionForm = getConstitutionFormByCode(
              this.proposal.registrationDataPersonalInfo.constitutionFormCode
            );
          } else {
            this.proposal.registrationDataPersonalInfo.constitutionForm = '';
          }

          this.validateCnpj();
        },
        (error) => {
          this.btnBlockToggle(false);
          this.handleError(error, 'Serpro');
        }
      );
  }

  private validatePrimaryCnae() {
    this.cnaeService
      .validatePrimaryCnaeByCnpj(this.proposal.offerDataDetails.cnae, this.proposal.registrationDataPersonalInfo.cpfCnpj)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response) {
            this.getCnpjInformation();
          }
        },
        (error) => {
          this.btnBlockToggle(false);
          this.handleError(error, 'Cnae');
        }
      );
  }

  public validSocialReason(businessName, socialReason) {
    const message = new Messages(
      'Atenção!',
      'A razão social digitada diverge do registro na Receita Federal: ' +
        '\n\n' +
        businessName +
        '\n\nAo confirmar você prosseguirá com a razão social indicada acima'
    );

    const businessNameWithTrim = businessName?.trim();
    const socialReasonWithTrim = socialReason.trim();

    return new Promise((resolve, reject) => {
      if (!businessName) {
        resolve(socialReasonWithTrim);
      } else {
        if (socialReasonWithTrim !== businessNameWithTrim) {
          this.dialogService.openConfirmDialog(
            message,
            () => {
              resolve(businessNameWithTrim);
            },
            () => {
              this.routingService.navigateToHome(false);
              resolve(socialReasonWithTrim);
            }
          );
        } else {
          resolve(socialReasonWithTrim);
        }
      }
    });
  }

  public handleError(error: any, serviceKey: string) {
    if (!error.status) {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
      console.error(`[${serviceKey}] - Unspecified error when querying CPF/CNPJ`);
    } else if (error.status === 400) {
      if (error.error.message) {
        this.dialogService.openDialog(new Messages('Atenção', error.error.message), () => this.routingService.navigateToHome(false));
      } else {
        this.dialogService.openDialog(Messages.CPF_CNPJ_NOT_AUTHORIZED, () => this.routingService.navigateToHome(false));
      }
    } else if (error.status === 404) {
      this.dialogService.openDialog(Messages.INVALID_CPF_CNPJ);
      console.error(`[${serviceKey}] - CPF/CNPJ not found`);
    } else {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
    }
  }

  public canProceed(response: ResponseClientSerproV2CnpjModel): boolean {
    if (Number(response.codigoRetorno)) {
      this.dialogService.openDialog(Messages.SERPRO_CNPJ_INFORMATION_ERROR, () => this.routingService.navigateToHome(false));
      return false;
    }
    return true;
  }

  public checkValidationFn() {
    if (this.isCPF) {
      this.validateCpf();
    } else if (this.isCNPJ) {
      this.validateCnpj();
    }
  }

  public validateCpf() {
    var date =
      this.proposal.registrationDataPersonalInfo.birthDate.slice(4, 8) +
      '-' +
      this.proposal.registrationDataPersonalInfo.birthDate.slice(2, 4) +
      '-' +
      this.proposal.registrationDataPersonalInfo.birthDate.slice(0, 2);
    const model: SerproCpfRequestModelV2 = {
      dataNascimento: date,
      cpf: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      isPep: this.proposal.registrationDataPersonalInfo.politicallyExposedPerson === null ? false : true,
      nome: this.proposal.registrationDataPersonalInfo.name,
    };

    this.serproService
      .validateCpf(model)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          this.handleResponse(response, () => {
            localStorage.setItem(Constants.SERPRO_SUCCESSFUL_DOCUMENTS_SESSIONSTORAGE_KEY, model.cpf);
            this.submitProposalBtnBlock(this.btnSubmit, this.btnCancelar);
          });
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          this.btnBlockToggle(false);
          return false;
        }
      );
  }

  public validateMinimumAge(cpfCnpj: string, birthDate: string, isPartnerValidation = false) {
    this.serproService
      .validateMinimumAge(cpfCnpj, birthDate)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (isValid) => {
          if (!isValid) {
            if (isPartnerValidation) {
              this.dialogService.openDialog(
                Messages.INVALID_MINIMUM_AGE_FOR_PARTNER,
                () =>
                  this.wizardService.goToSpecificStep(
                    orderRoutingDefinitions.proposal.stepNumber,
                    orderRoutingDefinitions.proposal.routes.PARTNER_DATA.order
                  ),
                'Corrigir dados'
              );
            } else {
              this.dialogService.openDialog(
                Messages.INVALID_MINIMUM_AGE,
                () =>
                  this.wizardService.goToSpecificStep(
                    orderRoutingDefinitions.registrationData.stepNumber,
                    orderRoutingDefinitions.registrationData.routes.PERSONAL_INFO.order
                  ),
                'Corrigir dados'
              );
            }

            return;
          }
          this.submitProposalBtnBlock(this.btnSubmit, this.btnCancelar);
        },
        (error) => {
          this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
          return false;
        }
      );
  }

  public handleResponse(response: any, nextAction: () => any, isPartnerValidation = false) {
    if (!response) {
      this.dialogService.openDialog(Messages.SERPRO_VALIDATION_ERROR);
      return;
    }
    if (response.situacao) {
      nextAction();
      return;
    }
    if (!response.situacao && response.inconsistencias && response.inconsistencias.length) {
      this.btnBlockToggle(false);
      this.validateInconsistenciesAndRedirect(response.inconsistencias, isPartnerValidation);
      return;
    }
  }

  public changeOption(event: boolean) {
    this.accepted = event;
  }

  public changeOptionOptIn(event: boolean) {
    this.acceptedOptIn = event;
  }

  public changeOptionOptInEdi(event: boolean) {
    this.acceptedOptInEdi = event;
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      accepted: [''],
      optIn: [''],
      optInEdi: [''],
    });
  }
  protected proposalGetCallback(): void {}

  protected persistData() {
    const termsAcceptance: TermsAcceptanceModel = {
      accepted: !!this.formControls.accepted.value,
    };

    const optIn: OptInAcceptanceModel = {
      accepted: !!this.formControls.optIn.value,
    };
    return this.dataStore.updateProposal({ termsAcceptance, optIn });
  }

  protected updateFields(proposal: Proposal) {
    this.termsAcceptanceModel = proposal.termsAcceptance;
    const item = proposal.partnerDataModel && proposal.partnerDataModel.find((item) => item.loadedPartners);
    this.loadedPartners = item && item.loadedPartners;
    if (this.termsAcceptanceModel) {
      (this.fields.accepted as FdRadioButtonConfig).items.forEach((item) => {
        item.selected = item.value === this.termsAcceptanceModel.accepted;
      });
      this.formControls.accepted.setValue(this.termsAcceptanceModel.accepted);
    }

    if (this.optInAcceptanceModel) {
      (this.fields.optIn as FdRadioButtonConfig).items.forEach((item) => {
        item.selected = item.value === this.optInAcceptanceModel.accepted;
      });

      if (this.configuration.ableOptin) {
        this.formControls.optIn.setValue(this.optInAcceptanceModel.accepted);
      } else {
        this.formControls.optIn.setValue(false);
      }
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      accepted: {
        label: 'Concordo',
        items: this.booleanOptions,
        controlName: 'accepted',
        messages: {
          required: 'Aceite os termos para continuar',
        },
      },
      optIn: {
        label: '',
        items: this.booleanOptions,
        controlName: 'optIn',
      },
      optInEdi: {
        label: 'O estabelecimento autoriza a Fiserv BIN a enviar o Extrato EDI a conciliadora',
        items: this.booleanOptions,
        controlName: 'optInEdi',
      },
    };
  }

  private startSerproRoutines() {
    const documentCategory = this.proposal.registrationDataPersonalInfo.documentCategory;

    if (!documentCategory) {
      this.btnBlockToggle(false);
      throw new Error('Document Category not defined');
    }

    if (this.isCPF) {
      this.startSerproCpfRoutine();
    } else if (this.isCNPJ) {
      this.startSerproCnpjRoutine();
    }
  }

  private startSerproCnpjRoutine() {
    if (this.isCNPJ) {
      if (this.configuration?.serproValidationStrategy === 'TERMS_ACCEPTANCE_PAGE' && this.configuration?.cnaeValidation) {
        this.validatePrimaryCnae();
      } else {
        this.getCnpjInformation();
      }
      return;
    }
  }

  private startSerproCpfRoutine() {
    this.isSerproSituationValid();
  }

  private btnBlockToggle(value: boolean) {
    this.btnSubmit.disabled = value;
    this.btnCancelar.disabled = value;
  }

  private validateInconsistenciesAndRedirect(serproInconsistencies: SerproInconsistenciesModelv2[], isPartnerValidation: boolean) {
    const currentItem = serproInconsistencies[0];
    const homePage = {
      51: Messages.MAX_DOCUMENT_CALL_LIMIT_EXCEEDED,
      61: Messages.COMMUNICATION_SERPRO_ERROR,
      62: Messages.INTERNAL_SERPRO_ERROR,
      63: Messages.unexpected_serpro_error,
    };
    const offerDetails = {
      21: Messages.INCORRECT_CNAE,
    };

    if (currentItem.codigo >= 31 && currentItem.codigo <= 41) {
      this.dialogService.openDialog(
        new Messages('Atenção', currentItem.descricao + '. Clique no botão abaixo para corrigir a informação.'),
        () =>
          this.wizardService.goToSpecificStep(
            orderRoutingDefinitions.registrationData.stepNumber,
            orderRoutingDefinitions.registrationData.routes.PARTNER_DATA.order
          ),
        'Corrigir dados'
      );
    } else if (currentItem.codigo == 21) {
      this.dialogService.openDialog(
        offerDetails[currentItem.codigo],
        () =>
          this.wizardService.goToSpecificStep(
            orderRoutingDefinitions.offer.stepNumber,
            orderRoutingDefinitions.offer.routes.OFFER_DETAILS.order
          ),
        'Corrigir dados'
      );
    } else if (currentItem.codigo >= 11 && currentItem.codigo <= 25) {
      this.dialogService.openDialog(
        new Messages('Atenção', currentItem.descricao + '. Clique no botão abaixo para corrigir a informação.'),
        () =>
          this.wizardService.goToSpecificStep(
            orderRoutingDefinitions.registrationData.stepNumber,
            orderRoutingDefinitions.registrationData.routes.PERSONAL_INFO.order
          ),
        'Corrigir dados'
      );
    } else {
      this.dialogService.openDialog(homePage[currentItem.codigo], () => this.routingService.navigateToHome(false));
    }
  }

  private showModalAboutTermFidc() {
    this.dialogService.openDialog(Messages.ABOUT_ACCEPT_TERM_FIDC);
  }

  private isAcceptedPrepaymentAndFIDC(): boolean {
    return this.proposal.feeTypeModel.enablePrepayment && !!!this.formControls.optIn.value && this.configuration.ableOptin;
  }
}
