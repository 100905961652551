import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { cnpjMask } from 'src/app/order/masks/document-masks';
import { Messages } from 'src/app/order/messages/order.messages';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { CnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { BranchOfficesModel } from '../models/branch-offices.model';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-branch-offices',
  templateUrl: './branch-offices.component.html',
  styleUrls: ['./branch-offices.component.scss'],
})
export class BranchOfficesComponent extends FormStep implements OnInit {
  private get branchCnpjFormsArray(): FormArray {
    return this.formControls.branchCnpjForms as FormArray;
  }

  public get branchCnpjFormsControls() {
    return this.branchCnpjFormsArray.controls;
  }

  get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }

  get addButtonHTML() {
    return this.renderPlusIcon() + ' <span>adicionar filial</span>';
  }

  get removeButtonHTML() {
    return this.renderCloseIcon() + ' <span>remover filial</span>';
  }

  public STEP_NUMBER = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    if (this.hasSplitTech) {
      this.setValueDefaultSplitTech();
    }
  }

  public next(): void {
    if (!this.formControls.hasBranchOffices.value) {
      this.branchCnpjFormsArray.controls.forEach((item) => {
        const formGroup = item as FormGroup;
        if (formGroup && formGroup.controls && formGroup.controls.cnpj) {
          formGroup.controls.cnpj.setErrors(null);
        }
      });
    }

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public getFormControlByIndex(index: number) {
    const formArray = this.branchCnpjFormsArray;
    return (formArray.controls[index] as FormGroup).controls;
  }

  public addBranchCnpjForm(branchCnpjData: Partial<string> = '') {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const includedIndex = this.fieldsArray.push(this.createFields());

    (this.fieldsArray[includedIndex - 1].cnpj as FdInputConfig).label = `CNPJ da filial ${includedIndex}`;

    this.branchCnpjFormsArray.push(this.createBranchCnpjForm(branchCnpjData));
  }

  public shouldShowRemoveButton(index: number): boolean {
    return this.fieldsArray.length > 1 && index < this.fieldsArray.length - 1;
  }

  public removeBranchCnpjForm(index: number) {
    this.branchCnpjFormsArray.removeAt(index);
    this.fieldsArray.splice(index, 1);
    this.fieldsArray.forEach((item, index, arr) => {
      (item.cnpj as FdInputConfig).label = `CNPJ da filial ${index + 1}`;
    });
  }

  public setAndCheckEmpty(control: AbstractControl, data: string) {
    if (data) {
      control.setValue(data);
      control.disable();
    } else {
      control.enable();
    }
  }
  protected proposalGetCallback(): void {}

  protected persistData() {
    const branchOffices = new BranchOfficesModel();

    if (!!this.formControls.hasBranchOffices.value) {
      const branchOfficeCnpjList: string[] = [];

      for (let index = 0; index < this.branchCnpjFormsArray.length; index++) {
        const branchOffice: any = (this.branchCnpjFormsArray.controls[index] as FormGroup).getRawValue();
        if (branchOffice && branchOffice.cnpj) {
          branchOfficeCnpjList.push(branchOffice.cnpj);
        }
      }

      const cnpjList = branchOfficeCnpjList;

      const hasDuplicatedCnpj = cnpjList.length !== new Set(cnpjList).size;

      if (hasDuplicatedCnpj) {
        this.dialogService.openDialog(Messages.DUPLICATED_BRANCH_CNPJ);
        return Promise.resolve(false);
      }

      branchOfficeCnpjList.forEach((item, index, arr) => {
        // Caso o índice seja 1, não concatenar na propriedade.
        const ind = index + 1 === 1 ? '' : index + 1;
        branchOffices[`cnpjBranch${ind}`] = item;
      });
    }

    branchOffices.hasBranchOffices = this.formControls.hasBranchOffices.value;

    return this.dataStore.updateProposal({ branchOffices });
  }

  protected updateFields(proposal: Proposal) {
    const branchCnpjData = proposal.branchOffices;

    if (branchCnpjData) {
      this.formControls.hasBranchOffices.setValue(branchCnpjData.hasBranchOffices);

      if (branchCnpjData.hasBranchOffices) {
        if (branchCnpjData.cnpjBranch) {
          this.addBranchCnpjForm(branchCnpjData.cnpjBranch);
        }
        if (branchCnpjData.cnpjBranch2) {
          this.addBranchCnpjForm(branchCnpjData.cnpjBranch2);
        }
        if (branchCnpjData.cnpjBranch3) {
          this.addBranchCnpjForm(branchCnpjData.cnpjBranch3);
        }
        if (branchCnpjData.cnpjBranch4) {
          this.addBranchCnpjForm(branchCnpjData.cnpjBranch4);
        }
        return;
      }
    }
    this.addBranchCnpjForm();
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      hasBranchOffices: ['', [Validators.required]],
      branchCnpjForms: this.formBuilder.array([]),
    });
  }

  protected createFields(index?: number): FdFieldConfigs {
    return {
      cnpj: {
        label: 'CNPJ da filial',
        controlName: 'cnpj',
        mask: cnpjMask,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CNPJ',
          invalidCnpj: 'CNPJ inválido',
        },
      },
      hasBranchOffices: {
        controlName: 'hasBranchOffices',
        items: [],
        messages: {
          required: 'Informe uma opção',
        },
      },
    };
  }

  private setValueDefaultSplitTech() {
    this.formGroup.controls.hasBranchOffices.setValue(false);
    this.formGroup.controls.hasBranchOffices.disable();
  }

  private renderPlusIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'plus-circle' });
    return iconDefinition.html;
  }

  private renderCloseIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'times' });
    return iconDefinition.html;
  }

  private createBranchCnpjForm(cnpj: Partial<string>): FormGroup {
    return this.formBuilder.group({
      cnpj: [cnpj, [RequiredIfValidator(() => !!this.formControls.hasBranchOffices.value), CnpjValidator]],
    });
  }
}
