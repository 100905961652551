import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  OptionPickerDialogData,
  OptionPickerModalComponent,
} from 'src/app/order/components/modals/option-picker-modal/option-picker-modal.component';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { FdSelectConfig, Item } from '../fd-select/fd-select.component';

@Component({
  selector: 'fd-option-picker',
  templateUrl: './fd-option-picker.component.html',
  styleUrls: ['./fd-option-picker.component.scss'],
})
export class FdOptionPickerComponent extends FdFieldBaseComponent {
  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl.errors !== null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter((val) => this.relatedFormControl.errors[val])
      .map((key) => this.field.messages[key]);
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }

  @Input()
  public field: FdOptionPickerConfig;

  @Input()
  public parentForm: FormGroup;

  @Output()
  public openDialogFn = new EventEmitter<void>();

  @Output()
  public modalClosed = new EventEmitter<void>();

  @Output()
  public infiniteScroll = new EventEmitter<void>();

  public limit = 10;
  public offset = 0;
  public controlName: string;
  public context: any;
  public label: string;
  public accessibilityLabel?: string;
  public placeholder?: string;
  public items: Item[];
  public hint?: string;
  public messages?: { [key: string]: string };
  public searchable = false;

  public focusedItem?: Item;

  private _items: Item[];
  private _filter: string;
  private _loadError: boolean;

  constructor(private dialog: MatDialog) {
    super();
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public openOptionDialog(): void {
    const dialogData: OptionPickerDialogData = {
      items: this.field.items,
      title: this.field.modalTitle,
      formControl: this.relatedFormControl,
      formGroup: this.parentForm,
    };

    const dialogRef = this.dialog.open(OptionPickerModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.modalClosed.emit(result);
    });
  }

  public getText() {
    const itemArray = this.relatedFormControl.value as Item[];

    if (!itemArray || !itemArray.length) {
      return this.field.placeholder;
    } else {
      return itemArray.map((item) => item.label).join(', ');
    }
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public handleSpace(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 32 || event.keyCode === 35 || event.keyCode === 36) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }
}

export interface FdOptionPickerConfig {
  controlName: string;
  disabled?: boolean;
  required?: boolean;
  placeholder: string;
  modalTitle: string;
  messages?: { [key: string]: string };
  label: string;
  items: Item[];
}
