<div>
  <div *ngIf="scheduledMaintenanceActive" class="fd-welcome-screen__alert">
    <fa-icon icon="exclamation-triangle"></fa-icon>
    <span class="fd-welcome-screen__alert-message">{{ scheduledMaintenanceMessage }}</span>
  </div>
  <p>
    Olá, <strong>{{ name }}</strong
    ><br />
    <ng-container *ngIf="!isCEF"> Seja bem-vindo ao Credenciamento Online! </ng-container>
    <ng-container *ngIf="isCEF"> Seja bem-vindo ao Visualizador de Taxas! </ng-container>
  </p>

  <div class="fd-welcome-screen__button-container" *ngIf="!hasCampaignSimulator">
    <button class="fd-welcome-screen__button" id="btnNew" (click)="navigateToForm()">
      {{ getButtonLabel() }}
    </button>
    <div class="fd-welcome-screen__arrow-circle" (click)="navigateToForm()">
      <fa-icon icon="chevron-right" size="2x"></fa-icon>
    </div>
  </div>

  <div class="fd-welcome-screen__button-container" *ngIf="hasCampaignSimulator">
    <div class="fd-welcome-screen__button-icon-wrapper">
      <button class="fd-welcome-screen__button" id="btnNew" (click)="goToCampaignSimulator()">Simulador de Preço</button>
      <div class="fd-welcome-screen__arrow-circle" (click)="goToCampaignSimulator()">
        <fa-icon icon="chevron-right" size="2x"></fa-icon>
      </div>
    </div>
    <div class="fd-welcome-screen__button-icon-wrapper">
      <button class="fd-welcome-screen__button" id="btnNew" (click)="navigateToForm()">
        {{ getButtonLabel() }}
      </button>
      <div class="fd-welcome-screen__arrow-circle" (click)="navigateToForm()">
        <fa-icon icon="chevron-right" size="2x"></fa-icon>
      </div>
    </div>
  </div>

  <ng-container *ngIf="unfilledProposals && unfilledProposals.length">
    <fd-unfilled-proposal-card *ngFor="let proposal of unfilledProposals" [proposalData]="proposal"></fd-unfilled-proposal-card>
  </ng-container>

  <ng-container *ngIf="recentProposals && recentProposals.length">
    <fd-proposal-card *ngFor="let proposal of recentProposals" [proposalData]="proposal"></fd-proposal-card>
  </ng-container>

  <div *ngIf="recentProposals && recentProposals.length" class="fd-welcome-screen__history-button-container">
    <fd-button fdSecondary fullWidth [content]="'Ver histórico'" (trigger)="goToProposalHistory()"> </fd-button>
  </div>
</div>
