import { ResponseBlackListLogModel } from './response-black-list-log.model';

export class ResponseBlackListModel {
  public id: number;
  public cpfCnpj: string;
  public instituicao: string;
  public serviceContract: number;
  public status: string;
  public blackListLog: Array<ResponseBlackListLogModel>;
}

export enum FieldTypeBlacklistEnum {
  DOCUMENT = '1',
  PHONE = '2',
  EMAIL = '3',
}
