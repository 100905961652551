import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { dynamicDigitsMask } from 'src/app/order/masks/dynamic-digits-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { BankService } from 'src/app/order/services/external/bank/bank.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { accountLengthValidator } from 'src/app/order/validators/agency-account-validator';
import { FdInputConfig } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { BankRuleModel } from 'src/app/shared/models/bank-rule.model';
import { BankModel } from 'src/app/shared/models/bank.model';
import { AccountsModel, Proposal } from 'src/app/shared/models/proposal';
import { BankRuleDetail } from 'src/app/shared/models/response/response-bank-rule.model';
import { FdFieldConfig, FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { environment } from 'src/environments/environment.local';
import { FormStep } from '../../form-step';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { BankValidateDataModel } from 'src/app/shared/models/bank-validade-data.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';

@Component({
  selector: 'app-bank-data',
  templateUrl: './bank-data.component.html',
  styleUrls: ['./bank-data.component.scss'],
})
export class BankDataComponent extends FormStep implements OnInit {
  public ACCOUNT_DIGIT_SEPARATOR = 1;
  public agencyMinLength: number;
  public agencyMaxLength: number;
  public agencyDigitLength: number;
  public debitAccountMinLength: number;
  public creditAccountMinLength: number;
  public debitAccountMaxLength: number;
  public creditAccountMaxLength: number;
  public debitAccountDigitLength: number;
  public creditAccountDigitLength: number;

  public userData: UserDataModel;
  public accountsModel: AccountsModel;
  public documentCategory: DocumentCategory;
  public fields: FdFieldConfigs;
  public proposal: Proposal;
  public bankList: Item[];

  protected STEP_NUMBER = 0;

  constructor(
    private formBuilder: FormBuilder,
    private bankService: BankService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.getBankList();
    this.userData = this.dataStoreService.getUserData();

    if (this.formControls.accountTypeCredit) {
      this.formControls.accountTypeCredit.valueChanges.subscribe((val) => {
        if (val) {
          if (this.isPaymentAccount(AccountTypeEnum.CREDIT)) {
            (this.fields.accountCredit as FdInputConfig).hint = '';
          } else {
            (this.fields.accountCredit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
          }
        }
      });
    }

    if (this.formControls.accountTypeDebit) {
      this.formControls.accountTypeDebit.valueChanges.subscribe((val) => {
        if (val) {
          if (this.isPaymentAccount(AccountTypeEnum.DEBIT)) {
            (this.fields.accountDebit as FdInputConfig).hint = '';
          } else {
            (this.fields.accountDebit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
          }
        }
      });
    }

    this.processItemOperationDebit();
    this.processItemOperationCredit();
  }

  public removeDigitChar(event: any) {
    if (event && event.target && event.target.value) {
      const currentValue = event.target.value;
      event.target.value = currentValue.replace(/\D/g, '');
    }
  }

  public getAccountMaxLengthType(modality: AccountTypeEnum) {
    if (modality === AccountTypeEnum.CREDIT) {
      return this.creditAccountMaxLength;
    } else {
      return this.debitAccountMaxLength;
    }
  }

  public getAccountMinLengthType(modality: AccountTypeEnum) {
    if (modality === AccountTypeEnum.CREDIT) {
      return this.creditAccountMinLength;
    } else {
      return this.debitAccountMinLength;
    }
  }

  public getAccountDigitLengthType(modality: AccountTypeEnum) {
    if (modality === AccountTypeEnum.CREDIT) {
      return this.creditAccountDigitLength;
    } else {
      return this.debitAccountDigitLength;
    }
  }

  public insertAccountDigitIntoValue(value: string, accountModality: AccountTypeEnum) {
    value = value.replace(/\D/g, '');

    if (this.isPaymentAccount(accountModality)) {
      value = value.padStart(this.getAccountMaxLengthType(accountModality), '0');
    } else {
      if (value.length === this.getAccountMaxLengthType(accountModality)) {
        return value.trim() + '-0';
      } else if (value.length < this.getAccountMaxLengthType(accountModality)) {
        value = value.padStart(this.getAccountMaxLengthType(accountModality) + 1, '0');
      }
      const digitArr = value.split('');
      digitArr.splice(digitArr.length - this.getAccountDigitLengthType(accountModality), 0, '-');
      value = digitArr.join('').trim();
    }

    return value;
  }

  public insertAgencyDigitIntoValue(value: string) {
    value = value.replace(/\D/g, '');
    if (value.length > this.agencyMaxLength) {
      const digitArr = value.split('');
      digitArr.splice(digitArr.length - this.agencyDigitLength, 0, '-');
      return digitArr.join('').trim();
    } else if (value.length === this.agencyMaxLength) {
      return value.trim() + '-0';
    } else {
      return value.trim();
    }
  }

  public insertDigitChar(event: any, type: string, accountModality: string) {
    if (event && event.target && event.target.value) {
      const currentValue = event.target.value.replace(/\D/g, '');
      if (type === 'ACCOUNT') {
        event.target.value = this.insertAccountDigitIntoValue(
          currentValue,
          accountModality === AccountTypeEnum.CREDIT ? AccountTypeEnum.CREDIT : AccountTypeEnum.DEBIT
        );
      } else if (type === 'AGENCY') {
        event.target.value = this.insertAgencyDigitIntoValue(currentValue);
      }
    }
  }

  public setValidatorCEF(accountModality: string) {
    let typeAccount: AccountTypeEnum;
    let accountField: FdFieldConfig;
    if (accountModality === 'DEBIT') {
      typeAccount = AccountTypeEnum.DEBIT;
      accountField = this.fields.accountDebit;
    } else {
      typeAccount = AccountTypeEnum.CREDIT;
      accountField = this.fields.accountCredit;
    }

    (accountField as FdInputConfig).mask = dynamicDigitsMask(
      this.getAccountMaxLengthType(typeAccount) + this.getAccountDigitLengthType(typeAccount)
    );
  }

  public setValidators(
    agencyValidations: BankValidationParams,
    accountValidations: BankValidationParams,
    agencyControl: AbstractControl,
    accountControl: AbstractControl,
    agencyField: FdFieldConfig,
    accountField: FdFieldConfig,
    modality: AccountTypeEnum
  ) {
    if (agencyValidations) {
      agencyControl.clearValidators();

      if (agencyValidations.required) {
        const validators: ValidatorFn[] = [];
        this.agencyMinLength = Number(agencyValidations.minLength);
        this.agencyMaxLength = Number(agencyValidations.maxLength);
        this.agencyDigitLength = Number(agencyValidations.digitLength);
        validators.push(Validators.required, accountLengthValidator(this.agencyMinLength, this.agencyMaxLength + this.agencyDigitLength));

        agencyControl.setValidators(validators);
      }
      (agencyField as FdInputConfig).mask = dynamicDigitsMask(this.agencyMaxLength + this.agencyDigitLength);
    }

    if (accountValidations) {
      accountControl.clearValidators();

      if (accountValidations.required) {
        const validators: ValidatorFn[] = [];
        if (modality === AccountTypeEnum.CREDIT) {
          this.creditAccountMinLength = Number(accountValidations.minLength);
          this.creditAccountMaxLength = Number(accountValidations.maxLength);
          this.creditAccountDigitLength = Number(accountValidations.digitLength);
        } else {
          this.debitAccountMinLength = Number(accountValidations.minLength);
          this.debitAccountMaxLength = Number(accountValidations.maxLength);
          this.debitAccountDigitLength = Number(accountValidations.digitLength);
        }

        validators.push(Validators.required);
        accountControl.setValidators(validators);
      }
      (accountField as FdInputConfig).mask = dynamicDigitsMask(
        this.getAccountMaxLengthType(modality) + this.getAccountDigitLengthType(modality)
      );
    }
  }

  public buildAgencyValidationParams(bankRules: BankRuleDetail) {
    const agencyValidationParams: BankValidationParams = {
      required: bankRules.agenciaObrigatorio,
      regex: bankRules.agenciaFormato,
      minLength: bankRules.agenciaTamanhoMinObrigatorio,
      maxLength: bankRules.agenciaTamanho,
      digitLength: bankRules.digitoVerificadorAgenciaTamanho,
      digitRequired: bankRules.digitoVerificadorAgenciaObrigatorio,
      digitRegex: bankRules.digitoVerificadorFormato,
    };

    return agencyValidationParams;
  }

  public buildAccountValidationParams(bankRules: BankRuleDetail, accountModality: AccountTypeEnum) {
    const accountValidationParams: BankValidationParams = {
      required: bankRules.contaObrigatorio,
      regex: bankRules.contaFormato,
      minLength: bankRules.contaTamanhoMinObrigatorio,
      maxLength: bankRules.contaTamanho,
      digitLength: this.isPaymentAccount(accountModality) ? '0' : bankRules.digitoVerContaTamanho,
      digitRequired: bankRules.digitoVerContaObrigatorio,
      digitRegex: bankRules.digitoVerContaFormato,
    };

    return accountValidationParams;
  }

  public isPf(): boolean {
    return this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF;
  }

  public selectBank(accountModality: string) {
    this.checkOperation(accountModality);
    this.getBankRules(accountModality);
  }

  public getBankRules(accountModality: string) {
    const validateFields: ValidateFields = this.getValidateFields(accountModality);
    if (validateFields === null) {
      return;
    }

    let opCef = null;
    let cefBank: boolean = false;

    if (validateFields.bankControl.value === '104') {
      cefBank = true;
      opCef = validateFields.operationControl.value;
    }

    const bank: BankRuleModel = {
      modalidade: this.getAccountTypeDescription(validateFields.accountTypeControl.value),
      titularidade: this.documentCategory,
      banco: validateFields.bankControl.value,
      operation: opCef,
    };

    if (validateFields.bankControl.valid && validateFields.accountTypeControl.valid) {
      this.bankService
        .getBankRules(bank)

        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            if (response && response.regraDomicilioBancarioVO) {
              this.changeFieldMessages(response.regraDomicilioBancarioVO);
              this.setValidators(
                this.buildAgencyValidationParams(response.regraDomicilioBancarioVO),
                this.buildAccountValidationParams(response.regraDomicilioBancarioVO, validateFields.modality),
                validateFields.agencyControl,
                validateFields.accountControl,
                validateFields.agencyField,
                validateFields.accountField,
                validateFields.modality
              );
              if (cefBank) {
                this.setValidatorCEF(validateFields.modality);
              }
              return;
            }
            this.dialogService.openDialog(Messages.FAILED_TO_VALIDATE_BANK_DATA);
          },
          (error) => {
            this.dialogService.openDialog(Messages.FAILED_TO_VALIDATE_BANK_DATA, () => this.wizardService.previousStep());
          }
        );
    }
  }

  public isPaymentAccount(modality: AccountTypeEnum) {
    if (modality === AccountTypeEnum.CREDIT) {
      return this.formControls.accountTypeCredit && this.formControls.accountTypeCredit.value === AccountModalityEnum.PAYMENT;
    } else {
      return this.formControls.accountTypeDebit && this.formControls.accountTypeDebit.value === AccountModalityEnum.PAYMENT;
    }
  }

  public listBank(text: string = '', field: FdFieldConfig) {
    if (!this.bankList || !this.bankList.length) {
      return;
    }

    if (field) {
      (field as FdSelectConfig).items = [];

      const itemsToAdd = this.bankList
        .filter((item) => item.label.match(text.toUpperCase()))
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (field as FdSelectConfig).items.push(...itemsToAdd);
    } else {
      (this.fields.bankCredit as FdSelectConfig).items = [];
      (this.fields.bankDebit as FdSelectConfig).items = [];

      const itemsToAdd = this.bankList
        .filter((item) => item.label)
        .map((value) => ({
          label: value.label,
          value: value.value,
        }));

      (this.fields.bankCredit as FdSelectConfig).items.push(...itemsToAdd);
      (this.fields.bankDebit as FdSelectConfig).items.push(...itemsToAdd);
    }
  }

  public getBankList() {
    this.bankService
      .getBankListByServiceContractAndInstitution()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (response !== null) {
            this.bankList = response;
            (this.fields.bankDebit as FdSelectConfig).items = [];
            (this.fields.bankCredit as FdSelectConfig).items = [];
            this.listBank('', null);
          }
        },
        (error) => {
          this.dialogService.openErrorDialog(
            Messages.FAILED_TO_GET_BANK_LIST,
            'Api Config - Dados Bancários',
            () => this.wizardService.previousStep(),
            null,
            'error'
          );
          (this.fields.bankDebit as FdSelectConfig).items = [];
          (this.fields.bankCredit as FdSelectConfig).items = [];
        }
      );
  }

  public clearFieldsValues() {
    this.formControls.accountTypeCredit.setValue('');
    this.formControls.accountTypeDebit.setValue('');
    this.formControls.bankCredit.setValue('');
    this.formControls.bankDebit.setValue('');
    this.formControls.agencyCredit.setValue('');
    this.formControls.agencyDebit.setValue('');
    this.formControls.accountCredit.setValue('');
    this.formControls.accountDebit.setValue('');
    this.formControls.accountCredit.setValue('');
    this.formControls.accountDebit.setValue('');
    this.formControls.operationDebit.setValue('');
    this.formControls.operationCredit.setValue('');
  }

  public checkSameData() {
    if (this.formControls.sameData.value) {
      this.formControls.accountTypeCredit.setValue(this.formControls.accountTypeDebit.value);
      this.formControls.accountDebit.setValue(
        this.insertAccountDigitIntoValue(this.formControls.accountDebit.value, AccountTypeEnum.DEBIT)
      );
      this.formControls.agencyDebit.setValue(this.insertAgencyDigitIntoValue(this.formControls.agencyDebit.value));
      this.formControls.bankCredit.setValue(this.formControls.bankDebit.value);
      this.formControls.agencyCredit.setValue(this.formControls.agencyDebit.value);
      this.formControls.accountCredit.setValue(this.formControls.accountDebit.value);

      this.formControls.operationCredit.setValue(this.formControls.operationDebit.value);
    }

    this.validateData();
  }

  public getAccountTypeDescription(accountChar: string) {
    switch (accountChar.toUpperCase()) {
      case AccountModalityEnum.CHECKING:
        return 'Conta Corrente';

      case AccountModalityEnum.GUARANTEED:
        return 'Conta Garantida';

      case AccountModalityEnum.SAVINGS:
        return 'Conta Poupança';

      case AccountModalityEnum.PAYMENT:
        return 'Conta Pagamento';

      default:
        this.dialogService.openErrorDialog(
          Messages.FAILED_TO_GET_PROPOSAL_DATA,
          'Api Fiserv Online - Modalidade Dados Bancários',
          null,
          'error'
        );
        return null;
    }
  }

  public wipeDigit(value: string) {
    const charArr = value.split('');
    const hifenPos = charArr.findIndex((val) => val === '-');

    if (hifenPos === -1) {
      return value.trim();
    } else {
      charArr.splice(hifenPos, charArr.length - hifenPos);
      return charArr.join('').trim();
    }
  }

  public getDigit(value: string, modality: AccountTypeEnum) {
    if (this.isPaymentAccount(modality)) {
      return '0';
    }

    const charArr = value.split('');
    return charArr[charArr.length - 1];
  }

  public validateBankAndBankBranch(accountType: string) {
    let agencyControl: AbstractControl;
    let bankControl: AbstractControl;

    switch (accountType) {
      case AccountTypeEnum.CREDIT:
        agencyControl = this.formControls.agencyCredit;
        bankControl = this.formControls.bankCredit;
        break;

      case AccountTypeEnum.DEBIT:
        agencyControl = this.formControls.agencyDebit;
        bankControl = this.formControls.bankDebit;
        break;
    }

    agencyControl.setValue(this.insertAgencyDigitIntoValue(agencyControl.value));

    if (agencyControl.valid && bankControl.valid) {
      const bank: BankModel = {
        agencia: this.wipeDigit(agencyControl.value),
        banco: bankControl.value,
      };

      this.bankService
        .validateBankAndBankBranch(bank)

        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (response) => {
            if (!response) {
              this.dialogService.openDialog(Messages.FAILED_TO_VALIDATE_BANK_DATA, () => this.wizardService.previousStep());
              return;
            }

            if (response.codigoRetorno !== '00') {
              agencyControl.setErrors({ invalid: true });
              return;
            }

            agencyControl.setErrors(null);
          },
          (error) => {
            this.dialogService.openDialog(Messages.FAILED_TO_VALIDATE_BANK_DATA, () => this.wizardService.previousStep());
          }
        );
    }
  }

  public getOperation(value: string, accountType: string, accountTypeEnum: AccountTypeEnum) {
    if (value !== '104') {
      return '';
    }

    if (accountType === AccountModalityEnum.CHECKING) {
      if (this.documentCategory === DocumentCategory.CPF) {
        if (accountTypeEnum === AccountTypeEnum.DEBIT) {
          return this.formControls.operationDebit.value;
        } else {
          return this.formControls.operationCredit.value;
        }
      } else if (this.documentCategory === DocumentCategory.CNPJ) {
        if (accountTypeEnum === AccountTypeEnum.DEBIT) {
          return this.formControls.operationDebit.value;
        } else {
          return this.formControls.operationCredit.value;
        }
      }
    }

    if (accountType === AccountModalityEnum.SAVINGS) {
      if (this.documentCategory === DocumentCategory.CPF) {
        if (accountTypeEnum === AccountTypeEnum.DEBIT) {
          return this.formControls.operationDebit.value;
        } else {
          return this.formControls.operationCredit.value;
        }
      } else if (this.documentCategory === DocumentCategory.CNPJ) {
        if (accountTypeEnum === AccountTypeEnum.DEBIT) {
          return this.formControls.operationDebit.value;
        } else {
          return this.formControls.operationCredit.value;
        }
      }
    }
  }

  public buildBankValidateDataValidationModel(accountModality: AccountTypeEnum): BankValidateDataModel {
    if (accountModality === AccountTypeEnum.DEBIT) {
      this.formControls.accountDebit.setValue(this.insertAccountDigitIntoValue(this.formControls.accountDebit.value, accountModality));
      let operation = null;
      if (this.formControls.bankDebit.value === '104' && this.formControls.operationDebit.value !== '') {
        operation = this.formControls.operationDebit.value;
      }
      return {
        serviceContract: this.userData.serviceContract,
        institution: this.userData.institution,
        bank: this.formControls.bankDebit.value,
        agency: this.wipeDigit(this.formControls.agencyDebit.value),
        agencyDigit: this.getDigit(this.formControls.agencyDebit.value, accountModality),
        account: this.wipeDigit(this.formControls.accountDebit.value),
        accountDigit: this.getDigit(this.formControls.accountDebit.value, accountModality),
        cpfCnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
        modality: this.getAccountTypeDescription(this.formControls.accountTypeDebit.value),
        titularity: this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF ? 'CPF' : 'CNPJ',
        operation,
      };
    } else {
      this.formControls.accountCredit.setValue(this.insertAccountDigitIntoValue(this.formControls.accountCredit.value, accountModality));
      let operation = null;
      if (this.formControls.bankCredit.value === '104' && this.formControls.operationCredit.value !== '') {
        operation = this.formControls.operationCredit.value;
      }
      return {
        serviceContract: this.userData.serviceContract,
        institution: this.userData.institution,
        bank: this.formControls.bankCredit.value,
        agency: this.wipeDigit(this.formControls.agencyCredit.value),
        agencyDigit: this.getDigit(this.formControls.agencyCredit.value, accountModality),
        account: this.wipeDigit(this.formControls.accountCredit.value),
        accountDigit: this.getDigit(this.formControls.accountCredit.value, accountModality),
        cpfCnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
        modality: this.getAccountTypeDescription(this.formControls.accountTypeCredit.value),
        titularity: this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF ? 'CPF' : 'CNPJ',
        operation,
      };
    }
  }

  public callCreditValidation(creditData: BankValidateDataModel) {
    this.bankService
      .validateBankDataV2(creditData)

      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response) => {
          if (!response.status || response.codigoRetorno !== '00') {
            this.dialogService.openDialog(Messages.INVALID_BANK_CREDIT);
            return;
          }
          this.next();
        },
        (error) => {
          this.dialogService.openDialog(Messages.BANK_VALIDATION_ERROR);
          return;
        }
      );
  }

  public validateData() {
    if (this.isFormGroupValid()) {
      const debitData = this.buildBankValidateDataValidationModel(AccountTypeEnum.DEBIT);

      if (this.formControls.sameData.value) {
        this.bankService
          .validateBankDataV2(debitData)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (response) => {
              if (!response.status || response.codigoRetorno !== '00') {
                this.dialogService.openDialog(Messages.INVALID_BANK_DEBIT);
                return;
              }

              this.next();
            },
            (error) => {
              this.dialogService.openDialog(Messages.BANK_VALIDATION_ERROR, () => this.wizardService.previousStep());
              return false;
            }
          );
      } else {
        const creditData = this.buildBankValidateDataValidationModel(AccountTypeEnum.CREDIT);

        this.bankService
          .validateBankDataV2(debitData)

          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (response) => {
              if (!response.status || response.codigoRetorno !== '00') {
                this.dialogService.openDialog(Messages.INVALID_BANK_DEBIT);
                return;
              }

              this.callCreditValidation(creditData);
            },
            (error) => {
              this.dialogService.openDialog(Messages.BANK_VALIDATION_ERROR);
              return;
            }
          );
      }
    }
  }

  public checkOperationDebit() {
    this.formControls.operationDebit.setValue('');

    if (this.formControls.accountTypeDebit.value === AccountModalityEnum.SAVINGS) {
      if (!this.isPf()) {
        this.formControls.bankDebit.setValue('104');
      }

      if (this.formControls.bankDebit.value == '104') {
        this.formControls.operationDebit.setValue('1288');
      }
    }

    if (this.formControls.bankDebit.value == '104' && this.formControls.accountTypeDebit.value === AccountModalityEnum.CHECKING) {
      if (this.isPf()) {
        this.formControls.operationDebit.setValue('01');
      } else {
        this.formControls.operationDebit.setValue('03');
      }
    }
  }

  public checkOperationCredit() {
    this.formControls.operationCredit.setValue('');

    if (this.formControls.accountTypeCredit.value === AccountModalityEnum.SAVINGS) {
      if (!this.isPf()) {
        this.formControls.bankCredit.setValue('104');
      }

      if (this.formControls.bankCredit.value == '104') {
        this.formControls.operationCredit.setValue('1288');
      }
    }

    if (this.formControls.bankCredit.value == '104' && this.formControls.accountTypeCredit.value === AccountModalityEnum.CHECKING) {
      if (this.isPf()) {
        this.formControls.operationCredit.setValue('01');
      } else {
        this.formControls.operationCredit.setValue('03');
      }
    }
  }

  public checkOperation(accountType: string) {
    if (accountType === AccountTypeEnum.DEBIT) {
      this.checkOperationDebit();
    } else {
      this.checkOperationCredit();
    }
  }

  public enableBank(accountType: string) {
    this.checkOperation(accountType);

    if (accountType === AccountTypeEnum.DEBIT) {
      this.formControls.bankDebit.enable();
      this.formControls.agencyDebit.setValue('');
      this.formControls.accountDebit.setValue('');

      if (this.isPaymentAccount(AccountTypeEnum.DEBIT)) {
        this.formControls.agencyDebit.setValue('0001');
        this.formControls.agencyDebit.disable();
      }
      if (this.formControls.bankDebit.value) {
        this.getBankRules(AccountTypeEnum.DEBIT);
      }
    } else {
      this.formControls.accountCredit.setValue('');
      this.formControls.agencyCredit.setValue('');

      if (this.isPaymentAccount(AccountTypeEnum.CREDIT)) {
        this.formControls.agencyCredit.setValue('0001');
        this.formControls.agencyCredit.disable();
      }
      this.formControls.bankCredit.enable();
      if (this.formControls.bankCredit.value) {
        this.getBankRules(AccountTypeEnum.CREDIT);
      }
    }

    this.processItemOperationDebit();
    this.processItemOperationCredit();
  }

  public disableFields() {
    this.formControls.bankDebit.disable();
    this.formControls.bankCredit.disable();
    this.formControls.agencyDebit.disable();
    this.formControls.agencyCredit.disable();
    this.formControls.accountDebit.disable();
    this.formControls.accountCredit.disable();
  }

  public enableFields() {
    this.formControls.bankDebit.enable();
    this.formControls.bankCredit.enable();
    this.formControls.agencyDebit.enable();
    this.formControls.agencyCredit.enable();
    this.formControls.accountDebit.enable();
    this.formControls.accountCredit.enable();
  }

  public setFieldValues() {
    if (!this.accountsModel) {
      this.formControls.sameData.setValue(true);
      this.disableFields();
      return;
    }

    this.enableFields();

    this.formControls.accountTypeDebit.setValue(this.accountsModel.accountTypeDebit);

    this.formControls.accountTypeCredit.setValue(this.accountsModel.accountTypeCredit);

    this.formControls.bankDebit.setValue(this.accountsModel.bankDebit);
    this.formControls.bankCredit.setValue(this.accountsModel.bankCredit);
    this.formControls.agencyDebit.setValue(this.accountsModel.agencyDebit);
    this.formControls.agencyCredit.setValue(this.accountsModel.agencyCredit);
    this.formControls.accountDebit.setValue(this.accountsModel.accountDebit);
    this.formControls.accountCredit.setValue(this.accountsModel.accountCredit);
    this.formControls.sameData.setValue(this.accountsModel.sameData);

    this.formControls.operationDebit.setValue(this.accountsModel.operationDebit);

    this.formControls.operationCredit.setValue(this.accountsModel.operationCredit);

    this.getBankRules(AccountTypeEnum.DEBIT);

    if (!this.accountsModel.sameData) {
      this.getBankRules(AccountTypeEnum.CREDIT);
    }
  }

  public accountType() {
    if (!this.proposal.productSelection.hasSplitTech) {
      return [
        {
          label: 'Conta corrente',
          value: AccountModalityEnum.CHECKING,
        },
        {
          label: 'Conta garantida',
          value: AccountModalityEnum.GUARANTEED,
        },
        {
          label: 'Conta poupança',
          value: AccountModalityEnum.SAVINGS,
        },
        {
          label: 'Conta pagamento',
          value: AccountModalityEnum.PAYMENT,
        },
      ];
    } else {
      return [
        {
          label: 'Conta corrente',
          value: AccountModalityEnum.CHECKING,
        },
        {
          label: 'Conta poupança',
          value: AccountModalityEnum.SAVINGS,
        },
        {
          label: 'Conta pagamento',
          value: AccountModalityEnum.PAYMENT,
        },
      ];
    }
  }

  public changeFieldMessages(bankRules: BankRuleDetail) {
    (this.fields.agencyDebit as FdInputConfig).messages.minlength = bankRules.agenciaMsgErroFormato;
    (this.fields.agencyCredit as FdInputConfig).messages.maxLength = bankRules.agenciaMsgErroFormato;
    (this.fields.accountDebit as FdInputConfig).messages.maxLength = bankRules.contaMsgErroFormato;
    (this.fields.accountCredit as FdInputConfig).messages.maxLength = bankRules.contaMsgErroFormato;
    (this.fields.accountDebit as FdInputConfig).hint = this.isPaymentAccount(AccountTypeEnum.DEBIT)
      ? ''
      : bankRules.digitoVerContaMsgErroFormato;
    (this.fields.accountCredit as FdInputConfig).hint = this.isPaymentAccount(AccountTypeEnum.CREDIT)
      ? ''
      : bankRules.digitoVerContaMsgErroFormato;
    (this.fields.agencyDebit as FdInputConfig).hint = bankRules.digitoVerAgenciaMsgErroFormato;
    (this.fields.agencyCredit as FdInputConfig).hint = bankRules.digitoVerAgenciaMsgErroFormato;
  }

  public createFields(): FdFieldConfigs {
    if (this.isPf()) {
      return {
        accountTypeDebit: {
          label: 'Tipo de conta',
          items: this.accountType(),
          controlName: 'accountTypeDebit',
          messages: {
            required: 'informe o tipo de conta',
            invalid: 'Tipo inválido',
          },
        },
        accountTypeCredit: {
          label: 'Tipo de conta',
          items: this.accountType(),
          controlName: 'accountTypeCredit',
          messages: {
            required: 'informe o tipo de conta',
            invalid: 'Tipo inválido',
          },
        },
        bankDebit: {
          label: 'Banco',
          items: [],
          controlName: 'bankDebit',
          searchPlaceholder: 'Buscar',
          searchable: true,
          messages: {
            required: 'informe o Banco de débito',
            invalid: 'Banco inválido',
            pattern: 'Banco inválido',
          },
        },
        bankCredit: {
          label: 'Banco',
          items: [],
          searchPlaceholder: 'Buscar',
          searchable: true,
          controlName: 'bankCredit',
          messages: {
            required: 'informe o Banco de crédito',
            invalid: 'Banco inválido',
            pattern: 'Banco inválido',
          },
        },
        agencyDebit: {
          label: 'Agência (com dígito)',
          hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
          controlName: 'agencyDebit',
          messages: {
            required: 'informe a agência de débito',
            invalid: 'combinação de banco e agência incorreta',
            pattern: 'agência inválida',
            maxlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
            minlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
          },
        },
        agencyCredit: {
          label: 'Agência (com dígito)',
          hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
          controlName: 'agencyCredit',
          messages: {
            required: 'informe a agência de crédito',
            invalid: 'combinação de banco e agência incorreta',
            pattern: 'agência inválida',
            maxlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
            minlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
          },
        },
        accountDebit: {
          label: 'Conta (com dígito)',
          hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
          controlName: 'accountDebit',
          messages: {
            required: 'informe a conta de debito',
            invalid: 'conta inválida',
            pattern: 'conta inválida',
            maxlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
            minlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
          },
        },
        accountCredit: {
          label: 'Conta (com dígito)',
          hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
          controlName: 'accountCredit',
          messages: {
            required: 'informe a conta de crédito',
            invalid: 'conta inválida',
            pattern: 'conta inválida',
            maxlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
            minlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
          },
        },
        sameData: {
          items: [],
          controlName: 'sameData',
          messages: {
            required: 'informe os dados de crédito',
            invalid: 'opção inválida',
          },
        },
        operationCredit: {
          label: 'Operação',
          items: [
            {
              label: '01',
              value: 1,
            },
            {
              label: '30',
              value: 30,
            },
            {
              label: '1288',
              value: 1288,
            },
            {
              label: '3701',
              value: 3701,
            },
            {
              label: 'Não Possui',
              value: '',
            },
          ],
          controlName: 'operationCredit',
          messages: {
            required: 'informe o tipo de operação',
            invalid: 'Tipo de operação inválido',
          },
        },

        operationDebit: {
          label: 'Operação',
          items: [
            {
              label: '01',
              value: 1,
            },
            {
              label: '30',
              value: 30,
            },
            {
              label: '1288',
              value: 1288,
            },
            {
              label: '3701',
              value: 3701,
            },
            {
              label: 'Não Possui',
              value: '',
            },
          ],
          controlName: 'operationDebit',
          messages: {
            required: 'informe o tipo de operação',
            invalid: 'Tipo de operação inválido',
          },
        },
      };
    }

    return {
      accountTypeDebit: {
        label: 'Tipo de conta',
        items: this.accountType(),
        controlName: 'accountTypeDebit',
        messages: {
          required: 'informe o tipo de conta',
          invalid: 'Tipo inválido',
        },
      },
      accountTypeCredit: {
        label: 'Tipo de conta',
        items: this.accountType(),
        controlName: 'accountTypeCredit',
        messages: {
          required: 'informe o tipo de conta',
          invalid: 'Tipo inválido',
        },
      },
      bankDebit: {
        label: 'Banco',
        items: [],
        controlName: 'bankDebit',
        searchPlaceholder: 'Buscar',
        searchable: true,
        messages: {
          required: 'informe o Banco de débito',
          invalid: 'Banco inválido',
          pattern: 'Banco inválido',
        },
      },
      bankCredit: {
        label: 'Banco',
        items: [],
        searchPlaceholder: 'Buscar',
        searchable: true,
        controlName: 'bankCredit',
        messages: {
          required: 'informe o Banco de crédito',
          invalid: 'Banco inválido',
          pattern: 'Banco inválido',
        },
      },
      agencyDebit: {
        label: 'Agência (com dígito)',
        hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
        controlName: 'agencyDebit',
        messages: {
          required: 'informe a agência de débito',
          invalid: 'combinação de banco e agência incorreta',
          pattern: 'agência inválida',
          maxlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
          minlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
        },
      },
      agencyCredit: {
        label: 'Agência (com dígito)',
        hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
        controlName: 'agencyCredit',
        messages: {
          required: 'informe a agência de crédito',
          invalid: 'combinação de banco e agência incorreta',
          pattern: 'agência inválida',
          maxlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
          minlength: 'Informe todos os dígitos da agência, completando com zeros a esquerda se necessário',
        },
      },
      accountDebit: {
        label: 'Conta (com dígito)',
        hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
        controlName: 'accountDebit',
        messages: {
          required: 'informe a conta de debito',
          invalid: 'conta inválida',
          pattern: 'conta inválida',
          maxlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
          minlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
        },
      },
      accountCredit: {
        label: 'Conta (com dígito)',
        hint: 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)',
        controlName: 'accountCredit',
        messages: {
          required: 'informe a conta de crédito',
          invalid: 'conta inválida',
          pattern: 'conta inválida',
          maxlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
          minlength: 'Informe todos os dígitos da conta, completando com zeros a esquerda se necessário',
        },
      },
      sameData: {
        items: [],
        controlName: 'sameData',
        messages: {
          required: 'informe os dados de crédito',
          invalid: 'opção inválida',
        },
      },

      operationCredit: {
        label: 'Operação',
        items: [
          {
            label: '01',
            value: '01',
          },
          {
            label: '30',
            value: '30',
          },
          {
            label: '006',
            value: '006',
          },
          {
            label: '1288',
            value: '1288',
          },
          {
            label: '1292',
            value: '1292',
          },
          {
            label: 'Não Possui',
            value: '',
          },
        ],
        controlName: 'operationCredit',
        messages: {
          required: 'informe o tipo de operação',
          invalid: 'Tipo de operação inválido',
        },
      },

      operationDebit: {
        label: 'Operação',
        items: [
          {
            label: '01',
            value: '01',
          },
          {
            label: '30',
            value: '30',
          },
          {
            label: '006',
            value: '006',
          },
          {
            label: '1288',
            value: '1288',
          },
          {
            label: '1292',
            value: '1292',
          },
          {
            label: 'Não Possui',
            value: '',
          },
        ],
        controlName: 'operationDebit',
        messages: {
          required: 'informe o tipo de operação',
          invalid: 'Tipo de operação inválido',
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      accountTypeCredit: ['', Validators.required],
      accountTypeDebit: ['', Validators.required],
      bankDebit: ['', Validators.required],
      bankCredit: ['', Validators.required],
      agencyDebit: ['', Validators.required],
      agencyCredit: ['', Validators.required],
      accountDebit: ['', Validators.required],
      accountCredit: ['', Validators.required],
      sameData: ['', Validators.required],
      operationDebit: [''],
      operationCredit: [''],
    });
  }

  public isCEF(accountType: string): boolean {
    if (!accountType) {
      return undefined;
    }

    const accountTypeToCall =
      accountType.toUpperCase() === AccountTypeEnum.DEBIT ? this.fields.bankDebit.controlName : this.fields.bankCredit.controlName;

    const CefBankCode = '104';

    if (this.isAccountModalitySavingOrChecking(accountType)) {
      return this.formControls[accountTypeToCall].value === CefBankCode;
    }
  }

  private isAccountModalitySavingOrChecking(accountType: string): boolean {
    const accountTypeToCall =
      accountType.toUpperCase() === AccountTypeEnum.DEBIT
        ? this.fields.accountTypeDebit.controlName
        : this.fields.accountTypeCredit.controlName;

    return (
      this.formControls[accountTypeToCall].value === AccountModalityEnum.SAVINGS ||
      this.formControls[accountTypeToCall].value === AccountModalityEnum.CHECKING
    );
  }

  protected proposalGetCallback(): void {
    if (
      this.dataStore.isObjectEmpty(this.proposal) ||
      !this.proposal.registrationDataPersonalInfo ||
      !this.proposal.registrationDataPersonalInfo.documentCategory
    ) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Dados Bancários',
        () => this.wizardService.previousStep(),
        null,
        'error'
      );
      return;
    }
    this.documentCategory = this.proposal.registrationDataPersonalInfo.documentCategory;
  }

  protected persistData() {
    const accounts: AccountsModel = {
      ...this.formGroup.getRawValue(),
    };

    const cloneObj = Object.assign({}, accounts);
    delete cloneObj.sameData;

    const isEmpty = Object.values(cloneObj).every((x) => !x);

    if (!isEmpty) {
      return this.dataStore.updateProposal({ accounts });
    }

    // todo: checar se esse if faz sentido após a conversão do updateproposal para promise
    return Promise.resolve(true);
  }

  protected updateFields(proposal: Proposal) {
    if (environment.saveSensitiveDataOnLocalStorage) {
      this.accountsModel = proposal.accounts;
      this.setFieldValues();
    } else {
      this.dataStore.getInMemoryProposal().subscribe((proposal) => {
        if (proposal && proposal.accounts) {
          this.accountsModel = proposal.accounts;
        }
        this.setFieldValues();
      });
    }
  }

  private processItemOperationDebit() {
    const itemsToAddDebit = [];
    if (this.isPf()) {
      if (this.formControls.accountTypeDebit.value === AccountModalityEnum.SAVINGS) {
        itemsToAddDebit.push(
          {
            label: '13',
            value: '13',
          },
          {
            label: '1288',
            value: '1288',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else if (this.formControls.accountTypeDebit.value === AccountModalityEnum.CHECKING) {
        itemsToAddDebit.push(
          {
            label: '01',
            value: '01',
          },
          {
            label: '3701',
            value: '3701',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else {
        itemsToAddDebit.push(
          {
            label: '01',
            value: '01',
          },
          {
            label: '3701',
            value: '3701',
          }
        );
      }
    } else {
      if (this.formControls.accountTypeDebit.value === AccountModalityEnum.CHECKING) {
        itemsToAddDebit.push(
          {
            label: '03',
            value: '03',
          },
          {
            label: '006',
            value: '006',
          },
          {
            label: '1292',
            value: '1292',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else if (this.formControls.accountTypeDebit.value === AccountModalityEnum.SAVINGS) {
        itemsToAddDebit.push(
          {
            label: '1288',
            value: '1288',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else {
        itemsToAddDebit.push(
          {
            label: '03',
            value: '03',
          },
          {
            label: '1292',
            value: '1292',
          }
        );
      }
    }

    (this.fields.operationDebit as FdSelectConfig).items = [];
    (this.fields.operationDebit as FdSelectConfig).items.push(...itemsToAddDebit);
  }

  private processItemOperationCredit() {
    const itemsToAdd = [];
    if (this.isPf()) {
      if (this.formControls.accountTypeCredit.value === AccountModalityEnum.SAVINGS) {
        itemsToAdd.push(
          {
            label: '13',
            value: '13',
          },
          {
            label: '1288',
            value: '1288',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else if (this.formControls.accountTypeCredit.value === AccountModalityEnum.CHECKING) {
        itemsToAdd.push(
          {
            label: '01',
            value: '01',
          },
          {
            label: '3701',
            value: '3701',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else {
        itemsToAdd.push(
          {
            label: '01',
            value: '01',
          },
          {
            label: '3701',
            value: '3701',
          }
        );
      }
    } else {
      if (this.formControls.accountTypeCredit.value === AccountModalityEnum.CHECKING) {
        itemsToAdd.push(
          {
            label: '03',
            value: '03',
          },
          {
            label: '006',
            value: '006',
          },
          {
            label: '1292',
            value: '1292',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else if (this.formControls.accountTypeCredit.value === AccountModalityEnum.SAVINGS) {
        itemsToAdd.push(
          {
            label: '1288',
            value: '1288',
          },
          {
            label: 'Não Possui',
            value: '',
          }
        );
      } else {
        itemsToAdd.push(
          {
            label: '03',
            value: '03',
          },
          {
            label: '1292',
            value: '1292',
          }
        );
      }
    }

    (this.fields.operationCredit as FdSelectConfig).items = [];
    (this.fields.operationCredit as FdSelectConfig).items.push(...itemsToAdd);
  }

  private getValidateFields(accountModality: string): ValidateFields {
    const validateFields: ValidateFields = new ValidateFields();
    switch (accountModality) {
      case AccountTypeEnum.CREDIT:
        validateFields.accountTypeControl = this.formControls.accountTypeCredit;
        validateFields.agencyControl = this.formControls.agencyCredit;
        validateFields.bankControl = this.formControls.bankCredit;
        validateFields.accountControl = this.formControls.accountCredit;
        validateFields.agencyField = this.fields.agencyCredit;
        validateFields.accountField = this.fields.accountCredit;
        validateFields.operationControl = this.formControls.operationCredit;
        validateFields.modality = AccountTypeEnum.CREDIT;
        break;

      case AccountTypeEnum.DEBIT:
        validateFields.accountTypeControl = this.formControls.accountTypeDebit;
        validateFields.agencyControl = this.formControls.agencyDebit;
        validateFields.bankControl = this.formControls.bankDebit;
        validateFields.accountControl = this.formControls.accountDebit;
        validateFields.agencyField = this.fields.agencyDebit;
        validateFields.accountField = this.fields.accountDebit;
        validateFields.operationControl = this.formControls.operationDebit;
        validateFields.modality = AccountTypeEnum.DEBIT;
        break;
    }

    this.setMsgHints(validateFields);

    if (
      this.isPf() === false &&
      validateFields.bankControl.value !== '104' &&
      validateFields.accountTypeControl.value === AccountModalityEnum.SAVINGS
    ) {
      validateFields.accountTypeControl.setValue(AccountModalityEnum.CHECKING);
    }

    validateFields.agencyControl.enable();
    validateFields.accountControl.enable();

    if (!validateFields.accountTypeControl || !validateFields.accountTypeControl.value) {
      this.dialogService.openDialog(Messages.SELECT_ACCOUNT_TYPE);
      return null;
    }

    validateFields.accountControl.enable();

    if (!this.isPaymentAccount(validateFields.modality)) {
      validateFields.agencyControl.enable();
    } else {
      validateFields.agencyControl.disable();
      validateFields.agencyControl.setValue('0001');
    }

    return validateFields;
  }

  private setMsgHints(validateFields: ValidateFields) {
    if (!this.isPf()) {
      if (validateFields.bankControl.value === '104') {
        if (validateFields.accountTypeControl.value === AccountModalityEnum.CHECKING) {
          (this.fields.accountCredit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
          (this.fields.accountDebit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
        } else if (validateFields.accountTypeControl.value === AccountModalityEnum.SAVINGS) {
          (this.fields.accountCredit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
          (this.fields.accountDebit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
        } else {
          (this.fields.accountCredit as FdInputConfig).hint = '';
          (this.fields.accountDebit as FdInputConfig).hint = '';
        }
      } else {
        (this.fields.accountCredit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
        (this.fields.accountDebit as FdInputConfig).hint = 'Se o digito for uma letra ou não existir, favor preencher com 0 (zero)';
      }
    }
  }
}

export class BankValidationParams {
  public required: boolean;
  public minLength: string;
  public maxLength: string;
  public regex: string;

  public digitRequired: boolean;
  public digitLength: string;
  public digitRegex: string;
}

export enum AccountTypeEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum AccountModalityEnum {
  CHECKING = 'CHECKING',
  GUARANTEED = 'GUARANTEED',
  SAVINGS = 'SAVINGS',
  PAYMENT = 'PAYMENT',
}

export class ValidateFields {
  public modality: any;
  public agencyControl: AbstractControl;
  public accountTypeControl: AbstractControl;
  public bankControl: AbstractControl;
  public accountControl: AbstractControl;
  public operationControl: AbstractControl;
  public agencyField: FdFieldConfig;
  public accountField: FdFieldConfig;
}
