import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiResponseDetails } from 'src/app/shared/models/response/base-api-response.model';
import { PaymentResponseModel } from 'src/app/shared/models/response/payment-response.model';
import { PaymentSubmitModel } from 'src/app/shared/models/response/payment-submit.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public submitPayment(paymentInfo: PaymentSubmitModel): Observable<PaymentResponseModel> {
    return this.http.post<PaymentResponseModel>(`${environment.apiUrls.payment}`, paymentInfo);
  }

  private invalidResponse(baseApiResponse: BaseApiResponseDetails<any>): boolean {
    return !baseApiResponse || !!baseApiResponse.error || (!baseApiResponse.details && !baseApiResponse.datails);
  }
}
