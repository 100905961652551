<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <h4 class="fd-subtitle">Como tomou conhecimento da oportunidade de negócio com estabelecimento?</h4>

    <div *ngFor="let item of prospectionTypeOptions" class="checkbox-container">
      <mat-checkbox
        [disabled]="hasSplitTech"
        (change)="onCheckboxChange(item, $event)"
        [value]="item.value"
        [checked]="item.selected"
        class="fd-checkbox"
        [id]="item.value"
        >{{ item.label }}</mat-checkbox
      >
    </div>

    <div *ngIf="!hasSplitTech" class="tooltip-container">
      <h4 class="tooltip-subtitle fd-subtitle">Descreva qual tipo de produto/serviço será comercializado:</h4>
      <fa-icon
        matTooltipClass="fd-input__tooltip-dialog"
        class="tooltip-icon"
        icon="info-circle"
        size="1x"
        mat-button
        [matTooltip]="getGatewayTooltipText()"
        #tooltip="matTooltip"
        (click)="showTooltipAndStopPropagation()"
      >
      </fa-icon>
    </div>

    <div *ngIf="!hasSplitTech">
      <fd-input [parentForm]="formGroup" [field]="fields.productTypeDescription"> </fd-input>
    </div>

    <h4 *ngIf="!hasSplitTech" class="fd-subtitle">Descreva como serão entregues os produtos adquiridos à distância:</h4>
    <div *ngIf="!hasSplitTech">
      <fd-input [parentForm]="formGroup" [field]="fields.productDeliveryDescription"> </fd-input>
    </div>
  </div>
  <div class="button-container">
    <fd-button fdSecondary [content]="'cancelar'" (trigger)="back()"> </fd-button>
    <fd-button [content]="'avançar'" (trigger)="next()"> </fd-button>
  </div>
</form>
