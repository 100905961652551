import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { FdFieldConfig } from 'src/app/shared/shared-components.module';
import { AppliedFilter } from '../../proposal-history/proposal-history.component';

@Component({
  selector: 'app-proposal-filter-modal',
  templateUrl: './proposal-filter-modal.component.html',
  styleUrls: ['./proposal-filter-modal.component.scss'],
})
export class ProposalFilterModalComponent implements OnInit {
  public items: Item[];
  public formGroup: FormGroup;

  @Output() public buttonClicked = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<ProposalFilterModalComponent>,
    private scrollService: ScrollService,
    @Inject(MAT_DIALOG_DATA) public data: ProposalFilterDialogData
  ) {}

  public async ngOnInit() {
    this.items = this.data.statusOptions;
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;

    if (item.markAll) {
      this.items.map((x) => (x.selected = item.selected));
    } else {
      const selectedItems = this.items.filter((x) => x.selected && !x.markAll);
      // -1 pra desconsiderar o todos
      this.items.filter((x) => x.markAll).map((x) => (x.selected = selectedItems.length === this.items.length - 1));
    }
  }

  public handleCancelClick(): void {
    if (this.data.cancelCallbackFn) {
      this.data.cancelCallbackFn();
    }
    this.dialogRef.close();
  }

  get initialDateControl(): AbstractControl {
    return this.data.relatedFormGroup.get(this.data.initialDateField.controlName);
  }

  get endDateControl(): AbstractControl {
    return this.data.relatedFormGroup.get(this.data.endDateField.controlName);
  }

  public handleConfirmClick(): void {
    if (!this.data.relatedFormGroup.valid) {
      this.scrollService.scrollAndFocusToFirstInvalid();
      return;
    }

    if (this.data.confirmCallbackFn) {
      this.data.confirmCallbackFn();
    }
    this.dialogRef.close(this.data);
  }
}

export interface ProposalFilterDialogData {
  statusOptions: Item[];
  initialDateField: FdFieldConfig;
  appliedFilters: AppliedFilter[];
  endDateField: FdFieldConfig;
  relatedFormGroup: FormGroup;
  title: string;
  cancelCallbackFn?: () => any;
  confirmCallbackFn?: () => any;
}
