import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PhoneMask } from 'src/app/order/masks/phone-mask';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { EmailRegExp } from 'src/app/order/validators/email-compare-validator';
import { PhoneValidator } from 'src/app/order/validators/phone-validator';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../../form-step';
import { TefIntegrationModel } from '../models/tef-integration.model';

import { defer } from 'rxjs';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-tef-integration',
  templateUrl: './tef-integration.component.html',
  styleUrls: ['./tef-integration.component.scss'],
})
export class TefIntegrationComponent extends FormStep implements OnInit {
  public STEP_NUMBER = 0;

  public tefIntegrationModel: TefIntegrationModel;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
  }

  public next(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.nextStep();
        }
      });
    }
  }

  public persistData() {
    const tefIntegration: TefIntegrationModel = {
      ...this.formGroup.getRawValue(),
    };

    return this.dataStore.updateProposal({ tefIntegration });
  }

  public createFormGroup() {
    return this.formBuilder.group({
      integratorEnterpriseName: ['', Validators.required],
      contactPhoneNumber: ['', [Validators.required, PhoneValidator]],
      itResponsibleName: ['', Validators.required],
      itResponsiblePhoneNumber: ['', [Validators.required, PhoneValidator]],
      itResponsibleEmail: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
      itResponsibleEmail2: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
    });
  }
  protected proposalGetCallback(): void {}

  protected updateFields(proposal: Proposal) {
    this.tefIntegrationModel = proposal.tefIntegration;

    if (this.tefIntegrationModel) {
      this.formControls.contactPhoneNumber.setValue(this.tefIntegrationModel.contactPhoneNumber);
      this.formControls.integratorEnterpriseName.setValue(this.tefIntegrationModel.integratorEnterpriseName);
      this.formControls.itResponsibleEmail.setValue(this.tefIntegrationModel.itResponsibleEmail);
      this.formControls.itResponsibleEmail2.setValue(this.tefIntegrationModel.itResponsibleEmail2);
      this.formControls.itResponsibleName.setValue(this.tefIntegrationModel.itResponsibleName);
      this.formControls.itResponsiblePhoneNumber.setValue(this.tefIntegrationModel.itResponsiblePhoneNumber);
    }
  }

  protected createFields(): FdFieldConfigs {
    return {
      integratorEnterpriseName: {
        label: 'Nome da empresa integradora',
        controlName: 'integratorEnterpriseName',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      contactPhoneNumber: {
        label: 'Telefone de contato',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'contactPhoneNumber',
        messages: {
          required: 'Campo obrigatório',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
      itResponsibleName: {
        label: 'Nome do responsável',
        controlName: 'itResponsibleName',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      itResponsiblePhoneNumber: {
        label: 'Celular',
        mask: PhoneMask,
        maskCharsReplace: /[-( )]/g,
        controlName: 'itResponsiblePhoneNumber',
        messages: {
          required: 'Campo obrigatório',
          invalid: 'Número inválido',
          pattern: 'Número inválido',
        },
      },
      itResponsibleEmail: {
        label: 'E-mail',
        controlName: 'itResponsibleEmail',
        messages: {
          required: 'Campo obrigatório',
        },
      },
      itResponsibleEmail2: {
        label: 'E-mail 2',
        controlName: 'itResponsibleEmail2',
        messages: {
          required: 'Campo obrigatório',
        },
      },
    };
  }
}
