export function getValueWithDecimalPlaces(value: any, decimalPlaces: number = 2) {
  if (value === null || value === undefined) {
    console.error('decimal-places: value not present');
    return;
  }
  if (isNaN(Number(value))) {
    console.error('decimal-places: value is not a number');
    return;
  }
  return Number(value).toFixed(decimalPlaces);
}
