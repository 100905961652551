<mat-form-field class="fd-search-bar__container" color="accent" floatLabel="never">
  <div class="fd-search-bar__field-container">
      <input matInput autocomplete="off" class="fd-search-bar__field"
          [placeholder]="field?.label"
          [textMask]="{ mask: field?.mask || false, guide: true, placeholderChar: '\u2000' }"
          [id]="field?.controlName" [required]="required || null" aria-label="Area code"
          [type]="field?.type || 'text'" autocomplete="off" [attr.disabled]="field?.disabled || null" tabindex="0"
          (blur)="handleBlur($event)" (input)="handleChange($event)">     
  </div>
  <mat-icon matPrefix class="fd-search-bar__icon-container">
    <fa-icon icon="search"></fa-icon>
  </mat-icon>
  <mat-hint class="fd-search-bar__hint">{{field?.hint}}</mat-hint>
</mat-form-field>