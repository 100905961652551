import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalDraftQueryResultModel } from 'src/app/shared/models/proposal-draft-query-result.model';
import { ProposalDraftQueryModel } from 'src/app/shared/models/proposal-draft-query.model';
import { ProposalDraftSendModel } from 'src/app/shared/models/proposal-draft-send.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class ProposalDraftService {
  constructor(private http: HttpClient) {}

  public saveProposalDraft(proposalDraft: ProposalDraftSendModel) {
    return this.http.post<ProposalDraftSendModel>(`${environment.apiUrls.proposalDraft}`, proposalDraft);
  }

  public getDraftProposals(draftQuery: ProposalDraftQueryModel) {
    return this.http.post<ProposalDraftQueryResultModel[]>(`${environment.apiUrls.proposalDraft}/query`, draftQuery);
  }

  public getDraftByUuid(draftUuid: string) {
    return this.http.get<ProposalDraftQueryResultModel>(`${environment.apiUrls.proposalDraft}/${draftUuid}`);
  }

  public getDraftByUuidAsProposal(draftUuid: string): Observable<Proposal> {
    return this.http
      .get<ProposalDraftQueryResultModel>(`${environment.apiUrls.proposalDraft}/${draftUuid}`)
      .pipe(map((x) => this.mapToProposalModel(x)));
  }

  public deleteDraft(deleteDraftObject: ProposalDraftQueryModel) {
    return this.http.post<any>(`${environment.apiUrls.proposalDraft}/delete`, deleteDraftObject);
  }

  private mapToProposalModel(item: ProposalDraftQueryResultModel) {
    if (!item || !item.content) {
      return null;
    }
    return JSON.parse(item.content) as Proposal;
  }
}
