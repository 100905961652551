<mat-card class="fd-owner-data-detail__card-container">
  <mat-card-title class="fd-owner-data-detail__card-title">Dados do Proprietário</mat-card-title>
  <ng-container>
    <div *ngFor="let owner of ownerData" class="fd-owner-data-detail__card-content" fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-owner-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>CPF</strong>
          <div>
            <span>{{ owner?.cpfCnpj | cpfCnpj }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Nome Completo</strong>
          <div>
            <span>{{ owner?.fullName }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Data de Nascimento</strong>
          <div>
            <span>{{ owner?.bornDate }}</span>
          </div>
        </div>
        <div fxFlex="5">
          <strong>PEP</strong>
          <div>
            <span>{{ owner?.isPep ? 'Sim' : 'Não' }}</span>
          </div>
        </div>
        <div fxFlex="15" *ngIf="owner?.percPartSoc">
          <strong>Participação na Empresa</strong>
          <div>
            <span>{{ owner?.percPartSoc | percent }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="owner?.nationality !== 'Brasileiro'">
          <strong>Nacionalidade</strong>
          <div>
            <span>{{ owner?.nationality }}</span>
          </div>
        </div>
        <div fxFlex="10" *ngIf="owner?.rne">
          <strong>RNE</strong>
          <div>
            <span>{{ owner?.rne }}</span>
          </div>
        </div>
        <div fxflexfill *ngIf="owner?.email">
          <strong>E-mail</strong>
          <div>
            <span>{{ owner?.email }}</span>
          </div>
        </div>
      </div>
    </div>
    <mat-card-title class="fd-owner-data-detail__card-title">Pessoa(s) de Contato</mat-card-title>
    <div *ngFor="let contact of contactPerson" fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout.xs="column" fxFlexFill class="fd-owner-data-detail__data_row" fxLayoutGap="10px">
        <div fxFlex="12">
          <strong>CPF</strong>
          <div>
            <span>{{ contact?.cpfCnpj | cpfCnpj }}</span>
          </div>
        </div>
        <div fxFlex="25">
          <strong>Nome Completo</strong>
          <div>
            <span>{{ contact?.fullName }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Telefone 1</strong>
          <div>
            <span>{{ contact?.phoneDDD | phoneMask: contact?.phone }}</span>
          </div>
        </div>
        <div fxFlex="15">
          <strong>Celular</strong>
          <div>
            <span>{{ contact?.cellphoneDDD | phoneMask: contact?.cellphone }}</span>
          </div>
        </div>
        <div fxflexfill>
          <strong>E-mail</strong>
          <div>
            <span>{{ contact?.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-card>
