import { Component, Input } from '@angular/core';
import { ProposalRejection } from '../../models/child-models/proposal-rejection.model';
import { RiskCreditNotes } from '../../models/child-models/risk-credit-notes.model';

@Component({
  selector: 'app-credit-risk-notes-detail',
  templateUrl: './credit-risk-notes-detail.component.html',
  styleUrls: ['./credit-risk-notes-detail.component.scss'],
})
export class CreditRiskNotesDetailComponent {

  @Input()
  public obsRiskCredit: RiskCreditNotes[];

  @Input()
  public proposalRejection: ProposalRejection[];

  constructor() { }
}
