import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckPrepaymentModel } from 'src/app/shared/models/response/response-check-prepayment.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class PrepaymentService {
  constructor(private httpClient: HttpClient) {}

  public callCheckIfHasPrepayment(campaignId: string, idRange: string, cnaeNumber: string): Observable<CheckPrepaymentModel> {
    return this.httpClient.get<CheckPrepaymentModel>(
      `${environment.apiUrls.validadeCheckPrepaymentRate}?cnae=${cnaeNumber}&id-campaign=${campaignId}&id-range=${idRange}`
    );
  }
}
